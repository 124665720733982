import React, { RefObject } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { CustomTagInputItem } from './CustomTagInputItem';

interface Props {
  inputValue: string;
  customTags: string[];
  isFocused: boolean;
  inputRef: RefObject<HTMLInputElement>;
  onSetIsFocused: (isFocused: boolean) => void;
  onSetShowResults: (showResults: boolean) => void;
  handleFocus(event: React.FocusEvent<HTMLInputElement, Element>): void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleRemoveTagAtIndex: (index: number) => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  register: UseFormReturn<PublishStatusAware<Proposal>>['register'];
}

export const CustomTagInput: React.FC<Props> = ({
  inputValue,
  customTags,
  isFocused,
  inputRef,
  onSetShowResults,
  onSetIsFocused,
  handleKeyDown,
  handleRemoveTagAtIndex,
  handleSearch,
  handleFocus,
  register,
}) => {
  const { formatMessage } = useIntl();

  function handleUnfocus() {
    onSetIsFocused(false);
  }

  function handleShowResults() {
    onSetShowResults(true);
  }

  return (
    <div
      className={`custom-tags__input__container ${
        isFocused ? 'custom-tags__input__container--focused' : ''
      }`}
      data-cy="custom-tags-search-bar"
    >
      {customTags?.map((item, index) => (
        <CustomTagInputItem
          key={index}
          value={item}
          onDelete={() => handleRemoveTagAtIndex(index)}
        />
      ))}
      <input
        type="text"
        className={`input custom-tags-search__input ${
          customTags?.length && 'u-width--auto'
        }`}
        placeholder={
          customTags?.length
            ? ''
            : formatMessage({
                id: 'inputs.custom-tags.placeholder',
              })
        }
        {...(register('customTags'),
        {
          onBlur: handleUnfocus,
          onChange: (event) => handleSearch(event),
          onClick: handleShowResults,
          onFocus: (event) => handleFocus(event),
          onKeyDown: (event) => handleKeyDown(event),
          ref: inputRef,
          value: inputValue,
        })}
      />
    </div>
  );
};
