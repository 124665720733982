interface RichTextHotkeys {
  [key: string]: RichTextVariant;
}

export const richTextHotkeys: RichTextHotkeys = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+shift+x': 'strikethrough',
  'mod+shift+8': 'ul',
  'mod+shift+7': 'ol',
  'mod+shift+9': 'blockquote',
  'mod+shift+l': 'link',
};
