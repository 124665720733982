import { clientSelector } from 'modules/clients';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFuseObjectSearch } from 'shared/hooks';
import { sortDescendingFromNewest } from 'shared/utils';
import { useProposalsSelector } from '../state';

export function useProposalGlobalSearch(query: string) {
  const proposals = useProposalsSelector();
  const { documents } = useSelector(clientSelector.getState);

  const filteredClients = useMemo(
    () =>
      [...useFuseObjectSearch(['name'], query, documents)].sort(
        sortDescendingFromNewest,
      ),
    [query, documents],
  );

  const filteredProposals = useMemo(
    () =>
      [...useFuseObjectSearch(['name', 'client.name'], query, proposals)].sort(
        sortDescendingFromNewest,
      ),
    [query, proposals],
  );

  return {
    proposals: filteredProposals,
    clients: filteredClients,
  };
}
