import { ClientButton } from 'modules/clients';
import { ServiceButton } from 'modules/services';
import { UnitButton } from 'modules/units';
import React, { PropsWithChildren } from 'react';
import { getDocumentWidth } from 'shared/utils';

interface Props extends PropsWithChildren {
  onButtonClick: VoidFunction;
  buttonType: SelectListButtonType;
  btnSelector?: string;
}

export const SelectList: React.FC<Props> = ({
  children,
  buttonType,
  onButtonClick,
  btnSelector,
}) => {
  const isMobile = getDocumentWidth() <= 760;

  const component = {
    client: <ClientButton onClick={onButtonClick} />,
    service: <ServiceButton onClick={onButtonClick} />,
    unit: <UnitButton onClick={onButtonClick} />,
  };

  return (
    <>
      {!isMobile && children}
      <div className="select__background" data-cy={btnSelector}>
        {component[buttonType]}
      </div>
      {isMobile && children}
    </>
  );
};
