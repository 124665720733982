import React, { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';

export const CompanyServicePricesVisibilityToggle: React.FC = () => {
  const { control } = useFormContext<Settings>();

  const defaultServicePricesVisibility = useWatch({
    name: 'defaultServicePricesVisibility',
  });

  const handleToggleServicePricesVisibility = useCallback(
    (
      field: ControllerRenderProps<Settings, 'defaultServicePricesVisibility'>,
    ) =>
      () => {
        field.onChange(
          defaultServicePricesVisibility === 'hidden' ? 'visible' : 'hidden',
        );
      },
    [defaultServicePricesVisibility],
  );

  return (
    <div className="f f--justify-between f--align-start g-32">
      <div className="f f--col g-4">
        <span className="text--xs t-text-1">Hide individual service cost </span>
        <span className="t-text-3 text--sm">
          Toggle to display or hide individual service prices in your proposal.
          When hidden, only the total price will be shown at the bottom.
        </span>
      </div>
      <div className="f f--align-center">
        <label className="toggle__label">
          <Controller
            control={control}
            name="defaultServicePricesVisibility"
            render={({ field }) => (
              <input
                {...field}
                type="checkbox"
                name="defaultServicePricesVisibility"
                className="toggle__input"
                checked={defaultServicePricesVisibility === 'hidden'}
                onChange={handleToggleServicePricesVisibility(field)}
              />
            )}
          />
          <span
            className="toggle__span"
            data-cy="company-service-prices-visibility-toggle"
          />
        </label>
      </div>
    </div>
  );
};
