import feedbackIcon from 'assets/icons/icon-feedback-12.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  className?: string;
}

export const LeaveFeedbackButton: React.FC<Props> = ({ className }) => {
  return (
    <a
      href="https://propoze.featurebase.app/"
      rel="noopener noreferrer"
      className={`dashboard__sidebar__footer__btn ${className || ''}`}
      target="_blank"
      data-cy="dashboard-sidebar-leave-feedback-btn"
    >
      <img
        src={feedbackIcon}
        alt="Feedback icon"
        className="btn__icon btn__icon__left"
      />
      <span className="text--sm t-text-3">
        <FormattedMessage id="buttons.leave-feedback" />
      </span>
    </a>
  );
};
