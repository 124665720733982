import React from 'react';

interface Props {
  client: Client | null;
}

export const ProposalOverviewListItemTitle: React.FC<Props> = ({ client }) => {
  return (
    <>
      {client && (
        <div className="proposal-overview-item__title__container">
          <p className="t-text-2 u-width--shorter proposal-item__client-name text--tny__responsive">
            {client.name}
          </p>
        </div>
      )}
    </>
  );
};
