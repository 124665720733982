import { useOrganizationIdSelector } from 'modules/authorization';
import React from 'react';
import { useModal } from 'shared/hooks';
import { useSubscriptionHelpers } from '../hooks';
import { FreeTrialLegacyNoticeModal } from './FreeTrialLegacyNoticeModal';

export const FreeTrialLegacyNoticeModalWrapper: React.FC = () => {
  const organizationId = useOrganizationIdSelector();
  const { isLegacyFreeUser, isTierOne, hasUsedFreeTrial } =
    useSubscriptionHelpers();
  const hideFreeTrialNoticeModalOrganizations = JSON.parse(
    localStorage.getItem('hideFreeTrialLegacyNoticeModal') || '[]',
  );
  const { isModalOpen, closeModal } = useModal(
    !hideFreeTrialNoticeModalOrganizations?.includes(organizationId),
  );

  function closeFreeTrialLegacyNoticeModal() {
    localStorage.setItem(
      'hideFreeTrialLegacyNoticeModal',
      JSON.stringify([
        ...hideFreeTrialNoticeModalOrganizations,
        organizationId,
      ]),
    );
    closeModal();
  }

  if (!isModalOpen || !isLegacyFreeUser || isTierOne || hasUsedFreeTrial) {
    return null;
  }

  return (
    <FreeTrialLegacyNoticeModal onClose={closeFreeTrialLegacyNoticeModal} />
  );
};
