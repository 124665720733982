import { motion } from 'framer-motion';
import React, { PropsWithChildren } from 'react';

export const MainMobile: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        type: 'spring',
        mass: 0.35,
        stiffness: 75,
        duration: 0.3,
        delay: 0.15,
      }}
    >
      {children}
    </motion.div>
  );
};
