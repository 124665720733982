import React from 'react';
import { PublicMediaSegmentList } from './fragments';

interface Props {
  item: MediaSegment;
  index: number;
}

export const PublicMediaSegmentCard: React.FC<Props> = ({ item, index }) => {
  return (
    <>
      {item?.files?.length > 0 && (
        <div className="proposal-block-file" id={`media-segment-${index}`}>
          <PublicMediaSegmentList item={item} />
        </div>
      )}
    </>
  );
};
