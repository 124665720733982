import { format } from 'date-fns';
import React from 'react';
import { TableHead } from 'shared/components';

export const UsersTable: React.FC<{ users: AnalyticsUser[] }> = ({ users }) => {
  return (
    <div className="table-wrapper">
      <table className="table table--fixed">
        <colgroup>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
        </colgroup>
        <thead>
          <tr>
            <TableHead label="ID" />
            <TableHead label="Email" />
            <TableHead label="Date created" />
            <TableHead label="Last login date" />
            <TableHead label="Proposals created" />
            <TableHead label="Last proposal created" />
            <TableHead label="# of published & draft last 7 days" />
            <TableHead label="# of published & draft last 30 days" />
            <TableHead label="# of published & draft last 120 days" />
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={`user-${user.userRecord?.uid}`}>
              <td>{user.userRecord?.uid}</td>
              <td>{user.userRecord?.email}</td>
              <td>
                {format(
                  new Date(user.userRecord?.metadata.creationTime),
                  'MMM d, yyyy h:mm a',
                )}
              </td>
              <td>
                {format(
                  new Date(user.userRecord?.metadata.lastSignInTime),
                  'MMM d, yyyy h:mm a',
                )}
              </td>
              <td>{user?.proposalsCreated}</td>
              <td>
                {format(
                  new Date(user?.lastProposalCreated || 0),
                  'MMMM dd, yyyy',
                )}
              </td>
              <td>{user?.publishedDraftLast7Days}</td>
              <td>{user?.publishedDraftLast30Days}</td>
              <td>{user?.publishedDraftLast120Days}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
