import leftIcon from 'assets/icons/icon-left-chevron-16.svg';
import rightIcon from 'assets/icons/icon-right-chevron-16.svg';
import React from 'react';
import { Button } from 'shared/components';

interface Props {
  pageNumber: number;
  numPages: number;
  changePage(offset: number): void;
}

export const MediaListItemPageControls: React.FC<Props> = ({
  pageNumber,
  numPages,
  changePage,
}) => {
  return (
    <div className="media-input__pdf--page-controls">
      <Button
        type="button"
        className="media-input__pdf--page-controls__btn"
        disabled={pageNumber <= 1}
        onClick={() => changePage(-1)}
      >
        <img src={leftIcon} alt="LeftIcon" />
      </Button>
      <span>
        {pageNumber} of {numPages}
      </span>
      <Button
        type="button"
        className="media-input__pdf--page-controls__btn"
        disabled={pageNumber >= numPages}
        onClick={() => changePage(1)}
      >
        <img src={rightIcon} alt="RightIcon" />
      </Button>
    </div>
  );
};
