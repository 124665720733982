import magnifierIcon from 'assets/icons/icon-magnifier-12.svg';
import { useProposalOverviewFilterOptions } from 'modules/proposals-overview';
import React, { useEffect } from 'react';
import { useMobile } from 'shared/hooks';
import { ProposalOverviewFilterSelect } from '../proposal-overview-filter-select';
import { ProposalOverviewAcceptanceStatusOptions } from './fragments/ProposalOverviewAcceptanceStatusOptions';
import { ProposalOverviewClientOptions } from './fragments/ProposalOverviewClientOptions';
import { ProposalOverviewCustomTagsOptions } from './fragments/ProposalOverviewCustomTagsOptions';
import { ProposalOverviewDateOptions } from './fragments/ProposalOverviewDateOptions';
import { ProposalOverviewProposalStatusOptions } from './fragments/ProposalOverviewProposalStatusOptions';
import { ProposalOverviewValidityStateOptions } from './fragments/ProposalOverviewValidityStateOptions';

interface Props {
  filterOption: FilterOption | null;
  onSetFilterOption?: (option: FilterOption | null) => void;
  onSetIsCalendarOpen?: (isOpen: boolean) => void;
}

export const ProposalOverviewFilterOptions: React.FC<Props> = ({
  filterOption,
  onSetFilterOption,
  onSetIsCalendarOpen,
}) => {
  const isMobile = useMobile();
  const {
    query,
    filteredClients,
    filteredCustomTags,
    filteredAcceptanceStatus,
    filteredProposalStatus,
    filteredValidityState,
    handleSearch,
    getPlaceholder,
  } = useProposalOverviewFilterOptions();

  useEffect(() => {
    return () => {
      if (!isMobile) onSetFilterOption?.(null);
    };
  }, []);

  if (filterOption === 'date') {
    return (
      <ProposalOverviewDateOptions onSetIsCalendarOpen={onSetIsCalendarOpen} />
    );
  }

  if (!filterOption) {
    return (
      <ProposalOverviewFilterSelect onSetFilterOption={onSetFilterOption} />
    );
  }

  return (
    <>
      <div className="u-pos--rel">
        <input
          className="input proposal-overview__search__input"
          type="text"
          data-cy="proposal-overview-search-bar"
          placeholder={getPlaceholder(filterOption)}
          value={query}
          autoFocus={isMobile ? false : true}
          onChange={handleSearch}
          onClick={() => onSetIsCalendarOpen?.(false)}
        />
        <img
          src={magnifierIcon}
          alt="MagnifierIcon"
          className="proposal-overview__search__input__icon"
        />
      </div>

      {filterOption === 'client' && (
        <ProposalOverviewClientOptions clients={filteredClients} />
      )}
      {filterOption === 'tags' && (
        <ProposalOverviewCustomTagsOptions customTags={filteredCustomTags} />
      )}
      {filterOption === 'proposal-status' && (
        <ProposalOverviewProposalStatusOptions
          proposalStatus={filteredProposalStatus}
        />
      )}
      {filterOption === 'acceptance' && (
        <ProposalOverviewAcceptanceStatusOptions
          acceptanceStatus={filteredAcceptanceStatus}
        />
      )}
      {filterOption === 'validity' && (
        <ProposalOverviewValidityStateOptions
          validityState={filteredValidityState}
        />
      )}
    </>
  );
};
