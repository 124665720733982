import eyeIconUnhidden from 'assets/icons/icon-eye-12-crossed.svg';
import eyeIconHidden from 'assets/icons/icon-eye-12.svg';
import { useScrollIntoView } from 'modules/authentication';
import { useChangePassword } from 'modules/settings';
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  FieldWrapper,
  LoadingSpinner,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  passwordRegex,
} from 'shared/components';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';

export const ChangePasswordModal: React.FC<ModalProps> = ({ onClose }) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { form, isLoading, onSubmit } = useChangePassword(onClose);
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = form;

  function toggleCurrentPasswordVisibility() {
    setShowCurrentPassword(!showCurrentPassword);
  }

  function togglePasswordVisibility() {
    setShowNewPassword(!showNewPassword);
  }

  function toggleConfirmPasswordVisibility() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <Modal isDashboardModal close={onClose}>
      <FormProvider {...form}>
        <ModalHeader>
          <p className="text--xl__responsive">
            <FormattedMessage id="account-settings.section.change-password.action" />
          </p>
        </ModalHeader>
        <ModalContent>
          <div className="field">
            <FieldWrapper
              name="currentPassword"
              labelId="inputs.change-password.current-password.label"
              errors={errors}
              isRequired
            >
              <div className="auth__eye__container">
                <input
                  type={showCurrentPassword ? 'text' : 'password'}
                  className={`input input__box input__med ${
                    errors.currentPassword ? 'input__box__error' : ''
                  }`}
                  placeholder={formatMessage({
                    id: 'inputs.password.placeholder',
                  })}
                  data-cy="form-current-password-input"
                  onFocus={handleFocus}
                  autoComplete="password"
                  {...register('currentPassword', {
                    required: formatMessage({ id: 'inputs.password.required' }),
                    pattern: {
                      value: passwordRegex,
                      message: formatMessage({ id: 'inputs.password.invalid' }),
                    },
                  })}
                />
                <div
                  onClick={toggleCurrentPasswordVisibility}
                  className="auth__eye"
                >
                  {showCurrentPassword ? (
                    <img src={eyeIconUnhidden} alt="eyeIconUnhidden" />
                  ) : (
                    <img src={eyeIconHidden} alt="eyeIconHidden" />
                  )}
                </div>
              </div>
            </FieldWrapper>
          </div>

          <div className="field">
            <FieldWrapper
              name="newPassword"
              labelId="inputs.change-password.new-password.label"
              errors={errors}
              isRequired
            >
              <div className="auth__eye__container">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  className={`input input__box input__med ${
                    errors.newPassword ? 'input__box__error' : ''
                  }`}
                  placeholder={formatMessage({
                    id: 'inputs.password.placeholder',
                  })}
                  data-cy="form-new-password-input"
                  onFocus={handleFocus}
                  autoComplete="password"
                  {...register('newPassword', {
                    required: formatMessage({ id: 'inputs.password.required' }),
                    pattern: {
                      value: passwordRegex,
                      message: formatMessage({ id: 'inputs.password.invalid' }),
                    },
                  })}
                />
                <div onClick={togglePasswordVisibility} className="auth__eye">
                  {showNewPassword ? (
                    <img src={eyeIconUnhidden} alt="eyeIconUnhidden" />
                  ) : (
                    <img src={eyeIconHidden} alt="eyeIconHidden" />
                  )}
                </div>
              </div>
            </FieldWrapper>
          </div>

          <FieldWrapper
            name="confirmPassword"
            labelId="inputs.change-password.confirm-password.label"
            errors={errors}
            isRequired
          >
            <div className="auth__eye__container">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                className={`input input__box input__med ${
                  errors.confirmPassword ? 'input__box__error' : ''
                }`}
                placeholder={formatMessage({
                  id: 'inputs.password.placeholder',
                })}
                data-cy="form-confirm-password-input"
                onFocus={handleFocus}
                autoComplete="password"
                {...register('confirmPassword', {
                  required: formatMessage({ id: 'inputs.password.required' }),
                  pattern: {
                    value: passwordRegex,
                    message: formatMessage({ id: 'inputs.password.invalid' }),
                  },
                })}
              />
              <div
                onClick={toggleConfirmPasswordVisibility}
                className="auth__eye"
              >
                {showConfirmPassword ? (
                  <img src={eyeIconUnhidden} alt="eyeIconUnhidden" />
                ) : (
                  <img src={eyeIconHidden} alt="eyeIconHidden" />
                )}
              </div>
            </div>
          </FieldWrapper>
        </ModalContent>

        <ModalFooter>
          <div className="btn-group btn-group--simple">
            <Button
              size="lrg"
              style="outline"
              onClick={onClose}
              ref={closeButtonRef}
              btnSelector="cancel-btn"
            >
              <FormattedMessage id="buttons.cancel" />
            </Button>
            {isLoading ? (
              <Button
                type="button"
                disabled={isLoading}
                size="lrg"
                className="u--width--full--mobile"
                style="primary"
              >
                <LoadingSpinner height={12} width={12} type="negative" />
                <p className="s-left--med">
                  <FormattedMessage id="buttons.saving" />
                </p>
              </Button>
            ) : (
              <Button
                value="Save"
                size="lrg"
                type="submit"
                style="primary"
                className="u--width--full--mobile"
                btnSelector="company-settings-save-btn"
                onClick={handleSubmit(onSubmit)}
                ref={submitButtonRef}
              >
                <FormattedMessage id="buttons.save" />
              </Button>
            )}
          </div>
        </ModalFooter>
      </FormProvider>
    </Modal>
  );
};
