export const defaultProposalStyle: ProposalStyle = {
  color: {
    proposalTitle: '#3B3B3B',
    headings: '#242424',
    subheadings: '#706F6F',
    cardTitle: '#706F6F',
    bodyText: '#706F6F',
    price: '#706F6F',
    subtotalPrice: '#242424',
    cardBackground: '#FFF',
    background: '#F8F8F8',
    borders: '#d9d9d9',
    accentColor: '#8039FF',
    buttonSecondary: '#8039FF',
  },
  typography: {
    heading: {
      fontFamily: 'Aeonik',
      fontWeight: '400',
    },
    body: {
      fontFamily: 'Aeonik',
      fontWeight: '400',
    },
  },
};

export const darkProposalStyle: ProposalStyle = {
  color: {
    proposalTitle: '#FCFCFC',
    headings: '#FCFCFC',
    subheadings: '#ACACAC',
    cardTitle: '#FCFCFC',
    bodyText: '#ACACAC',
    price: '#8A8A8A',
    subtotalPrice: '#FCFCFC',
    cardBackground: '#353535',
    background: '#282828',
    borders: '#494949',
    accentColor: '#333333',
    buttonSecondary: '#E0E0E0',
  },
  typography: {
    heading: {
      fontFamily: 'Aeonik',
      fontWeight: '400',
    },
    body: {
      fontFamily: 'Aeonik',
      fontWeight: '400',
    },
  },
};

export const defaultCustomPresets: CustomPreset[] = [
  {
    title: 'Default',
    color: defaultProposalStyle.color,
    typography: defaultProposalStyle.typography,
  },
  {
    title: 'Dark',
    color: darkProposalStyle.color,
    typography: darkProposalStyle.typography,
  },
];
