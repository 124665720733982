import 'css/app.css';
import { GatsbyBrowser } from 'gatsby';
import { adminReducer } from 'modules/admin';
import { authenticationReducer } from 'modules/authentication';
import { authorizationReducer } from 'modules/authorization';
import { clientReducer } from 'modules/clients';
import { dashboardReducer } from 'modules/dashboard';
import { createReduxStore } from 'modules/redux-store';
import { serviceReducer } from 'modules/services';
import { settingsReducer } from 'modules/settings';
import { unitReducer } from 'modules/units';
import 'normalize.css';
import React from 'react';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { ReducersMapObject } from 'redux';
import { ToastWrapper } from 'shared/components';
import {
  FirebaseAuthProvider,
  HistoryProvider,
  IntlProvider,
  NetworkStatusProvider,
} from 'shared/providers';

const rootReducer: ReducersMapObject<AppState> = {
  admin: adminReducer,
  auth: authenticationReducer,
  authorization: authorizationReducer,
  settings: settingsReducer,
  clients: clientReducer,
  units: unitReducer,
  services: serviceReducer,
  dashboard: dashboardReducer,
};

/**
 *
 * @param element root node element
 * @description Used for wrapping the root node with all of the providers that should be available in the app and avoid remounting
 */
export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => (
  <Provider store={createReduxStore(rootReducer)}>
    <RecoilRoot>
      <HistoryProvider>
        <IntlProvider>
          <FirebaseAuthProvider>
            <NetworkStatusProvider>
              <ToastWrapper />
              {element}
            </NetworkStatusProvider>
          </FirebaseAuthProvider>
        </IntlProvider>
      </HistoryProvider>
    </RecoilRoot>
  </Provider>
);

declare global {
  interface Window {
    [key: string]: boolean;
  }
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  if (location.pathname.startsWith('/s/')) {
    window[`ga-disable-${process.env.GATSBY_GA_ID}`] = true;
  } else {
    window[`ga-disable-${process.env.GATSBY_GA_ID}`] = false;
  }
};
