import closeIcon from 'assets/icons/icon-close.svg';
import retryIcon from 'assets/icons/icon-retry-16.svg';
import uploadedIcon from 'assets/icons/icon-uploaded-file-16.svg';
import warningIcon from 'assets/icons/icon-warning-file-16.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { getFileSize } from 'shared/utils';

interface Props {
  proposalFiles: ProposalFile[];
  fileErrors?: Record<string, string>;
  onRemoveFile: (file: ProposalFile) => void;
  onRemoveFileError: (fileName: string) => void;
}

export const FileAttachmentInputList: React.FC<Props> = ({
  proposalFiles,
  fileErrors,
  onRemoveFile,
  onRemoveFileError,
}) => {
  return (
    <ul className="file-input__uploaded__list">
      {proposalFiles.map((file, index) => (
        <li key={index} className="file-input__uploaded__list-item__container">
          <div className="file-input__uploaded__list-item__information__container t-ellipsis">
            <img
              className="file-input__uploadedmessage__img"
              src={uploadedIcon}
              alt="UploadedIcon"
            />
            <div className="f f--col t-ellipsis">
              <span className="t-text-1 text--xs__responsive">{file.name}</span>
              <span className="t-text-2 text--xxs__responsive t-ellipsis">
                {getFileSize(file.size)}
              </span>
            </div>
          </div>

          <Button
            className="file-input__uploadedmessage__close--btn"
            onClick={() => onRemoveFile(file)}
          >
            <img src={closeIcon} alt="CloseIcon" />
          </Button>
        </li>
      ))}

      {fileErrors &&
        Object.keys(fileErrors).map((fileName, index) => (
          <li
            key={index}
            className="file-input__uploaded__list-item__container file-input__uploaded__list-item__container--error"
          >
            <div className="file-input__uploaded__list-item__information__container t-ellipsis">
              <img
                className="file-input__uploadedmessage__img"
                src={warningIcon}
                alt="WarningIcon"
              />
              <div className="f f--col t-ellipsis">
                <span className="t-text-1 text--xs__responsive">
                  {fileName}
                </span>
                <span className="t-label--error text--xxs__responsive t-ellipsis">
                  {fileErrors[fileName]}
                </span>
              </div>
            </div>

            {fileErrors[fileName].includes('Upload failed') && (
              <>
                <Button
                  type="button"
                  style="primary"
                  size="med"
                  className="u-fit--content d--desktop--only"
                  btnSelector="upload-files-error-btn"
                >
                  <img
                    className="file-input__uploadedmessage__img"
                    src={retryIcon}
                    alt="RetryIcon"
                  />
                  <span className="s-left--sml">
                    <FormattedMessage id="uploaders.file.error-cta" />
                  </span>
                </Button>
                <Button
                  type="button"
                  style="primary"
                  size="med"
                  className="u-fit--content d--mobile--only file-input__uploadedmessage__retry--btn"
                  btnSelector="upload-files-error-btn"
                >
                  <img
                    className="file-input__uploadedmessage__img"
                    src={retryIcon}
                    alt="RetryIcon"
                  />
                </Button>
              </>
            )}

            <Button
              className="file-input__uploadedmessage__close--btn"
              onClick={() => onRemoveFileError(fileName)}
            >
              <img src={closeIcon} alt="CloseIcon" />
            </Button>
          </li>
        ))}
    </ul>
  );
};
