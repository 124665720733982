import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  description?: string;
  href?: string;
}

export const SEO: React.FC<Props> = ({ title, description, href }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta property="og:title" content={title} />
        <meta property="og:image" content="/og-image.jpg" />
        <meta name="image" content="/og-image.jpg" />
        <meta name="image:alt" content="Open Graph Image" />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content="/og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {description && (
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
      )}

      {href && (
        <Helmet>
          <link rel="canonical" href={href} />
          <link rel="og:url" href={href} />
          <link rel="twitter:url" href={href} />
          <link rel="prerender" href="<page url>" />
        </Helmet>
      )}
    </>
  );
};
