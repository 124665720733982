import { motion } from 'framer-motion';
import {
  demoProposal,
  isFileMediaElementVisible,
  isFileSegment,
  isHeadingSegment,
  isHeadingSegmentVisible,
  isMediaSegment,
  isServiceSegment,
  isServiceSegmentVisible,
  isTextSegment,
  isTextSegmentVisible,
} from 'modules/proposals';
import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableOfContentsFreemiumList } from './TableOfContentsFreemiumList';
import { TableOfContentsFileIcon } from './fragments/TableOfContentsFileIcon';
import { TableOfContentsListItem } from './fragments/TableOfContentsListItem';
import { TableOfContentsMediaIcon } from './fragments/TableOfContentsMediaIcon';
import { TableOfContentsRichTextIcon } from './fragments/TableOfContentsRichTextIcon';
const variants = {
  initial: { opacity: 0, y: -8 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -8 },
};

interface Props {
  proposal?: PublishStatusAware<Proposal> | Proposal;
  isPreview?: boolean;
  isSubscribed?: boolean;
  scrollToActiveSection: (index: number, type: ProposalSegment['type']) => void;
  isActiveSection: (index: number, type: ProposalSegment['type']) => boolean;
}

export const TableOfContentsList = forwardRef<HTMLUListElement, Props>(
  (
    {
      proposal,
      isPreview,
      isSubscribed,
      scrollToActiveSection,
      isActiveSection,
    },
    ref,
  ) => {
    if (!isSubscribed) {
      return (
        <TableOfContentsFreemiumList
          ref={ref}
          isPreview={isPreview}
          proposal={demoProposal}
        />
      );
    }

    return (
      <motion.ul
        initial="initial"
        animate="enter"
        exit="exit"
        variants={variants}
        transition={{ duration: 0.15 }}
        ref={ref}
        className="toc__list"
      >
        {proposal?.segments?.map((segment, index) => (
          <React.Fragment key={index}>
            {isHeadingSegment(segment) &&
              isHeadingSegmentVisible(segment, isPreview) && (
                <TableOfContentsListItem
                  index={index}
                  type="heading"
                  className={`t-bold toc__heading s-bottom--tny text--sm ${
                    isActiveSection(index, 'heading') && 'toc__active__section'
                  }`}
                  tabIndex={0}
                  scrollToActiveSection={scrollToActiveSection}
                >
                  <span>{segment?.title || 'Heading'}</span>
                </TableOfContentsListItem>
              )}
            {isServiceSegment(segment) &&
              isServiceSegmentVisible(segment, isPreview) && (
                <TableOfContentsListItem
                  index={index}
                  type="service"
                  className="toc__sidebar__item__container"
                  tabIndex={0}
                  scrollToActiveSection={scrollToActiveSection}
                >
                  <div className="toc__sidebar__item__divider" />
                  <div
                    className={`toc__sidebar__item ${
                      isActiveSection(index, 'service') &&
                      'toc__active__section'
                    }`}
                  >
                    <span className="t-label">
                      <FormattedMessage id="table-of-contents.service" />
                    </span>
                    <p className="text--sm t-text-2 t-ellipsis">
                      {segment?.service?.name || 'Service content'}
                    </p>
                  </div>
                </TableOfContentsListItem>
              )}
            {isTextSegment(segment) &&
              isTextSegmentVisible(segment, isPreview) && (
                <TableOfContentsListItem
                  index={index}
                  type="text"
                  className="toc__sidebar__item__container toc__stroke__segment"
                  tabIndex={0}
                  scrollToActiveSection={scrollToActiveSection}
                >
                  <div className="toc__sidebar__item__divider" />
                  <div
                    className={`toc__sidebar__attachment ${
                      isActiveSection(index, 'text') &&
                      'toc__active__section toc__active__section--stroke'
                    }`}
                  >
                    <TableOfContentsRichTextIcon />
                    <span className="text--sm btn__icon__right">
                      <FormattedMessage id="table-of-contents.rich-text" />
                    </span>
                  </div>
                </TableOfContentsListItem>
              )}
            {isFileSegment(segment) &&
              isFileMediaElementVisible(segment, isPreview) && (
                <TableOfContentsListItem
                  index={index}
                  type="file"
                  className="toc__sidebar__item__container toc__stroke__segment"
                  tabIndex={0}
                  scrollToActiveSection={scrollToActiveSection}
                >
                  <div className="toc__sidebar__item__divider" />
                  <div
                    className={`toc__sidebar__attachment ${
                      isActiveSection(index, 'file') &&
                      'toc__active__section toc__active__section--stroke'
                    }`}
                  >
                    <TableOfContentsFileIcon />
                    <span className="text--sm t-text-2 btn__icon__right">
                      {segment?.files?.length > 1 ? (
                        <FormattedMessage id="table-of-contents.file-multiple" />
                      ) : (
                        <FormattedMessage id="table-of-contents.file-1" />
                      )}
                    </span>
                  </div>
                </TableOfContentsListItem>
              )}
            {isMediaSegment(segment) &&
              isFileMediaElementVisible(segment, isPreview) && (
                <TableOfContentsListItem
                  index={index}
                  type="media"
                  className="toc__sidebar__item__container toc__fill__segment"
                  tabIndex={0}
                  scrollToActiveSection={scrollToActiveSection}
                >
                  <div className="toc__sidebar__item__divider" />
                  <div
                    className={`toc__sidebar__attachment ${
                      isActiveSection(index, 'media') &&
                      'toc__active__section toc__active__section--fill'
                    }`}
                  >
                    <TableOfContentsMediaIcon />
                    <span className="text--sm t-text-2 btn__icon__right">
                      {segment?.files?.length > 1 ? (
                        <FormattedMessage id="table-of-contents.media-multiple" />
                      ) : (
                        <FormattedMessage id="table-of-contents.media-1" />
                      )}
                    </span>
                  </div>
                </TableOfContentsListItem>
              )}
          </React.Fragment>
        ))}
      </motion.ul>
    );
  },
);
