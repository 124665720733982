import React from 'react';
import { CreateFromTemplateButton } from '../../item-actions/fragments';
import { SharedButtonsClassNames } from '../SharedButtonsClassNames';
import { PreviewButton } from '../preview-button';

interface Props extends SharedButtonsClassNames {
  isEditorActions?: boolean;
  isItemActions?: boolean;
}

export const TemplateSharedButtons: React.FC<Props> = ({
  previewClassName,
  isEditorActions,
  isItemActions,
}) => {
  if (isEditorActions) {
    return (
      <div className="actionbar__shared__buttons">
        <PreviewButton
          className={`${previewClassName} ${
            isEditorActions ? 'actionbar__editor__preview' : ''
          }`}
          btnSelector="template-page-preview-btn"
          isEditorActions={isEditorActions}
        />
      </div>
    );
  }
  if (isItemActions) {
    return (
      <>
        <CreateFromTemplateButton
          className="btn btn__primary btn__med"
          btnSelector="template-page-create-from-btn"
        />
        <PreviewButton
          className={previewClassName}
          btnSelector="template-page-preview-btn"
        />
      </>
    );
  }
  return (
    <PreviewButton
      className={previewClassName}
      btnSelector="template-page-preview-btn"
    />
  );
};
