import { useIntl } from 'react-intl';
import { VALIDATORS } from 'shared/utils';

export function useNotifyOwnerTimestamp(
  email: string,
  functionToCall: () => void,
  onSetError: (error: string) => void,
) {
  const { formatMessage } = useIntl();

  function handleSubmit() {
    /** If email is invalid, don't insert it. */
    if (!VALIDATORS.email.test(email)) {
      onSetError(formatMessage({ id: 'proposal-expired.action.error' }));
      return;
    }

    functionToCall();
    return;
  }

  return { handleSubmit };
}
