import {
  Functions,
  httpsCallable,
  HttpsCallableResult,
} from 'firebase/functions';
import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { functions } from '../initFirebase';
import { firebaseAtoms } from '../state';

type DefaultResponseType<T> = Omit<HttpsCallableResult, 'data'> & {
  data: T;
};

type CallableResultType<Params, SuccessType, ErrorType> =
  Params extends undefined
    ? () =>
        | Promise<DefaultResponseType<CallableSuccessResult<SuccessType>>>
        | Promise<DefaultResponseType<ErrorType | CallableErrorResult>>
    : (
        data: Params,
      ) =>
        | Promise<DefaultResponseType<CallableSuccessResult<SuccessType>>>
        | Promise<DefaultResponseType<ErrorType | CallableErrorResult>>;

export const useFunction = <
  Params = undefined,
  SuccessType = CallableSuccessResult<unknown>,
  ErrorType = CallableErrorResult,
>(
  functionName: FirebaseFunctions,
): CallableResultType<Params, SuccessType, ErrorType> => {
  const [callables, setCallables] = useRecoilState(firebaseAtoms.callables);
  const functionsReference: Functions = useMemo(() => functions, []);

  useEffect(() => {
    if (!functionsReference || callables[functionName]) return;

    setCallables({
      ...callables,
      [functionName]: httpsCallable(functionsReference, functionName),
    });
  }, [callables, functionName, functionsReference]);

  const callable = useMemo(() => {
    if (callables[functionName]) return callables[functionName];
    return httpsCallable(functionsReference, functionName);
  }, [functionName, functionsReference, callables]) as CallableResultType<
    Params,
    SuccessType,
    ErrorType
  >;

  return callable;
};
