import warningIconSpinner from 'assets/icons/icon-loading-spinner-12-red.svg';
import negativeIconSpinner from 'assets/icons/icon-loading-spinner-12-white.svg';
import primaryIconSpinner from 'assets/icons/icon-loading-spinner-12.svg';
import { motion } from 'framer-motion';
import React from 'react';

interface Props {
  height?: string | number;
  width?: string | number;
  type: LoadingSpinnerTypes;
}

export const LoadingSpinner: React.FC<Props> = ({ type, height, width }) => (
  <motion.div
    initial={{ rotate: 0 }}
    animate={{ rotate: 359 }}
    transition={{ repeat: Infinity, duration: 1, ease: 'linear' }}
    className="f f--center"
  >
    {type === 'primary' && (
      <img
        src={primaryIconSpinner}
        alt="PrimaryIconSpinner"
        style={{ height: height || 40, width: width || height || 40 }}
      />
    )}

    {type === 'negative' && (
      <img
        src={negativeIconSpinner}
        alt="NegativeIconSpinner"
        className="btn__icon"
        style={{ height: height || 40, width: width || height || 40 }}
      />
    )}

    {type === 'warning' && (
      <img
        src={warningIconSpinner}
        alt="WarningIconSpinner"
        style={{ height: height || 40, width: width || height || 40 }}
      />
    )}
  </motion.div>
);
