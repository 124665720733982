import { useFunction } from 'modules/firebase';

export function useResendEmailVerificationLink() {
  const resendEmailVerificationLinkCallable =
    useFunction<ResendEmailVerificationLinkData>('resendEmailVerificationLink');

  async function resendEmailVerificationLink(
    params: ResendEmailVerificationLinkData,
  ) {
    if (!resendEmailVerificationLinkCallable) {
      throw new Error('Unreachable function');
    }
    const { data } = await resendEmailVerificationLinkCallable(params);
    if (!data.success) throw new Error(data.message);
  }

  return {
    resendEmailVerificationLink,
  };
}
