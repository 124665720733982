import { useParams } from '@reach/router';
import returnToDraftBtn from 'assets/icons/icon-reset-12.svg';
import { useFirestoreDocument } from 'modules/firebase';
import { confirmationModalOptions } from 'modules/proposals';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ConfirmationModal } from 'shared/components';
import { useMobile, useModal } from 'shared/hooks';
import { toast } from 'sonner';

interface Props {
  className: string;
  proposal?: Proposal;
}

export const ReturnToDraftPreviewButton: React.FC<Props> = ({
  className,
  proposal,
}) => {
  const { organizationId = '' } = useParams() || {};
  const { isModalOpen, closeModal, openModal } = useModal();
  const isMobile = useMobile();

  const { set } = useFirestoreDocument<PublishStatusAware<Proposal>>(
    `${organizationId}/proposals/entities/${proposal?.id}`,
  );

  async function handleReturnToDraft() {
    try {
      await set({
        nextPublishStatus: 'draft',
        publishStatus: 'draft',
      });
      toast.success(
        `Proposal ${proposal?.name} has successfully been updated.`,
      );
      closeModal();
    } catch {
      toast.error('Something went wrong. Please try again.');
    }
  }

  return (
    <>
      <Button
        className={`${className} btn__icon__fill`}
        onClick={openModal}
        btnSelector="return-to-draft-btn"
        type="button"
      >
        {isMobile ? (
          <img
            src={returnToDraftBtn}
            alt="ReturnToDraftIcon"
            className="btn__icon btn__icon__left"
          />
        ) : (
          <>
            <img
              src={returnToDraftBtn}
              alt="ReturnToDraftIcon"
              className="btn__icon btn__icon__left"
            />
            <FormattedMessage id="buttons.draft-return" />
          </>
        )}
      </Button>
      {isModalOpen && (
        <ConfirmationModal
          onClose={closeModal}
          onSubmit={handleReturnToDraft}
          title={confirmationModalOptions['archived']['draft']?.title || ''}
          subtitle={
            confirmationModalOptions['archived']['draft']?.subtitle || ''
          }
          confirmationButtonLabel={
            confirmationModalOptions['archived']['draft']
              ?.confirmationButtonLabel || ''
          }
          declineButtonLabel={
            confirmationModalOptions['archived']['draft']?.declineButtonLabel ||
            ''
          }
          type="confirmation"
        />
      )}
    </>
  );
};
