import { sortAZ } from './sortAZ';
import { sortAscendingFromOldest } from './sortAscendingFromOldest';
import { sortDescendingFromNewest } from './sortDescendingFromNewest';
import { sortZA } from './sortZA';

export function applySort<
  T extends { name?: string; createdAt?: number; creationDateTime?: number },
>(sortOption: SortOption, items: T[]) {
  switch (sortOption) {
    case 'newest':
      return [...items].sort(sortDescendingFromNewest);
    case 'oldest':
      return [...items].sort(sortAscendingFromOldest);
    case 'ascending':
      return [...items].sort(sortAZ);
    case 'descending':
      return [...items].sort(sortZA);
    default:
      return items;
  }
}
