export interface Service {
  id?: string;
  name: string;
  createdAt: number;
}

export class Service {
  constructor(data?: Service) {
    this.id = data?.id;
    this.name = data?.name ?? '';
    this.createdAt = data?.createdAt || 1609455600000; // 2021-01-01 fallback for services created before createdAt was introduced
  }
}
