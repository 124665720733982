import { useFunction } from 'modules/firebase';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import { adminAction } from '../redux';

export const useProposalStats = () => {
  const dispatch = useDispatch();
  const getStatsDataForDate = useFunction<
    number,
    ProposalCounter<number> | null
  >('getStatsDataForDate');

  async function fetchStatsForDate(statsDateTime: number) {
    try {
      if (!getStatsDataForDate) {
        throw new Error('getStatsDataForDate is not defined');
      }
      dispatch(adminAction.loading());
      const { data } = await getStatsDataForDate(statsDateTime);
      if (!data.success || !data.payload) return;

      dispatch(adminAction.addProposalStatsData(data.payload));
    } catch (error) {
      adminAction.error(error);
      toast.error('Failed to fetch stats for date. Please try again.');
    }
  }

  return { fetchStatsForDate };
};
