import { clientSelector } from 'modules/clients';
import { useProposalCustomTagsSelector } from 'modules/proposals';
import { subscriptionSelectors } from 'modules/subscription';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { useFuseObjectSearch, useFuseStringSearch } from 'shared/hooks';
import { filterOptions, publishStatusLabels } from '../const';

export function useProposalOverviewFilteredOptions(query: string) {
  const { formatMessage } = useIntl();

  const customTags = useProposalCustomTagsSelector();
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const { documents } = useSelector(clientSelector.getState);

  /**Filtered values by search */
  const filteredClients = useMemo(
    () => useFuseObjectSearch(['name'], query, documents),
    [documents, query],
  );

  const filteredFilterOptions = useMemo(() => {
    return useFuseStringSearch<FilterOption>(
      query,
      isTierOne
        ? filterOptions
        : filterOptions.filter((option) => option !== 'tags'),
    );
  }, [query, filterOptions]);

  const filteredCustomTags = useMemo(
    () => useFuseStringSearch(query, customTags),
    [customTags, query],
  );

  const filteredProposalStatus = useMemo(() => {
    const statusLabelPairs = publishStatusLabels.map((status) => ({
      status,
      label: formatMessage({ id: `proposals.${status}.title` }),
    }));
    const filteredStatusLabelPairsFuse = useFuseObjectSearch(
      ['label'],
      query,
      statusLabelPairs,
    );

    return {
      labels: filteredStatusLabelPairsFuse.map((pair) => pair.label),
      statuses: filteredStatusLabelPairsFuse.map((pair) => pair.status),
    };
  }, [query, formatMessage]);

  const filteredAcceptanceStatus = useMemo(
    () => useFuseStringSearch(query, ['pending', 'accepted', 'rejected']),
    [query],
  );

  const filteredValidityState = useMemo(
    () => useFuseStringSearch(query, ['active', 'expired']),
    [query],
  );

  return {
    filteredClients,
    filteredFilterOptions,
    filteredCustomTags,
    filteredProposalStatus,
    filteredAcceptanceStatus,
    filteredValidityState,
  };
}
