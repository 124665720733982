import { ProposalAcceptanceStatus, proposalsAtoms } from 'modules/proposals';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Button, LoadingSpinner } from 'shared/components';
import { ProposalAcceptanceModal } from './fragments';

interface Props {
  proposalId: string;
  organizationId: string;
  /** Is the user authorized already */
  authorized: boolean;
  status: Proposal['acceptanceStatus'];
  acceptanceDateTime: Proposal['acceptanceDateTime'];
  responseStatus: Proposal['responseStatus'];
  buttonColor: string;
}

export const ProposalAcceptanceController: React.FC<Props> = ({
  proposalId,
  status,
  acceptanceDateTime,
  responseStatus,
  ...rest
}) => {
  const accessLoading = useRecoilValue(proposalsAtoms.accessLoading);
  const [isModalVisible, setIsModalVisible] = useState(false);
  if (status !== 'pending') {
    return (
      <ProposalAcceptanceStatus
        status={status}
        acceptanceDateTime={acceptanceDateTime}
      />
    );
  }

  if (responseStatus === 'disallowed') return null;

  return (
    <>
      <div
        className="proposal-acceptance-controller"
        data-cy="proposal-accepance-status-respond"
      >
        <div
          className="proposal-acceptance-controller__heading"
          data-cy="proposal-acceptance-status-heading-pending"
        >
          <p className="t-text-1 t-bold text--xxs__responsive">
            <FormattedMessage id="proposal-acceptance-controller.heading" />
          </p>
          <p className="t-text-2 text--xxs__responsive">
            <FormattedMessage id="proposal-acceptance-controller.subheading" />
          </p>
        </div>

        {accessLoading ? (
          <LoadingSpinner type="primary" height={32} />
        ) : (
          <Button
            type="button"
            style="primary"
            size="lrg"
            className="u--width--full--mobile"
            data-cy="acceptance-respond-btn"
            btnSelector="acceptance-respond-btn"
            onClick={() => setIsModalVisible(true)}
          >
            <FormattedMessage id="proposal-acceptance-controller.action" />
          </Button>
        )}
      </div>

      {isModalVisible && (
        <ProposalAcceptanceModal
          {...rest}
          proposalId={proposalId}
          close={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};
