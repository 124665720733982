import { Service } from 'modules/services';
import { Unit } from 'modules/units';
import { isServiceSegment } from '../type-guards';
import { hasServiceChanged } from './hasServiceChanged';
import { hasUnitChanged } from './hasUnitChanged';

interface UpdateProposalData {
  data: PublishStatusAware<Proposal>;
  services?: Service[];
  units?: Unit[];
}

export function getUpdatedProposal({
  data,
  services,
  units,
}: UpdateProposalData) {
  data.segments.forEach((segment) => {
    if (!isServiceSegment(segment)) return;
    const { unit, service } = segment;

    const serviceMatch = services?.find(
      (s: Service) => s?.id === service?.id || s?.name === service?.name,
    );
    if (serviceMatch && hasServiceChanged(serviceMatch, service)) {
      segment.service = { ...new Service(serviceMatch) };
    }

    const unitMatch = units?.find(
      (u: Unit) => u.id === unit?.id || u.name === unit?.name,
    );
    if (unitMatch && hasUnitChanged(unitMatch, unit)) {
      segment.unit = { ...new Unit(unitMatch) };
    }
  });

  return data;
}
