import { useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { areUnitArraysEqual } from '../utils';

interface FormValues {
  units?: Unit[];
}

export function useUnitsOrdering(documents?: Unit[]) {
  const { control, reset, getValues } = useFormContext<FormValues>();

  const { fields, move } = useFieldArray({
    control,
    name: 'units',
    keyName: 'key',
  });

  function reorderUnits(dropResult: DropResult) {
    if (!dropResult.destination) return;
    move(dropResult.source.index, dropResult.destination?.index);
  }

  useEffect(() => {
    const data = getValues('units');

    const areEqual = areUnitArraysEqual(data, documents);
    if (!areEqual) reset({ units: documents });
  }, [documents, getValues]);

  return {
    units: fields,
    reorderUnits,
    getValues,
  };
}
