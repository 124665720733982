import { useCallback, useEffect, useState } from 'react';
import { FieldValues, PathValue, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

type NestedSegmentPaths = `segments.${number}.${
  | 'type'
  | 'title'
  | 'subtitle'
  | 'service'
  | 'unit'
  | 'quantity'
  | 'pricePerUnit'
  | 'discount'
  | 'content'
  | 'files'}`;

export type ProposalUpdatePaths = keyof Proposal | NestedSegmentPaths;

interface Attributes<T> {
  valuesToCompare: (keyof T)[];
  callback: VoidFunction;
  valueToUpdate?: ProposalUpdatePaths;
  selector: { getState: (state: AppState) => EntityState<T> };
}

export const useLazyProposalUpdate = <T extends FieldValues>({
  selector,
  valuesToCompare,
  callback,
  valueToUpdate,
}: Attributes<T>) => {
  const { documents } = useSelector(selector.getState);
  const context = useFormContext<Proposal>();
  const [formItem, setFormItem] = useState<T | null>(null);

  const setNewValue = useCallback(() => {
    if (!formItem || !context) return;

    const newItem = documents?.find((item) =>
      valuesToCompare.every((value) => item[value] === formItem[value]),
    );

    if (context && newItem && valueToUpdate) {
      context.setValue(valueToUpdate, { ...newItem } as PathValue<T, Path<T>>);
      context.clearErrors(valueToUpdate);
      setFormItem(null);
      callback();
    }
    callback();
  }, [formItem, documents, callback, valuesToCompare]);

  useEffect(setNewValue, [setNewValue]);

  return { setFormItem };
};
