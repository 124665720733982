import { useParams } from '@reach/router';
import iconGear from 'assets/icons/icon-gear-12.svg';
import { useProposalAccessList } from 'modules/proposals';
import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Button } from 'shared/components';
import { ProposalSettingsModal } from './ProposalSettingsModal';

export const ProposalSettingsController: React.FC = () => {
  const { id } = useParams();

  const visibility = useWatch({ name: 'visibility' });
  const publishStatus = useWatch({ name: 'publishStatus' });

  const {
    buttonDisabled,
    isModalOpen,
    openModal,
    closeModal,
    handleUpdateList,
  } = useProposalAccessList(id);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflowY = 'hidden';
    }

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isModalOpen]);

  return (
    <>
      <div className="f f--col">
        <Button
          className="f f--align-center f--justify-end m-left-auto"
          style="ghost"
          size="med"
          onClick={openModal}
          btnSelector="access-control-btn"
        >
          <img
            src={iconGear}
            alt="Proposal settings icon"
            className="btn__icon btn__icon__left"
          />
          <p className="text--xsm t-text-1">Proposal options</p>
        </Button>
      </div>
      {isModalOpen && (
        <ProposalSettingsModal
          publishStatus={publishStatus}
          confirmButtonDisabled={buttonDisabled}
          visibility={visibility}
          handleUpdateList={handleUpdateList}
          onClose={closeModal}
        />
      )}
    </>
  );
};
