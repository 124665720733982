import arrowRightIcon from 'assets/icons/icon-arrow-right-12.svg';
import checkMarkIcon from 'assets/icons/icon-checkmark-16-primary.svg';
import documentIcon from 'assets/icons/icon-document-16.svg';
import lockedIcon from 'assets/icons/icon-locked-16.svg';
import mediaIcon from 'assets/icons/icon-media-16.svg';
import pdfExportIcon from 'assets/icons/icon-pdf-export-16.svg';
import pencilIcon from 'assets/icons/icon-pencil-16.svg';
import { useOrganizationIdSelector } from 'modules/authorization';
import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape, useModal, useSubmitOnEnter } from 'shared/hooks';
import { useSubscriptionHelpers } from '../hooks';
import { SubscriptionSelectModal } from './SubscriptionSelectModal';

export const FreeTrialEndedLegacyModal: React.FC = () => {
  const organizationId = useOrganizationIdSelector();
  const [currentStep, setCurrentStep] = useState(1);
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const { isLegacyFreeUser, isTierOne, hasUsedFreeTrial } =
    useSubscriptionHelpers();
  const hideFreeTrialEndedLegacyModalOrganizations = JSON.parse(
    localStorage.getItem('hideFreeTrialEndedLegacyModal') || '[]',
  );
  const { isModalOpen, closeModal } = useModal(
    !hideFreeTrialEndedLegacyModalOrganizations?.includes(organizationId),
  );

  function closeFreeTrialEndedLegacyModal() {
    localStorage.setItem(
      'hideFreeTrialEndedLegacyModal',
      JSON.stringify([
        ...hideFreeTrialEndedLegacyModalOrganizations,
        organizationId,
      ]),
    );
    closeModal();
  }

  if (!isModalOpen || !isLegacyFreeUser || !hasUsedFreeTrial || isTierOne) {
    return null;
  }

  function renderStep() {
    switch (currentStep) {
      case 1:
        return (
          <Modal
            isDashboardModal
            containerClassName="free-trial-notice__modal"
            close={closeFreeTrialEndedLegacyModal}
          >
            <ModalHeader>
              <p className="text--xl__responsive t-bold t-text-1">
                Your Trial Has Ended!{' '}
              </p>
            </ModalHeader>
            <ModalContent
              className="subscription-product__group__content"
              contentSelector="propoze-plus-modal-content"
            >
              <p className="t-text-1 text--xxs__responsive s-bottom--med u-maxwidth--400__responsive">
                Thank you for exploring Propoze Plus! Upgrade to Propoze Plus
                now and continue creating winning proposals that help you close
                new deals..
              </p>
              <p className="t-text-1 t-bold text--xs__responsive s-bottom--tny">
                Exclusive offer: Continue with Propoze Plus!
              </p>
              <p className="t-text-1 text--xs__responsive s-bottom--med">
                Unlock all premium features at <strong>50% off!</strong> Enjoy
                features like:{' '}
              </p>
              <ul
                className="list--bullets s-bottom--lrg list--2x2__responsive r-g-16"
                data-cy="free-trial-notice-modal-features"
              >
                <li className="f f--align-center g-16">
                  <img src={documentIcon} alt="Document Icon" />
                  <div className="f f--col g-4 t-text-1">
                    <p className="t-bold text--xxs__responsive">
                      Unlimited Proposals
                    </p>
                    <span className="text--xxs__responsive">
                      Create and send as many proposals as you want—no limits!
                    </span>
                  </div>
                </li>
                <li className="f f--align-center g-16">
                  <img src={pencilIcon} alt="Pencil Icon" />
                  <div className="f f--col g-4 t-text-1">
                    <p className="t-bold text--xxs__responsive">
                      Custom Branding
                    </p>
                    <span className="text--xxs__responsive">
                      Personalize with your logos, fonts, and colors.{' '}
                    </span>
                  </div>
                </li>
                <li className="f f--align-center g-16">
                  <img src={pdfExportIcon} alt="PDF Export Icon" />
                  <div className="f f--col g-4 t-text-1">
                    <p className="t-bold text--xxs__responsive">PDF Export</p>
                    <span className="text--xxs__responsive">
                      Download proposals as PDFs for easy offline sharing.{' '}
                    </span>
                  </div>
                </li>
                <li className="f f--align-center g-16">
                  <img src={lockedIcon} alt="Locked Icon" />
                  <div className="f f--col g-4 t-text-1">
                    <p className="t-bold text--xxs__responsive">
                      Access Control
                    </p>
                    <span className="text--xxs__responsive">
                      Manage who can view and interact with your proposals.{' '}
                    </span>
                  </div>
                </li>
                <li className="f f--align-center g-16">
                  <img src={mediaIcon} alt="Media Icon" />
                  <div className="f f--col g-4 t-text-1">
                    <p className="t-bold text--xxs__responsive">
                      Media & Attachments
                    </p>
                    <span className="text--xxs__responsive">
                      Enrich your proposals with images and videos.{' '}
                    </span>
                  </div>
                </li>{' '}
                <li className="f f--align-center g-16">
                  <img src={checkMarkIcon} alt="Checkmark Icon" />
                  <div className="f f--col g-4 t-text-1">
                    <p className="t-bold text--xxs__responsive">
                      Streamlined Approvals{' '}
                    </p>
                    <span className="text--xxs__responsive">
                      Simplify internal sign-offs and approvals.{' '}
                    </span>
                  </div>
                </li>
              </ul>
              <p className="t-text-1 text--xxs__responsive s-bottom--med u-maxwidth--400__responsive">
                Your free Propoze plan is always there if you choose not to
                upgrade. We appreciate your support as a valued member of the
                Propoze community!
              </p>
            </ModalContent>
            <ModalFooter>
              <div className="btn-group btn-group--simple f f--justify-between u-width--full s-top--sml">
                <Button
                  type="button"
                  ref={closeButtonRef}
                  onClick={closeFreeTrialEndedLegacyModal}
                  size="lrg"
                  style="secondary"
                >
                  Back to Free Plan
                </Button>
                <Button
                  type="button"
                  ref={submitButtonRef}
                  onClick={() => setCurrentStep(currentStep + 1)}
                  size="lrg"
                  style="primary"
                >
                  <span>Upgrade to Plus</span>
                  <img
                    src={arrowRightIcon}
                    alt="Arrow right icon"
                    className="btn__icon btn__icon__right"
                  />
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        );
      case 2:
        return (
          <SubscriptionSelectModal onClose={closeFreeTrialEndedLegacyModal} />
        );
      default:
        return null;
    }
  }

  return <>{renderStep()}</>;
};
