import { useFeatureFlag } from 'modules/feature-flags';
import React, { PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';
import { useSubscriptionHelpers, useSubscriptionTier } from '../hooks';
import { subscriptionAtoms } from '../state';

interface Props extends PropsWithChildren {
  tier: SubscriptionTierAlias;
  /** Check for EXACTLY that tier, nothing more */
  exact?: boolean;
}

/** Determine whether user is in grace period. */
export const GracePeriodWrapper: React.FC<Props> = ({
  tier,
  exact,
  children,
}) => {
  const { isGracePeriod } = useSubscriptionHelpers();
  const subscriptionsEnabled = useFeatureFlag('SUBSCRIPTION');
  const enabled = useSubscriptionTier(tier, exact);
  const status = useRecoilValue(subscriptionAtoms.status);

  if (!isGracePeriod || (enabled && status === null) || !subscriptionsEnabled) {
    return null;
  }
  return <>{children}</>;
};
