import React, { useState } from 'react';
import { ProposalExpiredActionModal } from './ProposalExpiredActionModal';
import { ProposalExpiredFailedModal } from './ProposalExpiredFailedModal';

interface Props extends ModalProps {
  proposalId: string;
}

export const ProposalExpiredModal: React.FC<Props> = ({
  proposalId,
  onClose,
}) => {
  const [error, setError] = useState(false);
  const [showExpiredModal, setShowExpiredModal] = useState(false);

  const isProposalExpiredFailed = error && !showExpiredModal;
  if (isProposalExpiredFailed) {
    return (
      <ProposalExpiredFailedModal
        onSetShowExpiredModal={setShowExpiredModal}
        onClose={onClose}
      />
    );
  }

  return (
    <ProposalExpiredActionModal
      proposalId={proposalId}
      onSetError={setError}
      onClose={onClose}
    />
  );
};
