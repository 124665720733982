import { motion, Variants } from 'framer-motion';
import React from 'react';
import { ProductInfo } from './ProductInfo';

const item: Variants = {
  hidden: {
    opacity: 0,
    y: 10,
  },
  show: {
    opacity: [0, 1],
    y: [10, 0],
    transition: {
      ease: 'easeInOut',
    },
  },
};

interface Props {
  name: string;
  product: StripeProductWithPrices;
  isCheckoutLoading?: boolean;
  onSelect: () => void;
}

export const ProductItem: React.FC<Props> = ({
  product,
  name,
  isCheckoutLoading,
  onSelect,
}) => {
  return (
    <motion.article
      variants={item}
      data-cy={`subscription-product-card-${name}`}
      className="subscription-product__card"
    >
      <ProductInfo
        product={product}
        isCheckoutLoading={isCheckoutLoading}
        onSelect={onSelect}
      />
    </motion.article>
  );
};
