import sparksIcon from 'assets/icons/icon-sparks-16.svg';
import { isLegacyFreeUserAtom } from 'modules/authorization';
import {
  PropozePlusUpgradeModal,
  subscriptionAtoms,
  usePropozePlusModal,
} from 'modules/subscription';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Button } from 'shared/components';
import { useModal } from 'shared/hooks';
import { FreeTrialLegacyNoticeModal } from '../../FreeTrialLegacyNoticeModal';

interface Props {
  noticeActionId?: string;
  noticeDescriptionId?: string;
  containerClassName?: string;
  onCloseSlidingMenu?: (menuOpen: boolean) => void;
}

export const FreeTierNotice: React.FC<Props> = ({
  noticeActionId = 'propoze-plus-notice.free.action',
  noticeDescriptionId = 'propoze-plus-notice.free.description',
  containerClassName,
  onCloseSlidingMenu,
}) => {
  const isLegacyFreeUser = useRecoilValue(isLegacyFreeUserAtom);
  const hasUsedFreeTrial = useRecoilValue(subscriptionAtoms.hasUsedFreeTrial);
  const { isModalVisible, openModal, closeModal } =
    usePropozePlusModal(onCloseSlidingMenu);
  const {
    isModalOpen: isLegacyModalOpen,
    closeModal: closeLegacyModal,
    openModal: openLegacyModal,
  } = useModal();

  function handleOpenModal() {
    if (!isLegacyFreeUser || hasUsedFreeTrial) {
      return openModal();
    }

    return openLegacyModal();
  }

  function renderContent() {
    if (!isLegacyFreeUser) {
      return (
        <>
          {' '}
          <Button
            type="button"
            onClick={handleOpenModal}
            className="btn__slim t-primary t-bold text--xsm"
            btnSelector="propoze-plus-notice-upgrade-btn"
          >
            <FormattedMessage id={noticeActionId} />
          </Button>
          <span className="text--xsm t-text-2">
            <FormattedMessage id={noticeDescriptionId} />
          </span>
        </>
      );
    }

    return (
      <>
        <span className="text--sm t-bold t-text-1">Continue with Plus!</span>{' '}
        <Button
          type="button"
          onClick={handleOpenModal}
          className="btn__slim t-primary t-bold text--xsm"
          btnSelector="propoze-plus-notice-upgrade-btn"
        >
          Upgrade at 50% Off
        </Button>
      </>
    );
  }

  return (
    <div
      className={`propoze-plus-notice__wrapper ${containerClassName || ''}`}
      data-cy="propoze-plus-notice"
      onClick={handleOpenModal}
    >
      <img
        src={sparksIcon}
        alt="Sparks icon"
        className="btn__icon s-right--12"
      />
      <div className="d--ib">{renderContent()}</div>
      {isModalVisible && <PropozePlusUpgradeModal onClose={closeModal} />}
      {isLegacyModalOpen && (
        <FreeTrialLegacyNoticeModal onClose={closeLegacyModal} />
      )}
    </div>
  );
};
