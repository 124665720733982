import React, { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useKeyPress } from 'shared/hooks';
import { ProtectedDraftTooltip } from './ProtectedDraftTooltip';

interface Props {
  visibility: Proposal['visibility'];
  publishStatus: Proposal['publishStatus'];
}

export const VisibilityController: React.FC<Props> = ({
  visibility,
  publishStatus,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const escapePressed = useKeyPress('Escape');

  const { control, setValue } = useFormContext<PublishStatusAware<Proposal>>();

  function setVisibilityValue(value: Proposal['visibility']) {
    setValue('visibility', value, { shouldDirty: true });
  }

  function toggleVisibilityValue() {
    if (typeof visibility === 'string') {
      setVisibilityValue(visibility === 'public' ? 'protected' : 'public');
    }
  }

  useEffect(() => {
    if (escapePressed) ref.current?.click();
  }, [escapePressed]);

  return (
    <>
      <div className="f f--justify-between">
        {publishStatus !== 'draft' ? (
          <p className="text--xs s-right--med" data-cy="title-modal">
            <FormattedMessage id="visibility-controller.label" />
          </p>
        ) : (
          <div className="f u-width--full  f--align-center">
            <p
              className="text--lg t-bold t-text-1 s-right--sml"
              data-cy="title-modal"
            >
              <FormattedMessage id="visibility-controller.label" />
            </p>
            <ProtectedDraftTooltip />
          </div>
        )}

        <div className="f f--align-center">
          <label className="toggle__label">
            <Controller
              control={control}
              name="visibility"
              render={({ field }) => (
                <input
                  {...field}
                  type="checkbox"
                  name="visibility"
                  className="toggle__input"
                  checked={visibility === 'protected'}
                  onChange={toggleVisibilityValue}
                />
              )}
            />
            <span className="toggle__span" data-cy="visibility-toggle-switch" />
          </label>
        </div>
      </div>

      {visibility === 'public' && (
        <p
          className="t-text-2 text--sm visibility-selector-modal__description"
          data-cy="description-paragraph-public"
        >
          <FormattedMessage
            id={`${
              publishStatus !== 'draft'
                ? 'visibility-controller.caption.public'
                : 'visibility-controller.draft.caption.public'
            }`}
          />
        </p>
      )}

      {visibility === 'protected' && (
        <p
          className="t-text-2 text--sm visibility-selector-modal__description"
          data-cy="description-paragraph-protected"
        >
          <FormattedMessage
            id={`${
              publishStatus !== 'draft'
                ? 'visibility-controller.caption.protected'
                : 'visibility-controller.draft.caption.protected'
            }`}
          />
        </p>
      )}
    </>
  );
};
