import React from 'react';

interface Props {
  className?: string;
  strokeColor?: string;
}

export const DownloadIcon: React.FC<Props> = ({
  className,
  strokeColor = '#333333',
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9347_14989)">
        <path
          d="M2.625 5.625L5.625 8.799L8.625 5.65575M5.625 0.375V8.625M1.125 10.875H10.125"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9347_14989">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
