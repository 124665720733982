/**
 * Updates an array of filters based on the given filter type and value.
 * If the filter type is string-valued and the same filter (type and value)
 * already exists, it removes the filter. Otherwise, it adds the new filter.
 * For non-string-valued filters, it updates the filter if it exists,
 * or adds a new one if it doesn't.
 *
 * @param {Filter[]} prevFilters - The array of existing filters.
 * @param {Filter} filter - The filter object to be added or updated.
 * @returns {Filter[]} The updated array of filters.
 */

export function updateFilters(
  prevFilters: Filter[],
  { type, value, label }: Filter,
): Filter[] {
  const isStringValuedFilter = [
    'client',
    'tags',
    'proposal-status',
    'acceptance',
  ].includes(type);

  if (isStringValuedFilter && typeof value === 'string') {
    const isFilterPresent = prevFilters.some(
      (filter) => filter.type === type && filter.value === value,
    );
    return isFilterPresent
      ? prevFilters.filter(
          (filter) => !(filter.type === type && filter.value === value),
        )
      : [
          ...prevFilters,
          {
            type,
            value,
            label: type === 'client' ? label : null,
          },
        ];
  }

  const existingFilter = prevFilters.find((filter) => filter.type === type);
  if (!existingFilter) return [...prevFilters, { type, value }];
  return prevFilters.map((filter) =>
    filter.type === type ? { ...filter, value } : filter,
  );
}
