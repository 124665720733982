import React, { useCallback } from 'react';
import Flatpickr from 'react-flatpickr';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';
import { ProposalStatsFormValues } from './ProposalsStats';

export const StatsDatePicker: React.FC = () => {
  const { formatMessage } = useIntl();

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<ProposalStatsFormValues>();

  const handleDateChange = useCallback(
    (field: ControllerRenderProps<ProposalStatsFormValues, 'statsDateTime'>) =>
      ([selectedDate]: Date[]) => {
        // Set to 12:00 so we don't have a difference in days when converting between GMT and UTC
        selectedDate.setHours(12, 0, 0, 0);
        field.onChange(+selectedDate);
      },
    [],
  );

  return (
    <FieldWrapper
      name="statsDateTime"
      labelId="datepickers.stats-date.label"
      errors={errors}
    >
      <Controller
        name="statsDateTime"
        control={control}
        rules={{
          required: formatMessage({
            id: 'datepickers.stats-date.required',
          }),
        }}
        defaultValue={getValues('statsDateTime')}
        render={({ field }) => (
          <Flatpickr
            {...field}
            data-enable-time
            options={{
              maxDate: new Date(),
              disableMobile: true,
              enableTime: false,
              dateFormat: 'Y-m-d',
            }}
            className={`input input__med input__box ${
              errors.statsDateTime ? 'input__box__error' : ''
            }`}
            placeholder={formatMessage({
              id: 'datepickers.display-date.placeholder',
            })}
            data-cy="stats-date-picker"
            onChange={handleDateChange(field)}
          />
        )}
      />
    </FieldWrapper>
  );
};
