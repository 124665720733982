import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  useStripeHelpers,
  useStripeProducts,
  useSubscriptionHelpers,
} from '../hooks';
import { subscriptionAtoms, subscriptionSelectors } from '../state';
import { CurrentPlanCardFooter } from './CurrentPlanCardFooter';
import { CurrentPlanCardHeader } from './CurrentPlanCardHeader';
import { CurrentPlanCardHeaderInfo } from './CurrentPlanCardHeaderInfo';
import { ProductFeatureList } from './ProductFeatureList';

interface Props {
  price: StripePrice;
  product: StripeProductWithPrices;
  active: Subscription['active'];
  end: Subscription['end'];
  onSetModalVisible: (visible: boolean) => void;
}

export const CurrentPlanCard: React.FC<Props> = ({
  product,
  price,
  active,
  end,
  onSetModalVisible,
}) => {
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const { plusYearlyProduct } = useStripeProducts();

  const discountPercentage = useRecoilValue(
    subscriptionAtoms.discountPercentage,
  );

  const {
    isGracePeriod,
    isFreeTrialActive,
    isFreeTrialEnded,
    isLegacyFreeUser,
  } = useSubscriptionHelpers();
  const { getProductFeatureListFromMetadata } = useStripeHelpers();

  const features = getProductFeatureListFromMetadata(
    isFreeTrialEnded && !isLegacyFreeUser
      ? plusYearlyProduct?.metadata
      : product.metadata,
  );
  const plusFeatures = getProductFeatureListFromMetadata(
    plusYearlyProduct?.metadata,
  );

  return (
    <div className="subscription-settings-plan" data-cy="current-plan-card">
      <CurrentPlanCardHeader
        product={product}
        active={active}
        end={end}
        isGracePeriod={isGracePeriod}
        isFreeTrialActive={isFreeTrialActive}
        isFreeTrialEnded={isFreeTrialEnded}
        isLegacyFreeUser={isLegacyFreeUser}
      />

      <CurrentPlanCardHeaderInfo
        end={end}
        isGracePeriod={isGracePeriod}
        isTierOne={isTierOne}
        price={price}
        discountPercentage={discountPercentage}
        isFreeTrialActive={isFreeTrialActive}
        isFreeTrialEnded={isFreeTrialEnded}
      />

      <ProductFeatureList
        features={features}
        plusFeatures={plusFeatures}
        productId={product.id}
        className="list--2x2__responsive"
        featuresSelector='product-description-feature-list"'
        isLegacyFreeUser={isLegacyFreeUser}
        isTierOne={isTierOne}
      />

      <CurrentPlanCardFooter
        isGracePeriod={isGracePeriod}
        isFreeTrialActive={isFreeTrialActive}
        isFreeTrialEnded={isFreeTrialEnded}
        isLegacyFreeUser={isLegacyFreeUser}
        end={end}
        isTierOne={isTierOne}
        onSetModalVisible={onSetModalVisible}
      />
    </div>
  );
};
