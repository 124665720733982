import React from 'react';

export const AddIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className="btn__icon btn__icon__left"
      fill="none"
    >
      <g clipPath="url(#clip0_13441_7383)">
        <path
          d="M6.375 0H5.625C5.211 0 4.875 0.336 4.875 0.75V4.875H0.75C0.336 4.875 0 5.211 0 5.625V6.375C0 6.789 0.336 7.125 0.75 7.125H4.875V11.25C4.875 11.664 5.211 12 5.625 12H6.375C6.789 12 7.125 11.664 7.125 11.25V7.125H11.25C11.664 7.125 12 6.789 12 6.375V5.625C12 5.211 11.664 4.875 11.25 4.875H7.125V0.75C7.125 0.336 6.789 0 6.375 0Z"
          fill="#8039FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_13441_7383">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
