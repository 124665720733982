import { countryToLocaleMap } from 'shared/const';

export function formatPercentage(value: number, ISOCountry = 'US'): string {
  const locale = countryToLocaleMap[ISOCountry] || 'en-US';

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
    localeMatcher: 'best fit',
    numberingSystem: 'latn',
  }).format(value);
}
