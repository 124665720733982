import { createAction } from 'modules/redux-store';

export const authorizationAction = {
  loading: () => createAction('authorization/LOADING'),

  onSnapshotUpdate: (documents: OrganizationUser[]) =>
    createAction('authorization/ON_SNAPSHOT_UPDATE', { documents }),

  onSnapshotError: (error: FirestoreError) =>
    createAction('authorization/ON_SNAPSHOT_ERROR', { error }),

  updateAdminRole: (adminRole: Role) =>
    createAction('authorization/UPDATE_ADMIN_ROLE', { adminRole }),

  updateUserRoles: (roles: RoleClaim[] | Partial<RoleClaim>[]) =>
    createAction('authorization/UPDATE_USER_ROLES', { roles }),
};
