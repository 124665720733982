import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  isLoading: boolean;
}

export const PageLoading: React.FC<Props> = ({ children, isLoading }) => {
  return isLoading ? (
    <div className="container container--full loading-container">
      <div className="loading"></div>
    </div>
  ) : (
    <>{children}</>
  );
};
