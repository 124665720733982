import { authenticationSelector } from 'modules/authentication';
import { useFunction } from 'modules/firebase';
import { useStripeProducts } from 'modules/subscription';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

interface AppSumoForm {
  code: string;
}

export function useAppSumo(
  onSetCurrentLocalStep: (step: AppSumoSteps) => void,
) {
  const { formatMessage } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector(authenticationSelector.getState);
  const { plusYearlyProduct } = useStripeProducts();

  const redeemAppSumoCode =
    useFunction<RedeemAppSumoCodeData>('redeemAppSumoCode');

  const form = useForm<AppSumoForm>({
    mode: 'onSubmit',
    defaultValues: {
      code: '',
    },
  });

  async function onSubmit({ code }: AppSumoForm) {
    try {
      setIsLoading(true);
      const { data } = await redeemAppSumoCode({
        code,
        organizationId: user?.uid || '',
        email: user?.email || '',
        priceId: plusYearlyProduct?.default_price || '',
        productId: plusYearlyProduct?.id || '',
      });
      if (!data?.success) {
        toast.error(data?.message);
        return;
      }
      toast.success(
        formatMessage({ id: 'settings.subscription.appsumo-success-toast' }),
      );
      onSetCurrentLocalStep('status');
      return;
    } catch {
      toast.error(
        'Something went wrong. Please try again or contact customer support.',
      );
    } finally {
      setIsLoading(false);
    }
  }

  return {
    form,
    isLoading,
    onSubmit,
  };
}
