import { isMediaSegment } from 'modules/proposals';
import { isFileSegment } from './isFileSegment';
import { isHeadingSegment } from './isHeadingSegment';
import { isServiceSegment } from './isServiceSegment';
import { isTextSegment } from './isTextSegment';

export function isProposalSegmentArray(
  items: any[],
): items is ProposalSegment[] {
  return items.every(
    (item) =>
      isServiceSegment(item) ||
      isHeadingSegment(item) ||
      isTextSegment(item) ||
      isFileSegment(item) ||
      isMediaSegment(item),
  );
}
