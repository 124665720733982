import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

export const ClientVatIdInput: React.FC = () => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<Client>();

  return (
    <FieldWrapper
      name="vatId"
      errors={errors}
      labelId="inputs.client-vat-id.label"
    >
      <input
        type="text"
        className={`input input__med input__box ${
          errors.vatId ? 'input__box__error' : ''
        }`}
        placeholder={formatMessage({
          id: 'inputs.client-vat-id.placeholder',
        })}
        data-cy="client-vat-id-input"
        autoComplete="off"
        onFocus={handleFocus}
        {...register('vatId')}
      />
    </FieldWrapper>
  );
};
