import closeIcon from 'assets/icons/icon-close-12.svg';
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useKeyPress } from 'shared/hooks';
import { Button } from '../button';

interface Props extends PropsWithChildren {
  isDashboardModal?: boolean;
  isPropozePlusModal?: boolean;
  isFilterModal?: boolean;
  animationClassName?: string;
  containerClassName?: string;
  closeIconClassName?: string;
  isOverlayClickIgnored?: boolean;
  close?: VoidFunction;
}

export const Modal: React.FC<Props> = ({
  isDashboardModal,
  isFilterModal,
  isPropozePlusModal,
  animationClassName,
  closeIconClassName,
  containerClassName,
  isOverlayClickIgnored = false,
  children,
  close,
}) => {
  const [root, setRoot] = useState<HTMLElement | null>(null);
  const overlay = useRef<HTMLDivElement>(null);
  const escPressed = useKeyPress('Escape');

  const isCloseButtonRendered = useMemo(
    () => close && (!isDashboardModal || isPropozePlusModal),
    [close, isDashboardModal, isPropozePlusModal],
  );

  function handleClickOutside() {
    if (isOverlayClickIgnored) return;

    close?.();
  }

  function handleClickInside(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
  }

  useEffect(() => {
    const htmlElement = document.documentElement;
    htmlElement.classList.add('is-locked');

    setRoot(document.getElementById('global-modal'));
    document.body.style.overflowY = 'hidden';

    return () => {
      htmlElement.classList.remove('is-locked');
      document.body.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    if (escPressed) close?.();
  }, [escPressed, close]);

  useEffect(() => {
    if (!overlay.current) return;

    function handleOverlayClick(event: MouseEvent) {
      event.preventDefault();
      event.stopPropagation();

      if (event.target && overlay.current?.isEqualNode(event.target as Node)) {
        close?.();
      }
    }

    overlay.current.addEventListener('click', handleOverlayClick);

    return () => {
      overlay.current?.removeEventListener('click', handleOverlayClick);
    };
  }, [overlay]);

  return (
    root &&
    ReactDOM.createPortal(
      <div
        ref={overlay}
        tabIndex={-1}
        className="modal__overlay"
        id="model-overlay"
        onClick={handleClickOutside}
      >
        <div
          className={`modal__container ${
            isDashboardModal || isPropozePlusModal ? `u-color--white-bg` : ''
          } ${isFilterModal ? 'modal__filter' : ''} ${
            animationClassName || 'default--animation'
          } ${
            containerClassName
              ? containerClassName
              : 'modal__container__default'
          }`}
          onClick={handleClickInside}
          id="modal-container"
        >
          {isCloseButtonRendered && (
            <Button
              type="button"
              onClick={close}
              className={`modal__close ${
                isDashboardModal ||
                (isPropozePlusModal ? `u-color--white-bg` : '')
              } ${closeIconClassName || ''}`}
              btnSelector="close-modal"
            >
              <img onClick={close} src={closeIcon} alt="CloseIcon" />
            </Button>
          )}
          {children}
        </div>
      </div>,
      root,
    )
  );
};
