import fileAttachmentIcon from 'assets/icons/icon-clipboard-12.svg';
import h1Icon from 'assets/icons/icon-heading-12.svg';
import mediaAttachmentIcon from 'assets/icons/icon-media-12.svg';
import addIcon from 'assets/icons/icon-plus-12-white.svg';
import textIcon from 'assets/icons/icon-rich-text-12.svg';
import sparksIcon from 'assets/icons/icon-sparks-duo-12.svg';
import { FeatureFlagWrapper } from 'modules/feature-flags';
import { subscriptionSelectors } from 'modules/subscription';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { SegmentButton } from '../segment-button';

interface Props {
  index: number;
  className?: string;
  onAdd: (type: ProposalSegment['type'], index: number) => void;
}

export const SegmentActions: React.FC<Props> = (props) => {
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);

  return (
    <div className={`proposal-service__actions ${props.className || ''}`}>
      <div className="proposal-service__add__container">
        <span className="d--desktop--only t-text-2 t-bold text--sm s-right--med">
          <FormattedMessage id="inputs.segment-actions.label" />
        </span>
        <div className="proposal-service__add">
          <span className="u-minwidth-fit--content t-text-2 t-bold text--sm d--mobile--only s-right--med">
            <FormattedMessage id="inputs.segment-actions.mobile-label" />
          </span>
          <SegmentButton
            type="service"
            {...props}
            btnSelector="builder-add-service-btn"
          >
            <img
              src={addIcon}
              alt="AddIcon"
              className="btn__icon s-right--sml"
            />
            <FormattedMessage id="buttons.service" />
          </SegmentButton>
          <SegmentButton
            type="heading"
            {...props}
            btnSelector="builder-add-heading-btn"
          >
            <img
              src={h1Icon}
              alt="H1 icon"
              className="btn__icon s-right--sml"
            />
            <FormattedMessage id="buttons.heading" />
          </SegmentButton>

          <SegmentButton
            type="text"
            {...props}
            btnSelector="builder-add-rich-text-btn"
          >
            <img
              src={textIcon}
              alt="TextIcon"
              className="btn__icon s-right--sml"
            />
            <FormattedMessage id="buttons.rich-text" />
          </SegmentButton>
          <FeatureFlagWrapper flag="FILE-ATTACHMENT">
            <SegmentButton
              type="file"
              {...props}
              btnSelector="builder-add-file-btn"
            >
              <img
                src={fileAttachmentIcon}
                alt="FileAttachmentIcon"
                className="btn__icon s-right--sml"
              />
              <FormattedMessage id="buttons.file-attachment" />
              {!isTierOne && (
                <img
                  src={sparksIcon}
                  alt="Sparks icon"
                  className="btn__icon btn__icon__right"
                />
              )}
            </SegmentButton>
            <SegmentButton
              type="media"
              {...props}
              btnSelector="builder-add-media-btn"
            >
              <img
                src={mediaAttachmentIcon}
                alt="MediaAttachmentIcon"
                className="btn__icon s-right--sml"
              />
              <FormattedMessage id="buttons.media-attachment" />
              {!isTierOne && (
                <img
                  src={sparksIcon}
                  alt="Sparks icon"
                  className="btn__icon btn__icon__right"
                />
              )}
            </SegmentButton>
          </FeatureFlagWrapper>
        </div>
      </div>
    </div>
  );
};
