import { useResendEmailVerificationLink } from 'modules/authentication';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, LoadingSpinner } from 'shared/components';
import { toast } from 'sonner';

interface Props {
  email: string;
}

export const EmailVerificationStatus: React.FC<Props> = ({ email }) => {
  const [loading, setLoading] = useState(false);

  const { resendEmailVerificationLink } = useResendEmailVerificationLink();

  async function handleResend() {
    try {
      setLoading(true);
      await resendEmailVerificationLink({ email });
      toast.success(
        'Verification link has been successfully sent. Please check your email.',
      );
    } catch {
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="f f--col settings__account--verification__container">
        <span className="t-bold t-text-1 text--xs__responsive">
          <FormattedMessage id="settings.account.verify-account.title" />
        </span>
        <p className="t-text-2 text--xsm">
          <FormattedMessage id="settings.account.verify-account.description" />
        </p>
        {loading ? (
          <Button
            type="button"
            disabled={loading}
            size="med"
            style="outline"
            className="u-fit--content s-top--med"
          >
            <LoadingSpinner height={12} width={12} type="primary" />
            <p className="s-left--med">
              <FormattedMessage id="free-subscription.subscribe.api.loading" />
            </p>
          </Button>
        ) : (
          <Button
            type="submit"
            size="med"
            style="outline"
            onClick={handleResend}
            className="u-fit--content s-top--med"
          >
            <FormattedMessage id="settings.account.verify-account.resend" />
          </Button>
        )}
      </div>
      <div className="divider" />
    </div>
  );
};
