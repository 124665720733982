import iconBack from 'assets/icons/icon-arrow-left-12-black.svg';
import { navigate } from 'gatsby';
import { mobileNavDropdownVisibleSelector } from 'modules/dashboard';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'shared/providers';
import { isProposalBuilderLocation } from 'shared/utils';

export const SettingsIconBack: React.FC = () => {
  const setMobileDropdownVisible = useSetRecoilState(
    mobileNavDropdownVisibleSelector,
  );
  const { history } = useHistory();

  function handleBack() {
    setMobileDropdownVisible(true);
    /**When user returns back from customer portal to settings page there is no history */
    if (!history?.length) return;

    const previousPath = history[history?.length - 2]; // -2 because the last element is the current path
    if (isProposalBuilderLocation(previousPath)) {
      navigate(-2);
      return;
    }
    navigate(-1);
  }

  return (
    <div className="f settings__icon--back" onClick={handleBack}>
      <img src={iconBack} alt="MagnifierIcon" className="btn__icon" />
      <span className="text--xsm t-text-1">
        <FormattedMessage id="buttons.back" />
      </span>
    </div>
  );
};
