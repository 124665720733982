import { OptionType } from 'shared/components';

export const weightOptions: OptionType[] = [
  {
    value: '400',
    label: 'Regular',
  },
  {
    value: '700',
    label: 'Bold',
  },
];
