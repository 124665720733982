/**
 * Checks if the given pathname matches the proposal builder location format.
 *
 * This function uses a regular expression to test if the pathname matches the pattern `/dashboard/proposal/:id/`,
 * where `:id` can be one or more alphanumeric characters.
 *
 * @param pathname - The pathname to check.
 * @returns `true` if the pathname matches the proposal builder location format, `false` otherwise.
 */
export function isProposalBuilderLocation(pathname: string) {
  const routeRegex = /^\/dashboard\/proposal\/([a-zA-Z0-9]+)\/$/;
  return routeRegex.test(pathname);
}
