import bellIcon from 'assets/icons/icon-bell-12-primary.svg';
import successIcon from 'assets/icons/icon-checkmark-success-12.svg';
import { getDatesAreOnSameDay, ProposalExpiredModal } from 'modules/proposals';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { useModal } from 'shared/hooks';

interface Props {
  proposalId: string;
  ownerNotifiedDate?: Date;
}

export const ProposalExpiredController: React.FC<Props> = ({
  proposalId,
  ownerNotifiedDate,
}) => {
  const { isModalOpen, closeModal, openModal } = useModal();

  const isNotifiedSectionVisible =
    ownerNotifiedDate && getDatesAreOnSameDay(ownerNotifiedDate, new Date());

  if (isNotifiedSectionVisible) {
    return (
      <div
        className="proposal-expired-success-controller"
        data-cy="notification-sent-container"
      >
        <span className="text--xsm t-text-2">
          <FormattedMessage id="proposal-expired.success-message" />
        </span>
        <img
          src={successIcon}
          alt="IconSuccess"
          className="btn__icon btn__icon__right"
          data-cy="notification-sent-icon"
        />
      </div>
    );
  }

  return (
    <>
      <Button
        type="button"
        style="text"
        size="med"
        className="f proposal__expired__extend__link c-pointer"
        btnSelector="notify-owner-btn"
        onClick={openModal}
      >
        <img
          src={bellIcon}
          className="btn__icon btn__icon__left c-pointer"
          alt="BellIcon"
        />
        <span className="text--xsm">
          <FormattedMessage id="proposal-expired.action" />
        </span>
      </Button>

      {isModalOpen && (
        <ProposalExpiredModal proposalId={proposalId} onClose={closeModal} />
      )}
    </>
  );
};
