import { PageProps } from 'gatsby';
import React from 'react';
import { useSelector } from 'react-redux';
import { PageLoading } from 'shared/components';
import { unitSelector } from '../redux';
import { Units } from './Units';

interface Props extends PageProps {
  proposalsLoading: boolean;
}

export const UnitsPage: React.FC<Props> = ({ proposalsLoading }) => {
  const { documents, isLoading, error } = useSelector(
    unitSelector.getUnitsByIndex,
  );

  if (proposalsLoading || isLoading) {
    return <PageLoading isLoading={proposalsLoading || isLoading} />;
  }

  return <Units documents={documents} error={error} isLoading={isLoading} />;
};
