import { differenceInDays, isValid, startOfToday } from 'date-fns';

/**
 * This function calculates the remaining free trial days based on the subscription end date.
 * It compares the end date with the current date and returns the remaining days.
 * @param end The end date of the subscription in milliseconds or as a Date object.
 * @returns The number of days left in the free trial or null if the trial has expired.
 */
export function getFreeTrialLeftDays(
  end: number | 'permanent' | null | undefined,
): number | null {
  if (!end || end === 'permanent') return null;
  const endDate = new Date(end);
  if (!isValid(endDate)) return null;
  const today = startOfToday();
  const remainingDays = differenceInDays(endDate, today);

  return Math.max(remainingDays, 0);
}
