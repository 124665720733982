import {
  isLegacyFreeUserAtom,
  useOrganizationIdSelector,
} from 'modules/authorization';
import { useFirestoreDocument } from 'modules/firebase';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { toast } from 'sonner';
import { subscriptionAtoms } from '../state';
import { useStripeSubscription } from './subscription';

export function useFreeSubscription() {
  const organizationId = useOrganizationIdSelector();
  const isLegacyFreeUser = useRecoilValue(isLegacyFreeUserAtom);

  const { formatMessage } = useIntl();
  const { isLoading, createStripeSubscription } = useStripeSubscription();
  const customerId = useRecoilValue(subscriptionAtoms.customerId);

  const { set } = useFirestoreDocument<Subscription>(
    `${organizationId}/subscription`,
  );

  async function handleFreeSubscription() {
    try {
      await createStripeSubscription({
        isFreeTrial: !isLegacyFreeUser,
        customerId,
      });
    } catch {
      toast.error(
        formatMessage({
          id: 'free-subscription.subscribe.api.error',
        }),
      );
    } finally {
      set({ status: null });
    }
  }

  return { isLoading, handleFreeSubscription };
}
