import iconEmailVerified from 'assets/icons/icon-success-gray-12.svg';
import iconEmailNotVerified from 'assets/icons/icon-warning-12.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  isVerified: boolean;
}

export const AccountVerificationStatus: React.FC<Props> = ({ isVerified }) => {
  return (
    <div className={`companyselector__tooltip__container`}>
      {isVerified ? (
        <img
          src={iconEmailVerified}
          className="btn__icon__right"
          alt="Email verified icon"
        />
      ) : (
        <img
          src={iconEmailNotVerified}
          className="btn__icon__right"
          alt="Email not verified icon"
        />
      )}
      <div
        className={`companyselector__account-verification__tooltip  ${
          isVerified ? 'u-minwidth--64' : 'u-minwidth--100'
        }`}
      >
        <span className="text--xsm">
          {isVerified ? (
            <FormattedMessage id="settings.account.verified" />
          ) : (
            <FormattedMessage id="settings.account.non-verified" />
          )}
        </span>
        <div className="companyselector__acount-verification__tooltip__arrow" />
      </div>
    </div>
  );
};
