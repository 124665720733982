import { PageProps } from 'gatsby';
import {
  SettingsLayout,
  useConfigSelector,
  useSettings,
} from 'modules/settings';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  CompanyProposalSettings,
  LoadingSpinner,
  SEO,
} from 'shared/components';
import { toast } from 'sonner';

export const ProposalSettings: React.FC<PageProps> = ({ location }) => {
  const { formatMessage } = useIntl();
  const { updateAsync } = useSettings();

  const [loading, setLoading] = useState(false);

  const config = useConfigSelector();

  async function handleSubmit(data: Settings) {
    try {
      setLoading(true);
      await updateAsync(data, 'Settings have been successfully saved.');
    } catch {
      toast.error('Something went wrong, try again.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <SEO
        title={formatMessage({ id: 'settings.seo.title' })}
        description={formatMessage({ id: 'settings.seo.description' })}
        href={location.href}
      />

      <SettingsLayout>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="company-settings-info-title"
        >
          <FormattedMessage id="settings.proposal-settings.title" />
        </header>
        <div className="dashboard__section__mobile__actions">
          <header className="text--lg t-bold">
            <FormattedMessage id="settings.proposal-settings.title" />
          </header>
        </div>
        <section className="settings">
          {config && (
            <div className="u-width--full">
              <CompanyProposalSettings config={config} onSubmit={handleSubmit}>
                {loading ? (
                  <Button
                    type="button"
                    disabled={loading}
                    size="lrg"
                    className="u--width--full--mobile s-top--48"
                    style="primary"
                  >
                    <LoadingSpinner height={12} width={12} type="negative" />
                    <p className="s-left--med">
                      <FormattedMessage id="buttons.saving" />
                    </p>
                  </Button>
                ) : (
                  <Button
                    value="Save"
                    size="lrg"
                    type="submit"
                    style="primary"
                    className="u--width--full--mobile s-top--48"
                    btnSelector="company-settings-save-btn"
                  >
                    <FormattedMessage id="buttons.save" />
                  </Button>
                )}
              </CompanyProposalSettings>
            </div>
          )}
        </section>
      </SettingsLayout>
    </>
  );
};
