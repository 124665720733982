import checkedIcon from 'assets/icons/icon-checkbox-primary-16.svg';
import React from 'react';

interface Props {
  text: string;
  Icon: React.FC;
  responseStatus: ProposalRespondStatus | null;
  feedback: string;
  className?: string;
  isActive?: boolean;
  onSetFeedback: (value: string) => void;
  onActionSelected: () => void;
}

export const ProposalAcceptanceAction: React.FC<Props> = ({
  text,
  Icon,
  className,
  isActive,
  feedback,
  responseStatus,
  onSetFeedback,
  onActionSelected,
}) => {
  return (
    <div
      className={`f f--col ${
        isActive
          ? 'proposal-acceptance-action__active'
          : 'proposal-acceptance-action__non-active'
      }`}
    >
      <div
        className={`proposal-acceptance-action__container u-width--full u-bg--transparent g-12 ${
          className || ''
        }`}
        onClick={onActionSelected}
      >
        <Icon />
        <p className="text--xs t-bold t-text-2">{text}</p>
        {isActive && (
          <img src={checkedIcon} className="m-left-auto" alt="checked" />
        )}
      </div>
      {responseStatus === 'suggest-changes' && isActive && (
        <textarea
          className="input input__box account-delete-modal__textarea input__textarea u-bg--transparent m-bottom-0"
          value={feedback}
          autoFocus
          rows={5}
          onChange={({ currentTarget: { value } }) => onSetFeedback(value)}
          placeholder="Enter your suggestion"
        />
      )}
    </div>
  );
};
