import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { DashboardFooter, PublicLayout, TawkLiveChat } from 'shared/components';

export const AuthenticationLayout: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { formatMessage } = useIntl();

  return (
    <PublicLayout
      rootUrl={formatMessage({ id: 'routes.home' })}
      containerClassName="auth"
      headerClassName="auth__nav"
      contentClassName="auth"
      footerComponent={DashboardFooter}
      footerClassName="u-bg--6"
    >
      <div className="auth__main">{children}</div>
      <TawkLiveChat />
    </PublicLayout>
  );
};
