import { motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMobile } from 'shared/hooks';

export const NoResultsFound: React.FC = () => {
  const isMobile = useMobile();

  return (
    <motion.aside
      style={{
        opacity: 0,
        y: -20,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.2,
        delay: isMobile ? 0.6 : 0.3,
        type: 'intertia',
      }}
      className="search__results"
    >
      <div className="search__results__wrapper search-result__error t-text-2">
        <span className="text--sm">
          <FormattedMessage id="search.results.noresults" />
        </span>
      </div>
    </motion.aside>
  );
};
