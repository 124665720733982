import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useOrganizationIdSelector } from 'modules/authorization';
import { db } from 'modules/firebase';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { proposalsFirestoreConverter } from '../const/proposalsFirestoreConverter';
import {
  proposalsAtoms,
  useSetProposalsErrorSelector,
  useSetProposalsSelector,
} from '../state';

export function useProposalSubscription() {
  const organizationId = useOrganizationIdSelector();
  const setProposals = useSetProposalsSelector();
  const setProposalsError = useSetProposalsErrorSelector();
  const setProposalsLoading = useSetRecoilState(proposalsAtoms.isLoading);

  useEffect(() => {
    const collectionReference = collection(
      db,
      organizationId,
      'proposals',
      'entities',
    ).withConverter(proposalsFirestoreConverter);

    const orderedQuery = query(
      collectionReference,
      orderBy('creationDateTime', 'desc'),
    );

    return onSnapshot(
      orderedQuery,
      (snapshot) => {
        setProposals(snapshot.docs.map((doc) => doc.data()));
        setProposalsLoading(false);
      },
      (error) => {
        setProposalsError(error.message);
        setProposalsLoading(false);
      },
    );
  }, [organizationId]);
}
