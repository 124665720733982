import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, LoadingSpinner } from 'shared/components';
import { getGracePeriodFormattedDate } from 'shared/utils';
import { usePricingSelectPlan } from '../hooks';

interface Props {
  isGracePeriod?: boolean | null;
  isFreeTrialActive?: boolean | null;
  isFreeTrialEnded?: boolean | null;
  isLegacyFreeUser?: boolean | null;
  isTierOne: boolean;
  end?: number | 'permanent' | null;
  onSetModalVisible: (visible: boolean) => void;
}

export const CurrentPlanCardFooter: React.FC<Props> = ({
  isGracePeriod,
  isFreeTrialActive,
  isFreeTrialEnded,
  isLegacyFreeUser,
  end,
  isTierOne,
  onSetModalVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const { handleCheckout } = usePricingSelectPlan();

  async function handleSelectPlan() {
    if (isTierOne || isFreeTrialEnded) {
      setLoading(true);
      await handleCheckout();
      setLoading(false);
      return;
    }

    onSetModalVisible(true);
  }

  if ((isFreeTrialActive || isFreeTrialEnded) && !isLegacyFreeUser) {
    return (
      <footer className="subscription-settings-plan__footer subscription-settings-free-trial__footer s-top--tny">
        <p className="t-text-1 t-bold text--xxs__responsive">
          <FormattedMessage
            id={`${isFreeTrialActive ? 'current-plan-card.free-trial.ongoing-footer' : 'current-plan-card.free-trial.ended-footer'}`}
          />
        </p>{' '}
        {loading ? (
          <Button
            type="button"
            size="med"
            style="outline"
            disabled={loading}
            className="u--width--full--mobile"
          >
            <LoadingSpinner height={12} width={12} type="primary" />
            <p className="s-left--med">
              <FormattedMessage id="buttons.loading" />
            </p>
          </Button>
        ) : (
          <Button
            type="button"
            size="med"
            style="primary"
            onClick={handleSelectPlan}
            btnSelector="upgrade-now-btn"
            className="u--width--full--mobile"
          >
            <FormattedMessage id="buttons.upgrade-now" />
          </Button>
        )}
      </footer>
    );
  }

  if (isLegacyFreeUser && !isTierOne) {
    return (
      <footer className="subscription-settings-plan__footer subscription-settings-free-trial__footer s-top--tny">
        <div className="f f--col">
          {' '}
          <p className="t-text-1 t-bold text--xxs__responsive">
            Continue your journey with Plus!
          </p>{' '}
          <Button
            type="button"
            onClick={handleSelectPlan}
            className="btn__slim t-primary t-bold text--xsm f--align-self-start"
            btnSelector="propoze-plus-notice-upgrade-btn"
          >
            Upgrade at 50% Off
          </Button>
        </div>

        {loading ? (
          <Button
            type="button"
            size="med"
            style="outline"
            disabled={loading}
            className="u--width--full--mobile"
          >
            <LoadingSpinner height={12} width={12} type="primary" />
            <p className="s-left--med">
              <FormattedMessage id="buttons.loading" />
            </p>
          </Button>
        ) : (
          <Button
            type="button"
            size="med"
            style="primary"
            onClick={handleSelectPlan}
            btnSelector="upgrade-now-btn"
            className="u--width--full--mobile"
          >
            <FormattedMessage id="buttons.upgrade-now" />
          </Button>
        )}
      </footer>
    );
  }

  if (end !== 'permanent' && !isGracePeriod) {
    return (
      <footer className="subscription-settings-plan__footer s-top--med">
        {loading ? (
          <Button
            type="button"
            size="lrg"
            style="outline"
            disabled={loading}
            btnSelector="downgrade-proccesing-disabled"
            className="u--width--full--mobile"
          >
            <LoadingSpinner height={12} width={12} type="primary" />
            <p className="s-left--med">
              <FormattedMessage id="subscription-action-controller.action.downgrading" />
            </p>
          </Button>
        ) : (
          <Button
            type="button"
            size="lrg"
            style="primary"
            onClick={handleSelectPlan}
            btnSelector="manage-plan-btn"
            className="u--width--full--mobile"
          >
            <FormattedMessage id="current-plan-card.action.manage-plan" />
          </Button>
        )}
      </footer>
    );
  }

  if (isGracePeriod && end !== 'permanent') {
    return (
      <>
        <footer className="subscription-settings-plan__grace-period s-top--tny">
          <p className="text--sm">
            <FormattedMessage
              id="current-plan-card.description.grace-period"
              values={{
                date: (
                  <strong>
                    {typeof end === 'number'
                      ? getGracePeriodFormattedDate(end)
                      : 'Lifetime'}
                  </strong>
                ),
              }}
            />
          </p>
          {loading ? (
            <Button
              type="button"
              size="med"
              style="outline"
              disabled={loading}
              className="u-fit--content u--width--full--mobile"
            >
              <LoadingSpinner height={12} width={12} type="primary" />
              <p className="s-left--med">
                <FormattedMessage id="buttons.loading" />
              </p>
            </Button>
          ) : (
            <Button
              type="button"
              size="med"
              style="primary"
              onClick={handleSelectPlan}
              btnSelector="upgrade-now-btn"
              className="u-fit--content u--width--full--mobile"
            >
              <FormattedMessage id="buttons.renew" />
            </Button>
          )}
        </footer>
      </>
    );
  }

  return null;
};
