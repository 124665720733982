import React from 'react';
import { PreviewButton } from '../../shared-buttons';
import { PublishButton } from '../../shared-buttons/publish-button';
import { ActionDrawerClassNames } from '../ActionDrawerClassNames';
import { ArchiveButton } from '../archive-button';
import { DuplicateAsTemplateButton } from '../duplicate-as-template-button';
import { DuplicateButton } from '../duplicate-button';

interface Props extends ActionDrawerClassNames {
  proposal: Proposal;
  isOverview?: boolean;
}

export const DraftDrawerButtons: React.FC<Props> = ({
  duplicateAsTemplateClassName,
  duplicateClassName,
  archiveClassName,
  proposal,
  isOverview,
}) => {
  return (
    <>
      {isOverview && (
        <>
          <PublishButton className="actionbar__drawer__btn" isDrawerBtn />
          <PreviewButton
            className="actionbar__drawer__btn"
            btnSelector="preview-proposal-btn"
            isDrawerBtn
          />
        </>
      )}
      <DuplicateButton
        className={duplicateClassName}
        proposal={proposal}
        btnSelector="duplicate-proposal-btn"
      />
      <DuplicateAsTemplateButton
        className={duplicateAsTemplateClassName}
        proposal={proposal}
        btnSelector="duplicate-as-template-btn"
      />
      <ArchiveButton
        className={archiveClassName}
        btnSelector="archive-proposal-btn"
      />
    </>
  );
};
