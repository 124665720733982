import { isLegacyFreeUserAtom } from 'modules/authorization';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { subscriptionAtoms, subscriptionSelectors } from '../state';

export function useSubscriptionHelpers() {
  const loading = useRecoilValue(subscriptionAtoms.loadingDocument);
  const active = useRecoilValue(subscriptionAtoms.active);
  const subscriptionId = useRecoilValue(subscriptionAtoms.subscriptionId);
  const isLegacyFreeUser = useRecoilValue(isLegacyFreeUserAtom);
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const isGracePeriod = useRecoilValue(subscriptionAtoms.isGracePeriod);
  const isFreeTrial = useRecoilValue(subscriptionAtoms.isFreeTrial);
  const hasUsedFreeTrial = useRecoilValue(subscriptionAtoms.hasUsedFreeTrial);

  const isInactive = useMemo(
    () => Boolean(subscriptionId) && !active,
    [subscriptionId, active],
  );
  const isFreeTrialActive = useMemo(
    () => isFreeTrial && !hasUsedFreeTrial && !isGracePeriod && isTierOne,
    [isFreeTrial, hasUsedFreeTrial, isGracePeriod, isTierOne],
  );
  const isFreeTrialEnded = useMemo(
    () => hasUsedFreeTrial && !isFreeTrial && !isGracePeriod && !isTierOne,
    [hasUsedFreeTrial, isFreeTrial, isGracePeriod, isTierOne],
  );

  return {
    isInactive,
    isFreeTrial,
    isLegacyFreeUser,
    hasUsedFreeTrial,
    isTierOne,
    isGracePeriod,
    isFreeTrialActive,
    isFreeTrialEnded,
    loading,
  };
}
