import { useLocation } from '@reach/router';
import createIcon from 'assets/icons/icon-create-proposal-12.svg';
import { Link } from 'gatsby';
import { useProposals } from 'modules/proposals';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  className?: string;
  btnSelector?: string;
}

export const CreateProposalButton: React.FC<Props> = ({
  className,
  btnSelector,
}) => {
  const { formatMessage } = useIntl();
  const { pathname, search } = useLocation();
  const { reserveProposalId } = useProposals();

  const [newProposalId, setNewProposalId] = useState('');

  function handleNewProposalId() {
    setNewProposalId(reserveProposalId);
  }

  useEffect(handleNewProposalId, []);

  return (
    <Link
      state={{ lastPageUrl: `${pathname}${search}` }}
      className={`btn btn__primary btn__lrg nav__button-menu ${
        className || ''
      }`}
      to={formatMessage({ id: 'routes.new-proposal' }, { id: newProposalId })}
      onMouseDown={handleNewProposalId}
      data-cy={btnSelector}
    >
      <img
        src={createIcon}
        alt="Create new proposal icon"
        className="btn__icon nav__button-icon"
      />
      <span className="nav__button-text">
        <FormattedMessage id="buttons.create-new-proposal" />
      </span>
    </Link>
  );
};
