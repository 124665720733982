import { useFormGuard } from 'modules/form';
import { Settings } from 'modules/settings';
import React, { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyLogoPicker } from './company-logo-picker';
import { CompanyNameInput } from './company-name-input';

interface Props extends PropsWithChildren {
  config: Settings;
  formClassName?: string;
  onSubmit: (data: Settings) => void;
}

export const CompanyInfo: React.FC<Props> = ({
  config,
  formClassName,
  children,
  onSubmit,
}) => {
  const form = useForm<Settings>({
    mode: 'onSubmit',
    defaultValues: {
      ...config,
    },
  });
  useFormGuard(form);

  return (
    <FormProvider {...form}>
      <form
        className={formClassName || ''}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <CompanyNameInput />
        <div className="field">
          <CompanyLogoPicker />
        </div>
        {children}
      </form>
    </FormProvider>
  );
};
