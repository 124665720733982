import { FeatureFlagWrapper } from 'modules/feature-flags';
import {
  ActionDrawer,
  ActionDrawerButtons,
  Proposal,
  ProposalSummaryDrawer,
  SharedButtons,
} from 'modules/proposals';
import { subscriptionSelectors } from 'modules/subscription';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { BackButton } from './back-button';
import { ProposalName } from './proposal-name';
import { SaveNewVersionButton } from './save-new-version-button';

interface Props {
  view: ProposalSummaryView;
  isSummaryOpen: boolean;
  proposal?: PublishStatusAware<Proposal>;
  toggleView: (newView: ProposalSummaryView) => void;
  onSetSummaryOpen: (isOpen: boolean) => void;
}

export const EditorActions: React.FC<Props> = ({
  view,
  isSummaryOpen,
  proposal,
  toggleView,
  onSetSummaryOpen,
}) => {
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const { getValues } = useFormContext<PublishStatusAware<Proposal>>();

  return (
    <div className="proposal-create__actions">
      <div className="actionbar__buttons">
        <FeatureFlagWrapper flag="TABLE-OF-CONTENTS">
          <ProposalSummaryDrawer
            isSummaryOpen={isSummaryOpen}
            view={view}
            isSubscribed={isTierOne}
            proposal={getValues()}
            toggleView={toggleView}
            isTocDisabled={!getValues('id')}
            onSetSummaryOpen={onSetSummaryOpen}
          />
        </FeatureFlagWrapper>
        <BackButton btnSelector="builder-back-btn" />
        <ProposalName />
        <div className="f f--align-center actionbar__buttons__container m-left-auto">
          {getValues('publishStatus') !== 'archived' && (
            <SaveNewVersionButton
              publishStatus={getValues('publishStatus')}
              isSaved={getValues('saved')}
              isEditorActions
            />
          )}

          {proposal?.id && (
            <>
              <SharedButtons
                isEditorActions
                publishStatus={proposal?.publishStatus}
                publishClassName="btn btn__primary btn__med actionbar__editor__actions"
                copyToClipboardClassName="btn btn__secondary btn__med actionbar__editor__actions"
                previewClassName="btn btn__outline btn__med actionbar__editor__actions"
                returnToDraftClassName="btn btn__outline btn__med actionbar__editor__actions"
                unpublishClassName="btn btn__outline btn__med actionbar__editor__actions"
              />
              <ActionDrawer
                className="actionbar__drawer__editor"
                editorClassName="actionbar__drawer__editor__btn"
                btnSelector="more-option-btn"
              >
                <ActionDrawerButtons
                  proposal={proposal}
                  publishStatus={proposal?.publishStatus}
                  isEditorActions
                  archiveClassName="actionbar__drawer__btn"
                  deleteClassName="actionbar__drawer__btn"
                  duplicateAsTemplateClassName="actionbar__drawer__btn"
                  duplicateClassName="actionbar__drawer__btn"
                  unpublishClassName="actionbar__drawer__btn"
                  previewClassName="actionbar__drawer__btn d--mobile--only"
                />
              </ActionDrawer>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
