import { settingsSelector } from 'modules/settings';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCountryISOFromName } from 'shared/utils';

export function useISOCountry(country?: string | null) {
  const { config } = useSelector(settingsSelector.getState);
  const ISOCountry = getCountryISOFromName(
    config?.address.country || country || 'United States',
  );

  const isISOCountryLoading = useMemo(() => !ISOCountry, [ISOCountry]);

  return { ISOCountry, isISOCountryLoading };
}
