import { atom } from 'recoil';

export const organizationIdAtom = atom<string>({
  key: 'organizationId',
  default: 'public',
});

export const isLegacyFreeUserAtom = atom<boolean>({
  key: 'isLegacyFreeUser',
  default: false,
});
