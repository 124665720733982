import {
  Settings,
  isOnboardingLastStepAtom,
  useConfigSelector,
  useSettings,
  useTaxActions,
} from 'modules/settings';
import { useStripeSubscription } from 'modules/subscription/hooks/subscription';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useConnectionErrorHandling } from 'shared/hooks';
import { toast } from 'sonner';

export function useOnboarding() {
  const isInternetOnline = useConnectionErrorHandling();
  const config = useConfigSelector();
  const { onOnboardFinish } = useTaxActions();
  const { createAsync, updateAsync } = useSettings();
  const [isOnboardingLastStep, setIsOnboardingLastStep] = useRecoilState(
    isOnboardingLastStepAtom,
  );
  const { createStripeSubscription } = useStripeSubscription();
  const [currentStep, setCurrentStep] = useState(1);
  const [isCreatingSubscription, setIsCreatingSubscription] = useState(false);

  async function onOnboardingFinished(data: Settings) {
    try {
      setIsCreatingSubscription(true);
      setIsOnboardingLastStep(true);
      await createStripeSubscription({
        isFreeTrial: true,
      });
      await onOnboardFinish({
        ...data,
        isOnboardingInProgress: false,
      });
    } catch {
      toast.error(
        'Whoops, something went wrong when saving your data. Try again.',
      );
    } finally {
      setIsCreatingSubscription(false);
    }
  }

  async function handleCompanyInfoStep(data: Settings) {
    try {
      await updateAsync(data);
      setCurrentStep(currentStep + 1);
    } catch {
      toast.error(
        'Whoops, something went wrong when saving your data. Try again.',
      );
    }
  }

  async function handleReferralStep(data: Settings) {
    try {
      await updateAsync(data);
      setCurrentStep(currentStep + 1);
    } catch {
      toast.error(
        'Whoops, something went wrong when saving your data. Try again.',
      );
    }
  }

  async function handleAddressStep(data: Settings) {
    try {
      await updateAsync(data);
      setCurrentStep(currentStep + 1);
    } catch {
      toast.error(
        'Whoops, something went wrong when saving your data. Try again.',
      );
    }
  }

  useEffect(() => {
    if (isInternetOnline === 'offline') {
      toast.error('No internet connection');
      return;
    }
    if (config) {
      return;
    }
    createAsync({
      ...new Settings(),
      isOnboardingInProgress: true,
    });
  }, [config, isInternetOnline]);

  return {
    config,
    currentStep,
    isOnboardingLastStep,
    isCreatingSubscription,
    setCurrentStep,
    handleAddressStep,
    handleCompanyInfoStep,
    handleReferralStep,
    onOnboardingFinished,
    setIsOnboardingLastStep,
  };
}
