import React from 'react';
import { RenderElementProps } from 'slate-react';
import { RichTextLinkElement } from './fragments';

interface Props extends RenderElementProps {
  index: number;
  isReadOnly?: boolean;
}

export const RichTextElement: React.FC<Props> = ({
  index,
  attributes,
  children,
  isReadOnly,
  element,
}) => {
  function renderElement() {
    switch (element.type) {
      case 'blockquote':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'ul':
        return <ul {...attributes}>{children}</ul>;
      case 'li':
        return <li {...attributes}>{children}</li>;
      case 'ol':
        return <ol {...attributes}>{children}</ol>;
      case 'link':
        return (
          <RichTextLinkElement
            attributes={attributes}
            element={element}
            isReadOnly={isReadOnly}
            index={index}
          >
            {children}
          </RichTextLinkElement>
        );
      default:
        return <p {...attributes}>{children}</p>;
    }
  }
  return <>{renderElement()}</>;
};
