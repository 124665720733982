import { useDualPricing } from 'modules/proposals/hooks';
import { PropsWithChildren } from 'react';

interface Props {
  currency: Proposal['currency'];
  dualPriceElement: JSX.Element;
  singlePriceElement: JSX.Element;
}

export function Price({
  currency,
  dualPriceElement,
  singlePriceElement,
}: PropsWithChildren<Props>) {
  const { isCurrencyWithDualPricing } = useDualPricing(currency);

  if (isCurrencyWithDualPricing) return dualPriceElement;

  return singlePriceElement;
}
