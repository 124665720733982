import { FirestoreError } from 'firebase/firestore';
import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreEntitites } from 'modules/firebase';
import { toast } from 'sonner';

export function useClients() {
  const organizationId = useOrganizationIdSelector();

  const entityFunctions = useFirestoreEntitites<Client>(
    'clients',
    organizationId,
  );

  async function createEntityAsync(data: Omit<Client, 'id'>) {
    try {
      const documentReference = await entityFunctions.createEntityAsync(data);
      if (documentReference instanceof FirestoreError) throw FirestoreError;
      toast.success(`Client ${data.name} has been successfully added.`);
      return documentReference.id;
    } catch {
      toast.error(
        `We couldn't save the client with name ${data.name}. Try again later.`,
      );
      return;
    }
  }

  async function updateEntityAsync(entityId: string, data: Omit<Client, 'id'>) {
    try {
      await entityFunctions.updateEntityAsync(entityId, data);
      toast.success(
        `Client with name ${data.name} has successfully been updated.`,
      );
    } catch {
      toast.error(
        `We couldn't update the client with name ${data.name}. Try again later.`,
      );
    }
  }

  async function deleteEntityAsync(entityId: string) {
    try {
      await entityFunctions.deleteEntityAsync(entityId);
      toast.success(`Client has successfully been deleted.`);
    } catch {
      toast.error(`We couldn't delete the client. Try again later.`);
    }
  }

  return {
    ...entityFunctions,
    createEntityAsync,
    updateEntityAsync,
    deleteEntityAsync,
  };
}
