import { format } from 'date-fns';
import { isLegacyFreeUserAtom } from 'modules/authorization';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { getGracePeriodFormattedDate } from 'shared/utils';
import { useStripeHelpers } from '../hooks';
import { ProductVatExcludedTooltip } from './ProductVatExcludedTooltip';

interface Props {
  end?: number | 'permanent' | null;
  isGracePeriod?: boolean | null;
  isTierOne: boolean;
  price: StripePrice;
  discountPercentage?: number | null;
  isFreeTrialActive?: boolean | null;
  isFreeTrialEnded?: boolean | null;
}

export const CurrentPlanCardHeaderInfo: React.FC<Props> = ({
  end,
  isGracePeriod,
  isTierOne,
  price,
  discountPercentage,
  isFreeTrialActive,
  isFreeTrialEnded,
}) => {
  const isLegacyFreeUser = useRecoilValue(isLegacyFreeUserAtom);
  const { getProductPriceStringByInterval, getPriceWithDiscount } =
    useStripeHelpers();
  const formattedPrice = getProductPriceStringByInterval(
    price,
    price.recurring?.interval,
  );
  const priceWithDiscount = getPriceWithDiscount(
    formattedPrice?.price,
    discountPercentage,
  );

  function renderPlanCardExpiration(end: number) {
    if (isGracePeriod) return getGracePeriodFormattedDate(end);

    if (!isTierOne && isLegacyFreeUser) return 'Free Forever';

    return format(end, 'MMM dd, yyyy');
  }

  function renderPlanCardEnd() {
    if (isFreeTrialEnded && !isLegacyFreeUser) return null;

    return (
      <div
        className="f f--col f--align-start u-width--half"
        data-cy="plan-card-end"
      >
        <span className="s-bottom--tny t-text-2 text--sm">
          <FormattedMessage id="current-plan-card.end-date" />
        </span>
        <p className="text--sm t-text-1 t-bold">
          {typeof end === 'number' ? renderPlanCardExpiration(end) : 'Lifetime'}
        </p>
      </div>
    );
  }

  function renderPlanCardPrice() {
    if (
      isFreeTrialActive ||
      isFreeTrialEnded ||
      (isLegacyFreeUser && !isTierOne)
    ) {
      return (
        <div
          className="f f--col f--align-start u-width--half"
          data-cy="current-plan-price-label"
        >
          <span className="s-bottom--tny t-text-2 text--sm">
            <FormattedMessage id="current-plan-card.price" />
          </span>
          <p className="text--sm t-text-1 t-bold">
            <FormattedMessage id="current-plan-card.free-price" />
          </p>
        </div>
      );
    }

    if (!discountPercentage) {
      return (
        <>
          <span className="s-bottom--tny t-text-2 text--sm">
            <FormattedMessage id="current-plan-card.price" />
          </span>
          <div className="f">
            <p className="text--sm t-text-1 t-bold">
              {end === 'permanent' ? 'AppSumo' : formattedPrice.price}
            </p>
            {isTierOne && end !== 'permanent' && (
              <Fragment>
                <span className="text--tny s-left--sml t-text-2 t-letter-1 f--align-self-end">
                  (
                  <FormattedMessage id="current-plan-card.label.vat-excluded" />
                  )
                </span>
                <ProductVatExcludedTooltip className="f--align-self-end" />
              </Fragment>
            )}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="f f--col f--align-start">
          <div className="f f--align-center g-4">
            <span className="t-text-2 text--sm">
              <FormattedMessage id="current-plan-card.price" />
            </span>
            <span className="t-primary t-bold text--tny">
              -{discountPercentage}%{' '}
            </span>
          </div>
          <div className="f f--align-center">
            <div className="f f--align-start g-4 subscription-settings-plan__discount">
              <p className="text--sm t-text-2 t-bold t-strike">
                {formattedPrice.price}
              </p>
              <p className="text--sm t-text-1 t-bold">{priceWithDiscount}</p>
            </div>
            <div className="f">
              <span className="text--tny s-left--sml t-text-2 t-letter-1 f--align-self-end">
                (
                <FormattedMessage id="current-plan-card.label.vat-excluded" />)
              </span>
              <ProductVatExcludedTooltip className="f--align-self-end" />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="f f--align-center">
        {renderPlanCardEnd()}
        <div
          className="f f--col f--align-start u-width--half"
          data-cy="plan-card-price"
        >
          {renderPlanCardPrice()}
        </div>
      </div>
      <div className="subscription-settings-plan__divider" />
    </>
  );
};
