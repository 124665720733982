import { useFunction } from 'modules/firebase';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'sonner';
import { adminAction, adminSelector } from '../redux';

interface Values {
  email?: string;
  uid?: string;
}

export const useUsersAnalytics = () => {
  const getRegisteredUsers = useFunction<undefined, AnalyticsUser[]>(
    'getRegisteredUsers',
  );
  const dispatch = useDispatch();
  const userAnalytics = useSelector(adminSelector.getState).userAnalytics;

  const searchUsers = ({ email, uid }: Values) => {
    try {
      const filteredUsers = userAnalytics.users.filter(
        (user) =>
          (email && user.userRecord?.email?.includes(email)) ||
          (uid && user.userRecord?.uid?.includes(uid)),
      );

      return filteredUsers;
    } catch (error) {
      dispatch(adminAction.error(error));
      toast.error('Failed to search users. Please try again.');
      return null;
    }
  };

  const getUsers = useCallback(async () => {
    try {
      dispatch(adminAction.loading());

      const { data } = await getRegisteredUsers();
      if (!data.success) throw new Error(data.message);

      dispatch(adminAction.addUsersData(data.payload));
    } catch (error) {
      adminAction.error(error);
      toast.error('Failed to get users. Please try again.');
    }
  }, [getRegisteredUsers]);

  useEffect(() => {
    if (!getRegisteredUsers) return;
    getUsers();
  }, [getRegisteredUsers]);

  return { getUsers, searchUsers };
};
