import { addDays, isAfter } from 'date-fns';

export function useLastWeekUsers(sortedUsers: AnalyticsUser[]) {
  const lastWeekDate = new Date(addDays(new Date(), -7));
  const lastWeekUsers = sortedUsers?.filter((value) =>
    isAfter(
      new Date(value.userRecord?.metadata.creationTime).getTime(),
      lastWeekDate.getTime(),
    ),
  );

  return { lastWeekUsers };
}
