import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  contentSelector?: string;
  className?: string;
}

export const ModalContent: React.FC<Props> = ({
  children,
  contentSelector,
  className,
}) => {
  return (
    <div
      className={`modal__content ${className || ''}`}
      data-cy={contentSelector || ''}
      id="modal-content"
    >
      {children}
    </div>
  );
};
