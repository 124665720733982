import { motion } from 'framer-motion';
import { useProposalStyle } from 'modules/proposals';
import React from 'react';
import { ProposalAppearanceAccordionFreemiumList } from './ProposalAppearanceAccordionFreemiumList';
import {
  ProposalAppearanceAccordionColorList,
  ProposalAppearanceAccordionFontPicker,
  ProposalAppearanceAccordionPresetsList,
} from './fragments';

export type AccordionType = 'presets' | 'company-logo' | 'color' | 'typography';

const variants = {
  initial: { opacity: 0, y: -8 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -8 },
};

interface Props {
  proposal: PublishStatusAware<Proposal> | Proposal;
  isSubscribed?: boolean;
}

export const ProposalAppearanceAccordionList: React.FC<Props> = ({
  proposal,
  isSubscribed,
}) => {
  const { currentPreset, expandedAccordions, handlePreset, handleToggle } =
    useProposalStyle(proposal);

  if (!isSubscribed) {
    return <ProposalAppearanceAccordionFreemiumList />;
  }

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={variants}
      transition={{ duration: 0.15 }}
      className="proposal__summary__accordion__container"
    >
      <ProposalAppearanceAccordionPresetsList
        activePreset={currentPreset}
        isSectionActive={expandedAccordions.includes('presets')}
        handleToggle={handleToggle}
        handlePreset={handlePreset}
      />
      <>
        <ProposalAppearanceAccordionColorList
          proposal={proposal}
          isSectionActive={expandedAccordions.includes('color')}
          handleToggle={handleToggle}
        />
        <ProposalAppearanceAccordionFontPicker
          isSectionActive={expandedAccordions.includes('typography')}
          proposal={proposal}
          handleToggle={handleToggle}
        />
      </>
    </motion.div>
  );
};
