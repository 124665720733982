import filterIcon from 'assets/icons/icon-filter-12.svg';
import { useOverviewFiltersState } from 'modules/proposals-overview';
import React from 'react';
import { Button } from 'shared/components';
import { useAnimatedDropdown } from 'shared/hooks';
import { ProposalOverviewFilterModal } from './ProposalOverviewFilterModal';

interface Props {
  filterOption: FilterOption | null;
  onSetFilterOption: (option: FilterOption | null) => void;
  handleRemoveFilter: (index: number) => void;
}

export const ProposalOverviewMobileFilter: React.FC<Props> = ({
  filterOption,
  onSetFilterOption,
  handleRemoveFilter,
}) => {
  const [filters, setFilters] = useOverviewFiltersState();
  const { isOpen, handleCloseDropdownToggle, handleOpenDropdownToggle } =
    useAnimatedDropdown();

  return (
    <div data-cy="proposal-overview-filter-container">
      <Button
        type="button"
        style="outline"
        size="sml"
        className={`${
          Boolean(filters?.length) && 'overview__filter__btn__active'
        } ${isOpen && 'btn__open'} overview__filter__btn`}
        onClick={isOpen ? handleCloseDropdownToggle : handleOpenDropdownToggle}
      >
        <img src={filterIcon} className="btn__icon" alt="FilterIcon" />
        {Boolean(filters?.length) && (
          <div className="proposal-overview__filter__counter" />
        )}
      </Button>
      {isOpen && (
        <ProposalOverviewFilterModal
          filters={filters}
          filterOption={filterOption}
          onSetFilterOption={onSetFilterOption}
          onSetFilters={setFilters}
          onClose={handleCloseDropdownToggle}
          handleRemoveFilter={handleRemoveFilter}
        />
      )}
    </div>
  );
};
