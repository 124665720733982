import { useOrganizatonUsersSubscription } from 'modules/authorization';
import { useClientsSubscription } from 'modules/clients';
import { useProposalSubscription } from 'modules/proposals';
import { useServicesSubscription } from 'modules/services';
import { useSettingsSubscription } from 'modules/settings';
import { useSubscriptionListener } from 'modules/subscription';
import { useUnitsSubscription } from 'modules/units';
import { useEmailVerificationCheckListener } from './useEmailVerificationCheckListener';

export function useDashboardSubscriptions() {
  useSubscriptionListener();
  useSettingsSubscription();
  useProposalSubscription();
  useClientsSubscription();
  useUnitsSubscription();
  useServicesSubscription();
  useOrganizatonUsersSubscription();
  useEmailVerificationCheckListener();
}
