import closeIcon from 'assets/icons/icon-close-10.svg';
import collapseIcon from 'assets/icons/icon-collapse-16.svg';
import dragIcon from 'assets/icons/icon-drag-16-secondary.svg';
import expandIcon from 'assets/icons/icon-expand-16.svg';
import downIcon from 'assets/icons/icon-move-down-16.svg';
import upIcon from 'assets/icons/icon-move-up-16.svg';
import React from 'react';
import { Button } from 'shared/components';

interface Props {
  file: ProposalFile;
  index: number;
  proposalFilesLength: number;
  isExpanded: boolean;
  onRemoveFile: (file: ProposalFile) => void;
  onSetIsExpanded: (isExpanded: boolean) => void;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

export const MediaListItemControls: React.FC<Props> = ({
  file,
  index,
  proposalFilesLength,
  isExpanded,
  onRemoveFile,
  onSetIsExpanded,
  moveItem,
}) => {
  return (
    <div className="media-input__uploaded__icons__container">
      <Button
        type="button"
        className="media-input__uploaditem__dragger"
        data-cy={`media-drag-and-drop-btn-${index}`}
      >
        <img src={dragIcon} alt="DragIcon" />
      </Button>
      <div className="d--mobile--only">
        <div
          className={`proposal-service__up--mobile media-input__uploaded__move--btn__container ${
            index === 0 && 'proposal-service__move--disabled'
          }`}
          onClick={() => moveItem(index, 'up')}
          data-cy={`drag-and-drop-btn-${index}`}
        >
          <img src={upIcon} alt="DragIcon" />
        </div>
        <div
          className={`proposal-service__down--mobile media-input__uploaded__move--btn__container ${
            index === proposalFilesLength - 1 &&
            'proposal-service__move--disabled'
          }`}
          onClick={() => moveItem(index, 'down')}
          data-cy={`drag-and-drop-btn-${index}`}
        >
          <img src={downIcon} alt="DragIcon" />
        </div>
      </div>
      {!isExpanded ? (
        <Button
          type="button"
          className="media-input__uploadeditem__control--btn media-input__uploadeditem__collapse--btn"
          onClick={() => onSetIsExpanded(!isExpanded)}
          btnSelector={`media-image-expand-${index}`}
        >
          <img src={expandIcon} alt="ExpandIcon" />
        </Button>
      ) : (
        <Button
          type="button"
          className="media-input__uploadeditem__control--btn media-input__uploadeditem__collapse--btn"
          onClick={() => onSetIsExpanded(!isExpanded)}
          btnSelector={`media-image-collapse-${index}`}
        >
          <img src={collapseIcon} alt="CollapseIcon" />
        </Button>
      )}

      <Button
        className="media-input__uploadeditem__close--btn"
        onClick={() => onRemoveFile(file)}
        btnSelector={`media-image-close-${index}`}
      >
        <img src={closeIcon} alt="CloseIcon" />
      </Button>
    </div>
  );
};
