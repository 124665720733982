import backIcon from 'assets/icons/icon-arrow-left-12-primary.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { PageProps } from 'gatsby';
import { useOnboarding } from 'modules/onboarding';
import { StripePlansController } from 'modules/subscription';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  DashboardFooter,
  LoadingSpinner,
  PublicLayout,
  SEO,
  TawkLiveChat,
} from 'shared/components';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';
import { OnboardingCompanyInfo as CompanyInfoStep } from '../onboarding-company-info';
import { OnboardingStepper } from '../onboarding-stepper';
import { OnboardingTax as TaxStep } from '../onboarding-tax';
import { OnboardingSuccessStep } from './fragments';
import { AddressStep } from './onboarding-address';
import { ReferralStep } from './referral-step';

const stepsAnimation = {
  hidden: { opacity: 0, y: 8 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, delay: 0.3 } },
  exit: { opacity: 0, transition: { duration: 0.3 } },
};

export const Onboarding: React.FC<PageProps> = ({ location }) => {
  const { formatMessage } = useIntl();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();

  const {
    config,
    currentStep,
    isOnboardingLastStep,
    isCreatingSubscription,
    setCurrentStep,
    handleAddressStep,
    handleReferralStep,
    handleCompanyInfoStep,
    onOnboardingFinished,
    setIsOnboardingLastStep,
  } = useOnboarding();

  function renderStep(config: Settings) {
    switch (currentStep) {
      case 1:
        return (
          <>
            <header className="onboarding__box__header">
              <h1
                className="text--xl t-bold t-black"
                data-cy="referral-question"
              >
                <FormattedMessage id="onboarding.referral-step.title" />
              </h1>
              <p className="text--sm t-text-2 u-maxwidth--500">
                <FormattedMessage id="onboarding.referral-step.description" />
              </p>
            </header>
            <ReferralStep config={config} onSubmit={handleReferralStep}>
              <div className="field__onboarding-form-buttons referral-step__actions">
                <Button
                  type="submit"
                  size="lrg"
                  style="primary"
                  ref={submitButtonRef}
                  btnSelector="submit-company-onbording-btn"
                  className="u--width--full--mobile"
                >
                  Next
                </Button>
              </div>
            </ReferralStep>
          </>
        );
      case 2:
        return (
          <>
            <header className="onboarding__box__header">
              <h1 className="text--xl t-bold t-black" data-cy="hello-message">
                <FormattedMessage id="onboarding.title" />
              </h1>
              <p className="text--sm t-text-2 u-maxwidth--500">
                <FormattedMessage id="onboarding.description" />
              </p>
            </header>
            <CompanyInfoStep
              config={config}
              formClassName="f f--col"
              onSubmit={handleCompanyInfoStep}
            >
              <div className="field__onboarding-form-buttons f--justify-between btn-group field__onboarding--simple">
                <Button
                  type="button"
                  ref={closeButtonRef}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="onboarding__back__button c-pointer"
                  btnSelector="onboarding-back-btn"
                >
                  <div className="actionbar__buttons__back__img__container">
                    <img src={backIcon} alt="BackIcon" className="btn__icon" />
                  </div>
                  <span className="text--sm t-text-1">
                    <FormattedMessage id="buttons.back" />
                  </span>
                </Button>
                <Button
                  type="submit"
                  size="lrg"
                  style="primary"
                  ref={submitButtonRef}
                  btnSelector="onboarding-submit-btn"
                >
                  Next
                </Button>
              </div>
            </CompanyInfoStep>
          </>
        );
      case 3:
        return (
          <AddressStep config={config} onSubmit={handleAddressStep}>
            <div className="field__onboarding-form-buttons f--justify-between btn-group field__onboarding--simple">
              <Button
                type="button"
                ref={closeButtonRef}
                onClick={() => setCurrentStep(currentStep - 1)}
                className="onboarding__back__button c-pointer"
                btnSelector="onboarding-back-btn"
              >
                <div className="actionbar__buttons__back__img__container">
                  <img src={backIcon} alt="BackIcon" className="btn__icon" />
                </div>
                <span className="text--sm t-text-1">
                  <FormattedMessage id="buttons.back" />
                </span>
              </Button>
              <Button
                type="submit"
                size="lrg"
                style="primary"
                ref={submitButtonRef}
                btnSelector="onboarding-submit-btn"
              >
                Next
              </Button>
            </div>
          </AddressStep>
        );
      case 4:
        return (
          <>
            <header className="onboarding__box__header">
              <h1 className="text--xl t-bold t-black" data-cy="hello-message">
                <FormattedMessage id="onboarding.stepper.step-3.title" />
              </h1>
              <p className="text--sm t-text-2 u-maxwidth--500">
                <FormattedMessage id="inputs.vat-percentage.description" />
              </p>
            </header>
            <TaxStep config={config} onSubmit={onOnboardingFinished}>
              <div className="field__onboarding-form-buttons f--justify-between btn-group field__onboarding--simple">
                <Button
                  type="button"
                  ref={closeButtonRef}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="onboarding__back__button c-pointer"
                  btnSelector="onboarding-back-btn"
                >
                  <div className="actionbar__buttons__back__img__container">
                    <img src={backIcon} alt="BackIcon" className="btn__icon" />
                  </div>
                  <span className="text--sm t-text-1">
                    <FormattedMessage id="buttons.back" />
                  </span>
                </Button>

                {isCreatingSubscription ? (
                  <Button
                    type="button"
                    disabled={isCreatingSubscription}
                    size="lrg"
                    style="primary"
                  >
                    <LoadingSpinner height={16} width={16} type="negative" />
                    <p className="s-left--med">
                      <FormattedMessage id="free-subscription.subscribe.api.loading" />
                    </p>
                  </Button>
                ) : (
                  <Button
                    size="lrg"
                    style="primary"
                    type="submit"
                    btnSelector="onboarding-submit-btn"
                  >
                    <FormattedMessage id="buttons.get-started" />
                  </Button>
                )}
              </div>
            </TaxStep>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <SEO
        title={formatMessage({ id: 'onboarding.seo.title' })}
        description={formatMessage({ id: 'onboarding.seo.description' })}
        href={location.href}
      />

      {isOnboardingLastStep ? (
        <OnboardingSuccessStep
          isCreatingSubscription={isCreatingSubscription}
          onSetIsOnboardingLastStep={setIsOnboardingLastStep}
        />
      ) : (
        <PublicLayout
          rootUrl={formatMessage({ id: 'routes.home' })}
          footerComponent={DashboardFooter}
          headerComponent={currentStep !== 1 ? OnboardingStepper : undefined}
          footerClassName="onboarding__footer"
          headerClassName={`onboarding__header ${currentStep === 1 && 'referral-step__header'}`}
          brandingClassName="onboarding__branding"
          contentClassName="onboarding__content"
          headerCurrentStep={currentStep}
          navButtons={['logout']}
        >
          <AnimatePresence>
            <div className="onboarding__layout">
              <section className="onboarding__box">
                <motion.div
                  key={currentStep}
                  variants={stepsAnimation}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="onboarding__box__content"
                >
                  {config && renderStep(config)}
                </motion.div>
              </section>
            </div>
          </AnimatePresence>
        </PublicLayout>
      )}
      <StripePlansController />
      <TawkLiveChat />
    </>
  );
};
