import React from 'react';

interface Props {
  className?: string;
  strokeColor?: string;
  fillColor?: string;
}

export const UploadedIcon: React.FC<Props> = ({ className, fillColor }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 0.5V5.5H14.5M9.5 0.5H1.5V15.5H14.5V5.5M9.5 0.5L14.5 5.5"
        stroke="#706F6F"
        fill={fillColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
