import { useFormGuard } from 'modules/form';
import { Settings, useTaxRedirects } from 'modules/settings';
import React, { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  OnboardingDefaultCurrency,
  OnboardingVatIdInput,
  OnboardingVatPercentageInput,
} from './fragments';

interface Props extends PropsWithChildren {
  config: Settings;
  onSubmit: (data: Settings) => void;
}

export const OnboardingTax: React.FC<Props> = ({
  config,
  onSubmit,
  children,
}) => {
  useTaxRedirects();
  const form = useForm<Settings>({
    mode: 'onSubmit',
    defaultValues: { ...config },
  });
  useFormGuard(form);

  return (
    <FormProvider {...form}>
      <form
        className="onboarding__tax__wrapper"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="f f--col">
          <div className="onboarding__tax__group">
            <div>
              <OnboardingVatIdInput />
            </div>
            <div className="field__half--width">
              <OnboardingVatPercentageInput className="u-height--40 onboarding__vat__id__input" />
            </div>
          </div>
          <div className="s-top--tny f f--col">
            <span className="text--sm t-text-2 s-bottom--sml">
              Set your default currency for all proposals. You can change this
              anytime.
            </span>
            <OnboardingDefaultCurrency />
          </div>
        </div>
        {children}
      </form>
    </FormProvider>
  );
};
