/**
 * @description Used to check if validity for proposal should be skipped.
 * If next statuses are true, validity check is skipped.
 * Validity should not be checked for draft, archived, template and proposals that are being unpublished.
 */

export function shouldSkipValidityCheck(
  publishStatus: PublishStatus,
  nextPublishStatus: 'deleted' | PublishStatus,
) {
  const isNextPublishStatusTemplate = nextPublishStatus === 'template';
  const isDraft = publishStatus === 'draft' && nextPublishStatus === 'draft';

  return isNextPublishStatusTemplate || isDraft;
}
