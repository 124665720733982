/**
 * Checks if a value is an object.
 * @param {any} object - The value to check.
 * @returns {boolean} - Returns true if the value is an object, false otherwise.
 */
function isObject(object: any): boolean {
  return object != null && typeof object === 'object';
}

/**
 * Deeply compares two objects for equality.
 * @param {T} obj1 - The first object to compare.
 * @param {T} obj2 - The second object to compare.
 * @param {keyof T} ignoreProperty - The property to ignore when comparing.
 * @returns {boolean} - Returns true if the objects are deeply equal, false otherwise.
 */

export function isDeepEqual<
  T extends {
    [key: string]: any;
  },
>(obj1: T, obj2: T, ignoreProperty?: keyof T): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (
    obj1 == null ||
    obj2 == null ||
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object'
  ) {
    return false;
  }

  const keys1 = Object?.keys(obj1);
  const keys2 = Object?.keys(obj2);

  if (ignoreProperty) {
    keys1?.splice(keys1?.indexOf(ignoreProperty as string), 1);
    keys2?.splice(keys2?.indexOf(ignoreProperty as string), 1);
  }

  if (keys1?.length !== keys2?.length) {
    return false;
  }

  for (const key of keys1) {
    if (ignoreProperty === key) continue;

    if (!keys2?.includes(key)) {
      return false;
    }

    const val1 = obj1?.[key];
    const val2 = obj2?.[key];
    const areObjects = isObject(val1) && isObject(val2);

    if (
      (areObjects && !isDeepEqual(val1, val2, ignoreProperty)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}
