import { SlateApiReturn } from 'modules/editor';
import React from 'react';
import Icon from 'react-icons-kit';
import { link } from 'react-icons-kit/fa/link';
import { unlink } from 'react-icons-kit/fa/unlink';
import { Button } from 'shared/components';
import { isLinkActive } from 'shared/utils';
import { useSlateStatic } from 'slate-react';
import { RichTextToolbarLinkModal } from './RichTextToolbarLinkModal';

interface Props {
  editorApi: SlateApiReturn;
}

export const RichTextToolbarLinkButton: React.FC<Props> = ({ editorApi }) => {
  const editor = useSlateStatic();
  const isActive = isLinkActive(editor);

  const {
    onSetText,
    onSetUrl,
    handleLinkMouseDown,
    handleSave,
    linkModal: { isModalOpen, closeModal },
    url,
    text,
  } = editorApi;

  function getIcon() {
    if (isActive) return unlink;
    return link;
  }

  let className = 'slate__toolbar__icon';
  if (isActive) className += ' slate__toolbar__icon--active';

  return (
    <>
      <Button
        type="button"
        className={className}
        onMouseDown={handleLinkMouseDown}
        btnSelector="slate-link-btn"
      >
        <Icon icon={getIcon()}>{getIcon()}</Icon>
      </Button>

      {isModalOpen && (
        <RichTextToolbarLinkModal
          defaultUrl={url}
          defaultText={text}
          onHandleSave={handleSave}
          onClose={closeModal}
          onSetText={onSetText}
          onSetUrl={onSetUrl}
        />
      )}
    </>
  );
};
