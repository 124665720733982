import { DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';

export function useMediaAttachmentList(
  proposalFiles: ProposalFile[],
  index: number,
) {
  const { setValue } = useFormContext<PublishStatusAware<Proposal>>();

  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const items = Array.from(proposalFiles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setValue(`segments.${index}.files`, items, {
      shouldDirty: true,
    });
  }

  function moveItem(index: number, direction: 'up' | 'down') {
    if (direction === 'up' && index === 0) return;
    if (direction === 'down' && index === proposalFiles?.length - 1) {
      return;
    }
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    const reorderedItems = Array.from(proposalFiles);
    const [movedItem] = reorderedItems.splice(index, 1);
    reorderedItems.splice(newIndex, 0, movedItem);

    setValue(`segments.${index}.files`, reorderedItems, {
      shouldDirty: true,
    });
  }

  return {
    handleOnDragEnd,
    moveItem,
  };
}
