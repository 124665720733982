import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Unit } from '../Unit';

export const unitsFirestoreConverter = {
  toFirestore: (unit: Unit) => ({
    ...new Unit(unit),
  }),
  fromFirestore: (doc: QueryDocumentSnapshot<Unit>) =>
    new Unit({ id: doc.id, ...doc.data() }),
};
