import { useAdminRightsManagement } from 'modules/admin';
import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, FieldWrapper, LoadingSpinner } from 'shared/components';
import { emailRegex } from 'shared/utils';

interface FormValues {
  email: string | null;
}

export const AdminUserForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const form = useForm<FormValues>();

  const { loading, changeAdminRole } = useAdminRightsManagement();

  function handleSubmit({ email }: { email: string | null }) {
    if (!email) {
      return;
    }

    changeAdminRole(email, 'superAdmin');
  }

  return (
    <FormProvider {...form}>
      <form
        className="admin-dashboard__admin-user__form"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FieldWrapper
          name="email"
          errors={form.formState.errors}
          labelId="inputs.email.label"
          containerClassName="u-width--full"
          isRequired
        >
          <input
            type="email"
            className={`input input__med input__box u-width--full ${
              form.formState.errors.email ? 'input__box__error' : ''
            }`}
            placeholder={formatMessage({ id: 'inputs.email.placeholder' })}
            data-cy="add-admin-email-input"
            onFocus={handleFocus}
            {...form.register('email', {
              required: formatMessage({ id: 'inputs.email.required' }),
              pattern: {
                value: emailRegex,
                message: formatMessage({ id: 'inputs.email.invalid' }),
              },
            })}
          />
        </FieldWrapper>
        {loading ? (
          <Button
            type="button"
            disabled={loading}
            size="lrg"
            className="s-top--med u--width--full--mobile"
            style="primary"
          >
            <LoadingSpinner height={12} width={12} type="negative" />
            <p className="s-left--med">
              <FormattedMessage id="buttons.saving" />
            </p>
          </Button>
        ) : (
          <Button
            style="primary"
            size="lrg"
            type="submit"
            btnSelector="admin-users-form-btn"
            className="s-top--med u--width--full--mobile"
          >
            <FormattedMessage id="inputs.admin.button" />
          </Button>
        )}
      </form>
    </FormProvider>
  );
};
