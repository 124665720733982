import { PageProps } from 'gatsby';
import {
  UserListError,
  UsersSearch,
  UsersTable,
  adminSelector,
  useActiveUsersStats,
  useLastWeekUsers,
  useUsersAnalytics,
} from 'modules/admin';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Loading, Pagination } from 'shared/components';
import { usePaginationPages } from 'shared/hooks';
import { AdminLayout } from '../components/admin-layout';

export const AdminUsersOverview: React.FC<PageProps> = () => {
  useActiveUsersStats();
  const { isLoading, userAnalytics, numberOfActiveUsersLastMonth } =
    useSelector(adminSelector.getState);
  const { lastWeekUsers } = useLastWeekUsers(userAnalytics.users);
  const [isSearching, setIsSearching] = useState(false);
  const usersLength = useMemo(
    () => userAnalytics?.users?.length,
    [userAnalytics?.users],
  );
  const userNotFound = useMemo(
    () => !usersLength && !isLoading && isSearching,
    [usersLength, isLoading, isSearching],
  );
  const loadingTable = useMemo(
    () =>
      isLoading ||
      (numberOfActiveUsersLastMonth > 0 && !usersLength && !isSearching),
    [isLoading, numberOfActiveUsersLastMonth, usersLength, isSearching],
  );

  const { totalPages, onGoToPage, endIndex, startingIndex, pageNumber } =
    usePaginationPages(50, userAnalytics?.users?.length);
  const { formatMessage } = useIntl();
  const { getUsers, searchUsers } = useUsersAnalytics();
  const [searchResults, setSearchResults] = useState<AnalyticsUser[]>([]);

  const handleUserSearch = (values: { email?: string; uid?: string }) => {
    if (!values.email && !values.uid) {
      getUsers();
      return;
    }
    setIsSearching(true);
    setSearchResults(
      searchUsers({
        email: values?.email?.trim(),
        uid: values?.uid?.trim(),
      }) || [],
    );
  };

  const handleSearchReset = () => {
    setIsSearching(false);
  };

  return (
    <>
      <AdminLayout>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="admin-users-title"
        >
          <FormattedMessage id="admin.users.title" />
        </header>
        <div className="admin-dashboard__container">
          <UsersSearch
            onSearch={handleUserSearch}
            onReset={handleSearchReset}
          />
          {userNotFound && <UserListError />}
          {loadingTable ? (
            <>
              <Loading type="list" />
              <Loading type="list" />
              <Loading type="list" />
            </>
          ) : (
            <>
              {!isSearching && (
                <>
                  <p className="t-small">
                    {formatMessage(
                      { id: 'admin.users.last-month' },
                      { number: numberOfActiveUsersLastMonth },
                    )}
                  </p>
                  <p className="t-small">
                    {formatMessage(
                      { id: 'admin.users.new-users' },
                      { number: lastWeekUsers?.length },
                    )}
                  </p>
                </>
              )}

              {!userNotFound && (
                <>
                  <UsersTable
                    users={
                      isSearching
                        ? searchResults
                        : userAnalytics?.users?.slice(startingIndex, endIndex)
                    }
                  />
                  {!isSearching && (
                    <Pagination
                      totalPages={totalPages}
                      pageNumber={pageNumber}
                      onGoToPage={onGoToPage}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </AdminLayout>
    </>
  );
};
