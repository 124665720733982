import React, { useEffect } from 'react';
import { RichTextEditor } from 'shared/components';
import { getServiceDescriptionText } from 'shared/utils';

interface Props {
  item: TextSegment;
  index: number;
  style: Proposal['style'];
}

export const PublicTextSegmentCard: React.FC<Props> = ({
  item,
  index,
  style: { color, typography },
}) => {
  const content = getServiceDescriptionText(item.content);

  if (!content?.length) return null;

  /** Update borders color */
  useEffect(() => {
    if (!color.borders) return;

    document.documentElement.style.setProperty(
      '--proposal-block-text-background',
      color.borders,
    );
  }, [color.borders, item.content]);

  return (
    <div
      className="proposal-block-text"
      id={`text-segment-${index}`}
      style={{
        fontFamily: typography.body.fontFamily,
        fontWeight: typography.body.fontWeight,
      }}
    >
      <RichTextEditor
        value={item.content}
        bodyText={color.bodyText}
        index={index}
      />
    </div>
  );
};
