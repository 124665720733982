import { getFileBaseName } from './getFileBaseName';
import { FilterType } from './getFilteredFiles';

/**
 * This function filters out files that are being uploaded currently.
 */

export function getFilteredAcceptedFiles(files: File[], type: FilterType) {
  const seenNames: string[] = [];

  return files.filter((file) => {
    const baseName = getFileBaseName(file.name);
    if (type === 'duplicate') {
      const isDuplicate = seenNames.includes(baseName);
      if (!isDuplicate) {
        seenNames.push(baseName);
      }
      return isDuplicate;
    }
    const isUnique = !seenNames.includes(baseName);
    if (isUnique) {
      seenNames.push(baseName);
    }
    return isUnique;
  });
}
