import { motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AddIcon, Button } from 'shared/components';

interface Props {
  onClick: VoidFunction;
}

export const UnitListError: React.FC<Props> = ({ onClick }) => (
  <motion.div
    style={{
      opacity: 0,
      y: 8,
    }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1, delay: 0.2, type: 'spring' }}
    className="alert alert--note settings-itemlist__alert__container"
  >
    <div className="f f--col">
      <p className="s-bottom--sml text--xs__responsive t-text-1 t-bold">
        <FormattedMessage id="errors.units.header" />
      </p>
      <p className="text--xsm t-text-2">
        <FormattedMessage id="errors.units.description" />
      </p>
    </div>
    <Button
      type="button"
      size="med"
      onClick={onClick}
      style="secondary"
      className="u-fit--content"
      btnSelector="add-new-unit"
    >
      <AddIcon />
      <FormattedMessage id="buttons.add-new-unit" />
    </Button>
  </motion.div>
);
