import { RoleClaim } from './RoleClaim';

export interface OrganizationUser extends RoleClaim {
  id: string;
  email: string;
  status: InviteStatus;
}

export class OrganizationUser extends RoleClaim {
  constructor(
    id: string,
    email: string,
    status: InviteStatus,
    role: Role,
    organizationId: string,
    organizationName: string,
  ) {
    super(role, organizationId, organizationName);
    this.id = id;
    this.email = email;
    this.status = status;
  }
}
