import { useLocation, useParams } from '@reach/router';
import { subscriptionSelectors } from 'modules/subscription';
import { useRecoilValue } from 'recoil';
import { useProposalExpiredSelector } from '../state';

export function useProposalExpired(
  hasPermissions: boolean,
  proposal: Proposal,
) {
  const { pathname } = useLocation();
  const { organizationId = '' } = useParams() || {};
  const isOrganizationFreeUser = useRecoilValue(
    subscriptionSelectors.getIsFreeUser(organizationId),
  );
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const isProposalExpired = useProposalExpiredSelector(
    proposal,
    !isOrganizationFreeUser || isTierOne,
  );

  return {
    isProposalExpired: isProposalExpired && pathname !== '/demo-proposal/',
    isProposalExpiredSectionEnabled:
      !hasPermissions && isProposalExpired && pathname !== '/demo-proposal/',
  };
}
