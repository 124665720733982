import { subscriptionSelectors } from 'modules/subscription';
import React, { PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';

interface Props extends PropsWithChildren {
  organizationId: string;
}

/** Allow only propoze plus users to use paid features */
export const PropozePlusWrapper: React.FC<Props> = ({
  children,
  organizationId,
}) => {
  const isFreeUser = useRecoilValue(
    subscriptionSelectors.getIsFreeUser(organizationId),
  );
  if (isFreeUser) return null;

  return <>{children}</>;
};
