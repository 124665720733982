import { createEntityState } from 'modules/redux-store';

const INITIAL_STATE = createEntityState<Service>();

export const serviceReducer = (
  state = INITIAL_STATE,
  action:
    | ActionUnion<EntityActions<'services', Service>>
    | Action<'authentication/LOGOUT'>,
): EntityState<Service> => {
  switch (action.type) {
    case 'services/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'services/ON_SNAPSHOT_UPDATE':
      return {
        ...state,
        isLoading: false,
        documents: action.payload.documents,
      };
    case 'services/ON_SNAPSHOT_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case 'authentication/LOGOUT':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
