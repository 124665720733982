import { useRecoilValue } from 'recoil';
import { SUBSCRIPTION_TIER_MAP } from '../const';
import { subscriptionAtoms } from '../state';
import { isSubscriptionTierEnabled } from '../utils';

export function useOrganizationSubscriptionTier(
  organizationId: string,
  required: SubscriptionTierAlias,
) {
  const tiers = useRecoilValue(subscriptionAtoms.organizationTiers);
  const organizationTier = tiers[organizationId];

  const lockEnabled = isSubscriptionTierEnabled(
    organizationTier,
    SUBSCRIPTION_TIER_MAP[required],
  );

  return {
    lockEnabled,
    organizationTier,
  };
}
