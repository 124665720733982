import { ErrorComponentProps } from 'modules/dashboard';
import React, { PropsWithChildren } from 'react';
import { Loading, Pagination } from 'shared/components';
import { usePaginationPages } from 'shared/hooks';

interface Props<T> extends EntityState<T> {
  itemComponent: React.FC<T>;
  limit?: number;
  results?: T[];
  errorComponent?: React.FC<ErrorComponentProps>;
  searchErrorComponent?: React.FC<ErrorComponentProps>;
  loadingType?: LoadingTypes;
  className?: string;
  errorClassName?: string;
  query?: string;
  publishStatus?: PublishStatus;
  onClick?: VoidFunction;
}

export function List<T>(props: PropsWithChildren<Props<T>>) {
  const {
    loadingType = 'card',
    documents,
    results,
    limit = 15,
    children,
    errorComponent: ErrorComponent,
    searchErrorComponent: SearchErrorComponent,
    itemComponent: ItemComponent,
    className,
    errorClassName,
    query,
    publishStatus,
    onClick,
  } = props;

  const { totalPages, onGoToPage, endIndex, startingIndex, pageNumber } =
    usePaginationPages(limit, documents?.length, query);

  if (!documents) {
    return (
      <>
        {[...new Array(3)].map((_, index) => (
          <Loading key={`loading-list-${index}`} type={loadingType} />
        ))}
      </>
    );
  }

  if (!documents?.length && ErrorComponent) {
    return (
      <div className={errorClassName}>
        <ErrorComponent
          onClick={() => onClick?.()}
          publishStatus={publishStatus}
        />
      </div>
    );
  }

  if (!results?.length && SearchErrorComponent) {
    return (
      <div className={errorClassName}>
        <SearchErrorComponent onClick={() => onClick?.()} />
      </div>
    );
  }

  return (
    <div className={className}>
      {children}
      {results
        ?.slice(startingIndex, endIndex)
        ?.map((item, index) => (
          <ItemComponent
            index={index}
            documents={results}
            key={index}
            {...item}
          />
        ))}

      {results && results?.length > limit && (
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          onGoToPage={onGoToPage}
        />
      )}
    </div>
  );
}
