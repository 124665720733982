import { useMemo } from 'react';

interface GroupedFilters {
  [key: string]: {
    count: number;
    currFilters: Filter[];
  };
}

export function useProposalOverviewGroupedFilters(filters: Filter[]) {
  const groupedFilters = useMemo(() => {
    return filters.reduce<GroupedFilters>((acc, filter) => {
      // If the filter type doesn't exist in the accumulator, initialize the count and current filters array.
      if (!acc[filter.type]) {
        acc[filter.type] = { count: 0, currFilters: [] };
      }

      // Check if the current filter's value is already in the currFilters array.
      // This prevents adding duplicate filters.
      if (
        !acc[filter.type].currFilters
          .map((f: Filter) => f.value)
          .includes(filter.value)
      ) {
        acc[filter.type].count += 1;
        acc[filter.type].currFilters.push(filter);
      }

      return acc;
    }, {});
  }, [filters]);

  return groupedFilters;
}
