import { SubscriptionSettings } from 'modules/settings';
import React from 'react';
import { AppRoute } from '../const';
import { ApplicationRoutes } from './ApplicationRoutes';

type Props = PageProps & { proposalsLoading: boolean };

export const SubscriptionEnabledRoutes = ({
  proposalsLoading,
  ...props
}: Props) => [
  ...ApplicationRoutes({ ...props, proposalsLoading }),
  <SubscriptionSettings
    key={props.path}
    {...props}
    path={AppRoute.SubscriptionSettings}
  />,
];
