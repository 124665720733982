import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreDocument } from 'modules/firebase';
import { useFreezeOverlayScroll } from 'modules/subscription';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { useModal } from 'shared/hooks';
import { toast } from 'sonner';
import { AccessList } from '../components';
import { proposalsAtoms } from '../state';
import { areAccessListsEqual } from '../utils';

export function useProposalAccessList(proposalId: string) {
  const { formatMessage } = useIntl();

  const organizationId = useOrganizationIdSelector();
  const accessList = useRecoilValue(proposalsAtoms.accessList);
  const { getValues } = useFormContext<PublishStatusAware<Proposal>>();

  const { isModalOpen, closeModal, openModal } = useModal();

  const [defaultAccessList, setDefaultAccessList] =
    useState<AccessList[]>(accessList);

  const buttonDisabled = useMemo(
    () =>
      areAccessListsEqual(accessList, defaultAccessList) || !getValues('id'),
    [accessList, defaultAccessList, getValues('id')],
  );

  const { set } = useFirestoreDocument<ProtectedAccessList>(
    `${organizationId}/proposals/access-rights/${proposalId}`,
  );
  useFreezeOverlayScroll(isModalOpen);

  async function updateAccessList(newList: string[]) {
    try {
      await set({ emails: newList });
      setDefaultAccessList(
        newList.map((email) => ({
          email,
          isSaved: true,
        })),
      );
    } catch {
      toast.error(
        formatMessage({ id: 'protected-access-list.api.update.error' }),
      );
    }
  }

  async function handleUpdateList() {
    const hasChanged =
      JSON.stringify(accessList) !== JSON.stringify(defaultAccessList);

    if (hasChanged) {
      const emails = accessList?.map(({ email }) => email);
      await updateAccessList(emails);
      toast.success(
        !accessList?.length
          ? 'Invitation list has successfully been created.'
          : 'Invitation list has successfully been updated.',
      );
    }

    closeModal();
  }

  return {
    buttonDisabled,
    isModalOpen,
    openModal,
    closeModal,
    handleUpdateList,
  };
}
