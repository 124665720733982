import returnToDraftMenu from 'assets/icons/icon-reset-12-negative.svg';
import returnToDraftBtn from 'assets/icons/icon-reset-12.svg';
import { Proposal } from 'modules/proposals';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  className: string;
  isDrawerBtn?: boolean;
}

export const ReturnToDraftButton: React.FC<Props> = ({
  className,
  isDrawerBtn,
}) => {
  const { setValue } = useFormContext<PublishStatusAware<Proposal>>();

  function handleReturnToDraft() {
    setValue('nextPublishStatus', 'draft');
  }

  return (
    <Button
      className={`${className} btn__icon__fill`}
      onClick={handleReturnToDraft}
      btnSelector="return-to-draft-btn"
      type="submit"
    >
      {isDrawerBtn ? (
        <img
          src={returnToDraftMenu}
          alt="ReturnToDraftIcon"
          className="btn__icon btn__icon__left"
        />
      ) : (
        <img
          src={returnToDraftBtn}
          alt="ReturnToDraftIcon"
          className="btn__icon btn__icon__left"
        />
      )}

      <FormattedMessage id="buttons.draft-return" />
    </Button>
  );
};
