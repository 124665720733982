import { motion } from 'framer-motion';
import {
  isFileMediaElementVisible,
  isFileSegment,
  isHeadingSegment,
  isHeadingSegmentVisible,
  isMediaSegment,
  isServiceSegment,
  isServiceSegmentVisible,
  isTextSegment,
  isTextSegmentVisible,
} from 'modules/proposals';
import { FreeTierNotice } from 'modules/subscription';
import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableOfContentsFileIcon } from './fragments/TableOfContentsFileIcon';
import { TableOfContentsListItem } from './fragments/TableOfContentsListItem';
import { TableOfContentsMediaIcon } from './fragments/TableOfContentsMediaIcon';
import { TableOfContentsRichTextIcon } from './fragments/TableOfContentsRichTextIcon';

const variants = {
  initial: { opacity: 0, y: -8 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -8 },
};

interface Props {
  proposal?: PublishStatusAware<Proposal> | Proposal;
  isPreview?: boolean;
}

export const TableOfContentsFreemiumList = forwardRef<HTMLUListElement, Props>(
  ({ proposal, isPreview }, ref) => {
    return (
      <>
        <motion.ul
          initial="initial"
          animate="enter"
          exit="exit"
          variants={variants}
          transition={{ duration: 0.15 }}
          ref={ref}
          className="toc__list toc__freemium__list"
        >
          <div className="toc__freemium__list__overlay" />
          {proposal?.segments?.map((segment, index) => (
            <React.Fragment key={index}>
              {isHeadingSegment(segment) &&
                isHeadingSegmentVisible(segment, isPreview) && (
                  <TableOfContentsListItem
                    index={index}
                    type="heading"
                    className="t-bold toc__heading s-bottom--tny text--sm"
                    tabIndex={0}
                  >
                    <span>{segment?.title || 'Heading'}</span>
                  </TableOfContentsListItem>
                )}
              {isServiceSegment(segment) &&
                isServiceSegmentVisible(segment, isPreview) && (
                  <TableOfContentsListItem
                    index={index}
                    type="service"
                    className="toc__sidebar__item__container"
                    tabIndex={0}
                  >
                    <div className="toc__sidebar__item__divider" />
                    <div className="toc__sidebar__item">
                      <span className="t-label">
                        <FormattedMessage id="table-of-contents.service" />
                      </span>
                      <p className="text--sm t-text-2 t-ellipsis">
                        {segment?.service?.name || 'Service content'}
                      </p>
                    </div>
                  </TableOfContentsListItem>
                )}
              {isTextSegment(segment) &&
                isTextSegmentVisible(segment, isPreview) && (
                  <TableOfContentsListItem
                    index={index}
                    type="text"
                    className="toc__sidebar__item__container toc__stroke__segment"
                    tabIndex={0}
                  >
                    <div className="toc__sidebar__item__divider" />
                    <div className="toc__sidebar__attachment">
                      <TableOfContentsRichTextIcon />
                      <span className="text--sm btn__icon__right">
                        <FormattedMessage id="table-of-contents.rich-text" />
                      </span>
                    </div>
                  </TableOfContentsListItem>
                )}
              {isFileSegment(segment) &&
                isFileMediaElementVisible(segment, isPreview) && (
                  <TableOfContentsListItem
                    index={index}
                    type="file"
                    className="toc__sidebar__item__container toc__stroke__segment"
                    tabIndex={0}
                  >
                    <div className="toc__sidebar__item__divider" />
                    <div className="toc__sidebar__attachment">
                      <TableOfContentsFileIcon />
                      <span className="text--sm t-text-2 btn__icon__right">
                        {segment?.files?.length > 1 ? (
                          <FormattedMessage id="table-of-contents.file-multiple" />
                        ) : (
                          <FormattedMessage id="table-of-contents.file-1" />
                        )}
                      </span>
                    </div>
                  </TableOfContentsListItem>
                )}
              {isMediaSegment(segment) &&
                isFileMediaElementVisible(segment, isPreview) && (
                  <TableOfContentsListItem
                    index={index}
                    type="media"
                    className="toc__sidebar__item__container toc__fill__segment"
                    tabIndex={0}
                  >
                    <div className="toc__sidebar__item__divider" />
                    <div className="toc__sidebar__attachment">
                      <TableOfContentsMediaIcon />
                      <span className="text--sm t-text-2 btn__icon__right">
                        {segment?.files?.length > 1 ? (
                          <FormattedMessage id="table-of-contents.media-multiple" />
                        ) : (
                          <FormattedMessage id="table-of-contents.media-1" />
                        )}
                      </span>
                    </div>
                  </TableOfContentsListItem>
                )}
            </React.Fragment>
          ))}
        </motion.ul>
        <div className="f f--col m-top-auto toc__freemium__notification">
          <span className="t-bold t-text-1 text--xs">
            <FormattedMessage id="proposal-summary.plus-notice-content.header" />
          </span>
          <p className="t-text-2 text--xsm">
            <FormattedMessage id="proposal-summary.plus-notice-content.description" />
          </p>
          <FreeTierNotice
            noticeActionId="propoze-plus-notice.free.action"
            noticeDescriptionId="propoze-plus-notice.free.description"
            containerClassName="s-top--med"
          />
        </div>
      </>
    );
  },
);
