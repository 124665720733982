import { useProposalOverviewFilterOptions } from 'modules/proposals-overview';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useKeyPress, useListNavigation, useMobile } from 'shared/hooks';

const validityStateOptions = ['active', 'expired'];

interface Props {
  validityState: string[];
}

export const ProposalOverviewValidityStateOptions: React.FC<Props> = ({
  validityState,
}) => {
  const isMobile = useMobile();
  const enterPress = useKeyPress('Enter');
  const { filters, handleFilterChange } = useProposalOverviewFilterOptions();
  const { cursor, setHovered } =
    useListNavigation<string>(validityStateOptions);

  /**Enter press */
  useEffect(() => {
    if (enterPress) {
      handleFilterChange('validity', validityStateOptions[cursor]);
    }
  }, [enterPress]);

  return (
    <ul className="proposal-overview__filter__options">
      {validityState.map((status, index) => (
        <React.Fragment key={status}>
          <li
            className={`proposal-overview__filter__option text--sm ${
              filters?.some(
                (filter) =>
                  filter.type === 'validity' && filter.value === status,
              )
                ? 't-text-1'
                : 't-text-2'
            } ${
              index === cursor
                ? 'proposal-overview__filter__option--active'
                : ''
            }`}
            onClick={() => handleFilterChange('validity', status)}
            data-cy={`proposal-overview-filter-${status}-btn`}
            onMouseEnter={() => setHovered(status)}
            onMouseLeave={() => setHovered(undefined)}
          >
            <FormattedMessage id={`proposals.overview.sort.${status}`} />
          </li>
          {index !== 1 && isMobile && (
            <div className="proposal-overview__actions__divider" />
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};
