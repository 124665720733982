import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { useFilesToRemoveState } from 'modules/proposals';
import { db } from '../initFirebase';
import { useFirestore } from './useFirestore';
import { useStorage } from './useStorage';

export function useFirestoreEntitites<T extends { id?: string }>(
  documentName: string,
  organizationId: string,
  entityCollection = 'entities',
) {
  const {
    getDocumentReference,
    handleError,
    resolveData,
    resolveQuery,
    isFirestoreError,
  } = useFirestore<T>(organizationId);
  const { removeStorageItemAsync, processUploadsAsync } = useStorage();
  const [filesToRemove, setFilesToRemove] = useFilesToRemoveState();

  function getEntityCollectionReference() {
    return collection(
      db,
      getDocumentReference(documentName).path,
      entityCollection,
    );
  }

  async function removeFilesFromStorage(refId: string) {
    const promises = filesToRemove.map((file: string) =>
      removeStorageItemAsync(
        `${organizationId}/${documentName}/${refId}/${file}`,
      ),
    );
    setFilesToRemove([]);

    await Promise.all(promises);
  }

  function getEntityDocumentReference(id?: string) {
    const ref = getEntityCollectionReference();
    return id ? doc(ref, id) : doc(ref);
  }

  async function createEntityAsync(data: T) {
    delete data.id;
    return addDoc(getEntityCollectionReference(), {
      ...data,
      createdAt: Date.now(),
    })
      .then((data) => data)
      .catch(handleError);
  }

  async function createEntityWithImagesAsync(
    data: T,
    imageProps: string[],
    entityId?: string,
  ) {
    delete data.id;
    const ref = getEntityDocumentReference(entityId);

    const processedData = await processUploadsAsync(
      documentName,
      ref.id,
      data,
      imageProps,
    );
    const plainObject = JSON.parse(JSON.stringify(processedData));

    await setDoc(ref, plainObject).catch(handleError);
    return;
  }

  async function getAllEntitiesAsync() {
    return getDocs(getEntityCollectionReference())
      .then(resolveQuery)
      .catch(handleError);
  }

  async function getEntityByIdAsync(entityId: string) {
    return getDoc(getEntityDocumentReference(entityId))
      .then(resolveData)
      .catch(handleError);
  }

  async function updateEntityAsync(entityId: string, data: T) {
    await updateDoc(getEntityDocumentReference(entityId), {
      ...data,
    }).catch(handleError);
    return;
  }

  async function updateEntityWithImagesAsync(
    data: Omit<T, 'id'>,
    imageProps: string[],
    entityId?: string,
  ) {
    const ref = getEntityDocumentReference(entityId);

    const processedData = await processUploadsAsync(
      documentName,
      ref.id,
      data,
      imageProps,
    );
    await removeFilesFromStorage(ref.id);

    await updateDoc(ref, { ...processedData });
    return;
  }

  async function deleteEntityAsync(entityId: string) {
    return deleteDoc(getEntityDocumentReference(entityId)).catch(handleError);
  }

  async function reorderEntityAsync(data: Omit<T[], 'id'>, path: string) {
    const batch = writeBatch(db);

    data.forEach((data, index) => {
      const docRef = doc(db, organizationId, `${path}/entities/${data.id}`);
      batch.update(docRef, { index });
    });

    await batch.commit();
  }
  return {
    getEntityDocumentReference,
    createEntityAsync,
    createEntityWithImagesAsync,
    updateEntityWithImagesAsync,
    getEntityByIdAsync,
    getAllEntitiesAsync,
    updateEntityAsync,
    deleteEntityAsync,
    reorderEntityAsync,
    isFirestoreError,
  };
}
