import { ACCEPTANCE_STATUS_CONFIG } from 'modules/proposals';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  publishStatus: PublishStatus;
  index: number;
  className?: string;
}

export const ProposalOverviewListItemStatus: React.FC<Props> = ({
  publishStatus,
  index,
  className = '',
}) => {
  const status = ACCEPTANCE_STATUS_CONFIG[publishStatus];

  return (
    <div
      className={`proposal-overview-item__publish-status__btn ${className} ${status.className}`}
      data-cy={`proposal-overview-list-item-publish-status-${index}`}
    >
      <span>
        <FormattedMessage id={status.messageId} />
      </span>
    </div>
  );
};
