import { WindowLocation } from '@reach/router';

interface ExtendedLocation extends WindowLocation<unknown> {
  state: {
    lastPageUrl?: string;
    scrollY?: string;
    redirectedFromProposalBanner?: boolean;
  };
}

export function isLocationTypeGuard(
  location: WindowLocation<unknown>,
): location is ExtendedLocation {
  return typeof location.state === 'object' && location.state !== null;
}
