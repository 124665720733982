import deleteIcon from 'assets/icons/icon-trash-12.svg';
import { useAuthorization } from 'modules/authorization';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DeleteModal } from 'shared/components';
import { useModal } from 'shared/hooks';
import { UserModal } from '../user-modal';

export const UserListItem: React.FC<OrganizationUser> = (props) => {
  const { deleteEntityAsync } = useAuthorization();

  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal();

  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();

  const removeItem = () => {
    deleteEntityAsync(props.id);
    closeDeleteModal();
  };

  return (
    <>
      <div className="settings-itemlist__item settings-itemlist__item--three">
        <span>{props.email}</span>
        <span>{props.status}</span>
        <div className="btn-group btn-group--simple">
          <Button
            type="button"
            size="med"
            style="outline"
            onClick={openEditModal}
          >
            <FormattedMessage id="buttons.edit" />
          </Button>
          <Button
            type="button"
            size="med"
            style="ghost"
            onClick={openDeleteModal}
          >
            <img src={deleteIcon} alt="DeleteIcon" />
          </Button>
        </div>
      </div>

      {isEditModalOpen && <UserModal item={props} onClose={closeEditModal} />}

      {isDeleteModalOpen && (
        <DeleteModal
          title={props.email}
          name="user"
          onClose={closeDeleteModal}
          onConfirm={removeItem}
        />
      )}
    </>
  );
};
