import React, { PropsWithChildren } from 'react';
import { useFeatureFlag } from '../hooks';

interface Props extends PropsWithChildren {
  flag: FeatureFlag;
  organizationId?: string;
}

/** Feature flags must be defined in the environment variables. Format: @example FEATURE_FLAG_[flagname] */
export const FeatureFlagWrapper: React.FC<Props> = ({ children, flag }) => {
  const enabled = useFeatureFlag(flag);

  if (!enabled) return null;

  return <>{children}</>;
};
