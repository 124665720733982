/**
 * Checks if only the 'customTags' field has changed in a set of dirty fields.
 *
 * This function takes an object representing the dirty fields and a boolean indicating whether the fields are dirty.
 * It returns true if the only key in the dirty fields is 'customTags' and the fields are dirty, and false otherwise.
 *
 * @param dirtyFields - An object representing the dirty fields.
 * @param isDirty - A boolean indicating whether the fields are dirty.
 * @returns A boolean indicating whether only the 'customTags' field has changed.
 */
export function hasOnlyCustomTagsChanged(
  dirtyFields: object,
  isDirty: boolean,
) {
  const keys = Object.keys(dirtyFields);
  return keys?.length === 1 && keys?.includes('customTags') && isDirty;
}
