import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import React, { FC } from 'react';

export const TawkLiveChat: FC = () => {
  return (
    <TawkMessengerReact
      propertyId={process.env.GATSBY_TAWK_PROPERTY_ID}
      widgetId={process.env.GATSBY_TAWK_WIDGET_ID}
    />
  );
};
