import backIcon from 'assets/icons/icon-search-back-16.svg';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useCloseOnEscape } from 'shared/hooks';
import { NavSearch } from '../navigation';

interface Props {
  inputFocused: boolean;
  onToggleSearch: () => void;
}

export const MobileSearch: React.FC<Props> = ({
  inputFocused,
  onToggleSearch,
}) => {
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onToggleSearch();
    }, 300);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, x: '-100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%', transition: { duration: 0.3 } }}
        transition={{ duration: 0.3, type: 'tween' }}
        className={`f u-width--full ${
          isClosing ? 'search--mobile__closing-animation' : ''
        }`}
      >
        <motion.button
          type="button"
          className="btn search__mobile__close"
          onClick={handleClose}
          ref={closeButtonRef}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <img
            src={backIcon}
            alt="BackIcon"
            className="btn__icon btn__icon__left"
          />
        </motion.button>
        <NavSearch inputFocused={inputFocused} />
      </motion.div>
    </AnimatePresence>
  );
};
