import { FirebaseError } from 'firebase/app';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import {
  loginAction,
  registrationAction,
  sendPasswordResetEmailAction,
} from 'modules/authentication';
import { auth, useFunction } from 'modules/firebase';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { toast } from 'sonner';
import { authErrorAtom, authLoadingAtom } from '../state';
import { getAuthErrors } from '../utils';

export function useAuthenticationFormActions() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sendPasswordResetEmailFn = useFunction<{ email: string }>(
    'sendForgotPasswordEmail',
  );

  const setAuthError = useSetRecoilState(authErrorAtom);
  const setAuthLoading = useSetRecoilState(authLoadingAtom);

  async function login(props: { email: string; password: string }) {
    try {
      setAuthLoading(true);
      await signInWithEmailAndPassword(auth, props.email, props.password);
      dispatch(loginAction.success());
      toast.success(formatMessage({ id: 'toasts.login.success' }));
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        setAuthError(getAuthErrors(errorCode));
      }
      dispatch(loginAction.error());
    } finally {
      setAuthLoading(false);
    }
  }

  async function loginWithGoogle() {
    try {
      setAuthLoading(true);
      const googleProvider = new GoogleAuthProvider();
      await signInWithPopup(auth, googleProvider);
      dispatch(loginAction.success());
      toast.success(formatMessage({ id: 'toasts.login.success' }));
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        setAuthError(getAuthErrors(errorCode));
      }
      dispatch(loginAction.error());
    } finally {
      setAuthLoading(false);
    }
  }

  async function loginWithTwitter() {
    try {
      setAuthLoading(true);
      const twitterProvider = new TwitterAuthProvider();
      await signInWithPopup(auth, twitterProvider);
      dispatch(loginAction.success());
      toast.success(formatMessage({ id: 'toasts.login.success' }));
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        setAuthError(getAuthErrors(errorCode));
      }
      dispatch(loginAction.error());
    } finally {
      setAuthLoading(false);
    }
  }

  async function loginWithFacebook() {
    try {
      setAuthLoading(true);
      const facebookProvider = new FacebookAuthProvider();
      await signInWithPopup(auth, facebookProvider);
      dispatch(loginAction.success());
      toast.success(formatMessage({ id: 'toasts.login.success' }));
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        setAuthError(getAuthErrors(errorCode));
      }
      dispatch(loginAction.error());
    } finally {
      setAuthLoading(false);
    }
  }

  async function register(props: { email: string; password: string }) {
    try {
      setAuthLoading(true);
      await createUserWithEmailAndPassword(auth, props.email, props.password);
      dispatch(registrationAction.success());
      toast.success(formatMessage({ id: 'toasts.registration.success' }));
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        setAuthError(getAuthErrors(errorCode));
      }
      dispatch(registrationAction.error());
    } finally {
      setAuthLoading(false);
    }
  }

  async function sendPasswordResetEmail(props: { email: string }) {
    try {
      setAuthLoading(true);
      await sendPasswordResetEmailFn({ email: props.email });
      dispatch(sendPasswordResetEmailAction.success());
      toast.success(
        formatMessage({ id: 'toasts.sendForgotPasswordEmail.success' }),
      );
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        setAuthError(getAuthErrors(errorCode));
      }
      dispatch(sendPasswordResetEmailAction.error());
    } finally {
      setAuthLoading(false);
    }
  }

  return {
    login,
    register,
    sendPasswordResetEmail,
    loginWithGoogle,
    loginWithTwitter,
    loginWithFacebook,
  };
}
