import closeIcon from 'assets/icons/icon-close-12.svg';
import React from 'react';
import { Button } from 'shared/components';
import { AccessList } from '../../models';

interface Props {
  email: string;
  index: number;
  isSaved: boolean;
  list: AccessList[];
  onSetList: (list: AccessList[]) => void;
}

export const ProtectedAccessListItem: React.FC<Props> = ({
  email,
  index,
  isSaved,
  list,
  onSetList,
}) => {
  function removeEmailFromList(email: string) {
    onSetList(list.filter((item) => item.email !== email));
  }

  return (
    <div
      key={`access-list-${email}`}
      className="f f--align-center f--justify-between s-top--tny"
    >
      <p
        className={`${
          isSaved ? 't-text-1' : 't-primary'
        } t-ellipsis protected-access-list__item text--sm`}
      >
        {email}
      </p>
      <Button
        type="button"
        style="ghost"
        size="med"
        onClick={() => removeEmailFromList(email)}
        btnSelector={`remove-email-btn-${index}`}
      >
        <img src={closeIcon} alt="Close icon" />
      </Button>
    </div>
  );
};
