import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, LoadingSpinner } from 'shared/components';
import { useSubscriptionHelpers } from '..';

interface Props {
  isCheckoutLoading?: boolean;
  onSelect: () => void;
  index?: number;
  name?: string;
}

export const ProductItemAction: React.FC<Props> = ({
  isCheckoutLoading,
  onSelect,
  index,
  name,
}) => {
  const [loading, setLoading] = useState(false);

  const { isInactive, isGracePeriod } = useSubscriptionHelpers();

  function handleSelectPlan() {
    setLoading(true);
    onSelect();
  }

  if (loading) {
    return (
      <Button
        type="button"
        disabled={isCheckoutLoading || loading}
        size="lrg"
        style="outline"
        className="f--one"
        btnSelector="downgrade-proccesing-disabled"
      >
        <LoadingSpinner height={12} width={12} type="primary" />
        <p className="s-left--med">
          <FormattedMessage id="subscription-action-controller.action.downgrading" />
        </p>
      </Button>
    );
  }

  if (isGracePeriod) {
    return (
      <Button
        type="button"
        size="lrg"
        style="primary"
        onClick={handleSelectPlan}
        className="f--one"
        btnSelector={`renew-plan-btn-${index}`}
      >
        <FormattedMessage id="buttons.renew" />
      </Button>
    );
  }

  if (isInactive) {
    return (
      <Button
        type="button"
        onClick={handleSelectPlan}
        size="lrg"
        style="primary"
        className="f--one"
        btnSelector="reactivate-plan-btn"
      >
        <FormattedMessage id="subscription-product-item.action.reactivate" />
      </Button>
    );
  }

  return (
    <Button
      type="button"
      size="lrg"
      style="primary"
      onClick={handleSelectPlan}
      className="f--one"
      btnSelector={`select-plan-btn-${name}`}
    >
      <FormattedMessage id="buttons.upgrade-plan" />
    </Button>
  );
};
