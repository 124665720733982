import React from 'react';
import { FormattedMessage } from 'react-intl';

const INTERVALS: {
  name: string;
  interval: PaymentInterval;
  price: string;
}[] = [
  {
    name: 'Yearly',
    interval: 'year',
    price: '€108',
  },
  {
    name: 'Monthly',
    interval: 'month',
    price: '€10',
  },
];

export const IntervalSelector: React.FC = () => {
  return (
    <div className="f f--col">
      <div className="f subscription-interval__selector">
        {INTERVALS.map((item, index) => (
          <React.Fragment key={item.interval}>
            <div
              className={`t-small payment-interval payment-interval-${item.interval}`}
            >
              <div className="f f--col f--align-start">
                <span className="text--xsm t-text-2">
                  <FormattedMessage
                    id={`payment-interval-selector.${item.interval}`}
                  />
                </span>
                <h2 className="f f--align-center t-bold t-text-1">
                  <strong className="text--lg">{item.price}</strong>

                  {item.interval && (
                    <span
                      className="t-small s-left--tny"
                      data-cy="interval-label"
                    >
                      / {'  '}
                      {item.interval}
                    </span>
                  )}
                </h2>
                <span className="payment-interval__vat t-text-2">
                  <FormattedMessage id="payment-interval-selector.vat-excluded" />
                </span>
              </div>

              {item.interval === 'year' && (
                <span className="payment-interval__yearly__discount text--tny t-bold">
                  <FormattedMessage id="payment-interval-selector.yearly-discount" />
                </span>
              )}
            </div>
            {index !== INTERVALS?.length - 1 && (
              <div className="divider-vertical" />
            )}
          </React.Fragment>
        ))}
      </div>
      <span className="t-text-3 text--tny s-bottom--12">
        <FormattedMessage id="payment-interval-selector.description" />
      </span>
    </div>
  );
};
