/**
 * Get Login Error Message utility
 * @name getAuthErrors
 * @param {string} errorCode - The error code returned from the login attempt in catch block.
 * @description Utility function that is used to return the error message based on the error code.
 */

export function getAuthErrors(errorCode: string) {
  switch (errorCode) {
    case 'auth/user-not-found':
      return 'auth-error.user-not-found';
    case 'auth/wrong-password':
      return 'auth-error.wrong-password';
    case 'auth/cancelled-popup-request':
      return 'auth-error.cancelled-popup-request';
    case 'auth/account-exists-with-different-credential':
      return 'auth-error.account-exists-with-different-credential';
    case 'auth/email-already-in-use':
      return 'auth-error.email-already-in-use';
    case 'auth/user-disabled':
      return 'auth-error.user-disabled';
    case 'auth/too-many-requests':
      return 'auth-error.too-many-requests';
    case 'auth/operation-not-allowed':
      return 'auth-error.operation-not-allowed';
    case 'auth/network-request-failed':
      return 'auth-error.network-request-failed';
    case 'auth/requires-recent-login':
      return 'auth-error.requires-recent-login';
    case 'auth/weak-password':
      return 'auth-error.weak-password';
    case 'auth/popup-closed-by-user':
      return 'auth-error.popup-closed-by-user';
    case 'auth/invalid-email':
      return 'auth-error.invalid-email';
    case 'auth/credential-already-in-use':
      return 'auth-error.credential-already-in-use';
    case 'auth/popup-blocked':
      return 'auth-error.popup-blocked';
    case 'auth/invalid-credential':
      return 'auth-error.invalid-credential';
    case 'auth/invalid-verification-code':
      return 'auth-error.invalid-verification-code';
    case 'auth/invalid-verification-id':
      return 'auth-error.invalid-verification-id';
    case 'auth/missing-verification-code':
      return 'auth-error.missing-verification-code';
    case 'auth/missing-verification-id':
      return 'auth-error.missing-verification-id';
    case 'auth/code-expired':
      return 'auth-error.code-expired';
    case 'auth/invalid-phone-number':
      return 'auth-error.invalid-phone-number';
    case 'auth/missing-phone-number':
      return 'auth-error.missing-phone-number';
    case 'auth/quota-exceeded':
      return 'auth-error.quota-exceeded';
    case 'auth/captcha-check-failed':
      return 'auth-error.captcha-check-failed';
    case 'auth/invalid-multi-factor-session':
      return 'auth-error.invalid-multi-factor-session';
    case 'auth/missing-multi-factor-info':
      return 'auth-error.missing-multi-factor-info';
    case 'auth/missing-multi-factor-session':
      return 'auth-error.missing-multi-factor-session';
    case 'auth/multi-factor-info-not-found':
      return 'auth-error.multi-factor-info-not-found';
    case 'auth/multi-factor-auth-required':
      return 'auth-error.multi-factor-auth-required';
    case 'auth/internal-error':
      return 'Internal error';
    case 'auth/session-cookie-expired':
      return 'auth-error.session-cookie-expired';
    case 'auth/session-cookie-revoked':
      return 'auth-error.session-cookie-revoked';
    default:
      return 'auth-error.default-error';
  }
}
