import { authorizationSelector } from 'modules/authorization';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

export const AdminGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const { adminRole, isLoading } = useSelector(authorizationSelector.getState);

  if (isLoading || adminRole !== 'superAdmin') {
    return null;
  }

  return <>{children}</>;
};
