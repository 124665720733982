import googleIcon from 'assets/icons/icon-google-16.svg';
import React from 'react';
import { Button } from 'shared/components';

interface Props {
  authLoading: boolean;
  loginWithGoogle: () => void;
}

export const GoogleLogin: React.FC<Props> = ({
  authLoading,
  loginWithGoogle,
}) => {
  return (
    <Button
      style="outline"
      size="lrg"
      disabled={authLoading}
      className="u-width--full"
      onClick={loginWithGoogle}
      btnSelector="google-login-btn"
    >
      <img src={googleIcon} alt="Google Icon" className="btn__icon" />
    </Button>
  );
};
