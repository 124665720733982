import { FirebaseError } from 'firebase/app';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, showDebugStatuses } from 'modules/firebase';
import { useEffect } from 'react';
import { useAuthDispatch } from './useAuthDispatch';

export function useEmailVerificationCheckListener() {
  const authenticationDispatch = useAuthDispatch();

  useEffect(() => {
    const subscription = onAuthStateChanged(auth, async (user) => {
      if (
        !user ||
        user?.emailVerified ||
        user?.providerData.some(
          (provider) => provider.providerId === 'twitter.com',
        )
      )
        return;

      if (showDebugStatuses) {
        console.log(`Listening to ${user.uid}: `, { user });
      }

      /**Polling firebase auth for e-mail verification */
      const intervalId = setInterval(async () => {
        await user.reload().catch((error) => {
          if (showDebugStatuses) console.error('Error reloading user: ', error);
          if (
            error instanceof FirebaseError &&
            error.code === 'auth/user-token-expired'
          ) {
            clearInterval(intervalId);
          }
        });
        if (user.emailVerified) {
          clearInterval(intervalId);
          authenticationDispatch.login(user.toJSON());
        }
      }, 15000); // Poll every 15 seconds
      return () => clearInterval(intervalId);
    });

    return () => {
      subscription();
    };
  }, []);
}
