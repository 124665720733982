import React from 'react';
import { ReturnToDraftButton } from '../../shared-buttons/return-to-draft-button';
import { ActionDrawerClassNames } from '../ActionDrawerClassNames';
import { DeleteButton } from '../delete-button';
import { DuplicateAsTemplateButton } from '../duplicate-as-template-button';
import { DuplicateButton } from '../duplicate-button';

interface Props extends ActionDrawerClassNames {
  proposal: Proposal;
  isOverview?: boolean;
}

export const ArchivedDrawerButtons: React.FC<Props> = ({
  duplicateAsTemplateClassName,
  duplicateClassName,
  deleteClassName,
  proposal,
  isOverview,
}) => (
  <>
    {isOverview && (
      <ReturnToDraftButton className="actionbar__drawer__btn" isDrawerBtn />
    )}
    <DuplicateButton
      className={duplicateClassName}
      proposal={proposal}
      btnSelector="duplicate-proposal-btn"
    />
    <DuplicateAsTemplateButton
      className={duplicateAsTemplateClassName}
      proposal={proposal}
      btnSelector="archived-page-duplicate-as-template-btn"
    />
    <DeleteButton
      className={deleteClassName}
      btnSelector="archived-page-delete-btn"
    />
  </>
);
