import {
  isFileSegment,
  isHeadingSegment,
  isMediaSegment,
  isServiceSegment,
} from 'modules/proposals';
import React from 'react';
import {
  PublicFileSegmentCard,
  PublicHeadingSegmentCard,
  PublicServiceSegmentCard,
  PublicTextSegmentCard,
} from './fragments';
import { PublicMediaSegmentCard } from './fragments/public-media-segment-card';

interface Props {
  item: ProposalSegment;
  index: number;
  currency: Proposal['currency'];
  style: Proposal['style'];
  servicePricesVisibility?: Proposal['servicePricesVisibility'];
}

export const PublicSegmentRenderer: React.FC<Props> = ({ item, ...rest }) => {
  if (isServiceSegment(item)) {
    return <PublicServiceSegmentCard item={item} {...rest} />;
  }

  if (isHeadingSegment(item)) {
    return <PublicHeadingSegmentCard item={item} {...rest} />;
  }

  if (isFileSegment(item)) {
    return <PublicFileSegmentCard item={item} {...rest} />;
  }

  if (isMediaSegment(item)) {
    return <PublicMediaSegmentCard item={item} {...rest} />;
  }

  return <PublicTextSegmentCard item={item} {...rest} />;
};
