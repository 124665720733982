const getState = (state: AppState) => state.auth;

const isTwitterLogin = (state: AppState): boolean => {
  const user = getState(state).user;
  return (
    user?.providerData.some(
      (provider) => provider.providerId === 'twitter.com',
    ) ?? false
  );
};

export const authenticationSelector = {
  isTwitterLogin,
  getState,
};
