import closeIcon from 'assets/icons/icon-close-16.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { authErrorAtom } from 'modules/authentication';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';

export const AuthenticationError: React.FC = () => {
  const { formatMessage } = useIntl();
  const [authError, setAuthError] = useRecoilState(authErrorAtom);

  useEffect(() => {
    return () => {
      setAuthError('');
    };
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 8 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.6, type: 'spring' },
        }}
        exit={{
          opacity: 0,
          y: 8,
          transition: { duration: 0.3, type: 'spring' },
        }}
        data-cy="firebase-error"
        className="auth__error__container"
      >
        <motion.span
          initial={{ opacity: 0, y: 8 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.1, type: 'spring' }}
          className="text--sm t-text-1"
        >
          {formatMessage({ id: authError })}
        </motion.span>
        <motion.img
          initial={{ opacity: 0, y: 8 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2, type: 'spring' }}
          src={closeIcon}
          onClick={() => setAuthError('')}
          data-cy="close-auth-error"
          alt="CloseIcon"
          className="btn__icon c-pointer"
        />
      </motion.div>
    </AnimatePresence>
  );
};
