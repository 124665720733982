import { createEntityState } from 'modules/redux-store';

const INITIAL_STATE = createEntityState<Client>();

export const clientReducer = (
  state = INITIAL_STATE,
  action:
    | ActionUnion<EntityActions<'clients', Client>>
    | Action<'authentication/LOGOUT'>,
): EntityState<Client> => {
  switch (action.type) {
    case 'clients/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'clients/ON_SNAPSHOT_UPDATE':
      return {
        ...state,
        isLoading: false,
        documents: action.payload.documents,
      };
    case 'clients/ON_SNAPSHOT_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case 'authentication/LOGOUT':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
