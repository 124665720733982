import { addDays, format, isValid } from 'date-fns';

/**
 * This function takes the end date of a subscription and adds a grace period to it (15 days).
 * Returns the grace period formatted date.
 */
export function getGracePeriodFormattedDate(
  end: number | Date | null | undefined,
): string | null {
  if (end == null) return null;

  const endDate = end instanceof Date ? end : new Date(end);

  if (!isValid(endDate)) return null;

  const gracePeriodEndDate = addDays(endDate, 15);

  return format(gracePeriodEndDate, 'MMM d, yyyy');
}
