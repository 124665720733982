import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { SearchForm, SearchResults } from 'shared/components';
import { useClickOutsideElement, useDashboardSearch } from 'shared/hooks';

interface Props {
  inputFocused?: boolean;
  className?: string;
  inputSelector?: string;
  toggleMobileSearch?: () => void;
}

export const NavSearch: React.FC<Props> = ({
  inputFocused,
  className,
  inputSelector,
  toggleMobileSearch,
}) => {
  const { formatMessage } = useIntl();
  const [showResults, setShowResults] = useState(false);

  const handleOnFocus = () => {
    setShowResults(true);
    toggleMobileSearch?.();
  };
  const searchCallback = () => setShowResults(true);
  const handleClickOutside = () => setShowResults(false);

  const { query, proposals, clients, handleSearch, setQuery } =
    useDashboardSearch(searchCallback);
  const wrapperRef = useClickOutsideElement<HTMLDivElement>(
    handleClickOutside,
    true,
    true,
  );

  return (
    <div
      className={`search ${className || ''}`}
      ref={wrapperRef}
      data-cy={inputSelector}
    >
      <SearchForm
        value={query}
        autoFocus={inputFocused}
        showResults={showResults}
        onFocus={handleOnFocus}
        onChange={handleSearch}
        onSetQuery={setQuery}
        placeholder={formatMessage({ id: 'search.input.placeholder' })}
      />
      {showResults && (
        <SearchResults
          query={query}
          proposals={proposals}
          clients={clients}
          onSetQuery={setQuery}
          onSetShowResults={setShowResults}
        />
      )}
    </div>
  );
};
