import { getOperatingSystem } from './getOperatingSystem';

/**
 * Determines the command key based on the user's operating system.
 *
 * This function first calls the `getOperatingSystem` function to get the operating system of the user's device.
 * If the operating system is 'macOS', it returns '⌘'.
 * If the operating system is not 'macOS', it returns 'Ctrl'.
 *
 * @returns A string representing the command key for the user's operating system. Possible values are '⌘' for macOS and 'Ctrl' for other operating systems.
 */

export function getCommandKeyCopy() {
  const os = getOperatingSystem();
  return os === 'macOS' ? '⌘' : 'Ctrl';
}
