import { Client, ClientModal, clientSelector } from 'modules/clients';
import { ClientUpdateToast, hasClientChanged } from 'modules/proposals';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select, { SingleValue, components } from 'react-select';
import { FieldWrapper, SelectList, getSelectStyles } from 'shared/components';
import { useMobile, useModal } from 'shared/hooks';
import { toast } from 'sonner';

export const ClientSelect: React.FC = () => {
  const isMobile = useMobile();
  const { formatMessage } = useIntl();
  const { openModal, closeModal, isModalOpen } = useModal();

  const { documents } = useSelector(clientSelector.getState);
  const [initialClientName, setInitialClientName] = useState('');
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<PublishStatusAware<Proposal>>();

  const options = useMemo(
    () =>
      documents?.map((client) => ({
        value: client,
        label: client.name,
      })),
    [documents],
  );

  const handleClientChange = useCallback(
    (field: ControllerRenderProps<PublishStatusAware<Proposal>, 'client'>) =>
      (
        selectedOption: SingleValue<{
          value: Client;
          label: string;
        }>,
      ) => {
        field.onChange({ ...selectedOption?.value });
      },
    [],
  );

  function getValue(client: Client | null) {
    if (!client) return null;

    return {
      value: client,
      label: client.name,
    };
  }

  function handleButtonClick() {
    const selectedElement = document.querySelector('.select__client--input');
    const selectInput = selectedElement?.querySelector('input');

    if (selectInput) selectInput.blur();
    openModal();
  }

  function handleInputChange(inputValue: string) {
    if (!inputValue) return;
    setInitialClientName(inputValue);
  }

  useEffect(() => {
    const isClientUpdated = documents?.some(
      (c: Client) =>
        c.id === getValues('client')?.id &&
        hasClientChanged({ ...c }, getValues('client')),
    );
    if (!isClientUpdated) return;

    let toastId: string | number | null = null;
    function updateClientData() {
      const clientToUpdate = documents?.find(
        (c: Client) => c.id === getValues('client')?.id,
      );
      if (!clientToUpdate) return;
      setValue(
        'client',
        { ...new Client(clientToUpdate) },
        { shouldDirty: true },
      );
      if (toastId) toast.dismiss(toastId);
    }

    toastId = toast.warning(
      <ClientUpdateToast onUpdateClientData={updateClientData} />,
      {
        duration: 15000,
        position: isMobile ? 'top-center' : 'top-right',
      },
    );

    return () => {
      if (toastId) toast.dismiss(toastId);
    };
  }, [documents, getValues]); // Notice only documents and getValues are dependencies now

  return (
    <div data-cy="client-dropdown">
      <FieldWrapper
        labelId="dropdowns.client.label"
        errors={errors}
        name="client"
        isRequired
      >
        <Controller
          name="client"
          control={control}
          rules={{
            required: formatMessage({ id: 'dropdowns.client.required' }),
            validate: (value) => {
              const clientMatch = documents?.find(
                (c: Client) => c.id === value?.id && c.name === value?.name,
              );
              if (clientMatch) return true;
              return `Client ${value?.name} doesn't exist`;
            },
          }}
          defaultValue={getValues('client') || null}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              onChange={handleClientChange(field)}
              value={getValue(field.value)}
              onInputChange={handleInputChange}
              styles={getSelectStyles<Client>(Boolean(errors.client))}
              id="client__styled__select"
              className="select__client--input"
              placeholder={formatMessage({
                id: 'dropdowns.client.placeholder',
              })}
              isSearchable={!isMobile}
              components={{
                MenuList: ({ children, ...rest }) => (
                  <components.MenuList {...rest} className="select__adder">
                    <SelectList
                      onButtonClick={handleButtonClick}
                      buttonType={'client'}
                      btnSelector="add-client"
                    >
                      {children}
                    </SelectList>
                  </components.MenuList>
                ),
              }}
            />
          )}
        />
      </FieldWrapper>

      {isModalOpen && (
        <ClientModal
          inputValue={initialClientName}
          valueToUpdate="client"
          onClose={closeModal}
        />
      )}
    </div>
  );
};
