module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Propoze","short_name":"Propoze","start_url":"/","background_color":"#8039ff","theme_color":"#8039ff","display":"minimal-ui","icon":"src/assets/favicons/android-chrome-256x256.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1488542b4d438331c992f240930ae1cd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://go.propoze.app","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com","https://fonts.googleapis.com"],"web":[{"name":"Staatliches","file":"https://fonts.googleapis.com/css2?family=Staatliches"},{"name":"Rubik Broken Fax","file":"https://fonts.googleapis.com/css2?family=Rubik+Broken+Fax"},{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter"},{"name":"Space Grotesk","file":"https://fonts.googleapis.com/css2?family=Space+Grotesk"},{"name":"Atkinson Hyperlegible","file":"https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible"},{"name":"Poppins","file":"https://fonts.googleapis.com/css2?family=Poppins"},{"name":"Fira Sans","file":"https://fonts.googleapis.com/css2?family=Fira+Sans"},{"name":"IBM Plex Sans","file":"https://fonts.googleapis.com/css2?family=IBM+Plex+Sans"},{"name":"PT Serif","file":"https://fonts.googleapis.com/css2?family=PT+Serif"},{"name":"EB Garamond","file":"https://fonts.googleapis.com/css2?family=EB+Garamond"},{"name":"Open Sans","file":"https://fonts.googleapis.com/css2?family=Open+Sans"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"123","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
