import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Proposal } from '../Proposal';

export const proposalsFirestoreConverter = {
  toFirestore: (proposal: Proposal) => ({
    ...new Proposal(proposal),
  }),
  fromFirestore: (doc: QueryDocumentSnapshot<Proposal>) => {
    const data = doc.data();
    return new Proposal({ ...data, id: doc.id });
  },
};
