import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreDocument } from 'modules/firebase';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { subscriptionAtoms, subscriptionSelectors } from '../state';

export function useSubscriptionListener() {
  const organizationId = useOrganizationIdSelector();
  const { listen } = useFirestoreDocument<Subscription>(
    `${organizationId}/subscription`,
  );

  /** Improve this, there has to be a better way */
  const resetter = useSetRecoilState(subscriptionSelectors.resetter);
  const setLoadingDoc = useSetRecoilState(subscriptionAtoms.loadingDocument);
  const setStripeError = useSetRecoilState(subscriptionAtoms.error);
  const setDocument = useSetRecoilState(
    subscriptionSelectors.setSubscriptionDocument,
  );

  /** Fetch subscription document from firestore */
  useEffect(() => {
    if (!organizationId) return;
    setStripeError(undefined);

    const listener = listen(setDocument, setStripeError, () =>
      setLoadingDoc(false),
    );

    return listener;
  }, [organizationId]);

  /** Improve */
  useEffect(() => {
    return () => resetter(undefined);
  }, []);
}
