import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  amount?: number;
  label: string;
}

export const StatsItem: React.FC<Props> = ({ amount, label }) => {
  return (
    <li className="stats__item">
      <div className="t-alpha">{amount ?? 'N/A'}</div>
      <div className="t-small o-80">
        <FormattedMessage id={`admin.stats.label.${label}`} />
      </div>
    </li>
  );
};
