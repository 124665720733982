import { QueryDocumentSnapshot } from 'firebase/firestore';
import { isStringArray } from 'shared/utils';
import { AccessList } from '../components';

export const accessListFirestoreConverter = {
  toFirestore: (accessList: AccessList[]): string[] => {
    return accessList.map((item) => item.email);
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): AccessList[] => {
    const emails = snapshot.data()?.emails;
    if (isStringArray(emails)) {
      return emails.map((email) => ({
        email,
        isSaved: true,
      }));
    }

    return [];
  },
};
