import copyLinkIconMenu from 'assets/icons/icon-copy-link-12.svg';
import { useOrganizationIdSelector } from 'modules/authorization';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'shared/components';
import { useDomain } from 'shared/hooks';
import { toast } from 'sonner';
import { ClipboardIcon } from './ClipboardIcon';

interface Props {
  className: string;
  btnSelector?: string;
  isDrawerBtn?: boolean;
}

export const CopyToClipboardButton: React.FC<Props> = ({
  className,
  isDrawerBtn,
  ...props
}) => {
  const { getValues } = useFormContext<Proposal>();
  const { formatMessage } = useIntl();
  const domain = useDomain();
  const organizationId = useOrganizationIdSelector();

  const id = getValues().id;

  const url = `${domain}/s/${organizationId}/proposal/${id}`;

  return (
    <CopyToClipboard
      text={url}
      onCopy={() =>
        toast.success(formatMessage({ id: 'toasts.link-copy-success' }))
      }
    >
      <Button type="button" className={className} {...props}>
        {isDrawerBtn ? (
          <img
            src={copyLinkIconMenu}
            alt="CopyLinkIcon"
            className="btn__icon btn__icon__left"
          />
        ) : (
          <ClipboardIcon />
        )}
        <span>
          <FormattedMessage id="buttons.copy-link" />
        </span>
      </Button>
    </CopyToClipboard>
  );
};
