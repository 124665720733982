import { FormattedMessage } from 'react-intl';
import React from 'react';
import { PublicClientAddressInfo } from '../public-client-address-info';

interface Props {
  client?: Proposal['client'];
}

export const PublicClientInfo: React.FC<Props> = ({ client }) => {
  const hasClientAddress =
    client?.address && Object.values(client?.address).some((value) => !!value);

  if (!client) {
    return (
      <div className="proposal__to">
        <div className="proposal__to__client">
          <p className="t-small">
            <FormattedMessage id="dropdowns.client.help-text" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="proposal__to">
      <div className="proposal__to__client">
        <p className="text--xs__responsive">{client.name}</p>
        {client.email && <p className="text--xs__responsive">{client.email}</p>}
        {client.phone && <p className="text--xs__responsive">{client.phone}</p>}
      </div>
      {hasClientAddress && (
        <div className="s-top--sml">
          <PublicClientAddressInfo
            values={[client.address.addressOne, client.address.addressTwo]}
          />
          <PublicClientAddressInfo
            values={[client.address.city, client.address.postalCode]}
          />
          {client.address.state && (
            <p className="text--xs__responsive">{client.address.state}</p>
          )}
          {client.address.country && (
            <p className="text--xs__responsive">{client.address.country}</p>
          )}
        </div>
      )}

      {client.vatId && (
        <p className="text--xs__responsive s-top--sml">
          <FormattedMessage id="proposals.prices.vat" /> {client.vatId}
        </p>
      )}
    </div>
  );
};
