import { useParams } from '@reach/router';
import { PageProps } from 'gatsby';
import React from 'react';
import { PageLoading } from 'shared/components';
import { ProposalEditor } from '../components';
import {
  useProposalAccessListListener,
  useSingleProposalListener,
} from '../hooks';

export const ProposalBuilder: React.FC<PageProps> = () => {
  const params = useParams();

  const { loading } = useProposalAccessListListener(params.id);
  const { isLoading, proposal } = useSingleProposalListener();

  if (isLoading || loading || !proposal) {
    return <PageLoading isLoading={isLoading || loading} />;
  }

  return <ProposalEditor proposal={proposal} />;
};
