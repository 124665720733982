import emailIcon from 'assets/icons/icon-email-12.svg';
import { authenticationSelector } from 'modules/authentication';
import { proposalsAtoms } from 'modules/proposals';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { AddToListIcon, Button } from 'shared/components';
import { VALIDATORS } from 'shared/utils';
import { toast } from 'sonner';
import { ProtectedAccessListItem } from './visibility-selector';

interface ProtectedAccessListProps {
  isVisible: boolean;
}

export const ProtectedAccessList: React.FC<ProtectedAccessListProps> = ({
  isVisible,
}) => {
  const { formatMessage } = useIntl();

  const [inputValue, setInputValue] = useState('');
  const { user } = useSelector(authenticationSelector.getState);
  const [accessList, setAccessList] = useRecoilState(proposalsAtoms.accessList);

  function handleSubmit() {
    if (!VALIDATORS.email.test(inputValue)) {
      toast.error(
        formatMessage({
          id: 'inputs.email.invalid',
        }),
      );
      return;
    }

    if (user?.email === inputValue) {
      toast.error(
        formatMessage({
          id: 'protected-access-controller.error.self-authorization',
        }),
      );
      return;
    }

    /** If already exists, do nothing */
    if (accessList.map(({ email }) => email).includes(inputValue)) {
      setInputValue('');
      toast.error(
        formatMessage({
          id: 'protected-access-controller.error.already-exists',
        }),
      );
      return;
    }

    setAccessList((prev) => [{ email: inputValue, isSaved: true }, ...prev]);
    setInputValue('');
  }

  function onEnterPress(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.currentTarget.value);
  }

  return (
    <section
      className={`protected-access-list__wrapper f f--col f--one ${
        !isVisible && 'protected-access-list__disabled'
      }`}
    >
      <label className="text--sm t-text-2 s-bottom--tny" htmlFor="email">
        <FormattedMessage id="protected-access-controller.input.label" />
      </label>
      <div className="f f--align-center u-pos--rel g-8">
        <input
          type="email"
          id="email"
          name="email"
          className="input input__med input__box protected-access-list__input"
          data-cy="email-input"
          placeholder={formatMessage({
            id: 'inputs.client-email.placeholder',
          })}
          onKeyDown={onEnterPress}
          value={inputValue}
          onChange={handleChange}
        />
        <img
          src={emailIcon}
          className="btn__icon protected-access-list__email__icon"
          alt="EmailIcon"
        />

        <Button
          type="button"
          style="secondary"
          size="lrg"
          className="u-fit--content protected-access-list__add"
          onClick={handleSubmit}
          btnSelector="protected-access-add-btn"
        >
          <AddToListIcon />
          <FormattedMessage id="buttons.add-to-list" />
        </Button>
      </div>

      <div
        className="protected-access-list__container"
        data-cy="protected-access-list"
      >
        {accessList.map(({ email, isSaved }, index) => (
          <ProtectedAccessListItem
            key={email}
            email={email}
            index={index}
            isSaved={isSaved}
            list={accessList}
            onSetList={setAccessList}
          />
        ))}
      </div>
    </section>
  );
};
