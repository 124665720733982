import {
  authenticationSelector,
  useAuthSubscription,
} from 'modules/authentication';
import React, { PropsWithChildren, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { PageLoading } from 'shared/components';

export const FirebaseAuthProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  useAuthSubscription();
  const { user } = useSelector(authenticationSelector.getState);

  if (typeof window === 'undefined' || user === undefined) return null;

  return (
    <Suspense fallback={<PageLoading isLoading={true} />}>{children}</Suspense>
  );
};
