import { mobileSearchVisibleSelector } from 'modules/dashboard';
import React, { PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';
import {
  CreateProposalButton,
  MobileNavDropdown,
  MobileSearch,
  NavSearch,
} from 'shared/components';
import { SlidingMenu } from '../sliding-menu';

export const MobileHeader: React.FC<PropsWithChildren> = () => {
  const [mobileSearchVisible, setMobileSearchVisible] = useRecoilState(
    mobileSearchVisibleSelector,
  );

  function toggleMobileSearch() {
    setMobileSearchVisible(!mobileSearchVisible);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <header className={`header__dashboard d--mobile--only`}>
      {!mobileSearchVisible ? (
        <>
          <SlidingMenu />
          <NavSearch toggleMobileSearch={toggleMobileSearch} />
          <CreateProposalButton />
          <div className="header__nav--mobile">
            <MobileNavDropdown />
          </div>
        </>
      ) : (
        <MobileSearch
          inputFocused={Boolean(mobileSearchVisible)}
          onToggleSearch={toggleMobileSearch}
        />
      )}
    </header>
  );
};
