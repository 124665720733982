import { motion } from 'framer-motion';
import React from 'react';

interface Props {
  type: LoadingTypes;
}

export const Loading: React.FC<Props> = ({ type }) => (
  <motion.div
    animate={{
      opacity: [0.4, 0.8],
      transition: {
        ease: 'linear',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 0.5,
        duration: 1,
      },
    }}
    className={`loading-skeleton loading-skeleton--animation loading-skeleton--${type}`}
  />
);
