import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { subscriptionAtoms } from 'modules/subscription';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { settingsSelector } from '../redux';

export function useTaxRedirects() {
  const customerId = useRecoilValue(subscriptionAtoms.customerId);
  const subscriptionId = useRecoilValue(subscriptionAtoms.subscriptionId);
  const { config } = useSelector(settingsSelector.getState);
  const { pathname } = useLocation();

  useEffect(() => {
    const isConfigCreated = config && !config.isOnboardingInProgress;
    const isSubscriptionCreated = customerId && subscriptionId;
    const shouldNavigateToDashboard =
      isConfigCreated && isSubscriptionCreated && pathname === '/onboarding/';

    if (shouldNavigateToDashboard) {
      navigate('/dashboard/proposals/overview');
    }
  }, [customerId, subscriptionId, config]);
}
