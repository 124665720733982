/**
 * Fetches a resource from a given URL and initiates a download.
 *
 * @param {string} url - The URL of the resource to be downloaded.
 * @param {string} filename - The name of the downloaded file.
 */

export async function downloadFromUrl(url: string, filename: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const blobURL = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = blobURL; // Set href to blobURL
  a.download = filename;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  URL.revokeObjectURL(blobURL); // Free up the blob object, as it's not needed anymore
}
