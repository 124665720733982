import timeIcon from 'assets/icons/icon-time-hourglass-16.svg';
import {
  FreeTrialLeftWrapper,
  subscriptionAtoms,
  SubscriptionSelectModal,
  usePropozePlusModal,
} from 'modules/subscription';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { getFreeTrialLeftDays } from 'shared/utils';

const FREE_TRIAL_LENGTH = 7;

interface Props {
  onCloseSlidingMenu?: (menuOpen: boolean) => void;
}

export const FreeTrialLeftNotice: React.FC<Props> = ({
  onCloseSlidingMenu,
}) => {
  const end = useRecoilValue(subscriptionAtoms.end);
  const { isModalVisible, openModal, closeModal } =
    usePropozePlusModal(onCloseSlidingMenu);
  const remainingDays = useMemo(() => getFreeTrialLeftDays(end), [end]);
  const isLastDay = useMemo(() => remainingDays === 1, [remainingDays]);
  const progressPercentage = useMemo(
    () => (remainingDays ? (remainingDays / FREE_TRIAL_LENGTH) * 100 : 0),
    [remainingDays],
  );

  return (
    <FreeTrialLeftWrapper>
      <div
        className="free-trial-left-notice__wrapper c-pointer"
        data-cy="free-trial-left-notice"
        onClick={openModal}
      >
        <div className="f f--align-center g-12 u-width--full">
          <img src={timeIcon} alt="Time icon" className="btn__icon" />
          <span className="text--xsm t-text-1 t-bold">
            {isLastDay ? (
              <FormattedMessage id="free-trial-left-notice.one-day-left" />
            ) : (
              <FormattedMessage
                id="free-trial-left-notice.title"
                values={{
                  remainingDays: remainingDays !== null ? remainingDays : 0,
                }}
              />
            )}
          </span>
        </div>
        <div className="free-trial-left-days__loading-bar-container">
          <div
            className={`free-trial-left-days__loading-bar ${isLastDay ? 'free-trial-left-days__loading-bar--last-day' : ''}`}
            style={{
              width: `${progressPercentage}%`,
            }}
          />
        </div>
        <span className="t-text-1 free-trial-left-days__description">
          <FormattedMessage id="free-trial-left-notice.description" />
        </span>
      </div>
      {isModalVisible && <SubscriptionSelectModal onClose={closeModal} />}
    </FreeTrialLeftWrapper>
  );
};
