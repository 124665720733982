import { createSelector } from 'reselect';

const getState = (state: AppState) => state.services;

const sortDescendingFromNewest = (a: Service, b: Service) =>
  b.createdAt - a.createdAt;

const getServicesByCreationTime = createSelector([getState], (serviceState) => {
  let { documents } = serviceState;
  documents = documents?.sort(sortDescendingFromNewest);

  return { ...serviceState, documents };
});

export const serviceSelector = {
  getState,
  getServicesByCreationTime,
};
