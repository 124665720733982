import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface Props {
  isChecked: boolean;
  checkmarkClassName?: string;
  scale?: number;
  handleToggle: () => void;
  register?: UseFormRegisterReturn;
}

export const SettingsNewsletterCheckbox: React.FC<Props> = ({
  isChecked,
  checkmarkClassName,
  scale,
  handleToggle,
  register,
}) => {
  return (
    <label
      style={{ transform: `scale(${scale})` }}
      className="newsletter-checkbox__container"
    >
      <input
        {...register}
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
        className="checkbox__input"
      />
      <span className={`checkmark ${checkmarkClassName || ''}`}></span>
    </label>
  );
};
