/**
 * Checks if an object has all the specified keys.
 *
 * This function is a type guard that iterates over a provided array of keys
 * and checks if each key exists in the given object. It's useful for validating
 * if a partial object of a certain type has all the properties required to
 * conform to that type.
 *
 * @template T The type of the object to check.
 * @param {Partial<T>} obj The object to check for the presence of keys. This object
 * is a partial representation of type T.
 * @param {(keyof T)[]} keys An array of keys that the object should have. These keys
 * should correspond to the properties defined in type T.
 * @returns {boolean} Returns `true` if all the specified keys exist in the object,
 * otherwise `false`. If `true`, the object can be treated as a complete instance of
 * type T.
 */
export function hasAllProperties<T>(
  obj: Partial<T>,
  keys: (keyof T)[],
): obj is T {
  return keys.every((key) => key in obj);
}
