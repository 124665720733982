import { collection, onSnapshot } from 'firebase/firestore';
import { db } from 'modules/firebase';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { organizationUsersFirestoreConverter } from '../const/organizationUsersFirestoreConverter';
import { authorizationAction } from '../redux';
import { useOrganizationIdSelector } from '../state';

export function useOrganizatonUsersSubscription() {
  const organizationId = useOrganizationIdSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    const collectionReference = collection(
      db,
      organizationId,
      'roles',
      'entities',
    ).withConverter(organizationUsersFirestoreConverter);

    return onSnapshot(
      collectionReference,
      (snapshot) =>
        dispatch(
          authorizationAction.onSnapshotUpdate(
            snapshot.docs.map((doc) => doc.data()),
          ),
        ),
      (error) => dispatch(authorizationAction.onSnapshotError(error)),
    );
  }, [organizationId]);
}
