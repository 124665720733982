import { AnimatePresence, motion } from 'framer-motion';
import {
  ProposalAppearanceAccordionList,
  TableOfContentsList,
  demoProposal,
  useTableOfContents,
} from 'modules/proposals';
import React from 'react';
import {
  ProposalSummaryDrawerToggle,
  ProposalSummaryHeader,
  ProposalSummaryOverlay,
  ProposalSummaryToggle,
} from './fragments';

interface Props {
  view: ProposalSummaryView;
  isSummaryOpen: boolean;
  proposal?: PublishStatusAware<Proposal> | Proposal;
  isPreview?: boolean;
  isSubscribed: boolean;
  isTocDisabled?: boolean;
  toggleView: (newView: ProposalSummaryView) => void;
  onSetSummaryOpen: (isOpen: boolean) => void;
}

export const ProposalSummaryDrawer: React.FC<Props> = ({
  view,
  isSummaryOpen,
  proposal,
  isPreview,
  isSubscribed,
  isTocDisabled,
  onSetSummaryOpen,
  toggleView,
}) => {
  const {
    ref,
    isMobile,
    scrollToActiveSection,
    isActiveSection,
    handleToggle,
  } = useTableOfContents(isSummaryOpen, onSetSummaryOpen, proposal);

  if (!proposal) return null;

  return (
    <>
      {(!isSummaryOpen || isMobile) && (
        <div className="proposal__summary__drawer--mobile">
          <ProposalSummaryDrawerToggle
            onHandleToggle={handleToggle}
            isSubscribed={isSubscribed}
            isTocDisabled={isTocDisabled}
          />
          <div
            className={`proposal__summary__vertical__divider ${
              isPreview ? 's-right--20' : ''
            }`}
          />
        </div>
      )}
      <div className="u-pos--rel f f--align-center">
        <AnimatePresence>
          {isSummaryOpen && (
            <>
              <motion.div
                key="toc"
                initial="closed"
                animate={isSummaryOpen ? 'open' : 'closed'}
                exit="closed"
                variants={{
                  open: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 0.25, ease: 'easeOut' },
                  },
                  closed: {
                    opacity: 1,
                    x: '-100%',
                    transition: { duration: 0.25, ease: 'easeOut' },
                  },
                }}
                className={`proposal__summary__container ${
                  view === 'brand-customization' &&
                  isSubscribed &&
                  'proposal__summary__container--brand-customization'
                }`}
              >
                <ProposalSummaryHeader
                  proposal={proposal}
                  isPreview={isPreview}
                  isSubscribed={isSubscribed}
                  onHandleToggle={handleToggle}
                />
                {!isPreview && (
                  <ProposalSummaryToggle view={view} toggleView={toggleView} />
                )}
                {view === 'table-of-contents' ? (
                  <TableOfContentsList
                    ref={ref}
                    isSubscribed={isSubscribed}
                    isPreview={isPreview}
                    proposal={isSubscribed ? proposal : demoProposal}
                    scrollToActiveSection={scrollToActiveSection}
                    isActiveSection={isActiveSection}
                  />
                ) : (
                  <ProposalAppearanceAccordionList
                    proposal={proposal}
                    isSubscribed={isSubscribed}
                  />
                )}
              </motion.div>
              <ProposalSummaryOverlay onHandleToggle={handleToggle} />
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};
