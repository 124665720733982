import { Accept } from 'react-dropzone';

export const fileAttachmentSupportedTypes: Accept = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ], // Microsoft Word Document
  'text/csv': ['.csv'], // CSV File
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ], // Microsoft Excel Spreadsheet
  'application/msword': ['.doc'], // Microsoft Word Document
  'application/vnd.ms-excel': ['.xls', '.xlsm', '.xltx', '.xltm'], // Microsoft Excel Spreadsheet
  'text/html': ['.html'], // HTML Document
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
    '.pptm',
    '.ppsm',
    '.ppsx',
    '.ppam',
    '.potx',
  ], // Microsoft PowerPoint Presentation
  'application/zip': ['.zip'], // Zip Archive
  'application/x-rar-compressed': ['.rar'], // RAR Archive
  'application/json': ['.json'], // JSON File
  'text/plain': ['.txt'], // Text File
  'application/vnd.oasis.opendocument.text': ['.odt'], // OpenDocument Text Document
  'application/vnd.oasis.opendocument.presentation': ['.odp'], // OpenDocument Presentation
  'application/rtf': ['.rtf'], // Rich Text Format File
  'application/x-tar': ['.tar'], // Tar Archive
  'application/x-bzip2': ['.bzip2'], // Bzip2 Archive
  'application/x-cpio': ['.cpio'], // CPIO Archive
  'application/gzip': ['.gzip'], // Gzip Archive
  'application/java-archive': ['.jar'], // Java Archive
  'application/x-pack200': ['.pack200'], // Pack200 Archive
  'application/x-dump': ['.dump'], // Memory Dump File
  'application/x-xz': ['.xz'], // XZ Archive
  'audio/x-aiff': ['.aiff', '.aif', '.aifc'], // Audio Interchange File Format
  'audio/basic': ['.au', '.snd'], // Basic Audio File
  'audio/midi': ['.mid', '.midi'], // MIDI File
  'audio/wav': ['.wav'], // WAV File
  'application/epub+zip': ['.epub'], // EPUB File
  'application/vnd.ms-outlook': ['.msg'], // Microsoft Outlook Message
  'application/vnd.ms-project': ['.mpp'], // Microsoft Project File
  'application/x-mspublisher': ['.pub'], // Microsoft Publisher Document
  'application/vnd.visio': ['.vsd'], // Microsoft Visio Document
  'application/vnd.oasis.opendocument.chart': ['.odc'], // OpenDocument Chart
  'application/vnd.oasis.opendocument.formula': ['.odf'], // OpenDocument Formula
  'application/vnd.oasis.opendocument.graphics': ['.odg'], // OpenDocument Graphics
  'application/vnd.oasis.opendocument.image': ['.odi'], // OpenDocument Image
  'application/vnd.oasis.opendocument.text-master': ['.odm'], // OpenDocument Master Document
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'], // OpenDocument Spreadsheet
  'application/vnd.oasis.opendocument.chart-template': ['.otc'], // OpenDocument Chart Template
  'application/vnd.oasis.opendocument.formula-template': ['.otf'], // OpenDocument Formula Template
  'application/vnd.oasis.opendocument.graphics-template': ['.otg'], // OpenDocument Graphics Template
  'application/vnd.oasis.opendocument.text-web': ['.oth'], // OpenDocument Web Document
  'application/vnd.oasis.opendocument.image-template': ['.oti'], // OpenDocument Image Template
  'application/vnd.oasis.opendocument.presentation-template': ['.otp'], // OpenDocument Presentation Template
  'application/vnd.oasis.opendocument.spreadsheet-template': ['.ots'], // OpenDocument Spreadsheet Template
  'application/vnd.oasis.opendocument.text-template': ['.ott'], // OpenDocument Text Template
  'video/x-flv': ['.flv'], // Flash Video File
  'application/java-vm': ['.class'], // Java Class File
  'application/mbox': ['.mbox'], // Mbox File
  'application/ibooks+zip': ['.ibooks'], // iBooks File
  'application/xml': ['.xml'], // XML File
  'application/font-sfnt': ['.ttf', '.otf'], // TrueType/OpenType Font
  'application/font-woff': ['.woff'], // Web Open Font Format
  'application/font-woff2': ['.woff2'], // Web Open Font Format 2
  'application/vnd.ms-fontobject': ['.eot'], // Embedded OpenType Font
  'application/pkix-cert': ['.cer'], // X.509 Certificate
  'application/x-x509-ca-cert': ['.crt', '.der'], // X.509 Certificate
  'application/x-pkcs12': ['.p12', '.pfx'], // PKCS #12 Certificate
  'application/vnd.apple.pages': ['.pages'], // Apple Pages Document
  'font/ttf': ['.ttf'], // TrueType Font
  'font/otf': ['.otf'], // OpenType Font
  'application/pkcs12': ['.p12', '.pfx'], // PKCS #12 Certificate
  'application/vnd.ms-powerpoint': ['.ppt'], // Microsoft PowerPoint
  'application/x-iwork-keynote-sffkey': ['.key'], // Apple Keynote Presentation
  'application/vnd.apple.keynote': ['.keynote'], // Apple Keynote (older versions)
  'application/vnd.ms-access': ['.mdb', '.accdb'], // Microsoft Access Database
  'application/x-sqlite3-database': ['.sqlite'], // SQLite Database
  'application/x-msdownload': ['.exe'], // Windows Executable File
  'application/x-shockwave-flash': ['.swf'], // Adobe Flash File
  'application/x-7z-compressed': ['.7z'], // 7-Zip Archive
  'application/x-bzip': ['.bz'], // Bzip Archive
  'application/x-zip-compressed': ['.zip'], // Zip Archive
  'application/vnd.ms-cab-compressed': ['.cab'], // Microsoft Cabinet Archive
  'application/vnd.android.package-archive': ['.apk'], // Android Package (APK)
  'application/x-deb': ['.deb'], // Debian Package
  'application/x-rpm': ['.rpm'], // RPM Package
  'application/x-msi': ['.msi'], // Windows Installer Package
  'application/vnd.apple.installer+xml': ['.pkg'], // Apple Installer Package
  'application/octet-stream': ['.bin', '.dmg', '.iso'], // Binary File, Disk Image, ISO Image,

  'application/pdf': ['.pdf'], // PDF File
};
