export const ACCEPTANCE_STATUS_CONFIG = {
  published: {
    className: 'proposal-overview-item__publish-status__btn--published',
    messageId: 'proposals.overview.publish-status.published',
  },
  draft: {
    className: 'proposal-overview-item__publish-status__btn--draft',
    messageId: 'proposals.overview.publish-status.draft',
  },
  template: {
    className: 'proposal-overview-item__publish-status__btn--template',
    messageId: 'proposals.overview.publish-status.template',
  },
  archived: {
    className: 'proposal-overview-item__publish-status__btn--archived',
    messageId: 'proposals.overview.publish-status.archived',
  },
};
