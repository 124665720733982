import iconBack from 'assets/icons/icon-arrow-left-12.svg';
import { motion } from 'framer-motion';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { ProposalOverviewFilterOptions } from '../proposal-overview-filter-options';
import { ProposalOverviewFilterList } from './ProposalOverviewFilterList';

interface Props {
  filters: Filter[];
  filterOption: FilterOption | null;
  onSetFilterOption: (option: FilterOption | null) => void;
  onSetFilters: (filters: Filter[]) => void;
  onClose: () => void;
  handleRemoveFilter: (index: number) => void;
}

export const ProposalOverviewFilterModal: React.FC<Props> = ({
  filterOption,
  filters,
  onSetFilterOption,
  onSetFilters,
  onClose,
  handleRemoveFilter,
}) => {
  const [step, setStep] = useState<1 | 2 | 3>(filters?.length ? 1 : 2);
  const currentFilters = useMemo(
    () => filters?.filter((filter) => filter.type === filterOption),
    [filters, filterOption],
  );

  function clearCurrentFilters() {
    onSetFilters(filters.filter(({ type }) => type !== filterOption));
  }

  function handleSelectFilterOption(option: FilterOption | null) {
    onSetFilterOption(option);
    setStep(3);
  }

  function handleBack() {
    onSetFilterOption(null);
    setStep(2);
  }

  function handleApply() {
    setStep(1);
  }

  function handleClearAll() {
    onSetFilterOption(null);
    onSetFilters([]);
    setStep(2);
  }

  function handleClose() {
    onSetFilterOption(null);
    onClose();
  }

  function handleEditFilter(type: FilterOption) {
    onSetFilterOption(type);
    setStep(3);
  }

  function renderModal() {
    if (step === 2) {
      return (
        <>
          <ModalHeader headerSelector="proposal-overview-filter-modal-header">
            <p className="text--lg">
              <FormattedMessage id="proposals.overview.filter.title" />
            </p>
          </ModalHeader>
          <ModalContent>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="proposal-overview__filter__modal"
            >
              <ProposalOverviewFilterOptions
                filterOption={filterOption}
                onSetFilterOption={handleSelectFilterOption}
              />
            </motion.div>
          </ModalContent>
          <ModalFooter>
            <Button
              type="button"
              style="outline"
              size="lrg"
              onClick={handleClose}
            >
              <FormattedMessage id="buttons.cancel" />
            </Button>
          </ModalFooter>
        </>
      );
    }

    if (step === 3) {
      return (
        <>
          <header data-cy="proposal-overview-filter-modal-header">
            <div className="f f--justify-between u-width--full f--align-center">
              <div
                className="f f--center f--align-center s-bottom--tny"
                onClick={handleBack}
              >
                <img src={iconBack} alt="MagnifierIcon" />
                <p className="proposal-overview__filter__action s-left--sml">
                  <FormattedMessage id="buttons.back-to-filters" />
                </p>
              </div>
              {Boolean(currentFilters?.length) && (
                <span
                  onClick={clearCurrentFilters}
                  className="proposal-overview__filter__action s-left--sml"
                >
                  <FormattedMessage id="proposals.overview.filter.clear-selection" />
                </span>
              )}
            </div>
          </header>
          <ModalContent>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="proposal-overview__filter__modal"
            >
              <ProposalOverviewFilterOptions
                filterOption={filterOption}
                onSetFilterOption={onSetFilterOption}
              />
            </motion.div>
          </ModalContent>
          <ModalFooter>
            {Boolean(currentFilters?.length) && (
              <Button
                type="button"
                style="primary"
                size="lrg"
                onClick={handleApply}
              >
                <FormattedMessage id="buttons.apply-filters" />
              </Button>
            )}
          </ModalFooter>
        </>
      );
    }

    return (
      <>
        <header data-cy="proposal-overview-filter-modal-header">
          <div className="f f--justify-between u-width--full f--align-center">
            <p className="text--lg t-text-1 t-bold">
              <FormattedMessage id="proposals.overview.filter.title" />{' '}
            </p>
            <span
              onClick={handleClearAll}
              className="proposal-overview__filter__action"
            >
              <FormattedMessage id="proposals.overview.filter.clear-all" />
            </span>
          </div>
        </header>
        <ModalContent>
          <ProposalOverviewFilterList
            filterOption={filterOption}
            onSetFilterOption={onSetFilterOption}
            onRemoveFilter={handleRemoveFilter}
            handleAddFilterModal={handleBack}
            handleEditFilterModal={handleEditFilter}
          />
        </ModalContent>
        <ModalFooter>
          <Button type="button" style="primary" size="lrg" onClick={onClose}>
            <FormattedMessage id="proposals.overview.filter.see-results" />
          </Button>
        </ModalFooter>
      </>
    );
  }

  return (
    <Modal close={handleClose} isDashboardModal isFilterModal>
      {renderModal()}
    </Modal>
  );
};
