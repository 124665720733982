import { createAction } from 'modules/redux-store';

export const authenticationAction = {
  loading: () => createAction('authentication/LOADING'),

  login: (user: FirebaseUser) => createAction('authentication/LOGIN', { user }),

  error: (error?: FirebaseAuthError) =>
    createAction('authentication/ON_SNAPSHOT_ERROR', { error }),

  resetErrors: () => createAction('authentication/RESET_ERRORS'),

  logout: () => createAction('authentication/LOGOUT'),
};
