import React, { useEffect, useState } from 'react';
import { useModal } from 'shared/hooks';
import {
  editWrappedLink,
  ensureHttpUrl,
  getLinkText,
  isLinkActive,
  isLinkHttpUrl,
  unwrapLink,
} from 'shared/utils';
import { Editor, Transforms } from 'slate';
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react';
import { RichTextToolbarLinkModal } from './RichTextToolbarLinkModal';
import { RichTextToolbarLinkTooltip } from './RichTextToolbarLinkTooltip';

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

interface Props {
  index: number;
  element: LinkElement;
  attributes: {
    'data-slate-node': 'element';
    'data-slate-inline'?: true;
    'data-slate-void'?: true;
    dir?: 'rtl';
    ref: any;
  };
  children: any;
  isReadOnly?: boolean;
}

export const RichTextLinkElement: React.FC<Props> = ({
  index,
  attributes,
  children,
  isReadOnly,
  element,
}) => {
  const selected = useSelected();
  const focused = useFocused();
  const editor = useSlateStatic();

  const { isModalOpen, closeModal, openModal } = useModal();
  const [url, setUrl] = useState('');
  const [text, setText] = useState('');

  function handleLinkClick(
    event: React.MouseEvent<HTMLAnchorElement>,
    element: LinkElement,
  ) {
    event.preventDefault();

    if (isReadOnly) {
      window.open(ensureHttpUrl(element.url), '_blank');
      return;
    }

    const path = ReactEditor.findPath(editor, element);
    const range = Editor.range(editor, path);
    Transforms.select(editor, range);

    event.currentTarget.classList.add('slate__link--selected');

    return;
  }

  function handleOpenEditModal() {
    const path = ReactEditor.findPath(editor, element);
    const range = Editor.range(editor, path);
    Transforms.select(editor, range);
    openModal();
  }

  function handleSave() {
    const updatedUrl = isLinkHttpUrl(url || element.url)
      ? url || element.url
      : `https://${url || element.url}`;
    editWrappedLink(editor, updatedUrl, text);
    closeModal();
    setText('');
    setUrl('');
  }

  function handleUnlink() {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }
    return;
  }

  function handleEscape(event: KeyboardEvent) {
    if (event.key !== 'Escape') return;

    // Deselect and unfocus the editor
    Transforms.deselect(editor);
    ReactEditor.blur(editor);
  }

  /**Event listener for keydown */
  useEffect(() => {
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [editor]);

  return (
    <>
      <a
        {...attributes}
        href={ensureHttpUrl(element.url)}
        onClick={(event) => handleLinkClick(event, element)}
        className="u-pos--rel"
      >
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </a>

      {selected && focused && !isModalOpen && (
        <RichTextToolbarLinkTooltip
          index={index}
          element={element}
          onHandleUnlink={handleUnlink}
          onOpenEditModal={handleOpenEditModal}
          attributes={attributes}
        />
      )}

      {isModalOpen && (
        <RichTextToolbarLinkModal
          defaultUrl={element.url}
          onHandleSave={handleSave}
          defaultText={getLinkText(element)}
          onSetText={setText}
          onSetUrl={setUrl}
          onClose={closeModal}
        />
      )}
      <span
        id={`global-tooltip-container-${index}-${element.url}-${element.children
          .map((child) => child.text)
          .join('')}`}
      />
    </>
  );
};
