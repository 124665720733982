import { createAction } from 'modules/redux-store';

export const settingsAction = {
  loading: () => createAction('settings/LOADING'),

  onSnapshotUpdate: (document: Settings | null) =>
    createAction('settings/ON_SNAPSHOT_UPDATE', { document }),

  onSnapshotError: (error: FirestoreError) =>
    createAction('settings/ON_SNAPSHOT_ERROR', { error }),
};
