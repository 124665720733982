import sparksIcon from 'assets/icons/icon-sparks-duo-12.svg';
import React, { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { subscriptionSelectors } from '../state';

export const ProposalResponseToggle: React.FC = () => {
  const { control } = useFormContext<PublishStatusAware<Proposal>>();
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const responseStatus = useWatch({
    name: 'responseStatus',
  });

  const handleToggleProposalReponse = useCallback(
    (
      field: ControllerRenderProps<
        PublishStatusAware<Proposal>,
        'responseStatus'
      >,
    ) =>
      () => {
        if (!isTierOne) return;
        field.onChange(responseStatus === 'allowed' ? 'disallowed' : 'allowed');
      },
    [responseStatus],
  );

  return (
    <div
      className={`f f--justify-between f--align-start g-32 ${
        !isTierOne && 'proposal-settings__modal__response__toggle--disabled'
      }`}
    >
      <div className="f f--col g-4">
        <div className="f g-8 f--align-center">
          <span className="text--xs t-text-1">
            Allow users to respond to this proposal
          </span>
          {!isTierOne && (
            <img
              src={sparksIcon}
              alt="Sparks icon"
              className="btn__icon s-right--sml d--desktop--only"
            />
          )}
        </div>
        <span className="t-text-3 text--sm">
          Give users permissions to Accept/Reject this proposal
        </span>
      </div>
      <div className="f f--align-center">
        <label className="toggle__label">
          <Controller
            control={control}
            name="responseStatus"
            render={({ field }) => (
              <input
                {...field}
                type="checkbox"
                name="responseStatus"
                className="toggle__input"
                checked={responseStatus === 'allowed'}
                onChange={handleToggleProposalReponse(field)}
              />
            )}
          />
          <span
            className="toggle__span"
            data-cy="proposal-respond-to-proposals-toggle"
          />
        </label>
      </div>
    </div>
  );
};
