import { generateFileButtonStyle } from 'modules/proposals';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DownloadIcon, UploadedIcon } from 'shared/components';
import { downloadLink, getFileSize } from 'shared/utils';

interface Props {
  file: ProposalFile;
  style: Proposal['style'];
}

export const PublicFileSegmentListItem: React.FC<Props> = ({
  file,
  style: { color, typography },
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const hasCustomButtonColor = color.buttonSecondary !== '#8039FF';

  return (
    <li
      style={{
        background: color.cardBackground,
        fontFamily: typography.body.fontFamily,
        fontWeight: typography.body.fontWeight,
      }}
      className="proposal-block-file__list--item"
    >
      <div className="file-input__uploaded__list-item__information__container t-ellipsis">
        <UploadedIcon
          className="file-input__uploadedmessage__img"
          fillColor={hasCustomButtonColor ? color.bodyText : undefined}
        />
        <div className="f f--col">
          <span
            style={{ color: color.cardTitle }}
            className="t-text-1 text--xs__responsive t-ellipsis"
          >
            {file.name}
          </span>
          <span
            style={{ color: color.bodyText }}
            className="t-text-2 text--xxs__responsive t-ellipsis"
          >
            {getFileSize(file.size)}
          </span>
        </div>
      </div>

      <Button
        style="outline"
        size="med"
        onClick={() => downloadLink(file.url, file.name)}
        className="d--desktop--only"
        btnSelector="download-file-btn"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        inlineStyle={{
          ...generateFileButtonStyle(
            color.buttonSecondary,
            typography,
            isHovered,
          ),
        }}
      >
        <DownloadIcon
          className="file-input__uploadedmessage__img btn__icon__left"
          strokeColor={hasCustomButtonColor ? color.buttonSecondary : undefined}
        />
        <FormattedMessage id="uploaders.file.download" />
      </Button>
      <Button
        onClick={() => downloadLink(file.url, file.name)}
        className="u-fit--content d--mobile--only"
        size="med"
        btnSelector="download-file-btn"
      >
        <DownloadIcon className="file-input__uploadedmessage__img" />
      </Button>
    </li>
  );
};
