import { useState } from 'react';

export function useAnimatedDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDropdownToggle = () => setIsOpen(true);
  const handleCloseDropdownToggle = () => setIsOpen(false);

  return {
    isOpen,
    handleOpenDropdownToggle,
    handleCloseDropdownToggle,
  };
}
