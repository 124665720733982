import pencilIcon from 'assets/icons/icon-pencil-12-primary.svg';
import { useDualPricing, useProposalTotalPrices } from 'modules/proposals';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { useISOCountry, useModal } from 'shared/hooks';
import {
  DiscountProposalPrice,
  ProposalVatPercentageInput,
  SubtotalIncludingDiscountsPrice,
  SubtotalProposalPrice,
  TotalDiscountModal,
  TotalDiscountPrice,
  TotalProposalPrice,
  VatAmountProposalPrice,
} from './fragments';

interface Props {
  proposal?: PublishStatusAware<Proposal>;
}

export const ProposalPrices: React.FC<Props> = ({ proposal }) => {
  const currency = useWatch({ name: 'currency' });
  const vatPercentage = useWatch({ name: 'vatPercentage' });
  const segments = useWatch({ name: 'segments' });
  const discountType =
    useWatch({
      name: `totalDiscount.discountType`,
    }) || 'absolute';
  const totalDiscountAmount =
    parseFloat(
      useWatch({
        name: `totalDiscount.discount`,
      }),
    ) || 0;

  const { isCurrencyWithDualPricing, exchangeRate } = useDualPricing(currency);

  const {
    subtotalAmount,
    vatAmount,
    totalAmount,
    servicesDiscountAmount,
    allDiscountsCombined,
    subtotalIncludingTotalDiscount,
    subtotalAfterDiscount,
    calculateServiceTotals,
  } = useProposalTotalPrices(
    vatPercentage,
    segments,
    discountType,
    totalDiscountAmount,
    proposal,
  );

  const {
    isModalOpen: isTotalDiscountModalOpen,
    closeModal: closeTotalDiscountModal,
    openModal: openTotalDiscountModal,
  } = useModal();
  const { ISOCountry } = useISOCountry();

  return (
    <>
      <div className="total-prices">
        <div className="total-prices__wrapper t-ellipsis">
          {Boolean(calculateServiceTotals()?.length) && (
            <>
              <p className="total-prices__label t-eta">
                <FormattedMessage id="proposals.prices.service-total" />
              </p>
              <ul className="total-prices__service-totals__list">
                {calculateServiceTotals().map((total, i) => (
                  <li
                    key={i}
                    className="f f--between-center total-prices__subtotal__value s-bottom--med"
                  >
                    <span className="total-prices__label overflow-hidden t-eta s-right--lrg">
                      {total.label}
                    </span>
                    <span className="t-eta">{total.value}</span>
                  </li>
                ))}
              </ul>
            </>
          )}
          <div className="divider-12 s-bottom--24" />
          <div className="f f--justify-between f--align-start s-bottom--med g-16">
            <p className="total-prices__label t-eta s-bottom--tny">
              <FormattedMessage id="proposals.prices.subtotal" />
            </p>
            <SubtotalProposalPrice
              currency={currency}
              subtotalAmount={subtotalAmount}
              exchangeRate={exchangeRate}
              ISOCountry={ISOCountry}
            />
          </div>
          <div className="f f--justify-between f--align-start s-bottom--med g-16">
            <p className="total-prices__label t-eta s-bottom--tny">
              <FormattedMessage id="proposals.prices.service-discount" />
            </p>
            <DiscountProposalPrice
              currency={currency}
              discountAmount={servicesDiscountAmount}
              exchangeRate={exchangeRate}
              ISOCountry={ISOCountry}
            />
          </div>
          <div
            className={`f f--justify-between s-bottom--med g-16 ${
              discountType === 'absolute' ? 'f--align-center' : 'f--align-start'
            }`}
          >
            <Button
              type="button"
              style="text"
              size="med"
              className="f c-pointer total-prices__label total-prices__enter-total-discount__btn"
              btnSelector="notify-owner-btn"
              onClick={openTotalDiscountModal}
            >
              <img
                src={pencilIcon}
                className="btn__icon btn__icon__left c-pointer"
                alt="PencilIcon"
              />
              <span className="total-prices__enter-total-discount  t-primary">
                {totalDiscountAmount > 0
                  ? 'Subtotal discount'
                  : 'Enter subtotal discount'}
              </span>
            </Button>
            <TotalDiscountPrice
              currency={currency}
              subtotalAfterDiscount={subtotalAfterDiscount}
              discountType={discountType}
              discountAmount={totalDiscountAmount}
              exchangeRate={exchangeRate}
              ISOCountry={ISOCountry}
            />
          </div>
          {totalDiscountAmount > 0 && (
            <div className="f f--justify-between f--align-start s-bottom--med g-16">
              <p className="total-prices__label t-eta s-bottom--tny">
                All discounts combined:
              </p>
              <DiscountProposalPrice
                currency={currency}
                discountAmount={allDiscountsCombined}
                exchangeRate={exchangeRate}
                ISOCountry={ISOCountry}
              />
            </div>
          )}
          <div className="divider-12 s-bottom--24" />
          <div className="f f--justify-between f--align-start s-bottom--med g-16">
            <p className="total-prices__label t-eta s-bottom--tny">
              Subtotal (incl. discounts):
            </p>
            <SubtotalIncludingDiscountsPrice
              currency={currency}
              amount={subtotalIncludingTotalDiscount}
              exchangeRate={exchangeRate}
              ISOCountry={ISOCountry}
            />
          </div>
          <div className="f f--between-center s-bottom--med">
            <div className="total-prices__vat">
              <p className="total-prices__label t-eta">
                <FormattedMessage id="proposals.prices.vat" />
              </p>
              <ProposalVatPercentageInput />
            </div>
          </div>
          <div className="f f--justify-between f--align-start s-bottom--med g-16">
            <p className="total-prices__label t-eta">
              <FormattedMessage id="proposals.prices.vat-amount" />
            </p>
            <VatAmountProposalPrice
              currency={currency}
              vatAmount={vatAmount}
              exchangeRate={exchangeRate}
              ISOCountry={ISOCountry}
            />
          </div>
          <div className="divider-12 s-bottom--24" />
          <div className="f f--justify-between f--align-start s-bottom--med g-16">
            <p className="t-bold">
              <FormattedMessage id="proposals.prices.total" />
            </p>
            <TotalProposalPrice
              currency={currency}
              totalAmount={totalAmount}
              exchangeRate={exchangeRate}
              ISOCountry={ISOCountry}
            />
          </div>
          {isCurrencyWithDualPricing && (
            <li className="proposal__total__list__item">
              <span className="proposal__total__list__label t-small t-text-3">
                <FormattedMessage id="proposals.prices.conversion-rate" />
              </span>
              <span className="proposal__total__list__value t-small t-text-3">
                <FormattedMessage
                  id={`proposals.prices.${currency}-conversion`}
                />
              </span>
            </li>
          )}
        </div>
      </div>
      {isTotalDiscountModalOpen && (
        <TotalDiscountModal
          currency={currency}
          ISOCountry={ISOCountry}
          item={{
            discount: totalDiscountAmount,
            discountType,
          }}
          onClose={closeTotalDiscountModal}
        />
      )}
    </>
  );
};
