import { useFeatureFlag } from 'modules/feature-flags';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useStripePrices } from '../hooks';
import { subscriptionSelectors } from '../state';

export const StripePlansController: React.FC = () => {
  const enabled = useFeatureFlag('SUBSCRIPTION');
  const products = useRecoilValue(subscriptionSelectors.productsSelector);
  const { getStripeProducts } = useStripePrices();

  useEffect(() => {
    if (!enabled || products?.length) {
      return;
    }
    getStripeProducts();
  }, [enabled]);

  return <React.Fragment />;
};
