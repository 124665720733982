import addIcon from 'assets/icons/icon-add-16-negative.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  onClick: VoidFunction;
}

export const ClientButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button style="primary" size="med" type="button" onClick={onClick}>
      <img src={addIcon} className="btn__icon btn__icon__left" alt="AddIcon" />
      <FormattedMessage id="buttons.add-new-client" />
    </Button>
  );
};
