import arrowLeft from 'assets/icons/icon-arrow-left-pagination-16.svg';
import arrowRight from 'assets/icons/icon-arrow-right-pagination-16.svg';
import React from 'react';
import { Button } from '../button';

interface Props {
  pageNumber: number;
  totalPages: number;
  paginationSelector?: string;
  onGoToPage: (pageNumber: number) => void;
}

export const Pagination: React.FC<Props> = ({
  pageNumber,
  totalPages,
  paginationSelector,
  onGoToPage,
}) => (
  <section className="pagination">
    <div className="pagination__wrapper" data-cy={paginationSelector}>
      <Button
        onClick={() => onGoToPage(--pageNumber)}
        disabled={pageNumber === 1}
        className="pagination__arrow"
        btnSelector="pagination-btn-left"
      >
        <img src={arrowLeft} alt="ArrowLeft" className="btn__icon" />
      </Button>

      <div className="pagination__numbers">
        {pageNumber > 2 && (
          <Button
            onClick={() => onGoToPage(1)}
            className="pagination__number pagination__dots"
            btnSelector="pagination-btn-first"
          >
            &hellip;
          </Button>
        )}

        {pageNumber > 2 && (
          <Button
            onClick={() => onGoToPage(pageNumber - 2)}
            className="pagination__number"
          >
            {pageNumber - 2}
          </Button>
        )}

        {pageNumber > 1 && (
          <Button
            onClick={() => onGoToPage(--pageNumber)}
            className="pagination__number"
            btnSelector="pagination-btn-previous"
          >
            {pageNumber - 1}
          </Button>
        )}

        <Button
          disabled
          data-page-number={pageNumber}
          onClick={() => onGoToPage(pageNumber)}
          className="pagination__number is-active"
          btnSelector="pagination-btn-active"
        >
          {pageNumber}
        </Button>

        {pageNumber < totalPages && (
          <Button
            onClick={() => onGoToPage(++pageNumber)}
            className="pagination__number"
          >
            {pageNumber + 1}
          </Button>
        )}

        {pageNumber < totalPages - 1 && (
          <Button
            onClick={() => onGoToPage(pageNumber + 2)}
            className="pagination__number"
          >
            {pageNumber + 2}
          </Button>
        )}

        {pageNumber < totalPages - 2 && (
          <Button
            onClick={() => onGoToPage(pageNumber + 3)}
            className="pagination__number pagination__dots"
          >
            {pageNumber + 3}
          </Button>
        )}

        {totalPages - 1 > pageNumber && (
          <Button
            onClick={() => onGoToPage(totalPages)}
            className="pagination__number pagination__dots"
          >
            ...
          </Button>
        )}
      </div>

      <Button
        onClick={() => onGoToPage(++pageNumber)}
        disabled={pageNumber === totalPages}
        className="pagination__arrow"
        btnSelector="pagination-btn-right"
      >
        <img src={arrowRight} alt="ArrowLeft" className="btn__icon" />
      </Button>
    </div>

    <div className="d--mobile--only pagination__mobile">
      <Button
        onClick={() => onGoToPage(--pageNumber)}
        disabled={pageNumber === 1}
        className="pagination__arrow f--justify-start"
      >
        <img src={arrowLeft} alt="ArrowLeft" className="btn__icon" />
      </Button>

      <span className="t-text-2 t-label">
        {pageNumber} of {totalPages}
      </span>
      <Button
        onClick={() => onGoToPage(++pageNumber)}
        disabled={pageNumber === totalPages}
        className="pagination__arrow f--justify-end"
      >
        <img src={arrowRight} alt="ArrowLeft" className="btn__icon" />
      </Button>
    </div>
  </section>
);
