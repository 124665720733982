import { DashboardLayout } from 'modules/dashboard';
import { SubscriptionTierWrapper } from 'modules/subscription';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageLoading, SEO, Sort } from 'shared/components';
import { ProposalStatisticsItem } from '../components';
import { useProposalStatistics } from '../hooks';
import { useProposalsLoadingSelector } from '../state';

export const ProposalsStatistics: React.FC<PageProps> = ({ location }) => {
  const { formatMessage } = useIntl();
  const loading = useProposalsLoadingSelector();
  const {
    sortOption,
    proposalsWon,
    proposalsLost,
    proposalsSent,
    proposalsViewed,
    proposalsCreated,
    proposalsExpired,
    setSortOption,
  } = useProposalStatistics();

  if (loading) return <PageLoading isLoading={loading} />;

  return (
    <>
      <SEO
        title={formatMessage({ id: 'proposals.seo.title' })}
        description={formatMessage({ id: 'proposals.seo.description' })}
        href={location.href}
      />
      <DashboardLayout>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="proposal-statistics-title"
        >
          <FormattedMessage id="proposals.statistics.title" />
        </header>
        <div className="dashboard__section__mobile__actions f--col f--align-start g-16">
          <header
            className="text--xs t-bold"
            data-cy="proposal-statistics-mobile-title"
          >
            <FormattedMessage id="proposals.statistics.title" />
          </header>
          <Sort
            sortOption={sortOption}
            onSetSortOption={setSortOption}
            sortBtnClassName="proposal-statistics__sort__btn"
            sortOptionMessageId={`proposals.statistics.sort.${sortOption}`}
            sortOptions={[
              'last7Days',
              'last30Days',
              'last90Days',
              'last365Days',
            ]}
            unit="statistics"
          />
        </div>
        <div className="dashboard__section__actions">
          <Sort
            sortOption={sortOption}
            onSetSortOption={setSortOption}
            sortOptionMessageId={`proposals.statistics.sort.${sortOption}`}
            btnSelector="sort-proposals"
            sortOptions={[
              'last7Days',
              'last30Days',
              'last90Days',
              'last365Days',
            ]}
            unit="statistics"
            sortContainerClassName="l-0"
          />
        </div>
        <div className="dashboard__section__list__container">
          <div className="dashboard__proposal-statistics__container">
            <ProposalStatisticsItem
              title={formatMessage({
                id: 'proposals.statistics.created',
              })}
              metric={proposalsCreated}
            />
            <ProposalStatisticsItem
              title={formatMessage({
                id: 'proposals.statistics.sent',
              })}
              metric={proposalsSent}
            />
            <ProposalStatisticsItem
              title={formatMessage({
                id: 'proposals.statistics.viewed',
              })}
              metric={proposalsViewed}
            />
            <ProposalStatisticsItem
              title={formatMessage({
                id: 'proposals.statistics.won-lost',
              })}
              metric={`${proposalsWon}/${proposalsLost}`}
            />
            <SubscriptionTierWrapper tier="plus">
              <ProposalStatisticsItem
                title={formatMessage({
                  id: 'proposals.statistics.expired',
                })}
                metric={proposalsExpired}
              />
            </SubscriptionTierWrapper>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
