import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AddressOneInput } from './address-one-input';
import { AddressTwoInput } from './address-two-input';
import { CityInput } from './city-input';
import { CountrySelect } from './county-select';
import { PostalCodeInput } from './postal-code-input';
import { StateInput } from './state-input';

export const ClientAddress: React.FC = () => (
  <>
    <h2 className="text--xs t-bold t-text-1 client-modal-address_header">
      <FormattedMessage id="inputs.address.title" />
    </h2>
    <div className="client-modal__input-field__full-width">
      <AddressOneInput
        label="inputs.client-address.label"
        placeholder="inputs.client-address.placeholder"
        inputSelector="client-addres-one-input"
      />
    </div>
    <div className="client-modal__input-field__full-width">
      <AddressTwoInput
        label="inputs.client-address-two.label"
        placeholder="inputs.client-address-two.placeholder"
        inputSelector="client-addres-two-input"
      />
    </div>

    <div className="client-modal__input-group">
      <div
        className="field__half--width client-modal__spacer client-modal__county-container"
        data-cy="country-modal-container"
      >
        <CountrySelect
          label="dropdowns.client-country.label"
          placeholder="dropdowns.client-country.placeholder"
        />
      </div>
      <div className="field__half--width">
        <StateInput
          label="inputs.client-state.label"
          placeholder="inputs.client-state.placeholder"
          inputSelector="client-state-input"
        />
      </div>
    </div>

    <div className="client-modal__input-group">
      <div className="client-modal-address__last-input-field client-modal__spacer client-modal__city-container">
        <CityInput
          label="inputs.client-city.label"
          placeholder="inputs.client-city.placeholder"
          inputSelector="client-city-input-input"
        />
      </div>
      <div className="client-modal-address__last-input-field">
        <PostalCodeInput
          label="inputs.client-postal-code.label"
          placeholder="inputs.client-postal-code.placeholder"
          inputSelector="client-postal-code-input"
        />
      </div>
    </div>
  </>
);
