import React from 'react';

export const DeleteIcon: React.FC = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className="btn__icon btn__icon__left"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 2.25H11.25C11.4489 2.25 11.6397 2.32902 11.7803 2.46967C11.921 2.61032 12 2.80109 12 3C12 3.19891 11.921 3.38968 11.7803 3.53033C11.6397 3.67098 11.4489 3.75 11.25 3.75H0.75C0.551088 3.75 0.360322 3.67098 0.21967 3.53033C0.0790176 3.38968 0 3.19891 0 3C0 2.80109 0.0790176 2.61032 0.21967 2.46967C0.360322 2.32902 0.551088 2.25 0.75 2.25H3.75V0.75C3.75 0.551088 3.82902 0.360322 3.96967 0.21967C4.11032 0.0790176 4.30109 0 4.5 0H7.5C7.69891 0 7.88968 0.0790176 8.03033 0.21967C8.17098 0.360322 8.25 0.551088 8.25 0.75V2.25ZM6.75 1.5H5.25V2.25H6.75V1.5ZM1.94065 11.5593C1.6596 11.2783 1.50119 10.8975 1.5 10.5V4.5H10.5V10.5C10.4988 10.8975 10.3404 11.2783 10.0593 11.5593C9.7783 11.8404 9.39746 11.9988 9 12H3C2.60254 11.9988 2.2217 11.8404 1.94065 11.5593Z"
        fill="#B9B9B9"
      />
    </svg>
  );
};
