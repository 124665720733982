import { UnitModal, unitSelector } from 'modules/units';
import React, { memo, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { FieldWrapper, SelectList, getSelectStyles } from 'shared/components';
import { useMobile, useModal } from 'shared/hooks';

interface SelectedOption {
  value: string;
  label: string;
  id?: string;
}
interface Props {
  index: number;
  control: UseFormReturn<PublishStatusAware<Proposal>>['control'];
  formState: UseFormReturn<PublishStatusAware<Proposal>>['formState'];
  isFieldRequired?: boolean;
  value?: Unit | null;
}

export const UnitSelect: React.FC<Props> = memo(
  ({ control, formState: { errors }, index, value, isFieldRequired }) => {
    const isMobile = useMobile();
    const { formatMessage } = useIntl();
    const { openModal, closeModal, isModalOpen } = useModal();

    const [unitNameInitialValue, setUnitNameInitialValue] = useState('');

    const { documents } = useSelector(unitSelector.getState);

    const options = useMemo(
      () =>
        documents?.map((doc) => ({
          value: doc.name,
          label: doc.name,
          id: doc.id,
        })) || [],
      [documents],
    );

    function getValue(data: Unit | null): SelectedOption | null {
      if (!data) return null;

      return {
        value: data.name,
        label: data.name,
        id: data.id,
      };
    }

    function handleButtonClick() {
      const selectedElement = document.querySelector(
        `select__unit--input${index}`,
      );
      const selectInput = selectedElement?.querySelector('input');

      if (selectInput) selectInput.blur();
      openModal();
    }

    function handleInputChange(inputValue: string) {
      if (!inputValue) return;
      setUnitNameInitialValue(inputValue);
    }

    return (
      <div data-cy={`unit-dropdown${index}`}>
        <FieldWrapper
          name={`segments.${index}.unit`}
          labelId="dropdowns.unit.label"
          isRequired={isFieldRequired}
          errors={errors}
        >
          <Controller
            name={`segments.${index}.unit`}
            control={control}
            rules={{
              required:
                isFieldRequired &&
                formatMessage({ id: 'dropdowns.unit.required' }),
              validate: (value) => {
                if (!isFieldRequired) return true;
                const unitMatch = documents?.find(
                  (u: Unit) => u?.id === value?.id || u?.name === value?.name,
                );
                if (unitMatch) return true;
                return `Unit with name: ${value?.name} doesn't exist.`;
              },
            }}
            defaultValue={value || null}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                options={options}
                onChange={(selectedOption: SelectedOption | null) => {
                  field.onChange({
                    name: selectedOption?.label || null,
                    id: selectedOption?.id || null,
                  });
                }}
                value={getValue(field.value)}
                onInputChange={handleInputChange}
                styles={getSelectStyles(!!fieldState.error)}
                id={`unit__styled__select${index}`}
                className={`select__unit--input${index}`}
                placeholder={formatMessage({
                  id: 'dropdowns.unit.placeholder',
                })}
                isSearchable={!isMobile}
                isDisabled={isModalOpen}
                components={{
                  MenuList: ({ children, ...rest }) => (
                    <components.MenuList {...rest} className="select__adder">
                      <SelectList
                        onButtonClick={handleButtonClick}
                        buttonType={'unit'}
                        btnSelector="add-unit"
                      >
                        {children}
                      </SelectList>
                    </components.MenuList>
                  ),
                }}
              />
            )}
          />
        </FieldWrapper>
        {isModalOpen && (
          <UnitModal
            inputValue={unitNameInitialValue}
            valueToUpdate={`segments.${index}.unit`}
            index={index}
            onClose={closeModal}
          />
        )}
      </div>
    );
  },
  (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    const hasErrors = !!Object.keys(nextProps.formState.errors);
    return (
      prevProps.formState.isDirty === nextProps.formState.isDirty && !hasErrors
    );
  },
);
