import closeIcon from 'assets/icons/icon-close-12-small.svg';
import addFilterIcon from 'assets/icons/icon-plus-12.svg';
import { AnimatePresence, motion } from 'framer-motion';
import {
  filterPlurals,
  useProposalOverviewFilterList,
  useProposalOverviewGroupedFilters,
} from 'modules/proposals-overview';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { replaceHyphensWithSpaces } from 'shared/utils';
import { ProposalOverviewFilterOptions } from '../proposal-overview-filter-options';

interface Props {
  filterOption: FilterOption | null;
  onSetFilterOption: (option: FilterOption | null) => void;
  onRemoveFilter: (index: number) => void;
  handleAddFilterModal?: () => void;
  handleEditFilterModal?: (type: FilterOption) => void;
}

export const ProposalOverviewFilterList: React.FC<Props> = ({
  filterOption,
  onSetFilterOption,
  onRemoveFilter,
  handleAddFilterModal,
  handleEditFilterModal,
}) => {
  const {
    filters,
    editFilter,
    isEditFilterOpen,
    isAddFilterOpen,
    addFilterDropdownRef,
    editFilterDropdownRef,
    isMobile,
    setIsCalendarOpen,
    handleAddFilter,
    handleEditFilter,
    removeFilterGroup,
    clearAllFilters,
    renderFilterValue,
  } = useProposalOverviewFilterList(
    handleAddFilterModal,
    handleEditFilterModal,
  );
  const groupedFilters = useProposalOverviewGroupedFilters(filters);

  return (
    <div
      ref={addFilterDropdownRef}
      className="proposal-overview__current__filters__container u-width--full"
    >
      <div
        ref={editFilterDropdownRef}
        className="proposal-overview__current__filters__list__container"
      >
        {Object.entries(groupedFilters).map(
          ([type, { count, currFilters }]) => {
            return (
              <div
                className="proposal-overview__filter__list__item"
                key={`proposal-overview-filter-${type}`}
                data-cy={`proposal-overview-filter-${type}`}
              >
                <span className="proposal-overview__filter__list__item__label t-label t-text-2">
                  {replaceHyphensWithSpaces(type)}
                </span>
                <div className="u-pos--rel u-height--full">
                  <div
                    onClick={() => handleEditFilter(type)}
                    className="proposal-overview__filter__group"
                  >
                    <span
                      className={`proposal-overview__filter__list__item__label t-ellipsis t-label t-text-1 ${
                        count > 1 && 't-lower'
                      }`}
                    >
                      {count > 1
                        ? `${count} ${filterPlurals[type] || type}`
                        : renderFilterValue(currFilters?.[0])}
                    </span>
                    <div className="proposal-overview-filter__group__tooltip">
                      <span className="text--xxs__responsive">
                        <FormattedMessage id="proposals.overview.filter.edit" />
                      </span>
                      <div className="proposal-overview-filter__group__tooltip__arrow" />
                    </div>
                  </div>

                  <AnimatePresence>
                    {isEditFilterOpen && !isMobile && editFilter === type && (
                      <motion.div
                        className="proposal-overview__filter__container proposal-overview__filter__list__container s-top--sml"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.2, ease: [0.33, 1, 0.68, 1] }}
                      >
                        <ProposalOverviewFilterOptions
                          filterOption={type}
                          onSetFilterOption={onSetFilterOption}
                          onSetIsCalendarOpen={setIsCalendarOpen}
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                <img
                  className="overview__filter__list__item__icon"
                  src={closeIcon}
                  alt="CloseIcon"
                  onClick={() =>
                    count > 1
                      ? removeFilterGroup(type)
                      : onRemoveFilter(filters.indexOf(currFilters[0]))
                  }
                />
              </div>
            );
          },
        )}
      </div>
      <div className="proposal-overview__add__filter__container">
        <Button
          style="text"
          className="overview__filter__clear__btn t-text-1 text--xxs__responsive"
          btnSelector="clear-filters-btn"
          onClick={clearAllFilters}
        >
          <FormattedMessage id="buttons.clear-filters" />
        </Button>
        <Button
          style="outline"
          className="overview__filter__add__btn"
          btnSelector="add-filter-btn"
          onClick={handleAddFilter}
        >
          <img
            src={addFilterIcon}
            alt="Add filter icon"
            className="btn__icon"
          />
          <span className="text--xxs__responsive s-left--sml">
            <FormattedMessage id="buttons.add-filter" />
          </span>
        </Button>
        <AnimatePresence>
          {isAddFilterOpen && (
            <motion.div
              className="proposal-overview__filter__container proposal-overview__filter__list__container"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2, ease: [0.33, 1, 0.68, 1] }}
            >
              <ProposalOverviewFilterOptions
                filterOption={filterOption}
                onSetFilterOption={onSetFilterOption}
                onSetIsCalendarOpen={setIsCalendarOpen}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
