import { useScrollIntoView } from 'modules/authentication';
import { unitSelector } from 'modules/units';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FieldWrapper } from 'shared/components';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  sendName: (name: string) => void;
  inputSelector?: string;
}

export const UnitNameInput: React.FC<Props> = ({
  autoFocus,
  sendName,
  inputSelector,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const [initialName, setInitialName] = useState('');

  const { documents } = useSelector(unitSelector.getState);

  const {
    formState: { errors },
    register,
    getValues,
  } = useFormContext<Unit>();

  function handleEmptyString(value: string) {
    return !!value.trim() || formatMessage({ id: 'inputs.units.required' });
  }

  function handleAlreadyExists(value: string) {
    if (value !== initialName) {
      return (
        !documents?.find(({ name }) => name === value) ||
        formatMessage({ id: 'inputs.units.already-exists' }, { name: value })
      );
    }
    return;
  }

  useEffect(() => {
    sendName(getValues('name'));
    setInitialName(getValues('name'));
  }, []);

  return (
    <FieldWrapper
      name="name"
      errors={errors}
      labelId="inputs.units.label"
      isRequired
    >
      <input
        type="text"
        autoComplete="off"
        autoFocus={autoFocus}
        className={`input input__med input__box ${
          errors.name ? 'input__box__error' : ''
        }`}
        placeholder={formatMessage({ id: 'inputs.units.placeholder' })}
        data-cy={inputSelector}
        onFocus={handleFocus}
        {...register('name', {
          required: formatMessage({ id: 'inputs.units.required' }),
          maxLength: {
            value: 200,
            message: formatMessage({
              id: 'inputs.units.max-length',
            }),
          },
          validate: {
            emptyString: (value) => handleEmptyString(value),
            alreadyExists: (value) => handleAlreadyExists(value),
          },
        })}
      />
    </FieldWrapper>
  );
};
