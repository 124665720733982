import { authenticationSelector } from 'modules/authentication';
import { useOrganizationIdSelector } from 'modules/authorization';
import { useFunction } from 'modules/firebase';
import { useSelector } from 'react-redux';

export function useSubscribeToNewsletter() {
  const subscribeToNewsletterCallable = useFunction<SubscribeToNewsletterData>(
    'subscribeToNewsletter',
  );
  const unsubscribeFromNewsletterCallable =
    useFunction<SubscribeToNewsletterData>('unsubscribeFromNewsletter');

  const organizationId = useOrganizationIdSelector();
  const { user } = useSelector(authenticationSelector.getState);

  async function subscribeToNewsletter() {
    try {
      if (!subscribeToNewsletterCallable || !user?.email) {
        throw new Error(
          'Something went wrong while subscribing to newsletter. Please contact our customer support.',
        );
      }
      const { data } = await subscribeToNewsletterCallable({
        email: user?.email || '',
        organizationId,
      });
      if (!data.success) throw new Error(data.message);
    } catch (e) {
      console.error(e);
    }
  }

  async function unsubscribeFromNewsletter() {
    try {
      if (!unsubscribeFromNewsletterCallable || !user?.email) {
        throw new Error(
          'Something went wrong while unsubscribing from newsletter. Please contact our customer support.',
        );
      }
      const { data } = await unsubscribeFromNewsletterCallable({
        email: user?.email || '',
        organizationId,
      });
      if (!data.success) throw new Error(data.message);
    } catch (e) {
      console.error(e);
    }
  }

  return {
    subscribeToNewsletter,
    unsubscribeFromNewsletter,
  };
}
