import {
  proposalAppearanceListItems,
  useProposalAppearanceColorList,
} from 'modules/proposals';
import React from 'react';
import { AccordionType } from '../ProposalAppearanceAccordionList';
import { ProposalAppearanceAccordionColorListItem } from './ProposalAppearanceAccordionColorListItem';
import { ProposalAppearanceAccordionListItemHeader } from './ProposalAppearanceAccordionListItemHeader';

interface Props {
  proposal: PublishStatusAware<Proposal> | Proposal;
  isSectionActive: boolean;
  handleToggle: (type: AccordionType) => void;
}

export const ProposalAppearanceAccordionColorList: React.FC<Props> = ({
  proposal,
  isSectionActive,
  handleToggle,
}) => {
  const { handleColorChange } = useProposalAppearanceColorList(proposal);

  return (
    <div className="proposal__summary__accordion__item__container">
      <ProposalAppearanceAccordionListItemHeader
        isSectionActive={isSectionActive}
        toggle="color"
        titleId="proposal-appearance-accordion.color.title"
        handleToggle={handleToggle}
      />

      <div
        className={`proposal__summary__accordion__container ${
          isSectionActive && 'accordion-active'
        }`}
      >
        <div
          className={`proposal__summary__accordion__color__container ${
            !isSectionActive && 'overflow-hidden'
          }`}
        >
          {proposalAppearanceListItems.map((item) => (
            <ProposalAppearanceAccordionColorListItem
              colorKey={item.colorKey}
              key={item.colorKey}
              proposal={proposal}
              handleColorChange={handleColorChange}
              titleId={item.titleId}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
