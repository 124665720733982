import { motion } from 'framer-motion';
import { StripePlansController } from 'modules/subscription';
import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  footerComponent?: React.FC<{ className?: string }>;
  containerClassName?: string;
  contentClassName?: string;
  contentId?: string;
  footerClassName?: string;
  isMobile?: boolean;
  isSummaryOpen?: boolean;
}

export const ProposalEditorLayout: React.FC<Props> = ({
  footerComponent: FooterComponent,
  containerClassName = 'container',
  contentClassName,
  contentId,
  footerClassName,
  isMobile = false,
  isSummaryOpen = false,
  children,
}) => (
  <>
    <motion.div
      id={contentId}
      variants={
        !isMobile
          ? {
              open: {
                marginLeft: '302px',
                transition: { duration: 0.25, ease: 'easeInOut' },
              },
              closed: {
                marginLeft: '0',
                transition: { duration: 0.25, ease: 'easeInOut' },
              },
            }
          : {}
      }
      animate={isSummaryOpen && !isMobile ? 'open' : 'closed'}
      className={`${containerClassName} container__content proposal-overflow`}
    >
      <main className={`container__main-content ${contentClassName}`}>
        {children}
      </main>
      {FooterComponent && <FooterComponent className={footerClassName} />}
    </motion.div>
    <StripePlansController />
    <div id="global-modal" />
  </>
);
