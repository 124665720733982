import { useOrganizationSubscriptionTier } from 'modules/subscription';
import { useMemo } from 'react';

export function useProposalLocked(
  organizationId: string,
  hasPermissions: boolean,
  proposal?: Proposal,
) {
  const { lockEnabled } = useOrganizationSubscriptionTier(
    organizationId,
    'plus',
  );

  /** Proposal should be locked if:
   * Protected visibility is enabled by the organizations subscription tier @param lockEnabled
   * Proposal visibility is set to "protected"
   * User does not have permissions to edit the proposal (is not in creator organization)
   */
  const isProposalLocked = useMemo(
    () =>
      lockEnabled && proposal?.visibility === 'protected' && !hasPermissions,
    [lockEnabled, proposal, hasPermissions],
  );

  return {
    isProposalLocked,
  };
}
