import assertNever from 'assert-never';
import React from 'react';
import { SharedButtonsClassNames } from './SharedButtonsClassNames';
import { ArchivedSharedButtons } from './archived-shared-buttons';
import { DraftSharedButtons } from './draft-shared-buttons';
import { PublishedSharedButtons } from './published-shared-buttons';
import { TemplateSharedButtons } from './template-shared-buttons';

interface Props extends SharedButtonsClassNames {
  publishStatus: PublishStatus;
  isEditorActions?: boolean;
  isItemActions?: boolean;
}

export const SharedButtons: React.FC<Props> = ({
  publishStatus,
  isEditorActions,
  isItemActions,
  ...rest
}) => {
  switch (publishStatus) {
    case 'draft':
      return <DraftSharedButtons isEditorActions={isEditorActions} {...rest} />;
    case 'published':
      return (
        <PublishedSharedButtons
          isEditorActions={isEditorActions}
          isItemActions={isItemActions}
          {...rest}
        />
      );
    case 'template':
      return (
        <TemplateSharedButtons
          isItemActions={isItemActions}
          isEditorActions={isEditorActions}
          {...rest}
        />
      );
    case 'archived':
      return <ArchivedSharedButtons {...rest} />;
    default:
      return assertNever(publishStatus);
  }
};
