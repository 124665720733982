import React from 'react';

interface Props {
  isChecked: boolean;
  checkmarkClassName?: string;
  handleToggle?: VoidFunction;
}

export const Checkbox: React.FC<Props> = ({
  isChecked,
  checkmarkClassName,
  handleToggle,
}) => {
  return (
    <label className="checkbox__container">
      <input
        onChange={handleToggle}
        type="checkbox"
        checked={isChecked}
        className="checkbox__input"
      />
      <span className={`checkmark ${checkmarkClassName || ''}`}></span>
    </label>
  );
};
