import { dashboardAction } from './actions';

interface DashboardState {
  readonly isDashboardOpen: boolean;
}

const INITIAL_STATE: DashboardState = {
  isDashboardOpen: false,
};

export const dashboardReducer = (
  state = INITIAL_STATE,
  action: ActionUnion<typeof dashboardAction> | Action<'authentication/LOGOUT'>,
): DashboardState => {
  switch (action.type) {
    case 'dashboard/SUCCESS':
      return {
        ...state,
        isDashboardOpen: action.payload.isDashboardOpen,
      };
    case 'authentication/LOGOUT':
      return INITIAL_STATE;
    default:
      return state;
  }
};
