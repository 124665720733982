import unpublishIcon from 'assets/icons/icon-unpublish-12.svg';
import { Proposal } from 'modules/proposals';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  className: string;
  isOverview?: boolean;
  btnSelector?: string;
}

export const UnpublishButton: React.FC<Props> = ({
  className,
  isOverview,
  ...props
}) => {
  const { setValue } = useFormContext<PublishStatusAware<Proposal>>();

  function handleUnpublish() {
    setValue('nextPublishStatus', 'draft');
  }

  return (
    <Button
      className={`${className} ${isOverview && 'btn__icon__stroke'}`}
      onClick={handleUnpublish}
      {...props}
      type="submit"
    >
      <img
        src={unpublishIcon}
        alt="UnpublishIcon"
        className="btn__icon btn__icon__left"
      />{' '}
      <FormattedMessage id="buttons.unpublish" />
    </Button>
  );
};
