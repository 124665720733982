const ON_VALUE = 'true';

export function useFeatureFlag(flag: FeatureFlag) {
  /**
   * NOT BAD CODE
   * requirement to actually get value of process.env.
   * Dynamically composed keys DO NOT WORK
   */
  switch (flag) {
    case 'SUBSCRIPTION':
      return process.env.GATSBY_FEATURE_FLAG_SUBSCRIPTION === ON_VALUE;
    case 'ACCEPTANCE':
      return process.env.GATSBY_FEATURE_FLAG_ACCEPTANCE === ON_VALUE;
    case 'VISIBILITY':
      return process.env.GATSBY_FEATURE_FLAG_VISIBILITY === ON_VALUE;
    case 'FILE-ATTACHMENT':
      return process.env.GATSBY_FEATURE_FLAG_FILE_ATTACHMENT === ON_VALUE;
    case 'CUSTOM-TAGS':
      return process.env.GATSBY_FEATURE_FLAG_CUSTOM_TAGS === ON_VALUE;
    case 'TABLE-OF-CONTENTS':
      return process.env.GATSBY_FEATURE_FLAG_TABLE_OF_CONTENTS === ON_VALUE;
    default:
      return false;
  }
}
