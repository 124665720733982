import { Link } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import { Button } from '../button';

interface Props extends PropsWithChildren {
  to?: string;
  className: string;
  onClick?: VoidFunction;
  testSelector?: string;
  disabled?: boolean;
}

export const NavButton: React.FC<Props> = ({
  className,
  to,
  children,
  onClick,
  testSelector,
  disabled,
}) => (
  <li className="nav__links__item">
    {onClick ? (
      <Button
        onClick={onClick}
        className={className}
        btnSelector={testSelector}
        disabled={disabled}
      >
        {children}
      </Button>
    ) : (
      <Link
        to={to || ''}
        activeClassName="active"
        className={className}
        data-cy={testSelector}
      >
        {children}
      </Link>
    )}
  </li>
);
