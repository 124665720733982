import React from 'react';
import { useMobile } from 'shared/hooks';
import { Toaster } from 'sonner';

export const ToastWrapper: React.FC = () => {
  const isMobile = useMobile();

  return (
    <div data-cy="toast-wrapper">
      <Toaster
        richColors
        position={isMobile ? 'top-center' : 'top-right'}
        expand={true}
        visibleToasts={5}
        toastOptions={{
          className: 'toast__wrapper',
        }}
        className="s-top--48"
        dir="ltr"
        duration={3000}
        closeButton={true}
      />
    </div>
  );
};
