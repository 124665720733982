import { useLocation } from '@reach/router';
import { format } from 'date-fns';
import { Link } from 'gatsby';
import { useOrganizationIdSelector } from 'modules/authorization';
import {
  ActionDrawer,
  ActionDrawerButtons,
  Proposal,
  useProposalActions,
  useProposalExpiredSelector,
} from 'modules/proposals';
import { ProposalExpiredTooltip } from 'modules/proposals-overview';
import {
  SubscriptionTierWrapper,
  subscriptionSelectors,
} from 'modules/subscription';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { ConfirmationModal } from 'shared/components';
import { useScrollPosition } from 'shared/hooks';
import { isLocationTypeGuard } from 'shared/utils';
import { ItemActions } from '../item-actions';
import { AcceptanceStatusSelect } from './fragments';

interface Props extends Proposal {
  documents?: Proposal[];
}

export const ProposalListItem: React.FC<Props> = ({
  id,
  name,
  client,
  publishStatus,
  acceptanceStatus,
  creationDateTime,
  documents,
}) => {
  const organizationId = useOrganizationIdSelector();
  const proposal = documents?.find((item) => item.id === id);
  const index = documents?.findIndex((item) => item.id === id);
  const scrollPosition = useScrollPosition();
  const location = useLocation();
  const { pathname, search } = location;
  const state = isLocationTypeGuard(location) && location.state;
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const isExpiredNotificationVisible = useProposalExpiredSelector(
    proposal,
    isTierOne,
  );

  const previewUrl = `${location?.origin}/s/${organizationId}/proposal/${id}`;

  const {
    submit,
    onCancel,
    onUpdate,
    getPublishStatusModalProps,
    form,
    publishStatusModal,
    acceptanceStatusModal,
  } = useProposalActions(proposal);

  const modalProps = getPublishStatusModalProps();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (state && Number(state.scrollY) > 0) {
      window.scrollTo(0, Number(state.scrollY));
    }
  }, []);

  return (
    <FormProvider {...form}>
      <form
        className="proposal-item"
        onSubmit={form.handleSubmit(submit)}
        data-cy={`list-item-${index}`}
      >
        <div className="proposal-item__main">
          {client && (
            <p className="u-width--shorter proposal-item__client-name t-label t-ellipsis s-bottom--sml">
              {client.name}
            </p>
          )}
          {publishStatus === 'archived' ? (
            <div className="f f--align-center proposal-overview-title__container">
              {isExpiredNotificationVisible && <ProposalExpiredTooltip />}
              <a
                href={previewUrl}
                target="_blank"
                className="proposal-item__title text--xs__responsive t-bold t-ellipsis-2-row"
                data-cy="proposal-item-title"
                rel="noreferrer"
              >
                {name}
              </a>
            </div>
          ) : (
            <div className="f f--align-center proposal-overview-title__container">
              {isExpiredNotificationVisible && <ProposalExpiredTooltip />}
              <Link
                to={formatMessage({ id: 'routes.new-proposal' }, { id })}
                state={{
                  lastPageUrl: `${pathname}${search}`,
                  scrollY: String(scrollPosition),
                }}
                className="proposal-item__title text--xs__responsive t-bold t-ellipsis-2-row"
                data-cy="proposal-item-title"
              >
                {name}
              </Link>
            </div>
          )}

          <div className="f f--align-center">
            <p className="t-tiny t-text-2 proposal-item__creation__date">
              {format(creationDateTime, 'MMM dd, yyyy, HH:mm')}
            </p>

            {acceptanceStatus && publishStatus === 'published' && (
              <>
                <span className="t-small t-text-2 proposal-item__divider m-bottom-auto">
                  ·{' '}
                </span>
                <div className="proposal-item__client-status__mobile">
                  <AcceptanceStatusSelect proposal={proposal} />
                </div>
              </>
            )}
          </div>

          <ItemActions publishStatus={publishStatus} />
        </div>
        <div className="proposal-item__acceptance-status__actions">
          {acceptanceStatus && publishStatus === 'published' && (
            <div
              className="proposal-item__client-status__desktop"
              data-cy={`acceptance-status-${index}`}
            >
              <AcceptanceStatusSelect proposal={proposal} />
            </div>
          )}
          {proposal && (
            <div className="proposal-item-create__actions">
              <ActionDrawer
                className="actionbar__drawer"
                isPublishModalOpen={publishStatusModal.isModalOpen}
                btnSelector={`more-option-btn-${index}`}
              >
                <ActionDrawerButtons
                  isItemActions
                  proposal={proposal}
                  publishStatus={publishStatus}
                  archiveClassName="actionbar__drawer__btn"
                  deleteClassName="actionbar__drawer__btn"
                  duplicateAsTemplateClassName="actionbar__drawer__btn"
                  duplicateClassName="actionbar__drawer__btn"
                  unpublishClassName="actionbar__drawer__btn"
                />
              </ActionDrawer>
            </div>
          )}
        </div>
        {publishStatusModal.isModalOpen && modalProps && (
          <ConfirmationModal
            onClose={onCancel}
            onSubmit={onUpdate}
            title={modalProps['title']}
            subtitle={modalProps['subtitle']}
            confirmationButtonLabel={modalProps['confirmationButtonLabel']}
            declineButtonLabel={modalProps['declineButtonLabel']}
            type={modalProps['type']}
          />
        )}
        <SubscriptionTierWrapper tier="plus">
          {acceptanceStatusModal.isModalOpen && (
            <ConfirmationModal
              onClose={acceptanceStatusModal.closeModal}
              onSubmit={onUpdate}
              title={formatMessage({
                id: 'proposal-editor.acceptance-status-modal.title',
              })}
              subtitle={formatMessage({
                id: 'proposal-editor.acceptance-status-modal.description',
              })}
              confirmationButtonLabel={formatMessage({
                id: 'proposal-editor.acceptance-status-modal.confirm',
              })}
              declineButtonLabel={formatMessage({
                id: 'proposal-editor.acceptance-status-modal.cancel',
              })}
            />
          )}
        </SubscriptionTierWrapper>
      </form>
    </FormProvider>
  );
};
