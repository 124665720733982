import failedIcon from 'assets/icons/icon-error-24.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import {
  useBeforeUnload,
  useCloseOnEscape,
  useSubmitOnEnter,
  useSupportMail,
} from 'shared/hooks';

interface Props extends ModalProps {
  onSetShowExpiredModal: (showExpiredModal: boolean) => void;
}

export const ProposalExpiredFailedModal: React.FC<Props> = ({
  onSetShowExpiredModal,
  onClose,
}) => {
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();

  const supportMail = useSupportMail();

  function handleError() {
    onSetShowExpiredModal(true);
  }

  useBeforeUnload(onClose);

  return (
    <Modal containerClassName="proposal-expired-status-modal__container">
      <ModalHeader className="f f--align-center">
        <img src={failedIcon} alt="IconFailed" className="btn__icon__left" />
        <p className="text--xl__responsive">
          <FormattedMessage id="proposal-expired.failed-modal.title" />
        </p>
      </ModalHeader>
      <ModalContent>
        <main className="proposal-expired-status-modal__content">
          <p className="t-text-2">
            <FormattedMessage
              id="proposal-expired.failed-modal.description"
              values={{
                support: (
                  <a href={supportMail}>
                    <Button
                      type="button"
                      style="text"
                      className="t-primary t-bold"
                    >
                      <FormattedMessage id="proposal-expired.failed-modal.contact" />
                    </Button>
                  </a>
                ),
              }}
            />
          </p>
        </main>
      </ModalContent>
      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            size="lrg"
            style="outline"
            onClick={onClose}
            ref={closeButtonRef}
            btnSelector="proposal-expired-failed-cancel-btn"
          >
            <FormattedMessage id="proposal-expired.failed-modal.cancel" />
          </Button>
          <Button
            size="lrg"
            style="primary"
            ref={submitButtonRef}
            onClick={handleError}
            btnSelector="proposal-expired-failed-confirm-btn"
          >
            <FormattedMessage id="proposal-expired.failed-modal.action" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
