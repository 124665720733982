import React from 'react';
import { isImageFile, isPDFFile, isVideoFile } from 'shared/utils';
import { PublicMediaSegmentListItemImage } from './PublicMediaSegmentListItemImage';
import { PublicMediaSegmentListItemVideo } from './PublicMediaSegmentListItemVideo';
import { PublicMediaSegmentListItemPDF } from './PublicMediaSegmentListItemPDF';

interface Props {
  item: MediaSegment;
}

export const PublicMediaSegmentList: React.FC<Props> = ({ item }) => {
  return (
    <ul className="proposal-block-file__list">
      {item.files.map((file, index) => (
        <div key={file.name}>
          {isImageFile(file.name) && (
            <PublicMediaSegmentListItemImage file={file} index={index} />
          )}
          {isVideoFile(file.name) && (
            <PublicMediaSegmentListItemVideo file={file} index={index} />
          )}
          {isPDFFile(file.name) && (
            <PublicMediaSegmentListItemPDF file={file} index={index} />
          )}
        </div>
      ))}
    </ul>
  );
};
