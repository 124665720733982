import { firebaseAtoms } from 'modules/firebase';
import { isFileSegment, maxUploadSize } from 'modules/proposals';
import { useConfigSelector } from 'modules/settings';
import { useCallback, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useBeforeUnload } from 'shared/hooks';
import { getFileSize } from 'shared/utils';
import { useFilesToRemoveState } from '../state';
import { getFilteredFiles, isAccountTotalUploadSizeUsed } from '../utils';
import { getFilteredAcceptedFiles } from '../utils/getFilteredAcceptedFiles';

export function useFileAttachment(
  index: number,
  segmentsFileSize: number,
  setValue: UseFormReturn<PublishStatusAware<Proposal>>['setValue'],
  getValues: UseFormReturn<PublishStatusAware<Proposal>>['getValues'],
) {
  const { formatMessage } = useIntl();
  const [fileErrors, setFileErrors] = useState<Record<string, string>>();

  const config = useConfigSelector();
  const [blobUrlCache, setBlobUrlCache] = useRecoilState(
    firebaseAtoms.blobUrlCache,
  );
  const resetBlobUrlCache = useResetRecoilState(firebaseAtoms.blobUrlCache);
  const [filesToRemove, setFilesToRemove] = useFilesToRemoveState();

  function clearBlobUrls() {
    for (const blobUrl in blobUrlCache) {
      URL.revokeObjectURL(blobUrl);
    }
    resetBlobUrlCache();
  }
  useBeforeUnload(clearBlobUrls);

  function removeFile(file: ProposalFile) {
    const filteredProposalFiles = getValues(`segments.${index}.files`)?.filter(
      (proposalFile) => proposalFile.name !== file.name,
    );
    setFilesToRemove(
      filesToRemove?.length ? [...filesToRemove, file.name] : [file.name],
    );
    setValue(`segments.${index}.files`, filteredProposalFiles, {
      shouldDirty: true,
    });
  }

  function removeFileError(fileName: string) {
    const newFileErrors = { ...fileErrors };
    delete newFileErrors[fileName];
    setFileErrors(newFileErrors);
  }

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      const currentFileSize = getValues(`segments.${index}.files`)?.reduce(
        (acc, file) => acc + file.size,
        0,
      );
      /**Total sum of files currently in one file attachment block */
      const acceptedFilesSize = acceptedFiles.reduce(
        (acc, file) => acc + file.size,
        currentFileSize,
      );

      if (acceptedFilesSize + segmentsFileSize > maxUploadSize) {
        setFileErrors((prevErrors) => ({
          ...prevErrors,
          [`Maximum size exceeded`]: formatMessage(
            { id: `uploaders.file.errors.maxSizeExceeded` },
            { max: '10MB' },
          ),
        }));
        return;
      }
      if (
        isAccountTotalUploadSizeUsed(
          acceptedFilesSize + segmentsFileSize,
          config,
        )
      ) {
        return;
      }

      const currentFiles = getValues('segments')?.flatMap((obj) =>
        isFileSegment(obj) ? obj.files.map((file) => file) : [],
      );
      const acceptedDuplicates = getFilteredAcceptedFiles(
        acceptedFiles,
        'duplicate',
      );
      if (acceptedDuplicates?.length > 0) {
        acceptedDuplicates.forEach((file) => {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [`Duplicate file: ${file.name}`]: formatMessage({
              id: `uploaders.file.errors.duplicateFile`,
            }),
          }));
        });
      }
      const acceptedUniques = getFilteredAcceptedFiles(acceptedFiles, 'unique');
      const duplicates = getFilteredFiles(
        acceptedUniques,
        [...currentFiles, ...getValues(`segments.${index}.files`)],
        'duplicate',
      );
      if (duplicates?.length > 0) {
        duplicates.forEach((file) => {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [`Duplicate file: ${file.name}`]: formatMessage({
              id: `uploaders.file.errors.duplicateFile`,
            }),
          }));
        });
      }

      const uniqueFiles = getFilteredFiles(
        acceptedUniques,
        [...currentFiles, ...getValues(`segments.${index}.files`)],
        'unique',
      );

      const newFiles = uniqueFiles.map((file) => {
        const blobUrl = URL.createObjectURL(file);
        setBlobUrlCache((prevBlobUrlCache) => ({
          ...prevBlobUrlCache,
          [file.name]: blobUrl,
        }));
        return {
          name: file.name,
          size: file.size,
          url: blobUrl,
        };
      });

      setValue(
        `segments.${index}.files`,
        [...getValues(`segments.${index}.files`), ...newFiles],
        {
          shouldDirty: true,
        },
      );
    },
    [setValue, segmentsFileSize],
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      const errorMessages: Record<string, string> = {};

      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          switch (error.code) {
            case 'file-too-large':
              errorMessages[file.name] = formatMessage(
                { id: `uploaders.file.errors.fileTooLarge` },
                { size: getFileSize(file.size) },
              );
              break;

            case 'file-invalid-type':
              errorMessages[file.name] = formatMessage({
                id: `uploaders.file.errors.fileInvalidType`,
              });
              break;

            default:
              errorMessages[file.name] = formatMessage({
                id: 'uploaders.file.errors.defaultError',
              });
              break;
          }
        });
      });

      setFileErrors((prevErrors) => ({
        ...prevErrors,
        ...errorMessages,
      }));
    },
    [fileErrors],
  );

  return {
    fileErrors,
    onDropAccepted,
    onDropRejected,
    removeFile,
    removeFileError,
  };
}
