export function useProposalPrices(
  pricePerUnit: number,
  quantity: number,
  discount: number,
) {
  const totalAmount = pricePerUnit * quantity;
  const discountAmount = totalAmount * (discount / 100);
  const subtotalAmount = totalAmount - discountAmount;

  return {
    totalAmount,
    discountAmount,
    subtotalAmount,
  };
}
