import { currencyOptions } from 'modules/proposals';
import React, { useCallback, useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select, { SingleValue } from 'react-select';
import { FieldWrapper, getSelectStyles } from 'shared/components';
import { useMobile } from 'shared/hooks';

export const CompanyDefaultCurrency: React.FC = () => {
  const { formatMessage } = useIntl();
  const isMobile = useMobile();

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<Settings>();

  const options = useMemo(
    () =>
      currencyOptions?.map((item) => ({
        value: item,
        label: item,
      })),
    [currencyOptions],
  );

  const handleCurrencyChange = useCallback(
    (field: ControllerRenderProps<Settings, 'defaultCurrency'>) =>
      (
        selectedOption: SingleValue<{
          value: string;
          label: string;
        }>,
      ) => {
        field.onChange(selectedOption?.value);
      },
    [],
  );

  function getValue(data?: string | null) {
    if (!data) return null;

    return {
      value: data,
      label: data,
    };
  }

  return (
    <>
      <p className="t-text-3 text--sm s-bottom--24 u-maxwidth--432">
        Customize your proposal settings. These will apply to all future
        proposals unless manually changed on the proposal page.
      </p>
      <div
        className="onboarding__default__currency s-bottom--12"
        data-cy="default-currency-dropdown"
      >
        <FieldWrapper
          labelId="inputs.default-currency.label"
          name="defaultCurrency"
          errors={errors}
        >
          <Controller
            name="defaultCurrency"
            control={control}
            rules={{
              required: formatMessage({
                id: 'dropdowns.currency.required',
              }),
            }}
            defaultValue={getValues('defaultCurrency') || null}
            render={({ field }) => (
              <Select
                {...field}
                options={options}
                onChange={handleCurrencyChange(field)}
                value={getValue(field.value)}
                styles={getSelectStyles<string>(
                  Boolean(errors.defaultCurrency),
                  124,
                )}
                id="currency__styled__select"
                className="select__currency--input"
                placeholder="Select currency"
                isSearchable={!isMobile}
              />
            )}
          />
        </FieldWrapper>
      </div>
      <p className="t-text-3 text--sm s-top--sml u-maxwidth--432">
        Your default currency will be pre-selected in all proposals. You can
        change this anytime.
      </p>
    </>
  );
};
