import React from 'react';

export const PencilIcon: React.FC = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15357_79897)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.86022 0.109199C10.0065 -0.0370508 10.2442 -0.0370508 10.3905 0.109199L11.8905 1.60995C12.0367 1.7562 12.0367 1.99395 11.8905 2.1402L6.63972 7.3887C6.59922 7.42995 6.54822 7.46145 6.49347 7.47945L4.24347 8.22945C4.20447 8.24295 4.16472 8.24895 4.12497 8.24895C4.02672 8.24895 3.93147 8.2107 3.85947 8.13945C3.75897 8.03895 3.72372 7.89045 3.76872 7.75545L4.51872 5.50545C4.53672 5.4507 4.56822 5.40045 4.60947 5.3592L9.86022 0.109199ZM10.5 7.4997C10.5 7.0497 10.8 6.7497 11.25 6.7497C11.7 6.7497 12 7.0497 12 7.4997V10.4997C12 11.3285 11.3287 11.9997 10.5 11.9997H1.5C0.67125 11.9997 0 11.3285 0 10.4997V1.4997C0 0.670951 0.67125 -0.000299453 1.5 -0.000299453H4.5C4.95 -0.000299453 5.25 0.299701 5.25 0.749701C5.25 1.1997 4.95 1.4997 4.5 1.4997H1.5V10.4997H10.5V7.4997Z"
          fill="#B9B9B9"
        />
      </g>
      <defs>
        <clipPath id="clip0_15357_79897">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
