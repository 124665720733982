import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const ForgotPasswordHeader: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="auth__header">
      <motion.h1
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, type: 'spring' }}
        className="auth__header--message t-bold t-text-1"
      >
        <FormattedMessage id="forgot-password.title" />
      </motion.h1>
      <motion.p
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, delay: 0.2, type: 'spring' }}
        className="text--xs__responsive t-text-1"
      >
        <FormattedMessage id="forgot-password.description" />{' '}
        <Link
          to={formatMessage({ id: 'routes.login' })}
          className="t-lower t-bold"
        >
          <FormattedMessage id="forgot-password.login" />
        </Link>
      </motion.p>
    </div>
  );
};
