import { useOverviewFiltersOptionSelector } from 'modules/proposals-overview';
import React from 'react';
import { Checkbox } from 'shared/components';

interface Props {
  type: FilterOption;
  name: string;
  label: string;
  isActive?: boolean;
  handleFilterChange: (type: FilterOption, value: string) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const ProposalOverviewFilterOption: React.FC<Props> = ({
  type,
  name,
  label,
  isActive,
  handleFilterChange,
  onMouseEnter,
  onMouseLeave,
}) => {
  const filters = useOverviewFiltersOptionSelector();
  const isChecked = filters?.some(
    (filter) => filter.type === type && filter.value === name,
  );

  return (
    <li
      className={`checkbox__option ${
        isActive ? 'proposal-overview__filter__option--active' : ''
      }`}
      onClick={() => handleFilterChange(type, name)}
      data-cy={`proposal-overview-filter-${type}-${name}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Checkbox
        isChecked={isChecked}
        handleToggle={() => handleFilterChange(type, name)}
      />
      <span
        className={`text--sm__responsive ${
          isChecked ? 't-text-1' : 't-text-2'
        }`}
      >
        {label}
      </span>
    </li>
  );
};
