import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreDocument } from 'modules/firebase';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { subscriptionAtoms } from '../state';

export function useResetSubscriptionStatus() {
  const organizationId = useOrganizationIdSelector();
  const isModalOpen = useRecoilValue(
    subscriptionAtoms.isStatusLoadingModalOpen,
  );
  const active = useRecoilValue(subscriptionAtoms.active);
  const status = useRecoilValue(subscriptionAtoms.status);
  const { set } = useFirestoreDocument<Subscription>(
    `${organizationId}/subscription`,
  );

  /** If user closes subscription modal during subscribe action set status to null automatically */
  useEffect(() => {
    if (status !== null && active && !isModalOpen) set({ status: null });
  }, [status]);
}
