import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Service } from '../Service';

export const servicesFirestoreConverter = {
  toFirestore: (service: Service) => ({
    ...new Service(service),
  }),
  fromFirestore: (doc: QueryDocumentSnapshot<Service>) =>
    new Service({ id: doc.id, ...doc.data() }),
};
