import { QueryDocumentSnapshot } from 'firebase/firestore';
import { OrganizationUser } from '../models';

export const organizationUsersFirestoreConverter = {
  toFirestore: (data: OrganizationUser) => ({
    ...new OrganizationUser(
      data.id,
      data.email,
      data.status,
      data.role,
      data.organizationId,
      data.organizationName,
    ),
  }),
  fromFirestore: (doc: QueryDocumentSnapshot<OrganizationUser>) => {
    const data = doc.data();
    return new OrganizationUser(
      doc.id,
      data.email,
      data.status,
      data.role,
      data.organizationId,
      data.organizationName,
    );
  },
};
