export const REFERRALS_LIST: Referral[] = [
  {
    source: 'friend',
    description: 'From a friend',
  },
  {
    source: 'google',
    description: 'Google search',
  },
  {
    source: 'blog',
    description: 'Blog or publication',
  },
  {
    source: 'facebook',
    description: 'Facebook',
  },
  {
    source: 'twitter',
    description: 'X (Twitter)',
  },
  {
    source: 'instagram',
    description: 'Instagram',
  },
  {
    source: 'linkedin',
    description: 'LinkedIn',
  },
  {
    source: 'socialMedia',
    description: 'Social media ads',
  },
  {
    source: 'softwareDirectory',
    description: 'App/Software directory',
  },
  {
    source: 'other',
    description: 'Other',
  },
];
