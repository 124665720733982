import { clientSelector } from 'modules/clients';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMobile } from 'shared/hooks';
import { isDateArray } from 'shared/utils';
import { useOverviewFiltersState } from '../state';
import { updateFilters } from '../utils';
import { useProposalOverviewFilteredOptions } from './useProposalOverviewFilteredOptions';

export function useProposalOverviewFilterOptions() {
  const isMobile = useMobile();
  const { formatMessage } = useIntl();

  const [query, setQuery] = useState('');

  const [filters, setFilters] = useOverviewFiltersState();
  const { documents: clients } = useSelector(clientSelector.getState);
  const {
    filteredClients,
    filteredFilterOptions,
    filteredCustomTags,
    filteredProposalStatus,
    filteredAcceptanceStatus,
    filteredValidityState,
  } = useProposalOverviewFilteredOptions(query);

  const defaultFilterSelectedDates = useMemo(
    () => filters?.find((filter) => filter.type === 'date')?.value,
    [filters],
  );
  const [selectedDates, setSelectedDates] = useState<Date[] | null>(
    isDateArray(defaultFilterSelectedDates)
      ? defaultFilterSelectedDates
      : [new Date()],
  );

  /**Handlers */
  function handleFilterChange(type: FilterOption, identifier: string | Date[]) {
    if (type === 'client') {
      const clientName = clients?.find(
        (client) => client.id === identifier,
      )?.name;
      setFilters((prevFilters) =>
        updateFilters(prevFilters, {
          type,
          value: identifier,
          label: clientName,
        }),
      );
      return;
    }
    setFilters((prevFilters) =>
      updateFilters(prevFilters, { type, value: identifier }),
    );
  }

  function handleDateChange(selectedDates: Date[]) {
    setSelectedDates(selectedDates);
    handleFilterChange('date', selectedDates);
  }

  function getPlaceholder(filterOption?: FilterOption) {
    if (!filterOption) {
      return formatMessage({
        id: 'proposals.overview.filter.search-filters',
      });
    }

    return formatMessage({
      id: `proposals.overview.filter.${filterOption}`,
    });
  }

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value);
  }

  useEffect(() => {
    /**User clears selection on mobile modal */
    if (!defaultFilterSelectedDates && selectedDates?.length && isMobile) {
      setSelectedDates(null);
    }
  }, [defaultFilterSelectedDates, selectedDates]);

  return {
    query,
    filters,
    selectedDates,
    filteredClients,
    filteredCustomTags,
    filteredFilterOptions,
    filteredProposalStatus,
    filteredAcceptanceStatus,
    filteredValidityState,
    handleSearch,
    getPlaceholder,
    handleDateChange,
    handleFilterChange,
  };
}
