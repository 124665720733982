import { useMatch } from '@reach/router';
import { motion } from 'framer-motion';
import {
  AuthenticationError,
  authErrorAtom,
  authLoadingAtom,
} from 'modules/authentication';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import {
  Button,
  EmailInput,
  LoadingSpinner,
  PasswordInput,
} from 'shared/components';
import { FacebookLogin } from '../facebook-login';
import { GoogleLogin } from '../google-login';
import { TwitterLogin } from '../twitter-login';
import { AuthenticationFormState } from './AuthenticationFormState';

interface Props {
  buttonText: JSX.Element;
  showPassword?: boolean;
  buttonClassName?: string;
  onSubmit: SubmitHandler<AuthenticationFormState>;
  loginWithGoogle?: () => void;
  loginWithTwitter?: () => void;
  loginWithFacebook?: () => void;
}

export const AuthenticationForm: React.FC<Props> = ({
  buttonText,
  showPassword,
  buttonClassName,
  onSubmit,
  loginWithGoogle,
  loginWithTwitter,
  loginWithFacebook,
}) => {
  const { formatMessage } = useIntl();
  const authLoading = useRecoilValue(authLoadingAtom);
  const authError = useRecoilValue(authErrorAtom);

  const form = useForm<AuthenticationFormState>({
    mode: 'onSubmit',
    defaultValues: {
      ...new AuthenticationFormState(),
    },
  });
  const isPathMatchingLogin = useMatch('/login');
  const isPathMatchingSignUp = useMatch('/sign-up');

  return (
    <FormProvider {...form}>
      <motion.form
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {authError && <AuthenticationError />}
        <div className="auth__field">
          <EmailInput />
        </div>

        {showPassword && (
          <div className="auth__field">
            <PasswordInput isPathMatchingLogin={Boolean(isPathMatchingLogin)} />
          </div>
        )}

        {authLoading ? (
          <Button
            type="button"
            disabled={authLoading}
            size="lrg"
            style="primary"
            className={`${
              buttonClassName ? buttonClassName : 'u-width--full'
            } s-top--lrg`}
          >
            <LoadingSpinner height={12} width={12} type="negative" />
            <p className="s-left--med">
              <FormattedMessage id="buttons.loading" />
            </p>
          </Button>
        ) : (
          <Button
            style="primary"
            size="lrg"
            type="submit"
            value="Submit"
            data-cy="form-submit-btn"
            btnSelector="form-submit-btn"
            className={`${
              buttonClassName ? buttonClassName : 'u-width--full'
            } s-top--lrg`}
          >
            {buttonText}
          </Button>
        )}
        {(loginWithFacebook || loginWithGoogle || loginWithTwitter) && (
          <div className="auth__social__container s-top--lrg">
            <div className="auth__social__separator">
              <span className="auth__social__separator__text text--sm t-text-1">
                {isPathMatchingLogin ? (
                  <FormattedMessage id="login.or" />
                ) : (
                  <FormattedMessage id="sign-up.or" />
                )}
              </span>
            </div>
            <div className="f f--align-center f--justify-center g-12">
              {loginWithGoogle && (
                <GoogleLogin
                  authLoading={authLoading}
                  loginWithGoogle={loginWithGoogle}
                />
              )}
              {loginWithTwitter && (
                <TwitterLogin
                  authLoading={authLoading}
                  loginWithTwitter={loginWithTwitter}
                />
              )}
              {loginWithFacebook && (
                <FacebookLogin
                  authLoading={authLoading}
                  loginWithFacebook={loginWithFacebook}
                />
              )}
            </div>
          </div>
        )}

        {isPathMatchingSignUp && (
          <div className="auth__signup__consent">
            <motion.span
              style={{
                opacity: 0,
                y: 8,
              }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
              className="text--xsm"
            >
              {' '}
              <FormattedMessage
                id="sign-up.consent"
                values={{
                  terms: (
                    <a
                      href={formatMessage({ id: 'routes.terms-of-service' })}
                      data-cy="terms-of-service-link"
                      className="t-bold"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="sign-up.terms" />
                    </a>
                  ),
                  privacy: (
                    <a
                      href={formatMessage({ id: 'routes.privacy-policy' })}
                      data-cy="privacy-policy-link"
                      className="t-bold"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="sign-up.privacy" />
                    </a>
                  ),
                }}
              />
            </motion.span>
          </div>
        )}
      </motion.form>
    </FormProvider>
  );
};
