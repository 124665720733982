import { collection, onSnapshot } from 'firebase/firestore';
import { useOrganizationIdSelector } from 'modules/authorization';
import { db } from 'modules/firebase';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { unitsFirestoreConverter } from '../const/unitsFirestoreConverter';
import { unitAction } from '../redux';

export function useUnitsSubscription() {
  const organizationId = useOrganizationIdSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(unitAction.loading());
    const collectionReference = collection(
      db,
      organizationId,
      'units',
      'entities',
    ).withConverter(unitsFirestoreConverter);

    return onSnapshot(
      collectionReference,
      (snapshot) =>
        dispatch(
          unitAction.onSnapshotUpdate(snapshot.docs.map((doc) => doc.data())),
        ),
      (error) => dispatch(unitAction.onSnapshotError(error)),
    );
  }, [organizationId]);
}
