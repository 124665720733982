import addIcon from 'assets/icons/icon-add-16-negative.svg';
import React from 'react';
import { Button } from 'shared/components';

interface Props {
  title: React.ReactNode | string;
  openModal: VoidFunction;
}

export const FormActionButton: React.FC<Props> = ({ openModal, title }) => (
  <Button type="button" style="primary" size="med" onClick={openModal}>
    <img src={addIcon} className="btn__icon btn__icon__left" alt="AddIcon" />
    {title}
  </Button>
);
