import checkmarkIcon from 'assets/icons/icon-checkmark-white-12.svg';
import saveIcon from 'assets/icons/icon-save-12.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadingSpinner } from 'shared/components';

interface Props {
  isSubmitting: boolean;
  isDirty: boolean;
  publishStatus: PublishStatus;
  isSaved?: boolean;
}

export const SaveNewVersionComponent: React.FC<Props> = ({
  isSubmitting,
  isDirty,
  publishStatus,
  isSaved,
}) => {
  const message = {
    draft: 'buttons.draft-updated',
    template: 'buttons.template-updated',
    published: 'buttons.published-updated',
  };

  if (!isDirty && isSaved) {
    return (
      <>
        <img
          src={checkmarkIcon}
          alt="CheckmarkIcon"
          className="btn__icon btn__icon__left"
        />
        <span>
          <FormattedMessage id={message[publishStatus]} />
        </span>
      </>
    );
  }

  if (isSubmitting) {
    return (
      <>
        <LoadingSpinner height={12} width={12} type="negative" />
        <p className="s-left--med t-small d--desktop--only">
          <FormattedMessage id="buttons.saving" />
        </p>
      </>
    );
  }

  return (
    <>
      <img
        src={saveIcon}
        alt="SaveIcon"
        className="btn__icon btn__icon__left"
      />
      <span>
        <FormattedMessage id="buttons.new-version" />
      </span>
    </>
  );
};
