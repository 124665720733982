import { getFileBaseName } from './getFileBaseName';

export type FilterType = 'unique' | 'duplicate';

/**
 * This function filters out files that are already in the proposalFiles array(files that are already uploaded).
 */

export function getFilteredFiles(
  files: File[],
  proposalFiles: ProposalFile[],
  type: FilterType,
): File[] {
  const filterFunction =
    type === 'unique'
      ? (file: File) =>
          !proposalFiles.some(
            (proposalFile) =>
              getFileBaseName(proposalFile.name) ===
                getFileBaseName(file.name) && proposalFile.size === file.size,
          )
      : (file: File) =>
          proposalFiles.some(
            (proposalFile) =>
              getFileBaseName(proposalFile.name) ===
                getFileBaseName(file.name) && proposalFile.size === file.size,
          );

  return files.filter(filterFunction);
}
