import { authorizationSelector } from 'modules/authorization';
import { useSelector } from 'react-redux';

export function useProposalPermissions(organizationId?: string) {
  const { roles } = useSelector(authorizationSelector.getState);
  const hasPermissions = Boolean(
    roles?.find((item) => item.organizationId === organizationId),
  );

  return {
    hasPermissions,
  };
}
