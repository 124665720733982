import { customPresetsAtom } from 'modules/settings';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

export function useStyledProposal(proposal: Proposal) {
  const customPresets = useRecoilValue(customPresetsAtom);

  const styledProposal = useMemo(() => {
    if (proposal.currentPreset === 'default') {
      const defaultPreset = customPresets.find(
        (preset) => preset.title.toLowerCase() === 'default',
      );

      return {
        ...proposal,
        style: {
          color: defaultPreset?.color || customPresets[0].color,
          typography: defaultPreset?.typography || customPresets[0].typography,
        },
      };
    }

    if (proposal.currentPreset === 'dark') {
      const darkPreset = customPresets.find(
        (preset) => preset.title.toLowerCase() === 'dark',
      );

      return {
        ...proposal,
        style: {
          color: darkPreset?.color || customPresets[1].color,
          typography: darkPreset?.typography || customPresets[1].typography,
        },
      };
    }

    return proposal;
  }, [customPresets, proposal]);

  return {
    styledProposal,
  };
}
