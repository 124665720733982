import { adminSelector, useProposalStats } from 'modules/admin';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { StatsDatePicker } from './StatsDatePicker';
import { StatsItem } from './StatsItem';

export interface ProposalStatsFormValues {
  statsDateTime: number;
}

export const ProposalsStats = () => {
  const form = useForm<ProposalStatsFormValues>({
    defaultValues: {
      statsDateTime: Date.now(),
    },
  });

  const { proposalStats: stats } = useSelector(adminSelector.getState);
  const { fetchStatsForDate } = useProposalStats();

  useEffect(() => {
    fetchStatsForDate(form.watch('statsDateTime'));
  }, [form.watch('statsDateTime')]);

  return (
    <FormProvider {...form}>
      <form className="stats-input s-bottom--lrg">
        <StatsDatePicker />
      </form>
      <ul className="stats">
        <StatsItem amount={stats?.draft} label="drafts" />
        <StatsItem amount={stats?.published} label="published" />
        <StatsItem amount={stats?.archived} label="archived" />
        <StatsItem amount={stats?.template} label="templates" />
        <StatsItem amount={stats?.pending} label="pending" />
        <StatsItem amount={stats?.accepted} label="accepted" />
        <StatsItem amount={stats?.rejected} label="rejected" />
      </ul>
    </FormProvider>
  );
};
