import collapseIcon from 'assets/icons/icon-collapse-16.svg';
import downloadFileIcon from 'assets/icons/icon-download-file-12.svg';
import expandIcon from 'assets/icons/icon-expand-16.svg';
import React from 'react';
import { Button } from 'shared/components';
import { downloadLink } from 'shared/utils';

interface Props {
  file: ProposalFile;
  index: number;
  isExpanded: boolean;
  onSetIsExpanded: (isExpanded: boolean) => void;
}

export const PublicMediaSegmentListItemControls: React.FC<Props> = ({
  file,
  index,
  isExpanded,
  onSetIsExpanded,
}) => {
  return (
    <div className="media-input__uploaded__icons__container u-color--transparent">
      <Button
        type="button"
        className="media-input__uploadeditem__control--btn media-input__previewitem__download--btn"
        onClick={() => downloadLink(file.url, file.name)}
        btnSelector={`media-image-download-${index}`}
      >
        <img src={downloadFileIcon} alt="Download file icon" />
      </Button>
      {!isExpanded ? (
        <Button
          type="button"
          className="media-input__uploadeditem__control--btn media-input__previewitem__btn"
          onClick={() => onSetIsExpanded(!isExpanded)}
          btnSelector={`media-image-expand-${index}`}
        >
          <img src={expandIcon} alt="ExpandIcon" />
        </Button>
      ) : (
        <Button
          type="button"
          className="media-input__uploadeditem__control--btn media-input__previewitem__btn"
          onClick={() => onSetIsExpanded(!isExpanded)}
          btnSelector={`media-image-collapse-${index}`}
        >
          <img src={collapseIcon} alt="CollapseIcon" />
        </Button>
      )}
    </div>
  );
};
