import failedIcon from 'assets/icons/icon-checkmark-fail-18.svg';
import successIcon from 'assets/icons/icon-checkmark-success-18.svg';
import { format } from 'date-fns';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  status: Proposal['acceptanceStatus'];
  acceptanceDateTime: Proposal['acceptanceDateTime'];
}

export const ProposalAcceptanceStatus: React.FC<Props> = ({
  status,
  acceptanceDateTime,
}) => {
  if (status === 'accepted') {
    return (
      <div
        className="proposal-acceptance-controller proposal-acceptance-controller--status"
        data-cy="proposal-acceptance-status-accepted"
      >
        <div className="f f--one f--align-center proposal-acceptance-controller__status">
          <img
            src={successIcon}
            alt="IconSuccess"
            style={{ width: 18, height: 18 }}
            className="proposal-acceptance-controller__status__icon"
          />
          {acceptanceDateTime ? (
            <p
              className="text--xs t-bold t-text-1"
              data-cy="proposal-acceptance-status-accepted-date"
            >
              <FormattedMessage
                id="proposal-acceptance-controller.accepted.heading.date"
                values={{
                  date: format(acceptanceDateTime, 'MMMM dd, yyyy, HH:mm'),
                }}
              />
            </p>
          ) : (
            <p
              className="text--xs t-bold t-text-1"
              data-cy="proposal-acceptance-status-heading-accepted"
            >
              <FormattedMessage id="proposal-acceptance-controller.accepted.heading" />
            </p>
          )}
        </div>
      </div>
    );
  }

  if (status === 'rejected') {
    return (
      <div
        className="proposal-acceptance-controller proposal-acceptance-controller--status"
        data-cy="proposal-acceptance-status-rejected"
      >
        <div className="f f--one f--align-center proposal-acceptance-controller__status">
          <img
            src={failedIcon}
            alt="IconFailed"
            style={{ width: 18, height: 18 }}
            className="proposal-acceptance-controller__status__icon"
          />
          {acceptanceDateTime ? (
            <p
              className="text--xs t-bold t-text-1"
              data-cy="proposal-acceptance-status-accepted-date"
            >
              <FormattedMessage
                id="proposal-acceptance-controller.declined.heading.date"
                values={{
                  date: format(acceptanceDateTime, 'MMMM dd, yyyy, HH:mm'),
                }}
              />
            </p>
          ) : (
            <p
              className="text--xs t-bold t-text-1"
              data-cy="proposal-acceptance-status-heading-accepted"
            >
              <FormattedMessage id="proposal-acceptance-controller.declined.heading" />
            </p>
          )}
        </div>
      </div>
    );
  }

  return null;
};
