import { useParams } from '@reach/router';
import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreDocument } from 'modules/firebase';
import { useEffect, useMemo, useState } from 'react';
import { hasAllProperties } from 'shared/utils';
import { toast } from 'sonner';
import { Proposal } from '../Proposal';

export function usePublicProposalListener(orgId?: string) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [proposal, setProposal] = useState<
    PublishStatusAware<Proposal> | undefined
  >(undefined);
  const organizationId = useOrganizationIdSelector();
  const memoizedOrgId = useMemo(
    () => orgId || organizationId,
    [orgId, organizationId],
  );

  const { listen } = useFirestoreDocument<PublishStatusAware<Proposal>>(
    `${memoizedOrgId}/proposals/entities/${id}`,
  );

  /** Fetch single proposal document from firestore */
  useEffect(() => {
    if (!memoizedOrgId || !id) return;
    setIsLoading(true);

    const listener = listen(
      (doc) => {
        if (!doc) return;

        /**Old proposal without style */
        if (!hasAllProperties(doc, ['style', 'currentPreset'])) {
          const proposal: PublishStatusAware<Proposal> = {
            ...new Proposal(doc),
            nextPublishStatus: doc.publishStatus,
            saved: false,
            customTags: doc.customTags || [],
          };
          setProposal(proposal);
          return;
        }
        setProposal({ ...doc, saved: false, customTags: doc.customTags || [] });
      },
      (error) => {
        if (error) {
          toast.error('Something went wrong. Please, try again later.');
        }
      },
      () => setIsLoading(false),
    );

    return listener;
  }, [memoizedOrgId, id]);

  return { isLoading, proposal };
}
