import { ServiceModal, serviceSelector } from 'modules/services';
import React, { memo, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { FieldWrapper, SelectList, getSelectStyles } from 'shared/components';
import { useMobile, useModal } from 'shared/hooks';

interface SelectedOption {
  value: string;
  label: string;
  id?: string;
}

interface Props {
  index: number;
  control: UseFormReturn<PublishStatusAware<Proposal>>['control'];
  formState: UseFormReturn<PublishStatusAware<Proposal>>['formState'];
  isFieldRequired?: boolean;
  value?: Service | null;
}

export const ServiceSelect: React.FC<Props> = memo(
  ({ control, formState: { errors }, index, value, isFieldRequired }) => {
    const isMobile = useMobile();
    const { formatMessage } = useIntl();
    const { openModal, closeModal, isModalOpen } = useModal();

    const [serviceNameInitialValue, setServiceNameInitialValue] = useState('');

    const { documents } = useSelector(serviceSelector.getState);

    const options = useMemo(
      () =>
        documents?.map((doc) => ({
          value: doc.name,
          label: doc.name,
          id: doc.id,
        })) || [],
      [documents],
    );

    function getValue(service: Service | null): SelectedOption | null {
      if (!service) return null;

      return {
        value: service.name,
        label: service.name,
        id: service.id,
      };
    }

    function handleButtonClick() {
      const selectedElement = document.querySelector(
        `select__service--input${index}`,
      );
      const selectInput = selectedElement?.querySelector('input');

      if (selectInput) selectInput.blur();
      openModal();
    }

    function handleInputChange(inputValue: string) {
      if (!inputValue) return;
      setServiceNameInitialValue(inputValue);
    }

    return (
      <div data-cy={`service-dropdown${index}`}>
        <FieldWrapper
          errors={errors}
          name={`segments.${index}.service`}
          labelId="dropdowns.service.label"
          isRequired={isFieldRequired}
        >
          <Controller
            name={`segments.${index}.service`}
            control={control}
            rules={{
              required:
                isFieldRequired &&
                formatMessage({ id: 'dropdowns.service.required' }),
              validate: (value) => {
                if (!isFieldRequired) return true;
                const serviceMatch = documents?.find(
                  (s: Service) =>
                    s?.id === value?.id || s?.name === value?.name,
                );
                if (serviceMatch) return true;
                return `Service with name: ${value?.name} doesn't exist.`;
              },
            }}
            defaultValue={value || null}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                options={options}
                onChange={(selectedOption: SelectedOption | null) =>
                  field.onChange({
                    name: selectedOption?.label || null,
                    id: selectedOption?.id || null,
                  })
                }
                value={getValue(field.value)}
                onInputChange={handleInputChange}
                styles={getSelectStyles(!!fieldState.error)}
                id={`service__styled__select${index}`}
                className={`select__service--input${index}`}
                placeholder={formatMessage({
                  id: 'dropdowns.service.placeholder',
                })}
                isSearchable={!isMobile}
                components={{
                  MenuList: ({ children, ...rest }) => (
                    <components.MenuList {...rest} className="select__adder">
                      <SelectList
                        onButtonClick={handleButtonClick}
                        buttonType={'service'}
                        btnSelector="add-service"
                      >
                        {children}
                      </SelectList>
                    </components.MenuList>
                  ),
                }}
              />
            )}
          />
        </FieldWrapper>
        {isModalOpen && (
          <ServiceModal
            inputValue={serviceNameInitialValue}
            valueToUpdate={`segments.${index}.service`}
            index={index}
            onClose={closeModal}
          />
        )}
      </div>
    );
  },
  (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    const hasErrors = !!Object.keys(nextProps.formState.errors);
    return (
      prevProps.formState.isDirty === nextProps.formState.isDirty && !hasErrors
    );
  },
);
