import React from 'react';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

interface Props {
  rootUrl?: string;
  headerVisible?: boolean;
  isManageSection?: boolean;
  navButtons?: NavigationButtons[];
}

export const Header: React.FC<Props> = ({
  rootUrl,
  headerVisible,
  isManageSection,
  navButtons,
}) => {
  return (
    <>
      <DesktopHeader
        rootUrl={rootUrl}
        headerVisible={headerVisible}
        navButtons={navButtons}
        isManageSection={isManageSection}
      />
      <MobileHeader />
    </>
  );
};
