import { useScreenWidth } from './useScreenWidth';

/**
 * @returns {boolean} If the screen is mobile or not
 */

export function useMobile(): boolean {
  const width = useScreenWidth();
  const isMobile = width < 768;

  return isMobile;
}
