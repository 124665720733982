import {
  isAcceptanceStatusArray,
  isDateArray,
  isPublishStatusArray,
  isStringArray,
} from 'shared/utils';
import { applyDateRangeFilter } from './applyDateRangeFilter';

function createFilterMap(filtersOption: Filter[]): FilterMap {
  return filtersOption.reduce((acc, { type, value }) => {
    /**If filter doesn't exist set empty array for that key */
    if (!acc[type]) acc[type] = [];
    /**If value is string push it to array */
    if (typeof value === 'string') acc[type].push(value);
    /**If value is date array push it to array. This is added because we need date range for date picker input */
    if (isDateArray(value)) acc[type] = value.flat();
    return acc;
  }, {});
}

export function applyFilters(filtersOption: Filter[], proposals: Proposal[]) {
  const filterMap = createFilterMap(filtersOption);

  return proposals.filter((proposal) => {
    return Object.entries(filterMap).every(([type, values]) => {
      switch (type) {
        case 'client':
          if (
            isStringArray(values) &&
            !isPublishStatusArray(values) &&
            !isAcceptanceStatusArray(values)
          ) {
            return values?.includes(
              proposal?.client?.id || proposal?.client?.name || '',
            );
          }
          break;
        case 'tags':
          if (isStringArray(values)) {
            return proposal.customTags?.some((tag) =>
              (values as string[])?.includes(tag),
            );
          }
          break;
        case 'proposal-status':
          if (isPublishStatusArray(values)) {
            return values?.includes(proposal?.publishStatus);
          }
          break;
        case 'acceptance':
          if (
            isPublishStatusArray(filterMap['proposal-status']) &&
            filterMap['proposal-status']?.every(
              (status) => status !== 'published',
            )
          ) {
            /**If user has selected all proposal statuses except 'published' we should skip filters for acceptance status. For example: If we allow user to select
             * Proposal status: archived, template, draft
             * Acceptance status: accepted, rejected
             * Result will always be 0.  */
            return true;
          }
          if (isAcceptanceStatusArray(values)) {
            return values?.includes(proposal.acceptanceStatus);
          }
          break;
        case 'validity':
          if (
            isStringArray(values) &&
            !isPublishStatusArray(values) &&
            !isAcceptanceStatusArray(values)
          ) {
            if (
              values?.includes('active') &&
              proposal?.validUntilDateTime > Date.now()
            ) {
              return true;
            }
            if (
              values?.includes('expired') &&
              proposal?.validUntilDateTime < Date.now()
            ) {
              return true;
            }
            return false;
          }
          break;
        case 'date':
          if (isDateArray(values)) {
            return values?.length === 2
              ? applyDateRangeFilter([proposal], values[0], values[1])?.length >
                  0
              : applyDateRangeFilter([proposal], values[0])?.length > 0;
          }
          break;
        default:
          return;
      }
      return;
    });
  });
}
