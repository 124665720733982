import { showDebugStatuses } from 'modules/firebase';
import { Descendant } from 'slate';

function recursiveTextCheck(node: Descendant): string {
  if (Array.isArray(node)) {
    let nodeText = '';
    for (const child of node) {
      if (child.children) {
        nodeText += recursiveTextCheck(child.children);
      }

      if (child.text) {
        nodeText += child.text;
      }
    }

    return nodeText;
  }

  // will only reach here if node is empty or not an array
  return '';
}

export const getServiceDescriptionText = (editorText: string): string => {
  try {
    const parsedEditorText = JSON.parse(editorText);
    return recursiveTextCheck(parsedEditorText);
  } catch (error) {
    if (showDebugStatuses) console.error('error', error);
    return '';
  }
};
