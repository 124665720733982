import { settingsAction } from './actions';

interface ReducerState {
  readonly isLoading: boolean;
  readonly config?: Settings | null;
  readonly error?: FirestoreError;
}

const INITIAL_STATE: ReducerState = {
  isLoading: false,
  config: undefined,
  error: undefined,
};

export const settingsReducer = (
  state: ReducerState = INITIAL_STATE,
  action: ActionUnion<typeof settingsAction> | Action<'authentication/LOGOUT'>,
): ReducerState => {
  switch (action.type) {
    case 'settings/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'settings/ON_SNAPSHOT_UPDATE':
      return {
        ...state,
        isLoading: false,
        config: action.payload.document,
      };
    case 'settings/ON_SNAPSHOT_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case 'authentication/LOGOUT':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
