import { ProposalUpdatePaths, useLazyProposalUpdate } from 'modules/proposals';
import { Service, serviceSelector, useServices } from 'modules/services';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import {
  useCloseOnEscape,
  useMobile,
  useModalGuard,
  useSubmitOnEnter,
} from 'shared/hooks';
import { ServiceNameInput } from './fragments';

interface Props extends ModalProps {
  inputValue?: string;
  valueToUpdate?: ProposalUpdatePaths;
  index?: number;
  item?: Service;
}

export const ServiceModal: React.FC<Props> = ({
  inputValue,
  valueToUpdate,
  index,
  item,
  onClose,
  ...rest
}) => {
  const isMobile = useMobile();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();

  const [nameInitialValue, setNameInitialValue] = useState('');

  const form = useForm<Service>({
    mode: 'onSubmit',
    defaultValues: new Service(item),
  });
  const context = useFormContext<Proposal>();
  const { setFormItem } = useLazyProposalUpdate<Service>({
    valueToUpdate,
    selector: serviceSelector,
    valuesToCompare: ['name'],
    callback: onClose,
  });

  const { handleClose } = useModalGuard();
  const { createEntityAsync, updateEntityAsync } = useServices();

  async function handleUpdate(service: Service) {
    const { id, ...rest } = service;
    if (id) await updateEntityAsync(id, rest);
  }

  async function handleCreate(service: Service) {
    const id = await createEntityAsync(service);
    if (!id) return;

    if (context && index !== undefined) {
      context.setValue(
        `segments.${index}.service`,
        { ...service, id },
        {
          shouldDirty: true,
        },
      );
    }
  }

  function handleCloseModal() {
    handleClose(
      form.getValues().name,
      nameInitialValue,
      onClose,
      form.formState.isDirty,
    );
  }

  function handleName(name: string) {
    setNameInitialValue(name);
  }

  async function saveData(data: Service) {
    const modifierFunction = item ? handleUpdate : handleCreate;
    await modifierFunction(new Service(data));
    if (context) {
      setFormItem(data);
    } else {
      onClose();
    }
  }

  useEffect(() => {
    if (inputValue) form.setValue('name', inputValue);
  }, []);

  return (
    <Modal {...rest} isDashboardModal close={handleCloseModal}>
      <FormProvider {...form}>
        <ModalHeader>
          <p className="text--xl__responsive">
            <FormattedMessage
              id={item ? 'buttons.edit-service' : 'buttons.add-new-service'}
            />
          </p>
        </ModalHeader>
        <ModalContent>
          <ServiceNameInput
            sendName={handleName}
            autoFocus={!isMobile}
            inputSelector="service-name-input"
          />
        </ModalContent>
        <ModalFooter>
          <div className="btn-group btn-group--simple">
            <Button
              type="button"
              ref={closeButtonRef}
              onClick={onClose}
              style="outline"
              size="lrg"
              btnSelector="modal-cancel-btn"
            >
              <FormattedMessage id="buttons.cancel" />
            </Button>
            <Button
              type="submit"
              ref={submitButtonRef}
              onClick={form.handleSubmit(saveData)}
              style="primary"
              size="lrg"
              btnSelector="save-modal-btn"
            >
              <FormattedMessage id="buttons.save" />
            </Button>
          </div>
        </ModalFooter>
      </FormProvider>
    </Modal>
  );
};
