import React from 'react';
import { useWatch } from 'react-hook-form';

export const ProposalName: React.FC = () => {
  const name = useWatch({ name: 'name' });

  return (
    <>
      {name && (
        <div className="actionbar__proposal__name">
          <span className="s-right--sml s-left--sml text--sm t-text-3">
            {' '}
            /{' '}
          </span>
          <span className="actionbar__buttons__name t-ellipsis text--sm t-text-3">
            {name}
          </span>
        </div>
      )}
    </>
  );
};
