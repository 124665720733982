import { useLocation } from '@reach/router';
import {
  ProposalListError,
  ProposalListItem,
  useProposalsByStatusSelector,
  useProposalsErrorSelector,
  useProposalsLoadingSelector,
} from 'modules/proposals';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List, Sort } from 'shared/components';
import { applySort } from 'shared/utils';

interface Props {
  publishStatus: PublishStatus;
}

export const ProposalsSection: React.FC<Props> = ({ publishStatus }: Props) => {
  const location = useLocation();
  const error = useProposalsErrorSelector();
  const loading = useProposalsLoadingSelector();
  const proposals = useProposalsByStatusSelector(publishStatus);

  const [sortOption, setSortOption] = useState<SortOption>('newest');
  const sortedProposals = useMemo(
    () => applySort(sortOption, proposals),
    [proposals, sortOption, publishStatus],
  );

  useEffect(() => {
    setSortOption('newest');
  }, [location.pathname]);

  return (
    <>
      <header
        className="dashboard__section__header__title text--xs t-bold"
        data-cy="dashboard-overview-title"
      >
        <FormattedMessage id={`proposals.${publishStatus}.title`} />
      </header>
      <div className="dashboard__section__mobile__actions">
        <header className="text--xs t-bold" data-cy={`${publishStatus}-title`}>
          <FormattedMessage
            id={`proposals.${publishStatus}.mobile-title`}
            values={{
              length: sortedProposals?.length,
            }}
          />
        </header>
        <div className="f f--align-center">
          <Sort sortOption={sortOption} onSetSortOption={setSortOption} />
        </div>
      </div>

      {Boolean(sortedProposals?.length) && (
        <div className="dashboard__section__actions">
          <span className="t-text-2 text--sm">
            <FormattedMessage
              id="proposals.overview.length"
              values={{
                length: sortedProposals?.length,
              }}
            />
          </span>
          <Sort
            sortOption={sortOption}
            onSetSortOption={setSortOption}
            btnSelector="sort-proposals"
          />
        </div>
      )}
      <List
        documents={proposals}
        results={sortedProposals}
        isLoading={loading}
        error={error}
        errorComponent={ProposalListError}
        itemComponent={ProposalListItem}
        publishStatus={publishStatus}
        className="dashboard__section__list__container"
        errorClassName="dashboard__section__error__container"
        loadingType="list"
      />
    </>
  );
};
