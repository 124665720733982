import moveIcon from 'assets/icons/icon-move-16.svg';
import deleteIcon from 'assets/icons/icon-trash-12.svg';
import { useUnits } from 'modules/units';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { Button, DeleteModal, ListItemActionDrawer } from 'shared/components';
import { useModal } from 'shared/hooks';
import { UnitModal } from '../unit-modal';

interface Props {
  item: Unit;
  index: number;
  // query?: string;
  provided?: DraggableProvided;
}

export const UnitListItem: React.FC<Props> = ({
  item,
  provided,
  index,
  // query,
}) => {
  const { deleteEntityAsync } = useUnits();

  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal();

  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();

  const removeItem = () => {
    if (item?.id) deleteEntityAsync(item.id);
    closeDeleteModal();
  };

  return (
    <>
      <div
        className="settings__unit__item"
        {...provided?.draggableProps}
        ref={provided?.innerRef}
        data-cy={`unit-list-item-${index}`}
      >
        <div className="manage__item__info__container">
          <div
            className="btn btn__tooltip manage__reorder__btn"
            {...provided?.dragHandleProps}
            data-cy={`reorder-unit-${index}`}
          >
            <img src={moveIcon} alt="MoveIcon" />
            <span className="tooltip--text">Reorder</span>
          </div>
          <Button
            onClick={openEditModal}
            type="button"
            className="d--ib t-ellipsis settings-itemlist__item__name text--xs__responsive t-text-1 t-bold"
          >
            {item.name}
          </Button>
        </div>
        <div className="btn-group btn-group--simple settings-itemlist__item__actions">
          <Button
            onClick={openEditModal}
            btnSelector={`edit-unit-${index}`}
            size="med"
            style="outline"
          >
            <FormattedMessage id="buttons.edit" />
          </Button>
          <Button
            onClick={openDeleteModal}
            size="med"
            style="ghost"
            btnSelector={`delete-unit-${index}`}
          >
            <img src={deleteIcon} alt="DeleteIcon" />
          </Button>
        </div>
        <ListItemActionDrawer className="settings__actionbar__drawer">
          <Button
            type="button"
            className="actionbar__drawer__btn"
            onClick={openEditModal}
            btnSelector={`edit-client-${index}`}
          >
            <FormattedMessage id="buttons.edit" />
          </Button>
          <Button
            type="button"
            className="actionbar__drawer__btn t-danger btn__icon__fill"
            onClick={openDeleteModal}
            btnSelector={`delete-unit-btn-${index}`}
          >
            <FormattedMessage id="buttons.delete" />
          </Button>
        </ListItemActionDrawer>
      </div>

      {isEditModalOpen && <UnitModal item={item} onClose={closeEditModal} />}

      {isDeleteModalOpen && (
        <DeleteModal
          title={item.name}
          name="unit"
          onClose={closeDeleteModal}
          onConfirm={removeItem}
          delSelector="delete-btn"
        />
      )}
    </>
  );
};
