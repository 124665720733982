import React, { memo, useCallback, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

interface Props {
  index: number;
  control: UseFormReturn<PublishStatusAware<Proposal>>['control'];
  formState: UseFormReturn<PublishStatusAware<Proposal>>['formState'];
  value?: string;
}

export const HeadingSubtitleInput: React.FC<Props> = memo(
  ({ index, formState: { errors }, value, control }) => {
    const { formatMessage } = useIntl();
    const [textAreaHeight, setTextAreaHeight] = useState(0);

    function handleTextAreaHeight(
      textAreaElement: React.FormEvent<HTMLTextAreaElement>,
    ) {
      textAreaElement.currentTarget.style.height = '1px';
      textAreaElement.currentTarget.style.height =
        textAreaElement.currentTarget.scrollHeight + 'px';
    }

    const measuredRef = useCallback((node: HTMLTextAreaElement) => {
      if (node !== null) {
        setTextAreaHeight(node.scrollHeight);
        return node.scrollHeight;
      }
      return 1;
    }, []);

    return (
      <FieldWrapper
        name={`segments.${index}.subtitle`}
        errors={errors}
        labelId="inputs.subheading.label"
      >
        <Controller
          name={`segments.${index}.subtitle`}
          control={control}
          defaultValue={value || ''}
          render={({ field }) => (
            <textarea
              {...field}
              onInput={(textAreaElement) =>
                handleTextAreaHeight(textAreaElement)
              }
              ref={measuredRef}
              style={{ height: textAreaHeight + 'px' }}
              className="input input__line input__textarea text--lg__responsive proposal-builder-heading__subtitle"
              placeholder={formatMessage({
                id: 'inputs.subheading.placeholder',
              })}
              data-cy={`subheading-input${index}`}
              rows={1}
            />
          )}
        />
      </FieldWrapper>
    );
  },
  (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    const hasErrors = !!Object.keys(nextProps.formState.errors);
    return (
      prevProps.formState.isDirty === nextProps.formState.isDirty && !hasErrors
    );
  },
);
