export function isFilterOption(type: any): type is FilterOption {
  return [
    'client',
    'date',
    'tags',
    'proposal-status',
    'acceptance',
    'validity',
  ].includes(type);
}
