import { collection, onSnapshot } from 'firebase/firestore';
import { useOrganizationIdSelector } from 'modules/authorization';
import { db } from 'modules/firebase';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { servicesFirestoreConverter } from '../const/servicesFirestoreConverter';
import { serviceAction } from '../redux';

export function useServicesSubscription() {
  const organizationId = useOrganizationIdSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    const collectionReference = collection(
      db,
      organizationId,
      'services',
      'entities',
    ).withConverter(servicesFirestoreConverter);

    return onSnapshot(
      collectionReference,
      (snapshot) =>
        dispatch(
          serviceAction.onSnapshotUpdate(
            snapshot.docs.map((doc) => doc.data()),
          ),
        ),
      (error) => dispatch(serviceAction.onSnapshotError(error)),
    );
  }, [organizationId]);
}
