import { navigate, useLocation } from '@reach/router';
import clientIcon from 'assets/icons/icon-search-client-12.svg';
import { Link } from 'gatsby';
import {
  mobileNavDropdownVisibleSelector,
  mobileSearchVisibleSelector,
} from 'modules/dashboard';
import { useOverviewFiltersState } from 'modules/proposals-overview';

import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { GLOBAL_RECENT_SEARCHES_LS_KEY } from 'shared/const';
import { useKeyPress, useOnClickRecentSearchResults } from 'shared/hooks';

interface Props {
  client: Client;
  isActive?: boolean;
  onSetShowResults: (showResults: boolean) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const ClientSearchResultsListItem: React.FC<Props> = ({
  client,
  isActive,
  onMouseEnter,
  onMouseLeave,
  onSetShowResults,
}) => {
  const { formatMessage } = useIntl();
  const enterPress = useKeyPress('Enter');
  const { pathname, search } = useLocation();
  const [filters, setFilters] = useOverviewFiltersState();
  const { handleSetRecentSearch } = useOnClickRecentSearchResults(
    GLOBAL_RECENT_SEARCHES_LS_KEY,
  );
  const [mobileSearchVisible, setMobileSearchVisible] = useRecoilState(
    mobileSearchVisibleSelector,
  );
  const [mobileNavdropdownVisible, setMobileNavdropdownVisible] =
    useRecoilState(mobileNavDropdownVisibleSelector);

  function handleClick(clientName: string) {
    const currentPage = Number(search.replace(/\D/g, ''));
    if (currentPage > 1) {
      navigate(pathname);
    }
    if (pathname !== formatMessage({ id: 'routes.overview' })) {
      navigate(formatMessage({ id: 'routes.overview' }));
    }

    const clientAlreadyExists = filters
      .filter(({ type }) => type === 'client')
      .some(({ value }) => value === clientName);
    if (!clientAlreadyExists) {
      setFilters([]);
      setFilters((prev) => [
        ...prev,
        { type: 'client', value: client.id || clientName, label: clientName },
      ]);
    }
    handleSetRecentSearch(clientName);
    onSetShowResults(false);
    if (mobileSearchVisible) setMobileSearchVisible(false);
    if (mobileNavdropdownVisible) setMobileNavdropdownVisible(false);
  }

  /**Enter press */
  useEffect(() => {
    if (enterPress && isActive) {
      handleClick(client.name);
    }
  }, [enterPress, isActive]);

  return (
    <Link
      to={formatMessage({ id: 'routes.overview' })}
      state={{
        clientName: client.name,
      }}
      onClick={() => handleClick(client.name)}
      className={`u-width--full search-result-item search__results__client__item ${
        isActive ? 'search__results__client__item--active' : ''
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <img src={clientIcon} alt="ClientIcon" />

      <span className="t-shorten text--sm t-text-1 t-ellipsis">
        {client.name}
      </span>
    </Link>
  );
};
