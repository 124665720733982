import { Client } from 'modules/clients';
import { Proposal } from 'modules/proposals';
import { useEffect, useRef, useState } from 'react';
import { useKeyPress } from './useKeyPress';

interface SearchResultListNavigationProps {
  clients?: Client[];
  proposals?: Proposal[];
}

export function useSearchResultsListNavigation({
  clients = [],
  proposals = [],
}: SearchResultListNavigationProps) {
  const [cursor, setCursor] = useState(0);
  const [focusedList, setFocusedList] = useState('clients');
  const [hoveredItem, setHoveredItem] = useState<Client | Proposal | null>(
    null,
  );
  const [keyboardNavigationActive, setKeyboardNavigationActive] =
    useState(false);

  const upPress = useKeyPress('ArrowUp');
  const downPress = useKeyPress('ArrowDown');

  const clientsRef = useRef<HTMLUListElement | null>(null);
  const proposalsRef = useRef<HTMLUListElement | null>(null);
  const keyboardNavigationTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  function handleMouseEnter(item: Client | Proposal) {
    if (!keyboardNavigationActive) {
      setHoveredItem(item);
    }
  }

  function handleMouseLeave() {
    setHoveredItem(null);
  }

  useEffect(() => {
    if (downPress || upPress) {
      setKeyboardNavigationActive(true);
      /** Clear previous timeout */
      if (keyboardNavigationTimeoutRef.current) {
        clearTimeout(keyboardNavigationTimeoutRef.current);
      }
      /** Set new timeout */
      keyboardNavigationTimeoutRef.current = setTimeout(() => {
        setKeyboardNavigationActive(false);
      }, 500);
    }
  }, [downPress, upPress]);

  useEffect(() => {
    // Clear timeout on unmount */
    return () => {
      if (keyboardNavigationTimeoutRef.current) {
        clearTimeout(keyboardNavigationTimeoutRef.current);
      }
    };
  }, []);

  // Hover follows cursor
  useEffect(() => {
    if (hoveredItem && hoveredItem instanceof Client) {
      if (focusedList === 'proposals') setFocusedList('clients');

      setCursor(clients.indexOf(hoveredItem));
    }

    if (hoveredItem && hoveredItem instanceof Proposal) {
      if (focusedList === 'clients') setFocusedList('proposals');

      setCursor(proposals.indexOf(hoveredItem));
    }
  }, [hoveredItem, clients, proposals]);

  // Move cursor down
  useEffect(() => {
    if (downPress) {
      setCursor((prevCursor) => {
        let newCursor = prevCursor + 1;
        /** User reach the end of first list */
        if (focusedList === 'clients' && newCursor >= clients?.length) {
          setFocusedList('proposals');
          newCursor = 0; // Start from the beginning of second list
        }
        /** User reach the end of second list */
        if (focusedList === 'proposals' && newCursor >= proposals?.length) {
          newCursor = proposals?.length - 1; // Keep within proposals list bounds
        }
        return newCursor;
      });
    }
  }, [downPress, clients?.length, proposals?.length]);

  // Move cursor up
  useEffect(() => {
    if (upPress) {
      setCursor((prevCursor) => {
        let newCursor = prevCursor - 1;
        /** User reach the beginning of second list */
        if (focusedList === 'proposals' && newCursor < 0) {
          setFocusedList('clients');
          newCursor = clients?.length; // Start from the end of first list
        }
        /** User reach the beginning of first list */
        if (focusedList === 'clients' && newCursor < 0) {
          newCursor = 0; // Keep within clients list bounds
        }
        return newCursor;
      });
    }
  }, [upPress, focusedList, clients?.length]);

  // Scroll to the active element
  useEffect(() => {
    const currentRef =
      focusedList === 'clients' ? clientsRef.current : proposalsRef.current;
    if (currentRef) {
      const listItems = currentRef.querySelectorAll('.search-result-item');

      if (listItems?.length && cursor < listItems?.length) {
        const activeElement = listItems[cursor];

        if (!(activeElement instanceof HTMLElement)) return;

        activeElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }, [cursor, focusedList]);

  return {
    clientsRef,
    proposalsRef,
    cursor,
    keyboardNavigationActive,
    focusedList,
    hoveredItem,
    setFocusedList,
    handleMouseEnter,
    setCursor,
    handleMouseLeave,
  };
}
