import React, { useState } from 'react';
import { PublicMediaSegmentListItemControls } from './PublicMediaSegmentListItemControls';

interface Props {
  file: ProposalFile;
  index: number;
}

export const PublicMediaSegmentListItemImage: React.FC<Props> = ({
  file,
  index,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div
      className={`media-input__image ${
        isExpanded
          ? 'media-input__image--expanded'
          : 'media-input__image--collapsed'
      }`}
    >
      <PublicMediaSegmentListItemControls
        file={file}
        index={index}
        isExpanded={isExpanded}
        onSetIsExpanded={setIsExpanded}
      />
      <img
        src={file.url}
        alt={file.name}
        data-cy={`media-file-${index}`}
        className="media-input__list__image"
      />
    </div>
  );
};
