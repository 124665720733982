export function hasClientChanged(
  client1?: Client | null,
  client2?: Client | null,
): boolean {
  const props = ['name', 'email', 'vatId', 'phone'];

  for (const prop of props) {
    if (client1?.[prop] !== client2?.[prop]) {
      return true;
    }
  }

  const addressProps = [
    'city',
    'postalCode',
    'addressOne',
    'addressTwo',
    'country',
    'state',
  ];

  for (const prop of addressProps) {
    const client1Value = !client1?.address?.[prop]
      ? null
      : client1?.address?.[prop];
    const client2Value = !client2?.address?.[prop]
      ? null
      : client2?.address?.[prop];

    if (client1Value !== client2Value) {
      return true;
    }
  }

  return false;
}
