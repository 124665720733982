import { SlateApiReturn } from 'modules/editor';
import React from 'react';
import {
  bold,
  italic,
  list2,
  listNumbered,
  quotesLeft,
  strikethrough,
  underline,
} from 'react-icons-kit/icomoon';
import { RichTextToolbarButton } from './RichTextToolbarButton';
import { RichTextToolbarLinkButton } from './fragments';

interface Props {
  editorApi: SlateApiReturn;
}

export const RichTextEditorToolbar: React.FC<Props> = ({ editorApi }) => {
  return (
    <ul className="slate__toolbar">
      <li>
        <RichTextToolbarButton variant="bold" icon={bold} />
      </li>
      <li>
        <RichTextToolbarButton variant="italic" icon={italic} />
      </li>
      <li>
        <RichTextToolbarButton variant="underline" icon={underline} />
      </li>
      <li>
        <RichTextToolbarButton variant="strikethrough" icon={strikethrough} />
      </li>
      <li>
        <RichTextToolbarLinkButton editorApi={editorApi} />
      </li>
      <li>
        <RichTextToolbarButton variant="blockquote" icon={quotesLeft} />
      </li>
      <li>
        <RichTextToolbarButton variant="ul" icon={list2} />
      </li>
      <li>
        <RichTextToolbarButton variant="ol" icon={listNumbered} />
      </li>
    </ul>
  );
};
