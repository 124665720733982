import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  className: string;
  to: string;
  onMouseDown?: VoidFunction;
  btnSelector?: string;
}

export const CreateNewProposalButton: React.FC<Props> = ({
  className,
  to: linkPath,
  onMouseDown,
  children,
  btnSelector,
}) => {
  const { pathname, search } = useLocation();

  return (
    <li className="nav__links__item">
      <Link
        state={{ lastPageUrl: `${pathname}${search}` }}
        className={className}
        to={linkPath}
        onMouseDown={onMouseDown}
        data-cy={btnSelector}
      >
        {children}
      </Link>
    </li>
  );
};
