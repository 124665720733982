import React, { useState } from 'react';
import { MediaListItemControls } from './MediaListItemControls';

interface Props {
  file: ProposalFile;
  index: number;
  proposalFilesLength: number;
  onRemoveFile: (file: ProposalFile) => void;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

export const MediaListItemVideo: React.FC<Props> = ({
  file,
  index,
  proposalFilesLength,
  onRemoveFile,
  moveItem,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div
      className={`media-input__image ${
        isExpanded
          ? 'media-input__image--expanded'
          : 'media-input__image--collapsed'
      }`}
    >
      <MediaListItemControls
        file={file}
        index={index}
        proposalFilesLength={proposalFilesLength}
        isExpanded={isExpanded}
        onRemoveFile={onRemoveFile}
        onSetIsExpanded={setIsExpanded}
        moveItem={moveItem}
      />
      <video className="media-input__list__image" controls draggable="false">
        <source src={file.url} />
        Your browser does not support the video element.
      </video>
    </div>
  );
};
