import arrowRightIcon from 'assets/icons/icon-arrow-right-12.svg';
import checkMarkIcon from 'assets/icons/icon-checkmark-16-primary.svg';
import documentIcon from 'assets/icons/icon-document-16.svg';
import lockedIcon from 'assets/icons/icon-locked-16.svg';
import mediaIcon from 'assets/icons/icon-media-16.svg';
import pdfExportIcon from 'assets/icons/icon-pdf-export-16.svg';
import pencilIcon from 'assets/icons/icon-pencil-16.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import {
  Button,
  LoadingSpinner,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';
import { useStripeSubscription } from '../hooks/subscription';
import { subscriptionAtoms } from '../state';

interface Props {
  onClose: () => void;
}

export const FreeTrialLegacyNoticeModal: React.FC<Props> = ({ onClose }) => {
  const { isLoading, createStripeSubscription } = useStripeSubscription();
  const customerId = useRecoilValue(subscriptionAtoms.customerId);
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();

  return (
    <Modal
      isDashboardModal
      containerClassName="free-trial-notice__modal"
      close={onClose}
    >
      <ModalHeader>
        <p className="text--xl__responsive t-bold t-text-1">
          We have a special offer for you!{' '}
        </p>
      </ModalHeader>
      <ModalContent
        className="subscription-product__group__content"
        contentSelector="propoze-plus-modal-content"
      >
        <p className="t-text-1 text--xxs__responsive s-bottom--med u-maxwidth--400__responsive">
          As one of our first adopters, you're invited to experience Plus with a
          special 7-day free trial. Get 50% off on your subscription when you
          upgrade!{' '}
        </p>
        <ul
          className="list--bullets s-bottom--lrg list--2x2__responsive r-g-16"
          data-cy="free-trial-notice-modal-features"
        >
          <li className="f f--align-center g-16">
            <img src={documentIcon} alt="Document Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">
                Unlimited Proposals
              </p>
              <span className="text--xxs__responsive">
                Create and send as many proposals as you want—no limits!
              </span>
            </div>
          </li>
          <li className="f f--align-center g-16">
            <img src={pencilIcon} alt="Pencil Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">Custom Branding</p>
              <span className="text--xxs__responsive">
                Personalize with your logos, fonts, and colors.{' '}
              </span>
            </div>
          </li>
          <li className="f f--align-center g-16">
            <img src={pdfExportIcon} alt="PDF Export Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">PDF Export</p>
              <span className="text--xxs__responsive">
                Download proposals as PDFs for easy offline sharing.{' '}
              </span>
            </div>
          </li>
          <li className="f f--align-center g-16">
            <img src={lockedIcon} alt="Locked Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">Access Control</p>
              <span className="text--xxs__responsive">
                Manage who can view and interact with your proposals.{' '}
              </span>
            </div>
          </li>
          <li className="f f--align-center g-16">
            <img src={mediaIcon} alt="Media Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">
                Media & Attachments
              </p>
              <span className="text--xxs__responsive">
                Enrich your proposals with images and videos.{' '}
              </span>
            </div>
          </li>{' '}
          <li className="f f--align-center g-16">
            <img src={checkMarkIcon} alt="Checkmark Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">
                Streamlined Approvals{' '}
              </p>
              <span className="text--xxs__responsive">
                Simplify internal sign-offs and approvals.{' '}
              </span>
            </div>
          </li>
        </ul>
        <p className="t-text-1 text--xxs__responsive s-bottom--med u-maxwidth--400__responsive">
          Your free Propoze account is yours forever. After the trial, choose to
          upgrade to Plus with a 50% discount or continue with your current free
          plan.
        </p>
      </ModalContent>
      <ModalFooter>
        <div className="btn-group btn-group--simple f f--justify-between u-width--full s-top--sml">
          <Button
            type="button"
            ref={closeButtonRef}
            onClick={onClose}
            size="lrg"
            style="secondary"
          >
            Maybe Later
          </Button>
          {isLoading ? (
            <Button
              type="button"
              disabled={isLoading}
              size="lrg"
              style="primary"
            >
              <LoadingSpinner height={16} width={16} type="negative" />
              <p className="s-left--med">
                <FormattedMessage id="buttons.loading" />
              </p>
            </Button>
          ) : (
            <Button
              type="button"
              ref={submitButtonRef}
              onClick={() =>
                createStripeSubscription({ isFreeTrial: true, customerId })
              }
              size="lrg"
              style="primary"
            >
              <span>Start my free trial</span>
              <img
                src={arrowRightIcon}
                alt="Arrow right icon"
                className="btn__icon btn__icon__right"
              />
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};
