import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button, DeleteIcon } from 'shared/components';
interface Props {
  className: string;
  btnSelector?: string;
}

export const DeleteButton: React.FC<Props> = ({ className, ...props }) => {
  const { setValue } = useFormContext<PublishStatusAware<Proposal>>();

  function handleDelete() {
    setValue('nextPublishStatus', 'deleted');
  }

  return (
    <Button
      className={`${className} btn__icon__fill__delete actionbar__drawer__btn__delete`}
      onClick={handleDelete}
      {...props}
      type="submit"
    >
      <DeleteIcon />
      <FormattedMessage id="buttons.delete" />
    </Button>
  );
};
