import { useMemo } from 'react';
import { isServiceSegment } from '../type-guards';

export function useProposalTotalPrices(
  vatPercentage: number,
  segments: ProposalSegment[],
  discountType: ProposalDiscountType,
  totalDiscountAmount: number,
  proposal?: PublishStatusAware<Proposal>,
) {
  const filteredServices = useMemo(
    () => segments?.filter(isServiceSegment),
    [segments],
  );
  const proposalServiceSegments = useMemo(
    () => proposal?.segments?.filter(isServiceSegment),
    [proposal?.segments],
  );
  const serviceSegments = useMemo(
    () =>
      filteredServices?.length > 0
        ? filteredServices
        : proposalServiceSegments || [],
    [filteredServices, proposalServiceSegments],
  );

  //Full price of all services without any discounts
  const subtotalAmount = useMemo(
    () =>
      serviceSegments.reduceRight(
        (total, current) => (total += current.pricePerUnit * current.quantity),
        0,
      ),
    [serviceSegments],
  );
  //Total discount amount for all services
  const servicesDiscountAmount = useMemo(
    () =>
      serviceSegments.reduceRight(
        (total, current) =>
          (total +=
            current.pricePerUnit * current.quantity * (current.discount / 100)),
        0,
      ),
    [serviceSegments],
  );

  // Full price of all services after applying discounts for each service
  // NOTE: This is not the final price, as the subtotal discount is not applied yet
  const subtotalAfterDiscount = useMemo(
    () => subtotalAmount - servicesDiscountAmount,
    [subtotalAmount, servicesDiscountAmount],
  );

  // Total discount amount for all services and the subtotal discount
  const allDiscountsCombined = useMemo(() => {
    if (discountType === 'percentage') {
      return (
        servicesDiscountAmount +
        (subtotalAfterDiscount * totalDiscountAmount) / 100
      );
    }
    return servicesDiscountAmount + totalDiscountAmount;
  }, [
    servicesDiscountAmount,
    totalDiscountAmount,
    discountType,
    subtotalAfterDiscount,
  ]);

  // Full price of all services after applying all discounts
  const subtotalIncludingTotalDiscount = useMemo(() => {
    if (discountType === 'percentage') {
      return (
        subtotalAfterDiscount -
        (subtotalAfterDiscount * totalDiscountAmount) / 100
      );
    }
    return subtotalAfterDiscount - totalDiscountAmount;
  }, [subtotalAfterDiscount, totalDiscountAmount, discountType]);

  // VAT amount for all services
  const vatAmount = useMemo(
    () => (subtotalIncludingTotalDiscount * vatPercentage) / 100,
    [subtotalIncludingTotalDiscount, vatPercentage],
  );
  // Total amount for all services including VAT
  const totalAmount = useMemo(
    () => vatAmount + subtotalIncludingTotalDiscount,
    [vatAmount, subtotalIncludingTotalDiscount],
  );

  function calculateServiceTotals() {
    return serviceSegments.reduceRight(
      (total: Array<{ label: string; value: number }>, current) => {
        if (!current.unit) return total;

        const index = total.findIndex(
          (item) => item.label === current?.unit?.name,
        );

        if (index >= 0) {
          total[index].value =
            Number(total[index].value) + Number(current.quantity);
          return total;
        }

        return [
          ...total,
          { label: current.unit.name, value: current.quantity },
        ];
      },
      [],
    );
  }

  return {
    calculateServiceTotals,
    subtotalAmount,
    servicesDiscountAmount,
    subtotalAfterDiscount,
    allDiscountsCombined,
    vatAmount,
    totalAmount,
    subtotalIncludingTotalDiscount,
    totalDiscountAmount,
  };
}
