import { motion } from 'framer-motion';
import React from 'react';

interface Props {
  message: JSX.Element;
  description?: JSX.Element;
}

export const PublicProposalStatus: React.FC<Props> = ({
  message,
  description,
}) => (
  <>
    <motion.h3
      style={{
        opacity: 0,
        y: 8,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2, type: 'intertia' }}
      className="text--lg__responsive restricted-proposal__title"
      data-cy="restricted-proposal-title"
    >
      {message}
    </motion.h3>
    {description && (
      <motion.p
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4, type: 'intertia' }}
        className="text--xxs__responsive s-top--sml t-text-2 restricted-proposal__description"
      >
        {description}
      </motion.p>
    )}
  </>
);
