import React from 'react';
import { SharedButtonsClassNames } from '../SharedButtonsClassNames';
import { PreviewButton } from '../preview-button';
import { PublishButton } from '../publish-button';

interface Props extends SharedButtonsClassNames {
  isEditorActions?: boolean;
}

export const DraftSharedButtons: React.FC<Props> = ({
  isEditorActions,
  publishClassName,
  previewClassName,
}) => {
  const buttons = [
    <PublishButton key="publish" className={publishClassName} />,
    <PreviewButton
      key="preview"
      className={`${previewClassName} ${
        isEditorActions ? 'actionbar__editor__preview' : ''
      }`}
      btnSelector="proposal-preview-btn"
      isEditorActions={isEditorActions}
    />,
  ];
  return isEditorActions ? (
    <div className="actionbar__shared__buttons">{buttons}</div>
  ) : (
    <>{buttons}</>
  );
};
