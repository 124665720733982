import { SettingsNewsletterCheckbox } from 'modules/settings';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  hasUnsubscribedFromNewsletter: boolean;
  handleToggle: () => void;
}

export const OnboardingSubscribeToNewsletter: React.FC<Props> = ({
  hasUnsubscribedFromNewsletter,
  handleToggle,
}) => {
  return (
    <li
      className="newsletter-checkbox__option referral-step__option onboarding__newsletter__container"
      data-cy="newsletter-checkbox"
    >
      <SettingsNewsletterCheckbox
        isChecked={hasUnsubscribedFromNewsletter || false}
        handleToggle={handleToggle}
      />
      <label
        onClick={handleToggle}
        className="text--sm t-text-2 t-normal onboarding__newsletter__label"
      >
        <FormattedMessage id="settings.newsletter.label" />
      </label>
    </li>
  );
};
