import { useFeatureFlag } from 'modules/feature-flags';
import React, { PropsWithChildren } from 'react';
import { useSubscriptionHelpers } from '../hooks';
import { useRecoilValue } from 'recoil';
import { isLegacyFreeUserAtom } from 'modules/authorization';

/** Determine whether user is in free trial period. */
export const FreeTrialEndedWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isFreeTrialEnded } = useSubscriptionHelpers();
  const isLegacyFreeUser = useRecoilValue(isLegacyFreeUserAtom);
  const subscriptionsEnabled = useFeatureFlag('SUBSCRIPTION');

  if (!isFreeTrialEnded || !subscriptionsEnabled || isLegacyFreeUser) {
    return null;
  }
  return <>{children}</>;
};
