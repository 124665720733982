import { navigate, useLocation } from '@reach/router';
import { useOverviewFiltersOptionSelector } from 'modules/proposals-overview';
import { useEffect, useState } from 'react';

export const usePaginationPages = (
  limit: number,
  length?: number,
  query?: string,
) => {
  const filters = useOverviewFiltersOptionSelector();
  const [pageNumber, setPageNumber] = useState(1);

  const endIndex = pageNumber * limit;
  const startingIndex = (pageNumber - 1) * limit;
  const totalPages = Math.ceil((length || 0) / limit);

  const { pathname, search } = useLocation();

  const onGoToPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
    const query = new URLSearchParams(`page=${pageNumber}`);
    if (query.toString() === 'page=1') {
      navigate(pathname);
      return;
    }
    navigate(`?${query.toString()}`);
  };

  useEffect(() => {
    if (!search) setPageNumber(1);
    const currentPage = Number(search.replace(/\D/g, ''));
    if (currentPage > 0 && currentPage <= totalPages) {
      setPageNumber(currentPage);
    }

    if (currentPage > totalPages) {
      setPageNumber(1);
      navigate(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    if (query?.length && pageNumber !== 1) {
      setPageNumber(1);
      navigate(pathname);
    }
  }, [query, pageNumber, filters]);

  useEffect(() => {
    const currentPage = Number(search.replace(/\D/g, ''));

    if (currentPage !== pageNumber || currentPage === 0) {
      setPageNumber(1);
    }
  }, [search]);

  useEffect(() => {
    /**When filters are applied and current pageNumber is greater than totalPages return user to last page. */
    if (pageNumber > totalPages && pageNumber > 1) {
      setPageNumber(totalPages);
      const query = new URLSearchParams(`page=${totalPages}`);
      navigate(`?${query.toString()}`);
    }
  }, [length, pageNumber, startingIndex, totalPages]);

  return {
    totalPages,
    onGoToPage,
    startingIndex,
    endIndex,
    pageNumber,
  };
};
