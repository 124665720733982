import React from 'react';

export const TableOfContentsRichTextIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      className="btn__icon"
      fill="none"
    >
      <path
        d="M10.5 1H15.5"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5H15.5"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 9H15.5"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 13H15.5"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 10L4 1L0.5 10"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66797 7H6.33397"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
