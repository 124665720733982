import Select, { Props as SelectProps } from 'react-select';
import { OptionType, getSelectStyles } from './select.styles';
import React from 'react';

type StyledSelectProps<T> = SelectProps<OptionType<T>, false>;

export const StyledSelect = <T extends object>(props: StyledSelectProps<T>) => {
  return (
    <Select
      styles={getSelectStyles<T>()}
      name={props.name}
      {...props}
      data-cy="btn"
    />
  );
};
