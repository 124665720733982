import { generateColor } from 'modules/proposals';
import React from 'react';

interface Props {
  proposal: Proposal;
  settings?: Settings | null;
}

export const PublicCompanyInfo: React.FC<Props> = ({ settings, proposal }) => {
  if (!settings) return null;
  const { name, address, tax } = settings;
  const { addressOne, city, country, postalCode } = address;
  const { vatId } = tax;

  return (
    <div
      className="proposal__footer__companyinfo__info"
      style={{
        color: generateColor('bodyText', proposal),
      }}
    >
      <p className="t-small">{name}</p>
      <p className="t-small">{`${addressOne}, ${postalCode}`}</p>
      <p className="t-small">{`${city}, ${country}`}</p>
      {vatId && <p className="t-small">{`VAT ID: ${vatId}`}</p>}
    </div>
  );
};
