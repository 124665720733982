import { CURRENCIES_WITH_DUAL_PRICING } from '../const';

export function useDualPricing(currency: string | null) {
  const isCurrencyWithDualPricing = CURRENCIES_WITH_DUAL_PRICING.some(
    (item) => item.currency === currency,
  );
  const exchangeRate = CURRENCIES_WITH_DUAL_PRICING.find(
    (item) => item.currency === currency,
  )?.fixedExchangeRate;

  return {
    isCurrencyWithDualPricing,
    exchangeRate,
  };
}
