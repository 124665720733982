import { authenticationSelector } from 'modules/authentication';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { IntervalSelector, ProductFeatureList, ProductItemAction } from '.';
import { useStripeHelpers, useSubscriptionHelpers } from '../hooks';
import { subscriptionAtoms } from '../state';

interface Props {
  product: StripeProductWithPrices;
  isCheckoutLoading?: boolean;
  onSelect: () => void;
}

export const ProductInfo: React.FC<Props> = ({
  product,
  isCheckoutLoading,
  onSelect,
}) => {
  const { getProductFeatureListFromMetadata } = useStripeHelpers();
  const { name, id, metadata } = product;
  const { isGracePeriod } = useSubscriptionHelpers();
  const active = useRecoilValue(subscriptionAtoms.active);
  const { user } = useSelector(authenticationSelector.getState);
  const featureList = useMemo(
    () => getProductFeatureListFromMetadata(metadata),
    [metadata],
  );
  const isGracePeriodLabelShown = useMemo(
    () => isGracePeriod && active && user,
    [isGracePeriod, active, user],
  );

  return (
    <>
      <header className="f f--between">
        <h2 className="text--lg">
          <span
            className="subscription-settings-plan__name"
            data-cy="premium-plan-product-title"
          >
            <FormattedMessage id="current-plan-card.propoze-plus" />
          </span>
        </h2>

        {isGracePeriodLabelShown && (
          <p
            className="subscription-settings-plan__status text--tny__responsive subscription-settings-plan__status--grace-period"
            data-cy="plan-status-label"
          >
            <FormattedMessage id="current-plan-card.label.grace-period" />
          </p>
        )}
      </header>
      <IntervalSelector />
      <main className="s-top--sml f--one">
        <ProductFeatureList
          features={featureList}
          productId={id}
          isVatNoteVisible
          className="list--bullets__bordered"
          featuresSelector="propoze-plus-features-list"
        />
      </main>
      <footer className="f s-top--med">
        <ProductItemAction
          onSelect={onSelect}
          name={name}
          isCheckoutLoading={isCheckoutLoading}
        />
      </footer>
    </>
  );
};
