import { useLocation } from '@reach/router';
import createIconMenu from 'assets/icons/icon-create-proposal-12-negative.svg';
import createIconBtn from 'assets/icons/icon-create-proposal-12.svg';
import { addDays } from 'date-fns';
import { Link } from 'gatsby';
import { Proposal, useProposals } from 'modules/proposals';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useScrollPosition } from 'shared/hooks';

interface Props {
  className: string;
  btnSelector?: string;
  isDrawerBtn?: boolean;
}

export const CreateFromTemplateButton: React.FC<Props> = ({
  className,
  btnSelector,
  isDrawerBtn,
}) => {
  const { createEntityWithImagesAsync, reserveProposalId } = useProposals();
  const { pathname, search } = useLocation();
  const { formatMessage } = useIntl();
  const { getValues } = useFormContext<Proposal>();
  const scrollPosition = useScrollPosition();
  const id = reserveProposalId();

  function onClick() {
    createEntityWithImagesAsync(
      id,
      new Proposal({
        ...getValues(),
        publishStatus: 'draft',
        creationDateTime: Date.now(),
        displayDateTime: Date.now(),
        validUntilDateTime: +new Date(addDays(new Date(), 14)),
      }),
    );
  }

  return (
    <Link
      to={formatMessage({ id: 'routes.new-proposal' }, { id })}
      state={{
        lastPageUrl: `${pathname}${search}`,
        scrollY: String(scrollPosition),
      }}
      className={`${className} btn__icon__fill`}
      onClick={onClick}
      data-cy={btnSelector}
    >
      {isDrawerBtn ? (
        <img
          src={createIconMenu}
          alt="Create new proposal icon"
          className="btn__icon btn__icon__left"
        />
      ) : (
        <img
          src={createIconBtn}
          alt="Create new proposal icon"
          className="btn__icon btn__icon__left"
        />
      )}
      <FormattedMessage id="buttons.create-from-template" />
    </Link>
  );
};
