import { createSelector } from 'reselect';

const getState = (state: AppState) => state.clients;

const sortDescendingFromNewest = (a: Client, b: Client) =>
  b.createdAt - a.createdAt;

const getClientsByCreationTime = createSelector([getState], (clientState) => {
  let { documents } = clientState;
  documents = documents?.sort(sortDescendingFromNewest);

  return { ...clientState, documents };
});

export const clientSelector = {
  getState,
  getClientsByCreationTime,
};
