import { useFormGuard } from 'modules/form';
import { Settings } from 'modules/settings';
import React, { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AddressOneInput } from './address-one-input';
import { AddressTwoInput } from './address-two-input';
import { CityInput } from './city-input';
import { CountrySelect } from './county-select';
import { PostalCodeInput } from './postal-code-input';
import { StateInput } from './state-input';

interface Props extends PropsWithChildren {
  config: Settings;
  onSubmit: (data: Settings) => void;
}

export const Address: React.FC<Props> = ({ config, onSubmit, children }) => {
  const form = useForm<Settings>({
    mode: 'onSubmit',
    defaultValues: {
      ...config,
    },
  });
  useFormGuard(form);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="address__container">
          <div className="address-form__input">
            <AddressOneInput
              autoFocus={!config?.address.addressOne}
              label="inputs.address.label"
              required="inputs.address.required"
              placeholder="inputs.address.placeholder"
              inputSelector="address-one-input"
            />
          </div>
          <div className="address-form__input">
            <AddressTwoInput
              label="inputs.address-two.label"
              placeholder="inputs.address-two.placeholder"
              inputSelector="address-two-input"
            />
          </div>
          <div
            className="address-form__input"
            data-cy="country-settings-container"
          >
            <CountrySelect
              label="dropdowns.country.label"
              required="dropdowns.country.required"
              placeholder="dropdowns.country.placeholder"
            />
          </div>
          <div className="address-form__input">
            <StateInput
              label="inputs.state.label"
              placeholder="inputs.state.placeholder"
              inputSelector="state-input"
            />
          </div>
          <div className="address-form__group">
            <div className="field__half--width client-modal__spacer u-width--full">
              <CityInput
                label="inputs.city.label"
                required="inputs.city.required"
                placeholder="inputs.city.placeholder"
                inputSelector="city-input"
              />
            </div>
            <div className="field__half--width">
              <PostalCodeInput
                label="inputs.postal-code.label"
                required="inputs.postal-code.required"
                placeholder="inputs.postal-code.placeholder"
                inputSelector="postal-code-input"
              />
            </div>
          </div>
        </div>

        {children}
      </form>
    </FormProvider>
  );
};
