import { motion } from 'framer-motion';
import {
  AuthorizationCodeForm,
  useClientEmailSelector,
  usePublicProposal,
} from 'modules/proposals';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  proposalId: string;
  organizationId: string;
  onAuthorized: VoidFunction;
}

export const ProtectedProposalAuthorization: React.FC<Props> = ({
  proposalId,
  organizationId,
  onAuthorized,
}) => {
  const { verifyAccessRights } = usePublicProposal();
  const clientEmail = useClientEmailSelector();

  async function handleEmailVerification() {
    return await verifyAccessRights({
      email: clientEmail,
      proposalId,
      organizationId,
    });
  }

  return (
    <motion.section
      data-cy="protected-proposal-view"
      animate={{
        y: [10, 0],
        opacity: [0, 1],
        transition: { ease: 'easeOut', duration: 0.2 },
      }}
    >
      <h1 className="t-zeta" data-cy="protected-proposal-title">
        <FormattedMessage id="protected-proposal-authorization.title" />
      </h1>
      <p className="t-text-3 t-small s-top--med">
        <FormattedMessage id="protected-proposal-authorization.protected.description" />
      </p>
      <p className="t-text-3 t-small s-bottom--med">
        <FormattedMessage id="protected-proposal-authorization.identify.description" />
      </p>
      <AuthorizationCodeForm
        organizationId={organizationId}
        proposalId={proposalId}
        verifyEmail={handleEmailVerification}
        onAuthorized={onAuthorized}
      />
    </motion.section>
  );
};
