import { selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { organizationIdAtom } from './atoms';

const organizationIdSelector = selector<string>({
  key: 'organizationIdSelector',
  get: ({ get }) => get(organizationIdAtom),
  set: ({ get, set }, newValue) =>
    set(organizationIdAtom, newValue || get(organizationIdAtom)),
});

export const useOrganizationIdSelector = () =>
  useRecoilValue(organizationIdSelector);

export const useSetOrganizationId = () =>
  useSetRecoilState(organizationIdSelector);
