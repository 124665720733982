import React from 'react';

interface Props {
  item: HeadingSegment;
  index: number;
  style: Proposal['style'];
  servicePricesVisibility?: Proposal['servicePricesVisibility'];
}

export const PublicHeadingSegmentCard: React.FC<Props> = ({
  item,
  index,
  style: { color, typography },
  servicePricesVisibility,
}) => {
  if (!item.title && !item.subtitle) return null;

  return (
    <header
      className="proposal-block-heading"
      id={`heading-segment-${index}`}
      style={{
        ...(servicePricesVisibility === 'hidden' && {
          flexDirection: 'column',
          gap: '12px',
        }),
      }}
    >
      <div
        className="proposal-block-heading__heading"
        style={{
          ...(servicePricesVisibility === 'hidden' && {
            width: '100%',
            maxWidth: '100%',
            flexBasis: '100%',
          }),
        }}
      >
        <h2
          style={{
            color: color.headings,
            fontFamily: typography.heading.fontFamily,
            fontWeight: typography.heading.fontWeight,
          }}
          className="text--xl__responsive"
        >
          {item.title}
        </h2>
      </div>
      <div
        className="proposal-block-heading__description"
        style={{
          ...(servicePricesVisibility === 'hidden' && {
            width: '100%',
            maxWidth: '100%',
          }),
        }}
      >
        <p
          style={{
            color: color.subheadings,
            fontFamily: typography.body.fontFamily,
            fontWeight: typography.body.fontWeight,
          }}
          className="text--lg__responsive t-text-2"
        >
          {item.subtitle}
        </p>
      </div>
    </header>
  );
};
