import { PageProps } from 'gatsby';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProposalsStats } from '../components';
import { AdminLayout } from '../components/admin-layout';

export const AdminProposalsOverview: React.FC<PageProps> = () => {
  return (
    <>
      <AdminLayout>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="admin-proposals-title"
        >
          <FormattedMessage id="admin.proposals.title" />
        </header>
        <div className="admin-dashboard__container">
          <section className="s-bottom--xlrg">
            <ProposalsStats />
          </section>
        </div>
      </AdminLayout>
    </>
  );
};
