import logoImage from 'assets/images/logo.svg';
import logoImageSmall from 'assets/images/new-logo-small.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  navigationUrl: string;
  shouldTransform?: boolean;
}

export const Logo: React.FC<Props> = ({ navigationUrl, shouldTransform }) => (
  <div className="logo">
    <a
      className="logo__link"
      href={navigationUrl}
      rel="noopener noreferrer"
      data-cy="logo-link-header"
    >
      <span className="visuallyhidden">
        <FormattedMessage id="product-info.name" />
      </span>
      {shouldTransform && (
        <img
          src={logoImageSmall}
          alt="LogoImageSmall"
          className="logo__image logo__image--small"
        />
      )}
      <img
        src={logoImage}
        alt="LogoImage"
        className={shouldTransform ? 'logo__image' : undefined}
      />
    </a>
  </div>
);
