import { FeatureFlagWrapper } from 'modules/feature-flags';
import { ProposalSettingsController } from 'modules/subscription';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ClientSelect,
  CoverImagePicker,
  CurrencySelect,
  CustomTagSelect,
  DisplayDateTimePicker,
  ProposalNameInput,
  ValidUntilDateTimePicker,
} from './fragments';

export const EditorHeader: React.FC = () => {
  const context = useFormContext<PublishStatusAware<Proposal>>();

  return (
    <>
      <CoverImagePicker {...context} />
      <section className="general-info">
        <header className="s-bottom--lrg f f--col">
          <ProposalNameInput />

          <div className="proposal-acceptance-controller__container">
            <FeatureFlagWrapper flag="VISIBILITY">
              <ProposalSettingsController />
            </FeatureFlagWrapper>
          </div>
        </header>
        <section className="general-info-settings">
          <div className="field">
            <ClientSelect />
          </div>
          <div className="field">
            <CurrencySelect />
          </div>

          <div className="general-info__date__container">
            <div className="general-info__date">
              <div className="field">
                <DisplayDateTimePicker />
              </div>
            </div>

            <div className="general-info__date">
              <div className="field">
                <ValidUntilDateTimePicker />
              </div>
            </div>
          </div>
        </section>
        <FeatureFlagWrapper flag="CUSTOM-TAGS">
          <CustomTagSelect />
        </FeatureFlagWrapper>
      </section>
    </>
  );
};
