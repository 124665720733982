import React from 'react';
import { GLOBAL_RECENT_SEARCHES_LS_KEY } from 'shared/const';
import { useOnClickRecentSearchResults } from 'shared/hooks';
import {
  NoResultsFound,
  RecentSearchesList,
  SearchResultsList,
} from './fragments';

interface Props {
  query: string;
  proposals?: Proposal[];
  clients?: Client[];
  onSetQuery: (query: string) => void;
  onSetShowResults: (showResults: boolean) => void;
}

export const SearchResults: React.FC<Props> = ({
  query,
  proposals,
  clients,
  onSetQuery,
  onSetShowResults,
}) => {
  const { recentSearches } = useOnClickRecentSearchResults(
    GLOBAL_RECENT_SEARCHES_LS_KEY,
  );

  /**Don't display any result */
  if (!recentSearches?.length && !query) return null;
  /**Display recent searches */
  if (recentSearches?.length && !query) {
    return <RecentSearchesList onSetQuery={onSetQuery} />;
  }
  /**Display no results found */
  if (!proposals?.length && !clients?.length) return <NoResultsFound />;

  return (
    <SearchResultsList
      proposals={proposals}
      clients={clients}
      onSetShowResults={onSetShowResults}
    />
  );
};
