import { useSlateApi } from 'modules/editor';
import React, { useCallback, useEffect, useMemo } from 'react';
import { withInlines } from 'shared/utils';
import { BaseEditor, BaseRange, Descendant, createEditor } from 'slate';
import { HistoryEditor, withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';
import { RichTextEditorToolbar } from './RichTextEditorToolbar';
import { RichTextElement } from './RichTextElement';
import { RichTextLeaf } from './RichTextLeaf';

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

export type CustomElement =
  | BulletedListElement
  | LinkElement
  | ListItemElement
  | ParagraphElement
  | BlockQuoteElement
  | ListItemElement
  | NumberedListElement;

/**Source https://docs.slatejs.org/concepts/12-typescript */
declare module 'slate' {
  export interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
    Range: BaseRange & {
      [key: string]: unknown;
    };
  }
}

interface Props {
  index: number;
  placeholder?: string;
  value?: string;
  autoFocus?: boolean;
  name?: string;
  inputSelector?: string;
  bodyText?: string;
  hasError?: boolean;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChange?: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
}

export const RichTextEditor: React.FC<Props> = ({
  index,
  placeholder,
  value: defaultValue,
  autoFocus,
  name,
  inputSelector,
  bodyText,
  hasError,
  onChange,
  onFocus,
  onBlur,
}) => {
  const editor = useMemo(
    () => withInlines(withHistory(withReact(createEditor()))),
    [],
  );
  const editorApi = useSlateApi(editor);

  const isReadOnly = onChange === undefined;

  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <RichTextElement {...props} isReadOnly={isReadOnly} index={index} />
    ),
    [],
  );

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <RichTextLeaf {...props} />,
    [],
  );

  function onValueChange(value: Descendant[]) {
    onChange?.(JSON.stringify(value));
  }

  /** Update body text color */
  useEffect(() => {
    if (!bodyText) return;

    document.documentElement.style.setProperty(
      '--readonly-body-color',
      bodyText,
    );
  }, [bodyText]);

  /**NOTE:
   * https://github.com/ianstormtaylor/slate/pull/4540
   * The value prop is now only used on the initial render to initialize the value prop. On subsequent changes it is ignored. This also clears the way to rename value to initialValue as the prop is really intended to be.
   * This means that on read only mode, on async data changes, the editor will not be updated.
   */

  return (
    <Slate
      editor={editor}
      initialValue={editorApi.getInitialValue(defaultValue)}
      onChange={onValueChange}
    >
      {!isReadOnly && <RichTextEditorToolbar editorApi={editorApi} />}

      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onBlur={onBlur}
        placeholder={placeholder}
        spellCheck
        autoFocus={autoFocus}
        style={{ overflowWrap: isReadOnly ? 'inherit' : 'break-word' }}
        onFocus={onFocus}
        readOnly={isReadOnly}
        className={
          isReadOnly
            ? 'slate is-readonly t-eta'
            : hasError
              ? 'slate__editor slate__editor__error'
              : 'slate__editor'
        }
        id={name}
        onKeyDown={editorApi.onKeydown}
        name={name}
        data-cy={inputSelector}
      />
    </Slate>
  );
};
