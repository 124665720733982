import addIcon from 'assets/icons/icon-add-16-negative.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  onClick: VoidFunction;
}

export const UnitButton: React.FC<Props> = ({ onClick }) => (
  <Button type="button" style="primary" size="med" onClick={onClick}>
    <img src={addIcon} alt="AddIcon" className="btn__icon btn__icon__left" />
    <FormattedMessage id="buttons.add-new-unit" />
  </Button>
);
