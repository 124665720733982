import { createSelector } from 'reselect';

const getState = (state: AppState) => state.units;

const sortAscendingFromIndex = (a: Unit, b: Unit) => a.index - b.index;

const getUnitsByIndex = createSelector([getState], (unitState) => {
  let { documents } = unitState;
  documents = documents?.sort(sortAscendingFromIndex);

  return { ...unitState, documents };
});

export const unitSelector = {
  getState,
  getUnitsByIndex,
};
