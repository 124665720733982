import { arePresetsDirtyAtom, customPresetsAtom } from 'modules/settings';
import { useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

export function useProposalAppearanceColorList(
  proposal: PublishStatusAware<Proposal> | Proposal,
) {
  const { setValue } = useFormContext<PublishStatusAware<Proposal>>();
  const setCustomPresets = useSetRecoilState(customPresetsAtom);
  const setArePresetsDirty = useSetRecoilState(arePresetsDirtyAtom);

  function handleColorChange(colorHex: string, colorKey: keyof ProposalColor) {
    setValue(
      'style',
      {
        ...proposal?.style,
        color: {
          ...proposal?.style.color,
          [colorKey]: colorHex,
        },
      },
      {
        shouldDirty: true,
      },
    );

    /**If current preset is default I want to update in my custom presets only default style */
    if (proposal?.currentPreset === 'default') {
      setCustomPresets((prev) => [
        {
          ...prev[0],
          color: {
            ...prev[0].color,
            [colorKey]: colorHex,
          },
        },
        prev[1],
      ]);
      setArePresetsDirty(true);
    }

    /**If current preset is dark I want to update in my custom presets only dark style */
    if (proposal?.currentPreset === 'dark') {
      setCustomPresets((prev) => [
        prev[0],
        {
          ...prev[1],
          color: {
            ...prev[1].color,
            [colorKey]: colorHex,
          },
        },
      ]);
      setArePresetsDirty(true);
    }
  }

  return {
    handleColorChange,
  };
}
