import { useFunction } from 'modules/firebase';
import { toast } from 'sonner';

export function useUserFeedback() {
  const sendFeedbackCallable = useFunction<DeleteAccountUserRequest>(
    'getAccountDeletionFeedback',
  );

  async function sendDeleteAccountFeedback(params: DeleteAccountUserRequest) {
    try {
      if (!sendFeedbackCallable) throw new Error('Unreachable function');
      const { data } = await sendFeedbackCallable(params);
      if (!data.success) throw new Error(data.message);
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  }

  return {
    sendDeleteAccountFeedback,
  };
}
