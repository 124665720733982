import {
  Proposal,
  ProposalListError,
  useProposalsSelector,
} from 'modules/proposals';
import { overviewAtoms } from 'modules/proposals-overview';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ListError, Loading, Pagination } from 'shared/components';
import { usePaginationPages } from 'shared/hooks';
import { ProposalOverviewListItem } from './ProposalOverviewListItem';

interface Props {
  proposals: Proposal[];
  className?: string;
  errorClassName?: string;
}

export const ProposalOverviewList: React.FC<Props> = ({
  proposals,
  className,
  errorClassName,
}) => {
  const query = useRecoilValue(overviewAtoms.overviewQuery);
  const { totalPages, onGoToPage, endIndex, startingIndex, pageNumber } =
    usePaginationPages(15, proposals?.length, query);
  const allProposals = useProposalsSelector();

  if (!proposals) {
    return (
      <div className={className}>
        {[...new Array(3)].map((_, index) => (
          <Loading key={`loading-list-${index}`} type="card" />
        ))}
      </div>
    );
  }

  if (!allProposals?.length) {
    return (
      <div className={errorClassName}>
        <ProposalListError />
      </div>
    );
  }

  if (!proposals?.length) {
    return (
      <div className={errorClassName}>
        <ListError />
      </div>
    );
  }

  return (
    <div className={className}>
      {proposals
        ?.slice(startingIndex, endIndex)
        .map((proposal, i) => (
          <ProposalOverviewListItem
            key={proposal?.id || i}
            proposal={proposal}
            index={i}
          />
        ))}

      {proposals && proposals?.length > 15 && (
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          onGoToPage={onGoToPage}
          paginationSelector="pagination"
        />
      )}
    </div>
  );
};
