import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  label?: string;
}

export const TableHead: React.FC<Props> = ({ children, label, ...rest }) => {
  return (
    <th {...rest}>
      <p className="s-bottom--tny table__heading">{label}</p>
      {children}
    </th>
  );
};
