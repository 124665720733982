import { useRef, useEffect } from 'react';

export const useHasMounted = () => {
  const isMountRef = useRef<boolean>(true);

  useEffect(() => {
    isMountRef.current = false;
  }, []);

  return isMountRef.current;
};
