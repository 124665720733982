import dragIcon from 'assets/icons/icon-drag-16-secondary.svg';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { UseFormReturn } from 'react-hook-form';
import { useRequiredProposalFieldCheck } from 'shared/hooks';
import { MobileSegmentMover } from '../mobile-segment-mover';
import { SegmentActions } from '../segment-actions';
import { SegmentDeleteButton } from '../segment-delete-button';
import { HeadingSubtitleInput, HeadingTitleInput } from './fragments';

interface Props {
  item: HeadingSegment;
  index: number;
  provided: DraggableProvided;
  segmentsLength: number;
  context: UseFormReturn<PublishStatusAware<Proposal>, any, undefined>;
  addSegment: (type: ProposalSegment['type'], index: number) => void;
  deleteSegment: (index: number) => void;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

export const HeadingSegmentCard: React.FC<Props> = ({
  item,
  index,
  provided,
  segmentsLength,
  context,
  addSegment,
  deleteSegment,
  moveItem,
}) => {
  const { isFieldRequired } = useRequiredProposalFieldCheck();

  return (
    <>
      <div
        className="proposal-service proposal-service--heading"
        id={`heading-segment-${index}`}
      >
        <div
          {...provided.dragHandleProps}
          className="proposal-service__dragger"
          data-cy={`drag-and-drop-btn-${index}`}
        >
          <img src={dragIcon} alt="DragIcon" />
        </div>
        <SegmentDeleteButton index={index} onDelete={deleteSegment} />
        <MobileSegmentMover
          index={index}
          moveItem={moveItem}
          segmentsLength={segmentsLength}
        />
        <div className="s-bottom--lrg">
          <HeadingTitleInput
            index={index}
            value={item.title}
            isFieldRequired={isFieldRequired}
            {...context}
          />
        </div>

        <HeadingSubtitleInput
          index={index}
          value={item.subtitle}
          {...context}
        />
      </div>

      <SegmentActions index={++index} onAdd={addSegment} />
    </>
  );
};
