import sparksIcon from 'assets/icons/icon-sparks-12.svg';
import offIcon from 'assets/icons/icon-toc-off-12.svg';
import onIcon from 'assets/icons/icon-toc-on-12.svg';
import React from 'react';
import { Button } from 'shared/components';

interface Props {
  isOpen?: boolean;
  isSubscribed?: boolean;
  isTocDisabled?: boolean;
  onHandleToggle: () => void;
}

export const ProposalSummaryDrawerToggle: React.FC<Props> = ({
  isOpen,
  isSubscribed,
  isTocDisabled,
  onHandleToggle,
}) => {
  return (
    <Button
      type="button"
      style="ghost"
      disabled={isTocDisabled}
      className="proposal__summary__icon__container"
      onClick={onHandleToggle}
    >
      {!isSubscribed && (
        <img
          src={sparksIcon}
          alt="Sparks icon"
          className="btn__icon proposal__summary__drawer__sparks__icon"
        />
      )}
      {isOpen ? (
        <img src={onIcon} className="btn__icon" alt="AddIcon" />
      ) : (
        <img src={offIcon} className="btn__icon" alt="AddIcon" />
      )}
    </Button>
  );
};
