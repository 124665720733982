import { useLocation } from '@reach/router';
import backIcon from 'assets/icons/icon-arrow-left-12-primary.svg';
import { Link, navigate } from 'gatsby';
import { useSetFilesToRemoveSelector } from 'modules/proposals';
import React from 'react';
import { useFormState } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'shared/components';
import { isLocationTypeGuard } from 'shared/utils';

interface Props {
  btnSelector?: string;
}

export const BackButton: React.FC<Props> = ({ btnSelector }) => {
  const location = useLocation();
  const { formatMessage } = useIntl();

  const { isDirty } = useFormState<PublishStatusAware<Proposal>>();
  const setFilesToRemove = useSetFilesToRemoveSelector();

  const state = isLocationTypeGuard(location) && location.state;
  const lastPageUrl = state
    ? state.lastPageUrl
    : '/dashboard/proposals/overview/';

  const handleConfirmNavigation = (e: React.MouseEvent) => {
    if (!isDirty) {
      setFilesToRemove([]);
      return;
    }

    e.preventDefault();
    const shouldNavigate = window.confirm(
      formatMessage({ id: 'buttons.confirm-navigation' }),
    );

    if (!shouldNavigate) return;
    navigate(lastPageUrl || '/dashboard/proposals/overview/', {
      state: { scrollY: state && String(state.scrollY) },
    });
    setFilesToRemove([]);
  };

  return (
    <Link
      to={lastPageUrl || formatMessage({ id: 'routes.overview' })}
      state={{ scrollY: state && String(state.scrollY) }}
      onClick={handleConfirmNavigation}
      data-cy={btnSelector}
      className="actionbar__buttons__back__btn"
    >
      <div className="actionbar__buttons__back__mobile">
        <img src={backIcon} alt="BackIcon" className="s-right--sml" />
        <Button type="button" className="text--xxs__responsive p-0 t-text-1">
          <FormattedMessage id="buttons.back" />
        </Button>
      </div>

      <div className="actionbar__buttons__back__desktop">
        <div className="actionbar__buttons__back__img__container">
          <img src={backIcon} alt="BackIcon" className="btn__icon" />
        </div>
        <span className="text--sm t-text-1">
          <FormattedMessage id="buttons.back" />
        </span>
      </div>
    </Link>
  );
};
