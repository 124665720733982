import React from 'react';

export const AcceptIcon: React.FC = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15357_67370)">
        <g clipPath="url(#clip1_15357_67370)">
          <g clipPath="url(#clip2_15357_67370)">
            <path
              d="M3.99943 11.0001C3.73424 11 3.47992 10.8946 3.29243 10.7071L0.292431 7.70706C0.110273 7.51846 0.00947813 7.26586 0.0117566 7.00366C0.014035 6.74147 0.119204 6.49065 0.304612 6.30524C0.49002 6.11984 0.740833 6.01467 1.00303 6.01239C1.26523 6.01011 1.51783 6.11091 1.70643 6.29306L3.90643 8.49306L10.2094 0.386063C10.3723 0.176542 10.6117 0.0402918 10.875 0.00728544C11.1383 -0.025721 11.4039 0.0472204 11.6134 0.210063C11.823 0.372906 11.9592 0.612311 11.9922 0.875613C12.0252 1.13891 11.9523 1.40454 11.7894 1.61406L4.78943 10.6141C4.70247 10.7264 4.59257 10.819 4.46704 10.8855C4.34151 10.9521 4.20323 10.9911 4.06143 11.0001H3.99943Z"
              fill="#B9B9B9"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_15357_67370">
          <rect width="12" height="12" fill="white" />
        </clipPath>
        <clipPath id="clip1_15357_67370">
          <rect width="12" height="12" fill="white" />
        </clipPath>
        <clipPath id="clip2_15357_67370">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
