import uploadIcon from 'assets/icons/icon-upload-media-48.svg';
import {
  mediaAttachmentSupportedTypes,
  useFileAttachment,
} from 'modules/proposals';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useMobile } from 'shared/hooks';
import { MediaAttachmentInputList } from '../media-attachment-input-list';
import { getBaseStyle } from './MediaAttachmentInput.styles';

interface Props {
  setValue: UseFormReturn<PublishStatusAware<Proposal>>['setValue'];
  getValues: UseFormReturn<PublishStatusAware<Proposal>>['getValues'];
  control: UseFormReturn<PublishStatusAware<Proposal>>['control'];
  formState: UseFormReturn<PublishStatusAware<Proposal>>['formState'];
  index: number;
  segmentsFileSize: number;
  value?: ProposalFile[] | null;
}

export const MediaAttachmentInput: React.FC<Props> = ({
  index,
  segmentsFileSize,
  setValue,
  getValues,
  control,
}) => {
  const files = useWatch({ name: `segments.${index}.files` });
  const {
    fileErrors,
    onDropAccepted,
    onDropRejected,
    removeFile,
    removeFileError,
  } = useFileAttachment(index, segmentsFileSize, setValue, getValues);
  const isMobile = useMobile();

  return (
    <>
      <div className="proposal-file__layout">
        <Controller
          control={control}
          name={`segments.${index}.files`}
          defaultValue={files || []}
          render={({ field: { ref, ...field } }) => (
            <Dropzone
              {...field}
              accept={mediaAttachmentSupportedTypes}
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    style: getBaseStyle(isMobile),
                  })}
                >
                  <input
                    name={`segments.${index}.files`}
                    {...getInputProps()}
                  />
                  <div
                    className="file-input__uploadmessage"
                    data-cy={`media-image-${index}`}
                  >
                    <img
                      className="file-input__uploadmessage__img"
                      src={uploadIcon}
                      alt="UploadIcon"
                    />
                    <p className="d--desktop--only">
                      <FormattedMessage id="uploaders.file.click" />
                    </p>
                    <p className="u-color--primary d--desktop--only">
                      <FormattedMessage id="uploaders.file.help" />
                    </p>
                  </div>
                  <div className="file-input__uploadmessage--mobile">
                    <div className="file-input__uploadfiles--mobile">
                      <img
                        className="file-input__uploadmessage__img"
                        src={uploadIcon}
                        alt="UploadIcon"
                      />
                      <p className="d--mobile--only text--xs__responsive t-primary">
                        <FormattedMessage id="uploaders.media.mobile-click" />
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          )}
        />
      </div>
      <span className="file-input__instructions text--xxs__responsive">
        <FormattedMessage id="uploaders.media.size" />
      </span>
      <MediaAttachmentInputList
        proposalFiles={files || []}
        fileErrors={fileErrors}
        index={index}
        onRemoveFile={removeFile}
        onRemoveFileError={removeFileError}
      />
    </>
  );
};
