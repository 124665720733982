import { arePresetsDirtyAtom, customPresetsAtom } from 'modules/settings';
import { useFormContext } from 'react-hook-form';
import { SingleValue } from 'react-select';
import { useSetRecoilState } from 'recoil';
import { OptionType } from 'shared/components';
import { toast } from 'sonner';
import { customFonts } from '../const';

export function useProposalAppearanceFontPicker(
  proposal: PublishStatusAware<Proposal> | Proposal,
) {
  const { setValue } = useFormContext<PublishStatusAware<Proposal>>();
  const setCustomPresets = useSetRecoilState(customPresetsAtom);
  const setArePresetsDirty = useSetRecoilState(arePresetsDirtyAtom);

  function handleHeadingFontChange(
    option: SingleValue<OptionType<string>>,
    property: 'heading' | 'body',
  ) {
    const selectedFont = customFonts.find((f) => f.name === option?.value);
    if (!selectedFont) {
      toast.error('Font not found. Please try again.');
      return;
    }

    setValue(
      'style',
      {
        ...proposal?.style,
        typography: {
          ...proposal?.style.typography,
          [property]: {
            fontFamily: selectedFont.name,
            fontWeight: proposal?.style.typography[property].fontWeight,
          },
        },
      },
      {
        shouldDirty: true,
      },
    );

    /**If current preset is default I want to update in my custom presets only default style */
    if (proposal?.currentPreset === 'default') {
      setCustomPresets((prev) => [
        {
          ...prev[0],
          typography: {
            ...prev[0].typography,
            [property]: {
              fontFamily: selectedFont.name,
              fontWeight: proposal?.style.typography[property].fontWeight,
            },
          },
        },
        prev[1],
      ]);
      setArePresetsDirty(true);
    }

    /**If current preset is dark I want to update in my custom presets only dark style */
    if (proposal?.currentPreset === 'dark') {
      setCustomPresets((prev) => [
        prev[0],
        {
          ...prev[1],
          typography: {
            ...prev[1].typography,
            [property]: {
              fontFamily: selectedFont.name,
              fontWeight: proposal?.style.typography[property].fontWeight,
            },
          },
        },
      ]);
      setArePresetsDirty(true);
    }
  }

  function handleHeadingWeightChange(
    option: SingleValue<OptionType<string>>,
    property: 'heading' | 'body',
  ) {
    const fontWeight = option?.value;
    if (!fontWeight) {
      toast.error('Font weight not found. Please try again.');
      return;
    }

    setValue(
      'style',
      {
        ...proposal?.style,
        typography: {
          ...proposal?.style.typography,
          [property]: {
            fontFamily: proposal?.style.typography[property].fontFamily,
            fontWeight,
          },
        },
      },
      {
        shouldDirty: true,
      },
    );

    /**If current preset is default I want to update in my custom presets only default style */
    if (proposal?.currentPreset === 'default') {
      setCustomPresets((prev) => [
        {
          ...prev[0],
          typography: {
            ...prev[0].typography,
            [property]: {
              fontFamily: proposal?.style.typography[property].fontFamily,
              fontWeight,
            },
          },
        },
        prev[1],
      ]);
      setArePresetsDirty(true);
    }

    /**If current preset is dark I want to update in my custom presets only dark style */
    if (proposal?.currentPreset === 'dark') {
      setCustomPresets((prev) => [
        prev[0],
        {
          ...prev[1],
          typography: {
            ...prev[1].typography,
            [property]: {
              fontFamily: proposal?.style.typography[property].fontFamily,
              fontWeight,
            },
          },
        },
      ]);
      setArePresetsDirty(true);
    }
  }

  return {
    handleHeadingFontChange,
    handleHeadingWeightChange,
  };
}
