import { PageProps } from 'gatsby';
import { authorizationSelector } from 'modules/authorization';
import { FormActionButton } from 'modules/dashboard/components';
import { SettingsLayout } from 'modules/settings';
import { UserListError, UserListItem, UserModal } from 'modules/users';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { List } from 'shared/components';
import { useModal } from 'shared/hooks';

export const AdminUsers: React.FC<PageProps> = () => {
  const modal = useModal();

  const roleState = useSelector(authorizationSelector.getState);

  return (
    <SettingsLayout>
      <section className="settings">
        <header className="settings__header">
          <div>
            <h1 className="admin-dashboard__title text--xl__responsive">
              <FormattedMessage id="users.title" />
            </h1>
            <p className="t-small o-60 admin-dashboard__description t-eta">
              <FormattedMessage id="users.description" />
            </p>
          </div>
          <div className="settings__header__action">
            <FormActionButton
              title={<FormattedMessage id="buttons.add-new-user" />}
              openModal={modal.openModal}
            />
          </div>
        </header>
        {modal.isModalOpen && <UserModal onClose={modal.closeModal} />}
        <List
          {...roleState}
          itemComponent={UserListItem}
          errorComponent={UserListError}
          loadingType="list"
        >
          <header className="settings-itemlist__header settings-itemlist__header--three">
            <span>
              <FormattedMessage id="inputs.service-name.label" />
            </span>
            <span>
              <FormattedMessage id="users.status" />
            </span>
            <span>
              <FormattedMessage id="proposals.actions" />
            </span>
          </header>
        </List>
      </section>
    </SettingsLayout>
  );
};
