/**
 * Converts a file size in bytes to a human-readable string format.
 *
 * NOTE: The reason why the base value is set to 1000 instead of 1024 is because the we are using the International System of Units (SI) prefixes to represent file sizes. In the SI system, the base unit for file size is the byte, and the prefixes are based on powers of 1000. For example, kilobyte (KB) is defined as 1000 bytes, megabyte (MB) is defined as 1000 kilobytes, and so on.
 *
 * However, in the context of computing, the base unit for file size is actually the binary byte (or "byte" for short), which is a unit of information storage that represents 8 bits. Because computers use binary arithmetic, the binary byte is based on powers of 2, not 10. This means that a kilobyte (KB) in computing is actually 1024 bytes, not 1000 bytes.
 */
export function getFileSize(size: number): string {
  const base = 1000;
  if (size < base) return size + ' B';
  if (size < Math.pow(base, 2)) return (size / base).toFixed(0) + ' KB';
  if (size < Math.pow(base, 3)) {
    return (size / Math.pow(base, 2)).toFixed(1) + ' MB';
  }
  return (size / Math.pow(base, 3)).toFixed(1) + ' GB';
}
