import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Client } from '../Client';

export const clientsFirestoreConverter = {
  toFirestore: (client: Client) => ({
    ...new Client(client),
  }),
  fromFirestore: (doc: QueryDocumentSnapshot<Client>) =>
    new Client({ id: doc.id, ...doc.data() }),
};
