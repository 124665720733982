import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

interface Props {
  label: string;
  required?: string;
  placeholder: string;
  inputSelector?: string;
}

export const PostalCodeInput: React.FC<Props> = ({
  label,
  required,
  placeholder,
  inputSelector,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<Settings>();

  return (
    <FieldWrapper
      name="address.postalCode"
      errors={errors}
      labelId={label}
      isRequired={!!required}
    >
      <input
        type="text"
        className={`input input__box input__med ${
          errors.address?.postalCode ? 'input__box__error' : ''
        }`}
        placeholder={formatMessage({
          id: placeholder,
        })}
        data-cy={inputSelector}
        onFocus={handleFocus}
        {...register('address.postalCode', {
          required: required && formatMessage({ id: required }),
          validate: (value) => {
            if (required) {
              return (
                !!value.trim() ||
                formatMessage({
                  id: required,
                })
              );
            }
            return;
          },
        })}
      />
    </FieldWrapper>
  );
};
