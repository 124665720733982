import React from 'react';

interface Props {
  message: string;
}

export const AdminListError: React.FC<Props> = ({ message }) => (
  <div className="message message--lrg message--info">
    <div className="message__content">
      <h2 className="t-epsilon">{message}</h2>
    </div>
  </div>
);
