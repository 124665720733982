import { useParams } from '@reach/router';
import { assertNever } from 'assert-never';
import createIcon from 'assets/icons/icon-create-proposal-12.svg';
import signoutIcon from 'assets/icons/icon-signout-12.svg';
import { Link } from 'gatsby';
import { useAuthentication } from 'modules/authentication';
import { PdfExportButton } from 'modules/pdf';
import { useProposals } from 'modules/proposals';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../button';
import { CreateNewProposalButton } from './CreateNewProposalButton';
import { NavButton } from './NavButton';
import { NavDropdown } from './NavDropdown';
import { NavSearch } from './NavSearch';
import { ReturnToDraftPreviewButton } from './ReturnToDraftPreviewButton';

interface Props {
  buttons?: NavigationButtons[];
  proposal?: Proposal;
}

export const Navigation: React.FC<Props> = ({ buttons, proposal }) => {
  const { formatMessage } = useIntl();
  const { logout } = useAuthentication();
  const { id = '' } = useParams() || {};
  const { reserveProposalId } = useProposals();
  const [newProposalId, setNewProposalId] = useState('');

  function handleNewProposalId() {
    setNewProposalId(reserveProposalId);
  }

  useEffect(handleNewProposalId, []);

  const buttonClassNames = {
    ghost: 'btn btn__ghost btn__med',
    outline: 'btn btn__outline btn__med',
    primaryOutline: 'btn btn__secondary btn__med',
    primary: 'btn btn__primary btn__med',
  };

  return (
    <nav className="nav">
      <ul className="nav__links">
        {buttons?.map((button) => {
          switch (button) {
            case 'login':
              return (
                <NavButton
                  key={button}
                  className={buttonClassNames.ghost}
                  to={formatMessage({ id: 'routes.login' })}
                  testSelector="navigation-login"
                >
                  <FormattedMessage id="buttons.login" />
                </NavButton>
              );
            case 'logout':
              return (
                <React.Fragment key={button}>
                  <img
                    onClick={logout}
                    src={signoutIcon}
                    alt="Signout icon"
                    className="btn__icon btn__icon__right nav__logout--mobile"
                  />
                  <Button
                    type="button"
                    size="med"
                    style="ghost"
                    onClick={logout}
                    btnSelector="navigation-logout"
                    className="d--desktop--only"
                  >
                    <img
                      src={signoutIcon}
                      alt="Sign out icon"
                      className="btn__icon btn__icon__left"
                    />
                    <FormattedMessage id="buttons.sign-out" />
                  </Button>
                </React.Fragment>
              );
            case 'create-account':
              return (
                <NavButton
                  key={button}
                  className={buttonClassNames.primaryOutline}
                  to={formatMessage(
                    { id: 'routes.register' },
                    { proposal: id },
                  )}
                  testSelector="navigation-create-account"
                >
                  <FormattedMessage id="buttons.create-account" />
                </NavButton>
              );
            case 'dashboard':
              return (
                <NavButton
                  key={button}
                  className={buttonClassNames.primaryOutline}
                  to={formatMessage({ id: 'routes.overview' })}
                  testSelector="navigation-dashboard-btn"
                >
                  <FormattedMessage id="buttons.dashboard" />
                </NavButton>
              );
            case 'edit-proposal':
              return (
                <div key={button}>
                  <Link
                    className="nav__button__link--mobile nav__button--mobile"
                    to={formatMessage({ id: 'routes.new-proposal' }, { id })}
                  >
                    <img
                      src={createIcon}
                      alt="Create new proposal icon"
                      className="btn__icon"
                      data-cy="navigation-edit-proposal-mobile-btn"
                    />
                  </Link>
                  <NavButton
                    className={`${buttonClassNames.primary} nav__button--desktop`}
                    to={formatMessage({ id: 'routes.new-proposal' }, { id })}
                    testSelector="navigation-edit-proposal-btn"
                  >
                    <img
                      src={createIcon}
                      alt="Create new proposal icon"
                      className="btn__icon btn__icon__left"
                    />
                    <FormattedMessage id="buttons.edit-proposal" />
                  </NavButton>
                </div>
              );
            case 'create-proposal':
              return (
                <CreateNewProposalButton
                  key={button}
                  className={`${buttonClassNames.primary} nav__button-menu`}
                  to={formatMessage(
                    { id: 'routes.new-proposal' },
                    { id: newProposalId },
                  )}
                  onMouseDown={handleNewProposalId}
                >
                  <img
                    src={createIcon}
                    alt="Create Proposal Icon"
                    className="btn__icon btn__icon__left nav__button-icon"
                  />
                  <span className="nav__button-text">
                    <FormattedMessage id="buttons.create-new-proposal" />
                  </span>
                </CreateNewProposalButton>
              );
            case 'nav-dropdown':
              return (
                <li key={button} className="nav__links__item">
                  <NavDropdown />
                </li>
              );
            case 'search':
              return <NavSearch key={button} />;
            case 'pricing':
              return (
                <NavButton
                  key={button}
                  className={buttonClassNames.primaryOutline}
                  to={formatMessage({ id: 'routes.pricing' }, { id })}
                >
                  <FormattedMessage id="buttons.pricing" />
                </NavButton>
              );
            case 'pdf-export':
              return (
                <div key={button}>
                  {proposal?.publishStatus === 'published' && (
                    <PdfExportButton proposal={proposal} />
                  )}
                </div>
              );
            case 'return-to-draft':
              return (
                <ReturnToDraftPreviewButton
                  proposal={proposal}
                  key={button}
                  className="btn btn__outline btn__med nav__return-to-draft"
                />
              );
            default:
              return assertNever(button);
          }
        })}
      </ul>
    </nav>
  );
};
