import { atom } from 'recoil';

export const authErrorAtom = atom<string>({
  key: 'auth.error',
  default: '',
});

export const authLoadingAtom = atom<boolean>({
  key: 'auth.loading',
  default: false,
});
