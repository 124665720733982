import React, { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';

export const CompanyRespondToProposalsToggle: React.FC = () => {
  const { control } = useFormContext<Settings>();

  const defaultProposalResponseStatus = useWatch({
    name: 'defaultProposalResponseStatus',
  });

  const handleToggleProposalsReponse = useCallback(
    (field: ControllerRenderProps<Settings, 'defaultProposalResponseStatus'>) =>
      () => {
        field.onChange(
          defaultProposalResponseStatus === 'allowed'
            ? 'disallowed'
            : 'allowed',
        );
      },
    [defaultProposalResponseStatus],
  );

  return (
    <div className="f f--justify-between f--align-start g-32">
      <div className="f f--col g-4">
        <span className="text--xs t-text-1">
          Allow users to respond to proposals
        </span>
        <span className="t-text-3 text--sm">
          Give users permissions to Accept/Reject proposals
        </span>
      </div>
      <div className="f f--align-center">
        <label className="toggle__label">
          <Controller
            control={control}
            name="defaultProposalResponseStatus"
            render={({ field }) => (
              <input
                {...field}
                type="checkbox"
                name="defaultProposalResponseStatus"
                className="toggle__input"
                checked={defaultProposalResponseStatus === 'allowed'}
                onChange={handleToggleProposalsReponse(field)}
              />
            )}
          />
          <span
            className="toggle__span"
            data-cy="company-respond-to-proposals-toggle"
          />
        </label>
      </div>
    </div>
  );
};
