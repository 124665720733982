import {
  AppSumoModal,
  SettingsLayout,
  useConfigSelector,
} from 'modules/settings';
import {
  CurrentPlanCard,
  NotSubscribedCard,
  SelectedProduct,
  StripePlansController,
  SubscriptionSelectModal,
  subscriptionAtoms,
  subscriptionSelectors,
  useStripeProducts,
  useSubscriptionHelpers,
} from 'modules/subscription';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Button, ClipboardButton, PageLoading, SEO } from 'shared/components';
import { SELECTED_PRODUCT_LS_KEY } from 'shared/const';
import { useISOCountry, useLocalStorage, useModal } from 'shared/hooks';
import { AppSumoClipboardButton } from '../components/app-sumo-modal/fragments/AppSumoClipboardButton';

export const SubscriptionSettings: React.FC<PageProps> = ({ location }) => {
  const {
    isModalOpen: isAppSumoModalOpen,
    closeModal: closeAppSumoModal,
    openModal: openAppSumoModal,
  } = useModal();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const end = useRecoilValue(subscriptionAtoms.end);
  const active = useRecoilValue(subscriptionAtoms.active);
  const customerId = useRecoilValue(subscriptionAtoms.customerId);
  const loadingDocument = useRecoilValue(subscriptionAtoms.loadingDocument);
  const config = useConfigSelector();
  const current = useRecoilValue(
    subscriptionSelectors.getCurrentProductAndPrice,
  );
  const { isTierOne } = useSubscriptionHelpers();

  const { formatMessage } = useIntl();
  const { productsLoading } = useStripeProducts();
  const { isISOCountryLoading } = useISOCountry();

  useLocalStorage<SelectedProduct>(SELECTED_PRODUCT_LS_KEY, {
    clearStorageOnLoad: true,
  });

  function closeSubscriptionSelectModal() {
    setIsModalVisible(false);
  }

  if (productsLoading || isISOCountryLoading) {
    return (
      <>
        <PageLoading isLoading={productsLoading} />
        <StripePlansController />
      </>
    );
  }

  if (loadingDocument) return null;

  return (
    <>
      <SEO
        title={formatMessage({ id: 'settings.seo.title' })}
        description={formatMessage({ id: 'settings.seo.description' })}
        href={location.href}
      />
      <SettingsLayout showNotice={false}>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="title-settings"
        >
          <FormattedMessage id="settings.subscription.title" />
        </header>
        <div className="dashboard__section__mobile__actions">
          <header className="text--lg t-bold">
            <FormattedMessage id="settings.subscription.title" />
          </header>
        </div>
        <section className="settings">
          {customerId && (
            <>
              <ClipboardButton
                description={
                  <FormattedMessage id="settings.subscription.customer-id" />
                }
                content={customerId}
              />
              <div className="divider" />
            </>
          )}
          <>
            <div>
              <p
                className="settings__header__title text--xs t-bold"
                data-cy="appsumo-section-title"
              >
                <FormattedMessage id="settings.subscription.appsumo-title" />
              </p>
              {end !== 'permanent' ? (
                <>
                  <p className="t-eta t-text-2">
                    <FormattedMessage
                      id={
                        isTierOne
                          ? 'settings.subscription.appsumo-description-active'
                          : 'settings.subscription.appsumo-description'
                      }
                    />
                  </p>
                  <Button
                    type="button"
                    size="med"
                    style="secondary"
                    className="s-top--lrg"
                    disabled={isTierOne}
                    onClick={openAppSumoModal}
                  >
                    <FormattedMessage id="buttons.redeem" />
                  </Button>
                </>
              ) : (
                <AppSumoClipboardButton content={config?.appSumoCode || ''} />
              )}
            </div>
            <div className="divider" />
          </>
          <>
            <span className="t-bold text--xs__responsive t-text-1">
              <FormattedMessage id="settings.subscription.plan" />
            </span>
            {current ? (
              <CurrentPlanCard
                product={current.product}
                price={current.price}
                active={active}
                end={end}
                onSetModalVisible={setIsModalVisible}
              />
            ) : (
              <NotSubscribedCard onSetModalVisible={setIsModalVisible} />
            )}
          </>
        </section>
      </SettingsLayout>
      {isAppSumoModalOpen && <AppSumoModal onClose={closeAppSumoModal} />}
      {isModalVisible && (
        <SubscriptionSelectModal onClose={closeSubscriptionSelectModal} />
      )}
    </>
  );
};
