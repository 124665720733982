import { navigate } from 'gatsby';
import { authenticationSelector } from 'modules/authentication';
import { settingsSelector } from 'modules/settings';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useAuthenticationRedirects() {
  const { config } = useSelector(settingsSelector.getState);
  const { user } = useSelector(authenticationSelector.getState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!config && !user) setLoading(false);
    if (user) {
      if (config?.name && config?.address?.addressOne) {
        navigate('/dashboard/proposals/overview');
      } else {
        navigate('/onboarding');
      }
    }
  }, [user, config]);

  return { loading };
}
