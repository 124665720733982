import { useParams } from '@reach/router';
import exportPDFIcon from 'assets/icons/icon-export-pdf-12.svg';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, LoadingSpinner } from 'shared/components';
import { useAnimatedDropdown, useClickOutsideElement } from 'shared/hooks';
import { useGeneratePDF } from '../hooks';

interface Props {
  proposal?: Proposal;
}

export const PdfExportButton: React.FC<Props> = ({ proposal }) => {
  const { organizationId = '' } = useParams() || {};
  const { isOpen, handleCloseDropdownToggle, handleOpenDropdownToggle } =
    useAnimatedDropdown();
  const { loading, generatePDF } = useGeneratePDF(handleCloseDropdownToggle);
  const drawerRef = useClickOutsideElement<HTMLDivElement>(
    handleCloseDropdownToggle,
    true,
    true,
  );
  const dropdownRef = isOpen ? drawerRef : null;

  return (
    <div ref={dropdownRef} className="header__nav">
      <img
        onClick={isOpen ? handleCloseDropdownToggle : handleOpenDropdownToggle}
        src={exportPDFIcon}
        alt="Export PDF mobile icon"
        className="btn__icon pdf--export__icon--mobile"
      />

      <AnimatePresence>
        {isOpen && proposal && (
          <motion.div
            className="pdf--export__container"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2, ease: [0.33, 1, 0.68, 1] }}
          >
            <div className="pdf--export__btn__container">
              <Button
                type="button"
                style="text"
                className="text--sm__responsive t-text-1"
                onClick={() =>
                  generatePDF(organizationId, 'single-page', proposal)
                }
                btnSelector="export-pdf-singlepage-btn"
              >
                <FormattedMessage id="buttons.pdf-export-single" />
              </Button>
              <span className="t-label t-text-2 pdf--export__label">
                <FormattedMessage id="pdf-export.label.single-page" />
              </span>
            </div>
            <div className="pdf--export__btn__container">
              <Button
                type="button"
                style="text"
                className="text--sm__responsive t-text-1"
                onClick={() =>
                  generatePDF(organizationId, 'multi-page', proposal)
                }
                btnSelector="export-pdf-multipage-btn"
              >
                <FormattedMessage id="buttons.pdf-export-multipage" />
              </Button>
              <span className="t-label t-text-2 pdf--export__label">
                <FormattedMessage id="pdf-export.label.multi-page" />
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <Button
        onClick={isOpen ? handleCloseDropdownToggle : handleOpenDropdownToggle}
        style="outline"
        size="med"
        className={`d--desktop--only nav__links__pdf--export ${
          isOpen && 'btn__open'
        }`}
        btnSelector="export-pdf-btn"
        disabled={loading}
      >
        {loading ? (
          <div className="btn__icon btn__icon__left">
            <LoadingSpinner height={12} width={12} type="primary" />
          </div>
        ) : (
          <img
            src={exportPDFIcon}
            alt="Export PDF icon"
            className="btn__icon btn__icon__left"
          />
        )}
        <FormattedMessage id="buttons.pdf-export" />
      </Button>
    </div>
  );
};
