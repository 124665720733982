import {
  isFileSegment,
  isHeadingSegment,
  isMediaSegment,
  isServiceSegment,
  isTextSegment,
} from 'modules/proposals';
import { subscriptionSelectors } from 'modules/subscription';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { FileSegmentCard } from './file-segment-card';
import { HeadingSegmentCard } from './heading-segment-card';
import { MediaSegmentCard } from './media-segment-card';
import { ServiceSegmentCard } from './service-segment-card';
import { TextSegmentCard } from './text-segment-card';

interface Props {
  item: Partial<ProposalSegment & Record<'id', string>>;
  index: number;
  provided: DraggableProvided;
  segmentsFileSize: number;
  segmentsLength: number;
  addSegment: (type: ProposalSegment['type'], index: number) => void;
  deleteSegment: (index: number) => void;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

export const SegmentRenderer: React.FC<Props> = ({
  item,
  provided,
  moveItem,
  ...rest
}) => {
  let Component = null;

  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const context = useFormContext<PublishStatusAware<Proposal>>();

  if (isServiceSegment(item)) {
    Component = (
      <ServiceSegmentCard
        provided={provided}
        context={context}
        item={item}
        moveItem={moveItem}
        {...rest}
      />
    );
  }

  if (isHeadingSegment(item)) {
    Component = (
      <HeadingSegmentCard
        provided={provided}
        context={context}
        item={item}
        moveItem={moveItem}
        {...rest}
      />
    );
  }

  if (isTextSegment(item)) {
    Component = (
      <TextSegmentCard
        provided={provided}
        context={context}
        item={item}
        moveItem={moveItem}
        {...rest}
      />
    );
  }

  if (isTierOne) {
    if (isFileSegment(item)) {
      Component = (
        <FileSegmentCard
          provided={provided}
          context={context}
          moveItem={moveItem}
          {...rest}
        />
      );
    }

    if (isMediaSegment(item)) {
      Component = (
        <MediaSegmentCard
          provided={provided}
          context={context}
          item={item}
          moveItem={moveItem}
          {...rest}
        />
      );
    }
  }

  return (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
      className="proposal-create__service-list__item"
    >
      {Component}
    </div>
  );
};
