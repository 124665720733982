import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

export function useConnectionErrorHandling() {
  const location = window.location.href;
  const isNavigatorOnline = window.navigator.onLine;
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(
    isNavigatorOnline ? 'online' : 'offline',
  );
  const networkStatusRef = useRef(networkStatus);
  const { formatMessage } = useIntl();
  const toastId = useRef<React.ReactText>();

  window.addEventListener('online', () => {
    setNetworkStatus('online');
  });

  window.addEventListener('offline', () => {
    setNetworkStatus('offline');
    networkStatusRef.current = 'offline';
  });

  const handleNetworkStatusChange = () => {
    if (networkStatusRef.current === 'online') {
      return;
    }
    if (networkStatus === 'offline' && location.includes('dashboard')) {
      toastId.current = toast(formatMessage({ id: 'toasts.connection.lost' }));
    }
    if (networkStatus === 'online' && location.includes('dashboard')) {
      toast.dismiss(toastId.current);
      toast.success(formatMessage({ id: 'toasts.connection.regained' }));
    }
  };

  useEffect(handleNetworkStatusChange, [networkStatus]);

  return networkStatus;
}
