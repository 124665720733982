import { useFormGuard } from 'modules/form';
import { Settings } from 'modules/settings';
import { subscriptionSelectors } from 'modules/subscription';
import React, { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { CompanyDefaultCurrency } from './company-default-currency';
import { CompanyRespondToProposalsToggle } from './company-respond-to-proposals-toggle';
import { CompanyServicePricesVisibilityToggle } from './company-service-prices-visibility-toggle';

interface Props extends PropsWithChildren {
  config: Settings;
  formClassName?: string;
  onSubmit: (data: Settings) => void;
}

export const CompanyProposalSettings: React.FC<Props> = ({
  config,
  formClassName,
  children,
  onSubmit,
}) => {
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const form = useForm<Settings>({
    mode: 'onSubmit',
    defaultValues: {
      ...config,
    },
  });
  useFormGuard(form);

  return (
    <FormProvider {...form}>
      <form
        className={formClassName || ''}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <CompanyDefaultCurrency />
        <div className="divider" />
        <CompanyServicePricesVisibilityToggle />
        {isTierOne && (
          <>
            <div className="divider" />
            <CompanyRespondToProposalsToggle />
          </>
        )}
        {children}
      </form>
    </FormProvider>
  );
};
