import infoIcon from 'assets/icons/icon-info-16.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ProtectedDraftTooltip: React.FC = () => {
  return (
    <div className="info-tooltip__container">
      <img
        src={infoIcon}
        alt="Info icon"
        className="visibility-selector-modal__icon"
      />
      <div className="info-tooltip">
        <p className="info-tooltip__content">
          <FormattedMessage id="proposal-acceptance-controller.protected-draft-tooltip" />
        </p>
        <div className="info-tooltip__arrow" />
      </div>
    </div>
  );
};
