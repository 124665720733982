import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  onSetModalVisible: (visible: boolean) => void;
}

export const NotSubscribedCard: React.FC<Props> = ({ onSetModalVisible }) => (
  <div className="u-maxwidth--500 s-top--sml subscription-settings-plan">
    <p className="s-bottom--sml text--xs__responsive t-text-1 t-bold">
      <FormattedMessage id="not-subscribed-card.title" />
    </p>
    <p className="t-text-2 s-bottom--med text--xsm">
      <FormattedMessage id="not-subscribed-card.description" />
    </p>
    <Button
      type="button"
      size="med"
      style="primary"
      onClick={() => onSetModalVisible(true)}
      className="u-fit--content"
      btnSelector="not-subscribed-btn"
    >
      <FormattedMessage id="not-subscribed-card.action" />
    </Button>
  </div>
);
