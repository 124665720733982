import { selector } from 'recoil';
import { mobileNavDropdownVisibleAtom, mobileSearchVisibleAtom } from './atoms';

export const mobileNavDropdownVisibleSelector = selector<boolean>({
  key: 'mobileNavDropdownVisibleSelector',
  get: ({ get }) => get(mobileNavDropdownVisibleAtom),
  set: ({ set }, newValue) => set(mobileNavDropdownVisibleAtom, newValue),
});

export const mobileSearchVisibleSelector = selector<boolean>({
  key: 'mobileSearchVisibleSelector',
  get: ({ get }) => get(mobileSearchVisibleAtom),
  set: ({ set }, newValue) => set(mobileSearchVisibleAtom, newValue),
});
