import { atom } from 'recoil';

export const configAtom = atom<Settings | null>({
  key: 'config',
  default: undefined,
});

export const arePresetsDirtyAtom = atom<boolean>({
  key: 'arePresetsDirty',
  default: false,
});

export const customPresetsAtom = atom<CustomPreset[]>({
  key: 'customPresets',
  default: [],
});

export const isOnboardingLastStepAtom = atom<boolean>({
  key: 'isOnboardingLastStep',
  default: false,
});

export const proposalOwnerCountryAtom = atom<string | null>({
  key: 'proposalOwnerCountry',
  default: null,
});
