import { onAuthStateChanged } from 'firebase/auth';
import {
  isLegacyFreeUserAtom,
  useAuthorizationDispatch,
  useSetOrganizationId,
} from 'modules/authorization';
import { auth } from 'modules/firebase';
import { configAtom } from 'modules/settings';
import { useEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { isRole, isRoleClaim } from '../type-guards';
import { useAuthDispatch } from './useAuthDispatch';

// This is the timestamp of the release of the free trial feature
// November 17, 2024
const FREE_TRIAL_RELEASE = 1731798000000;

export function useAuthSubscription() {
  const authenticationDispatch = useAuthDispatch();
  const authorizationDispatch = useAuthorizationDispatch();
  const setOrganizationId = useSetOrganizationId();
  const resetConfig = useResetRecoilState(configAtom);
  const setIsLegacyFreeUser = useSetRecoilState(isLegacyFreeUserAtom);

  useEffect(() => {
    const subscription = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        resetConfig();
        authenticationDispatch.logout();
        return;
      }

      const token = await user.getIdTokenResult();
      const roles: RoleClaim[] | Partial<RoleClaim>[] =
        token.claims.roles && isRoleClaim(token.claims.roles)
          ? token.claims.roles
          : [{ role: 'owner', organizationId: user.uid }];

      const claimAdminRole = isRole(token.claims?.adminRole)
        ? token.claims?.adminRole
        : null;

      const adminRole: Role =
        process.env.NODE_ENV === 'development' ? 'superAdmin' : claimAdminRole;

      /**Not needed until we have multitenancy */
      // const [primaryRole] = roles;
      const userCreationTimeMs = new Date(
        user.metadata.creationTime || '',
      ).getTime();
      setIsLegacyFreeUser(userCreationTimeMs < FREE_TRIAL_RELEASE);
      authorizationDispatch.updateUserRoles(roles);
      authorizationDispatch.updateAdminRole(adminRole);

      setOrganizationId(user.uid || 'public');
      authenticationDispatch.login(user.toJSON());
    });

    return () => {
      subscription();
    };
  }, []);
}
