import { DualPrice, Price, SinglePrice, formatPrice } from 'modules/proposals';
import React from 'react';

interface Props {
  currency: string;
  totalAmount: number;
  exchangeRate?: number;
  ISOCountry?: string;
}

export const TotalPublicProposalPrice: React.FC<Props> = ({
  currency,
  totalAmount,
  exchangeRate,
  ISOCountry,
}) => (
  <Price
    currency={currency}
    dualPriceElement={
      <DualPrice>
        {exchangeRate && (
          <h3 className="text--xxl__responsive">
            <strong>
              {formatPrice(totalAmount / exchangeRate, 'eur', ISOCountry)}
            </strong>
          </h3>
        )}

        <p className="o-60 t-eta">
          {formatPrice(totalAmount, currency, ISOCountry)}
        </p>
      </DualPrice>
    }
    singlePriceElement={
      <SinglePrice>
        <h3 className="text--xxl__responsive">
          <strong>{formatPrice(totalAmount, currency, ISOCountry)}</strong>
        </h3>
      </SinglePrice>
    }
  />
);
