import React from 'react';

interface Props {
  title: string;
  metric: string | number;
}

export const ProposalStatisticsItem: React.FC<Props> = ({ title, metric }) => {
  return (
    <div className="proposal-statistics-item__container">
      <div className="f f--col g-4">
        <span className="text--xs__responsive t-text-2">{title}</span>
        <p className="text--xl__responsive t-bold t-text-1">{metric}</p>
      </div>
    </div>
  );
};
