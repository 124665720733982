import { useIntl } from 'react-intl';

export function useSupportMail() {
  const { formatMessage } = useIntl();

  const supportMail = formatMessage(
    { id: 'email.mailto' },
    { email: formatMessage({ id: 'email.support' }) },
  );

  return supportMail;
}
