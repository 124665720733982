import { settingsSelector } from 'modules/settings';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Button,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';

interface Props extends ModalProps {
  onSetCurrentLocalStep: (currentLocalStep: deleteAccountSteps) => void;
}

export const DeleteAccountInfoModal: React.FC<Props> = ({
  onSetCurrentLocalStep,
  onClose,
}) => {
  const { config } = useSelector(settingsSelector.getState);
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();

  return (
    <>
      <ModalHeader>
        <p className="account-delete-modal__header text--xl__responsive">
          <FormattedMessage id="account-settings.delete-info-modal.title" />
        </p>
      </ModalHeader>
      <ModalContent>
        <p className="t-text-2 text--xs__responsive">
          <FormattedMessage
            id="account-settings.delete-info-modal.description"
            values={{
              companyName: <b>{config && config.name}</b>,
            }}
          />
        </p>
        <ul className="list--bullets s-top--med t-text-2 account-delete-modal__list">
          <li>
            <FormattedMessage id="account-settings.delete-info-modal.description.bullet1" />
          </li>
          <li>
            <FormattedMessage id="account-settings.delete-info-modal.description.bullet2" />
          </li>
        </ul>
        <div className="divider-16" />
        <p className="text--xsm t-note-0 u-maxwidth--320__responsive">
          <FormattedMessage id="account-settings.delete-info-modal.pdf-notice" />
        </p>
      </ModalContent>

      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            size="lrg"
            style="outline"
            onClick={onClose}
            ref={closeButtonRef}
            btnSelector="cancel-btn"
          >
            <FormattedMessage id="account-settings.delete-modal.action.cancel" />
          </Button>
          <Button
            size="lrg"
            style="destructive"
            onClick={() => onSetCurrentLocalStep('confirm')}
            ref={submitButtonRef}
            btnSelector="info-modal-delete-account-btn"
          >
            <FormattedMessage id="buttons.confirm" />
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
