export function convertDate(end?: number | null) {
  if (!end) return null;
  const formattedDate = new Date(end);

  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(formattedDate);
}
