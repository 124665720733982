import { getServiceDescriptionText } from 'shared/utils';

/**
 * Determines if a text segment should be visible.
 *
 * This function takes a `TextSegment` object and a boolean indicating whether the current view is a preview.
 * If the view is not a preview, the function returns true.
 * If the view is a preview, the function checks if the segment has text content, and returns true if it does, and false otherwise.
 *
 * @param segment - The `TextSegment` object to check.
 * @param isPreview - A boolean indicating whether the current view is a preview.
 * @returns A boolean indicating whether the text segment should be visible.
 */
export function isTextSegmentVisible(
  segment: TextSegment,
  isPreview?: boolean,
): boolean {
  if (!isPreview) return true;

  const hasText = Boolean(getServiceDescriptionText(segment.content));
  return hasText;
}
