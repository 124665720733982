import React, { PropsWithChildren } from 'react';
import { IntlProvider as IntlWrapper } from 'react-intl';
import { flattenObject } from 'shared/utils';

// TODO: Remove this once messages are added thropugh redux
import messages from 'modules/i18n/languages/en.json';

export const IntlProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // TODO: Replace with real props, remove placeholder
  const config = {
    messages: flattenObject(messages),
    locale: 'en',
    defaultLocale: 'en',
  };

  return <IntlWrapper {...config}>{children}</IntlWrapper>;
};
