import { useLocation } from '@reach/router';
import { SettingsLayout } from 'modules/settings';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import {
  AddIcon,
  Button,
  CreateProposalButton,
  DraggableList,
  ListError,
  SEO,
} from 'shared/components';
import { useCloseModalOnCleanup } from 'shared/hooks';
import { UnitListError, UnitModal } from '../components';
import { isUnitModalOpen } from '../state';

interface FormValues {
  units?: Unit[];
}

interface Props {
  isLoading: boolean;
  documents?: Unit[];
  error?: string;
}

/**TODO @markoboras0712
 * check with design team why search is removed
 */

export const Units: React.FC<Props> = ({ isLoading, documents, error }) => {
  const { formatMessage } = useIntl();
  const { href } = useLocation();

  // const [query, setQuery] = useState('');

  const [modalOpen, setModalOpen] = useRecoilState(isUnitModalOpen);
  const form = useForm<FormValues>({
    defaultValues: { units: documents },
  });

  // const units = useMemo(() => {
  //   return useFuseObjectSearch(['name'], query, documents);
  // }, [documents, query]);

  useCloseModalOnCleanup(setModalOpen);

  return (
    <>
      <SEO
        title={formatMessage({ id: 'units.seo.title' })}
        description={formatMessage({ id: 'units.seo.description' })}
        href={href}
      />

      <SettingsLayout isManageSection>
        <FormProvider {...form}>
          <header
            className="dashboard__section__header__title text--xs t-bold"
            data-cy="units-title"
          >
            <FormattedMessage id="units.title" />
          </header>
          <div className="manage__header__action__container">
            <Button
              type="button"
              onClick={() => setModalOpen(true)}
              style="secondary"
              size="lrg"
              className="manage__header__action"
              btnSelector="add-unit-btn"
            >
              <AddIcon />
              <span className="d--desktop--only">
                <FormattedMessage id="buttons.add-new-unit" />
              </span>
            </Button>
            <CreateProposalButton className="d--desktop--only" />
          </div>
          <div className="dashboard__section__mobile__actions">
            <header className="text--xs t-bold" data-cy="units-mobile-title">
              <FormattedMessage
                id="units.mobile-title"
                values={{
                  length: documents?.length,
                }}
              />
            </header>
            {Boolean(documents?.length) && (
              <div className="f f--align-center">
                <Button
                  type="button"
                  onClick={() => setModalOpen(true)}
                  style="secondary"
                  size="med"
                  btnSelector="add-unit-mobile-btn"
                >
                  <span className="d--mobile--only">
                    <FormattedMessage id="buttons.add-new-unit" />
                  </span>
                </Button>
              </div>
            )}
          </div>

          {/**TODO @markoboras0712 in new design we've removed search, check with design team why */}
          {/* <div className="manage__header__container">
            {Boolean(documents?.length) && (
              <div className="manage__actions__container">
                <ListSearch
                  query={query}
                  onSetQuery={setQuery}
                  recentSearchLocalStorageKey={UNIT_RECENT_SEARCHES_LS_KEY}
                  selector="units"
                />
              </div>
            )}
          </div> */}
          {Boolean(documents?.length) && (
            <div className="dashboard__section__actions">
              <span className="t-text-2 text--sm">
                <FormattedMessage
                  id="units.length"
                  values={{
                    length: documents?.length,
                  }}
                />
              </span>
            </div>
          )}
          <DraggableList
            // query={query}
            documents={documents}
            // results={units}
            isLoading={isLoading}
            error={error}
            searchErrorComponent={ListError}
            errorComponent={UnitListError}
            className="unit__list__container"
            errorClassName="dashboard__section__error__container"
            loadingType="list"
            onClick={() => setModalOpen(true)}
          />
        </FormProvider>
      </SettingsLayout>

      {modalOpen && <UnitModal onClose={() => setModalOpen(false)} />}
    </>
  );
};
