import { atom } from 'recoil';

const overviewQuery = atom<string>({
  key: 'overview-query',
  default: '',
});

const sortOption = atom<SortOption>({
  key: 'sort-option',
  default: 'newest',
});

const filtersOption = atom<Filter[]>({
  key: 'filters-option',
  default: [],
});

const isPlusUpgradeModalOpen = atom<boolean>({
  key: 'is-plus-upgrade-modal-open',
  default: false,
});

export const overviewAtoms = {
  overviewQuery,
  sortOption,
  filtersOption,
  isPlusUpgradeModalOpen,
};
