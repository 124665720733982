import infoIcon from 'assets/icons/icon-info-16.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const CustomTagTooltip: React.FC = () => {
  return (
    <div className="custom-tags-tooltip__container">
      <img
        src={infoIcon}
        alt="Info icon"
        className="visibility-selector-modal__icon"
      />
      <div className="custom-tags-tooltip">
        <span className="text--xxs__responsive">
          <FormattedMessage id="inputs.custom-tags.tooltip" />
        </span>
        <div className="custom-tags-tooltip__arrow" />
      </div>
    </div>
  );
};
