export interface Settings extends CompanyInfo {
  address: Address;
  tax: Tax;
  storageLeftInBytes: number | null;
  customPresets?: CustomPreset[] | null;
  isTrustPilotFollowUpEmailSent?: boolean | null;
  referral?: ReferralSource | null;
  isSubscribedToNewsletter?: boolean | null;
  hasUnsubscribedOnOnboarding?: boolean | null;
  appSumoCode?: string | null;
  defaultCurrency?: string | null;
  defaultProposalResponseStatus?: 'allowed' | 'disallowed';
  defaultServicePricesVisibility?: 'hidden' | 'visible';
}
export class Settings {
  constructor(data?: Settings) {
    this.name = data?.name || '';
    this.companyImage = data?.companyImage || null;
    this.isOnboardingInProgress = Boolean(data?.isOnboardingInProgress);
    this.storageLeftInBytes =
      data?.storageLeftInBytes || 1 * 1024 * 1024 * 1024; // 1GB;
    this.customPresets = data?.customPresets || null;
    this.isTrustPilotFollowUpEmailSent =
      data?.isTrustPilotFollowUpEmailSent || null;
    this.referral = data?.referral || null;
    this.isSubscribedToNewsletter = data?.isSubscribedToNewsletter || false;
    this.hasUnsubscribedOnOnboarding =
      data?.hasUnsubscribedOnOnboarding || false;
    this.appSumoCode = data?.appSumoCode || null;
    this.defaultCurrency = data?.defaultCurrency || null;
    this.defaultProposalResponseStatus =
      data?.defaultProposalResponseStatus || 'allowed';
    this.defaultServicePricesVisibility =
      data?.defaultServicePricesVisibility || 'visible';

    this.address = {
      addressOne: data?.address.addressOne || '',
      city: data?.address.city || '',
      postalCode: data?.address.postalCode || '',
      addressTwo: data?.address.addressTwo || null,
      country: data?.address.country || null,
      state: data?.address.state || null,
    };

    this.tax = {
      vatId: data?.tax.vatId || null,
      vatPercentage: data?.tax.vatPercentage || 0,
    };
  }
}
