import { isFileSegment, isMediaSegment } from 'modules/proposals';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export function useSegmentsFileSize() {
  const segments = useWatch({ name: 'segments' });

  const segmentsFileSize = useMemo(() => {
    return segments
      .flatMap((segment: ProposalSegment) => {
        if (isFileSegment(segment)) {
          return segment.files.map((file) => file.size);
        }
        if (isMediaSegment(segment)) {
          return segment.files.map((media) => media.size);
        }
        return [];
      })
      .reduce((acc: number, size: number) => acc + size, 0);
  }, [segments]);

  return segmentsFileSize;
}
