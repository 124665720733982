import React, { useState } from 'react';
import { PublicMediaSegmentListItemControls } from './PublicMediaSegmentListItemControls';

interface Props {
  file: ProposalFile;
  index: number;
}

export const PublicMediaSegmentListItemVideo: React.FC<Props> = ({
  file,
  index,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div
      className={`media-input__image ${
        isExpanded
          ? 'media-input__image--expanded'
          : 'media-input__image--collapsed'
      }`}
    >
      <PublicMediaSegmentListItemControls
        file={file}
        index={index}
        isExpanded={isExpanded}
        onSetIsExpanded={setIsExpanded}
      />
      <video className="media-input__list__image" controls draggable="false">
        <source src={file.url} />
        Your browser does not support the video element.
      </video>
    </div>
  );
};
