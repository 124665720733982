import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';
import { Button } from '../button';
import { Modal } from './Modal';
import { ModalContent } from './ModalContent';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';

interface Props extends ModalProps {
  title: string;
  name?: string;
  onConfirm: VoidFunction;
  delSelector?: string;
}

export const DeleteModal: React.FC<Props> = ({
  onClose,
  onConfirm,
  title,
  name,
  delSelector,
}) => {
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();

  return (
    <Modal close={onClose} isDashboardModal>
      <ModalHeader>
        {name ? (
          <p className="text--xl__responsive">
            <FormattedMessage
              id="modals.delete.title"
              values={{
                name,
              }}
            />
          </p>
        ) : (
          <p className="text--xl__responsive">
            <FormattedMessage id="modals.delete.section-title" />
          </p>
        )}
      </ModalHeader>
      <ModalContent>
        <p className="text--xs__responsive t-text-2">
          {name ? (
            <>
              <FormattedMessage
                id="modals.delete.subtitle"
                values={{
                  name,
                }}
              />{' '}
              <strong>{title}</strong>?
            </>
          ) : (
            <>
              <FormattedMessage id="modals.delete.section-subtitle" /> {title}?
            </>
          )}
        </p>
      </ModalContent>
      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            type="button"
            ref={closeButtonRef}
            onClick={onClose}
            style="outline"
            size="lrg"
            btnSelector="keep-it-btn"
          >
            <FormattedMessage id="modals.delete.decline" />
          </Button>
          <Button
            ref={submitButtonRef}
            type="submit"
            onClick={onConfirm}
            style="destructive"
            size="lrg"
            btnSelector={delSelector}
          >
            <FormattedMessage id="modals.delete.confirm" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
