import React, { PropsWithChildren } from 'react';
import { Logo, Navigation } from 'shared/components';

interface FooterComponentsProps {
  className?: string;
}

interface HeaderComponentsProps {
  currentStep: number;
}

interface Props extends PropsWithChildren {
  rootUrl: string;
  contentClassName?: string;
  containerClassName?: string;
  brandingClassName?: string;
  headerClassName?: string;
  navButtons?: NavigationButtons[];
  footerComponent?: React.FC<FooterComponentsProps>;
  footerClassName?: string;
  headerComponent?: React.FC<HeaderComponentsProps>;
  headerCurrentStep?: number;
}

export const PublicLayout: React.FC<Props> = ({
  rootUrl,
  contentClassName,
  containerClassName = 'container',
  headerClassName,
  navButtons,
  brandingClassName,
  footerComponent: FooterComponent,
  headerComponent: HeaderComponent,
  footerClassName,
  headerCurrentStep,
  children,
}) => (
  <>
    <div className={`${containerClassName || ''} container__content`}>
      <header className={`${headerClassName || ''} header header--visible`}>
        <div className={`header__branding ${brandingClassName || ''}`}>
          <Logo navigationUrl={rootUrl} shouldTransform />
        </div>
        {HeaderComponent && (
          <HeaderComponent currentStep={headerCurrentStep || 1} />
        )}
        <div className="header__nav">
          <Navigation buttons={navButtons} />
        </div>
      </header>
      <main className={`container__main-content ${contentClassName || ''}`}>
        <>{children}</>
      </main>
      {FooterComponent && <FooterComponent className={footerClassName} />}
    </div>
    <div id="global-modal" />
  </>
);
