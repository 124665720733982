import { generateColor } from 'modules/proposals';
import { useStyledProposal } from 'modules/subscription';
import React, { useMemo } from 'react';
import { DashboardFooter, PreviewLayout, SEO } from 'shared/components';
import { PublicProposalInfo } from '../public-proposal-info';

interface Props {
  organizationId: string;
  hasPermissions: boolean;
  isUnlocked: boolean;
  proposal: Proposal;
}

export const PublicProposalController: React.FC<Props> = ({
  organizationId,
  hasPermissions,
  isUnlocked,
  proposal,
}) => {
  const { styledProposal } = useStyledProposal(proposal);
  const isAuthorized = useMemo(
    () => proposal?.visibility === 'protected' && isUnlocked,
    [proposal?.visibility, isUnlocked],
  );

  function getNavButtons(): NavigationButtons[] {
    const { publishStatus } = proposal;
    if (hasPermissions && publishStatus !== 'archived') {
      return ['edit-proposal', 'pdf-export'];
    }
    if (hasPermissions && publishStatus === 'archived') {
      return ['return-to-draft', 'pdf-export'];
    }
    return ['pdf-export'];
  }

  return (
    <>
      <SEO
        title={proposal.name || ''}
        description={`Proposal ${proposal.name} | ${proposal?.client?.name}`}
      />
      <PreviewLayout
        footerComponent={DashboardFooter}
        navButtons={getNavButtons()}
        organizationId={organizationId}
        proposal={proposal}
        containerId="public-proposal__container"
        contentClassName="public-proposal__wrapper"
        headerClassName="public-proposal__header"
        inlineStyles={{
          backgroundColor: generateColor('background', proposal),
        }}
        isToCVisible
      >
        <PublicProposalInfo
          proposal={styledProposal}
          organizationId={organizationId}
          logoUrl={proposal?.companyInfo?.companyImage}
          hasPermissions={hasPermissions}
          authorized={isAuthorized}
          validUntilDateTime={proposal?.validUntilDateTime}
        />
      </PreviewLayout>
    </>
  );
};
