import { customPresetsAtom } from 'modules/settings';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { AccordionType } from '../components';

export function useProposalStyle(
  proposal: PublishStatusAware<Proposal> | Proposal,
) {
  const customPresets = useRecoilValue(customPresetsAtom);
  const currentPreset = useWatch({ name: 'currentPreset' });

  const [expandedAccordions, setExpandedAccordions] = useState<AccordionType[]>(
    [],
  );

  const { setValue } = useFormContext<PublishStatusAware<Proposal>>();

  function handleToggle(type: AccordionType) {
    setExpandedAccordions((prev) =>
      prev.includes(type)
        ? prev.filter((item) => item !== type)
        : [...prev, type],
    );
  }

  function handlePreset(preset: 'default' | 'dark' = 'default') {
    setValue('currentPreset', preset, {
      shouldDirty: true,
    });
  }

  /**Update proposal style based on currently active preset */
  useEffect(() => {
    if (currentPreset === 'default') {
      setValue('style', {
        ...proposal.style,
        color: customPresets[0].color,
        typography: customPresets[0].typography,
      });
      return;
    }

    if (currentPreset === 'dark') {
      setValue('style', {
        ...proposal.style,
        color: customPresets[1].color,
        typography: customPresets[1].typography,
      });
      return;
    }
  }, [currentPreset]);

  return {
    currentPreset,
    expandedAccordions,
    handlePreset,
    handleToggle,
  };
}
