import { useState } from 'react';

export function useProposalSummary() {
  const [view, setView] = useState<ProposalSummaryView>('table-of-contents');

  function toggleView(newView: ProposalSummaryView) {
    setView(newView);
  }

  return {
    view,
    toggleView,
  };
}
