import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, FieldWrapper } from 'shared/components';
import { emailRegex } from 'shared/utils';

interface FormValues {
  email?: string;
  uid?: string;
}

interface Props {
  onSearch: (values: { email?: string; uid?: string }) => void;
  onReset: VoidFunction;
}

export const UsersSearch: React.FC<Props> = ({ onSearch, onReset }) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const form = useForm<FormValues>();

  const handleReset = () => {
    form.reset();
    onReset();
  };

  return (
    <FormProvider {...form}>
      <form className="s-bottom--xlrg" onSubmit={form.handleSubmit(onSearch)}>
        <div className="s-bottom--med f f--col">
          <div className="f--one s-bottom--med">
            <FieldWrapper
              name="uid"
              errors={form.formState.errors}
              labelId="admin.search.uid.label"
            >
              <input
                className={`input input__med input__box ${
                  form.formState.errors.uid ? 'input__box__error' : ''
                }`}
                placeholder={formatMessage({
                  id: 'admin.search.uid.placeholder',
                })}
                type="text"
                data-cy="admin-search-uid-input"
                onFocus={handleFocus}
                {...form.register('uid')}
              />
            </FieldWrapper>
          </div>
          <div className="f--one">
            <FieldWrapper
              name="email"
              errors={form.formState.errors}
              labelId="admin.search.email.label"
            >
              <input
                className={`input input__med input__box ${
                  form.formState.errors.email ? 'input__box__error' : ''
                }`}
                placeholder={formatMessage({
                  id: 'admin.search.email.placeholder',
                })}
                type="text"
                data-cy="admin-search-email-input"
                onFocus={handleFocus}
                {...form.register('email', {
                  pattern: {
                    value: emailRegex,
                    message: formatMessage({ id: 'inputs.email.invalid' }),
                  },
                })}
              />
            </FieldWrapper>
          </div>
        </div>

        <Button
          type="submit"
          style="primary"
          size="lrg"
          className="s-right--med"
          btnSelector="admin-users-search-btn"
        >
          <FormattedMessage id="admin.search.search.label" />
        </Button>
        <Button
          type="reset"
          style="ghost"
          size="lrg"
          btnSelector="admin-users-reset-btn"
          onClick={handleReset}
        >
          <FormattedMessage id="admin.search.reset.label" />
        </Button>
      </form>
    </FormProvider>
  );
};
