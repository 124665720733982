import downIcon from 'assets/icons/icon-move-down-16.svg';
import upIcon from 'assets/icons/icon-move-up-16.svg';
import React from 'react';

interface Props {
  index: number;
  segmentsLength: number;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

export const MobileSegmentMover: React.FC<Props> = ({
  index,
  segmentsLength,
  moveItem,
}) => {
  return (
    <div className="s-top--med d--mobile--only">
      <div
        className={`proposal-service__up--mobile ${
          index === 0 && 'proposal-service__move--disabled'
        }`}
        onClick={() => moveItem(index, 'up')}
        data-cy={`drag-and-drop-btn-${index}`}
      >
        <img src={upIcon} alt="DragIcon" />
      </div>
      <div
        className={`proposal-service__down--mobile ${
          index === segmentsLength - 1 && 'proposal-service__move--disabled'
        }`}
        onClick={() => moveItem(index, 'down')}
        data-cy={`drag-and-drop-btn-${index}`}
      >
        <img src={downIcon} alt="DragIcon" />
      </div>
    </div>
  );
};
