export function sortAscendingFromOldest<
  T extends { createdAt?: number; creationDateTime?: number },
>(a: T, b: T) {
  if (a?.createdAt && b?.createdAt) {
    return a.createdAt - b.createdAt;
  }
  if (a.creationDateTime && b.creationDateTime) {
    return a.creationDateTime - b.creationDateTime;
  }
  return 0;
}
