import { authorizationAction } from './actions';

interface ReducerState {
  readonly isLoading: boolean;
  readonly documents?: OrganizationUser[];
  readonly roles?: RoleClaim[] | Partial<RoleClaim>[];
  readonly error?: FirestoreError;
  readonly adminRole?: Role;
}

const INITIAL_STATE: ReducerState = {
  isLoading: false,
  documents: undefined,
  roles: undefined,
  error: undefined,
  adminRole: undefined,
};

export const authorizationReducer = (
  state: ReducerState = INITIAL_STATE,
  action:
    | ActionUnion<typeof authorizationAction>
    | Action<'authentication/LOGOUT'>,
): ReducerState => {
  switch (action.type) {
    case 'authorization/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'authorization/ON_SNAPSHOT_UPDATE':
      return {
        ...state,
        isLoading: false,
        documents: action.payload.documents,
      };
    case 'authorization/UPDATE_USER_ROLES':
      return {
        ...state,
        isLoading: false,
        roles: action.payload.roles,
      };
    case 'authorization/ON_SNAPSHOT_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case 'authorization/UPDATE_ADMIN_ROLE':
      return {
        ...state,
        isLoading: false,
        adminRole: action.payload.adminRole,
      };
    case 'authentication/LOGOUT':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
