import {
  usePublicProposal,
  useSetClientEmailSelector,
} from 'modules/proposals';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, LoadingSpinner } from 'shared/components';
import { useSubmitOnEnter } from 'shared/hooks';
import { VALIDATORS } from 'shared/utils';
import { toast } from 'sonner';

interface AuthorizationCodeFormProps {
  proposalId: string;
  organizationId: string;
  /** An additional async check to verify the entered email (after regex validation) */
  verifyEmail?: (email: string) => Promise<boolean>;
  /** Happens when the user has authorized successfully */
  onAuthorized: VoidFunction;
  /** Happens when the authorization code is sent to the email */
  onCodeSent?: VoidFunction;
  /** Shows back button in the first step of the flow */
  onCancel?: VoidFunction;
}

export const AuthorizationCodeForm: React.FC<AuthorizationCodeFormProps> = ({
  proposalId,
  organizationId,
  onAuthorized,
  verifyEmail,
  onCodeSent,
  onCancel,
}) => {
  const { formatMessage } = useIntl();
  const [error, setError] = useState<string>();
  const { sendAuthorizationCode, verifyAuthorizationCode } =
    usePublicProposal();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [blacklisted, setBlacklisted] = useState<string[]>([]);
  const [step, setStep] = useState<'get-code' | 'authorize'>('get-code');
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const setClientEmail = useSetClientEmailSelector();

  async function onGetCodeSubmit() {
    if (!VALIDATORS.email.test(email)) {
      setError(
        formatMessage({
          id: 'authorization-code-form.get-code.error.invalid-email',
        }),
      );
      return;
    }

    setLoading(true);
    if (verifyEmail) {
      const verified = await verifyEmail(email);
      if (!verified) {
        setBlacklisted([...blacklisted, email]);
        setLoading(false);
        setError(
          formatMessage({
            id: 'authorization-code-form.get-code.error.no-access',
          }),
        );
        return;
      }
    }

    sendAuthorizationCode({ proposalId, organizationId, email })
      .then((sent) => {
        if (!sent) return;
        setStep('authorize');
        onCodeSent?.();
      })
      .finally(() => setLoading(false));
  }

  function onAuthorizeCodeSubmit() {
    setLoading(true);

    verifyAuthorizationCode({ proposalId, organizationId, code, email })
      .then((verified) => {
        if (!verified) {
          setCode('');
          setError(
            formatMessage({
              id: 'authorization-code-form.authorize.error.wrong-code',
            }),
          );
          return;
        }

        onAuthorized();
      })
      .finally(() => setLoading(false));
  }

  function onResendCode() {
    setLoading(true);
    sendAuthorizationCode({ proposalId, organizationId, email }).then(() => {
      toast.success('Your code has been resent. Please check your email.');
      setLoading(false);
    });
  }

  return (
    <div className="f--col u-width--full">
      {step === 'get-code' && (
        <>
          <div className="f f--col">
            <p
              className={`s-bottom--tny t-label t-text-3 ${
                error && 't-label--error'
              }`}
            >
              <FormattedMessage id="authorization-code-form.get-code.heading" />
              *
            </p>
            <input
              type="email"
              className={`input input__med input__box ${
                error ? 'input__box__error' : ''
              }`}
              data-cy="auth-email-input"
              value={email}
              disabled={loading}
              placeholder={formatMessage({ id: 'inputs.email.placeholder' })}
              onChange={({ currentTarget: { value } }) => {
                if (error) setError(undefined);
                setEmail(value);
                setClientEmail(value);
              }}
            />
          </div>
          <>
            {error && <p className="t-danger text--sm s-top--tny">{error}</p>}
            <div className="btn-group btn-group--simple authorization-code-form__buttons">
              {onCancel && (
                <Button
                  type="button"
                  size="lrg"
                  style="outline"
                  className="u-minwidth--128"
                  onClick={onCancel}
                >
                  <FormattedMessage id="buttons.back" />
                </Button>
              )}
              {loading ? (
                <Button
                  type="button"
                  size="lrg"
                  style="primary"
                  disabled={loading}
                  className="u-minwidth--128"
                >
                  <LoadingSpinner height={12} width={12} type="negative" />
                  <p className="s-left--med">
                    <FormattedMessage id="subscription-action-controller.action.downgrading" />
                  </p>
                </Button>
              ) : (
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  onClick={onGetCodeSubmit}
                  disabled={blacklisted.includes(email)}
                  size="lrg"
                  style="primary"
                  className="u-minwidth--128"
                  btnSelector="verify-email-btn"
                >
                  <FormattedMessage id="buttons.verify-email" />
                </Button>
              )}
            </div>
          </>
        </>
      )}

      {step === 'authorize' && (
        <>
          <div className="f f--col">
            <p
              className={`s-bottom--tny t-label t-text-3 ${
                error && 't-label--error'
              }`}
            >
              <FormattedMessage id="authorization-code-form.authorize.heading" />
              *
            </p>
            <input
              className={`input input__med input__box ${
                error ? 'input__box__error' : ''
              }`}
              data-cy="auth-code-input"
              value={code}
              disabled={loading}
              onChange={({ currentTarget: { value } }) => {
                if (error) setError(undefined);
                setCode(value);
              }}
              placeholder={formatMessage({
                id: 'authorization-code-form.authorize.placeholder',
              })}
            />
          </div>
          {error && <p className="t-danger text--sm s-top--tny">{error}</p>}
          <p className="t-text-3 t-small s-top--med">
            <FormattedMessage
              id="authorization-code-form.authorize.caption"
              values={{
                here: (
                  <Button
                    type="button"
                    style="text"
                    onClick={onResendCode}
                    disabled={loading}
                    btnSelector="resend-code-btn"
                  >
                    <FormattedMessage id="authorization-code-form.authorize.caption.resend" />
                  </Button>
                ),
              }}
            />
          </p>
          <div className="btn-group btn-group--simple authorization-code-form__buttons">
            <Button
              type="button"
              size="lrg"
              style="outline"
              className="u-minwidth--128"
              btnSelector="back-btn"
              onClick={() => setStep('get-code')}
            >
              <FormattedMessage id="buttons.back" />
            </Button>
            {loading ? (
              <Button
                type="button"
                disabled={loading}
                size="lrg"
                style="primary"
                className="u-minwidth--128"
              >
                <LoadingSpinner height={12} width={12} type="negative" />
                <p className="s-left--med">
                  <FormattedMessage id="subscription-action-controller.action.downgrading" />
                </p>
              </Button>
            ) : (
              <Button
                type="button"
                ref={submitButtonRef}
                onClick={onAuthorizeCodeSubmit}
                size="lrg"
                style="primary"
                btnSelector="authorize-btn"
                className="u-minwidth--128"
              >
                <FormattedMessage id="buttons.authorize" />
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
