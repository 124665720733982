import { useScrollIntoView } from 'modules/authentication';
import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from './FieldWrapper';

export const VatIdInput: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  autoFocus,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<Settings>();

  return (
    <FieldWrapper
      name="tax.vatid"
      errors={errors}
      labelId="inputs.vat-id.label"
      helpTextId="inputs.vat-id.help"
    >
      <input
        type="text"
        autoFocus={autoFocus}
        autoComplete="off"
        className={`input input__box input__med ${
          errors.tax?.vatId ? 'input__box__error' : ''
        }`}
        placeholder={formatMessage({ id: 'inputs.vat-id.placeholder' })}
        onFocus={handleFocus}
        {...register('tax.vatId')}
        data-cy="vat-id-input"
      />
    </FieldWrapper>
  );
};
