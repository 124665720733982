import React, { useState } from 'react';
import { Modal } from 'shared/components';
import { AppSumoFormModal } from './fragments/AppSumoFormModal';
import { AppSumoStatusModal } from './fragments/AppSumoStatusModal';

export const AppSumoModal: React.FC<ModalProps> = ({ onClose }) => {
  const [currentLocalStep, setCurrentLocalStep] =
    useState<AppSumoSteps>('form');

  const appSumoModal = {
    ['form']: (
      <AppSumoFormModal
        onClose={onClose}
        onSetCurrentLocalStep={setCurrentLocalStep}
      />
    ),
    ['status']: <AppSumoStatusModal onClose={onClose} />,
  };

  return (
    <Modal isDashboardModal close={onClose}>
      {appSumoModal[currentLocalStep]}
    </Modal>
  );
};
