import { isSameDay, isWithinInterval } from 'date-fns';

export function applyDateRangeFilter(
  proposals: Proposal[],
  start: Date,
  end?: Date,
) {
  return proposals.filter((proposal) => {
    const creationDate = new Date(proposal.creationDateTime);

    if (!end || (end && start > end)) {
      return isSameDay(creationDate, start);
    }

    return (
      isWithinInterval(creationDate, { start, end }) ||
      isSameDay(creationDate, end) ||
      isSameDay(creationDate, start)
    );
  });
}
