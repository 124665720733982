import Fuse from 'fuse.js';

export function useFuseStringSearch<T>(query?: string, strings?: T[]) {
  if (!strings) return [];
  if (!query) return strings;

  // Fuse.js requires an array of objects
  const processedStrings = strings.map((item) => ({ item }));

  const options = {
    keys: ['item'],
    threshold: 0.3,
  };

  const fuse = new Fuse(processedStrings, options);
  return fuse.search(query).map((result) => result.item.item);
}
