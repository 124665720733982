import { createAction } from './createAction';

export function createEntityActions<K extends string, T>(
  key: K,
): EntityActions<K, T> {
  return {
    loading: () => createAction(`${key}/LOADING` as const),

    onSnapshotUpdate: (documents: T[]) =>
      createAction(`${key}/ON_SNAPSHOT_UPDATE` as const, {
        documents,
      }),

    onSnapshotError: (error: FirestoreError) =>
      createAction(`${key}/ON_SNAPSHOT_ERROR` as const, { error }),

    add: () => createAction(`${key}/ADD` as const),
    addError: () => createAction(`${key}/ADD_ERROR` as const),

    remove: () => createAction(`${key}/REMOVE` as const),
    removeError: () => createAction(`${key}/REMOVE_ERROR` as const),

    update: () => createAction(`${key}/UPDATE` as const),
    updateError: () => createAction(`${key}/UPDATE_ERROR` as const),
  };
}
