export async function downloadLink(url: string, name: string) {
  // For iOS Safari, create a Blob and use object-url for downloading
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    const response = await fetch(url);
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = objectUrl;
    a.download = name;
    a.click();
    URL.revokeObjectURL(objectUrl); // Revoke the object URL to avoid memory leaks
    return;
  }
  // For other browsers, use the default behavior
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();
  return;
}
