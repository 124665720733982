import { useFormGuard } from 'modules/form';
import { Settings, useTaxRedirects } from 'modules/settings';
import React, { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { VatIdInput, VatPercentageInput } from 'shared/components';

interface Props extends PropsWithChildren {
  config: Settings;
  onSubmit: (data: Settings) => void;
}

export const Tax: React.FC<Props> = ({ config, onSubmit, children }) => {
  useTaxRedirects();
  const form = useForm<Settings>({
    mode: 'onSubmit',
    defaultValues: { ...config },
  });
  useFormGuard(form);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="field">
          <VatIdInput />
        </div>
        <div className="field f f--col">
          <VatPercentageInput />
        </div>
        {children}
      </form>
    </FormProvider>
  );
};
