import { DefaultValue, selector, selectorFamily } from 'recoil';
import { subscriptionAtoms } from './atoms';

const setSubscriptionDocument = selector<Subscription | undefined>({
  key: 'subscription-document-selector',
  get: ({ get }) => {
    return get(subscriptionAtoms.document);
  },
  set: ({ set }, document) => {
    if (document instanceof DefaultValue) return;

    set(subscriptionAtoms.document, document);
    set(subscriptionAtoms.active, Boolean(document?.active));
    set(subscriptionAtoms.customerId, document?.stripe?.customerId);
    set(subscriptionAtoms.subscriptionId, document?.stripe?.subscriptionId);
    set(subscriptionAtoms.status, document?.status || null);
    set(subscriptionAtoms.end, document?.end);
    set(subscriptionAtoms.priceId, document?.stripe?.priceId);
    set(
      subscriptionAtoms.discountPercentage,
      document?.stripe?.discountPercentage,
    );
    set(subscriptionAtoms.productId, document?.stripe?.productId);
    set(subscriptionAtoms.tier, document?.tier || 0);
    set(
      subscriptionAtoms.upcomingSubscription,
      document?.stripe?.upcomingSubscription,
    );
    set(
      subscriptionAtoms.isGracePeriod,
      document?.stripe?.isGracePeriod || null,
    );
    set(subscriptionAtoms.isFreeTrial, document?.stripe?.isFreeTrial || null);
    set(
      subscriptionAtoms.hasUsedFreeTrial,
      document?.stripe?.hasUsedFreeTrial || null,
    );
  },
});

const productsSelector = selector<StripeProductWithPrices[]>({
  key: 'subscription-products-selector',
  get: ({ get }) => {
    return get(subscriptionAtoms.products);
  },
  set: ({ set }, products) => {
    if (products instanceof DefaultValue) return;

    set(subscriptionAtoms.products, products);
  },
});

const getCurrentProductAndPrice = selector<
  { price: StripePrice; product: StripeProductWithPrices } | undefined
>({
  key: 'subscription-selected-product-and-price',
  get: ({ get }) => {
    const priceId = get(subscriptionAtoms.priceId);
    const productId = get(subscriptionAtoms.productId);
    if (!priceId || !productId) return;

    const products = get(subscriptionAtoms.products);
    const product = products?.find((p) => p.id === productId);
    const price = product?.prices.find((p) => p.id === priceId);
    if (!product || !price) return;
    return { price, product };
  },
});

const resetter = selector({
  key: 'subscription-reset',
  get: () => undefined,
  set: ({ reset }) => {
    (
      Object.keys(subscriptionAtoms) as Array<keyof typeof subscriptionAtoms>
    ).forEach((key) => reset(subscriptionAtoms[key]));
  },
});

const hasTierOne = selector({
  key: 'subscription-has-tier-one',
  get: ({ get }) => {
    const tiers = get(subscriptionAtoms.organizationTiers);
    return Object.values(tiers).some((tier) => tier === 1);
  },
});

const isTierOne = selector({
  key: 'subscription-is-tier-one',
  get: ({ get }) => {
    const tier = get(subscriptionAtoms.tier);
    return tier === 1;
  },
});

const getIsFreeUser = selectorFamily({
  key: 'subscription-is-free-user',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const tiers = get(subscriptionAtoms.organizationTiers);
      const organizationTier = tiers[organizationId || ''];

      return organizationTier === 0 || !organizationTier;
    },
});

export const subscriptionSelectors = {
  setSubscriptionDocument,
  getCurrentProductAndPrice,
  resetter,
  productsSelector,
  hasTierOne,
  isTierOne,
  getIsFreeUser,
};
