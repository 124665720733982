import { useFunction } from 'modules/firebase';
import { customPresetsAtom, proposalOwnerCountryAtom } from 'modules/settings';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { subscriptionAtoms } from '../state';

export function useFetchOrganizationProposalData(organizationId: string) {
  const getOrganizationProposalDataCallable = useFunction<
    FetchOrganizationProposalData,
    FetchOrganizationProposalResponse
  >('getOrganizationProposalData');
  const [loading, setLoading] = useRecoilState(
    subscriptionAtoms.loadingOrganizationTiers,
  );
  const [tiers, setTiers] = useRecoilState(subscriptionAtoms.organizationTiers);
  const setCustomPresets = useSetRecoilState(customPresetsAtom);
  const setProposalOwnerCountry = useSetRecoilState(proposalOwnerCountryAtom);

  async function getCustomPresets() {
    if (!getOrganizationProposalDataCallable) {
      setLoading(false);
      return;
    }

    const { data } = await getOrganizationProposalDataCallable({
      organizationId,
    });
    if (!data.success) {
      setLoading(false);
      return;
    }

    setTiers({
      ...tiers,
      [organizationId]: data.payload.tier,
    });
    setCustomPresets(data.payload.customPresets);
    setProposalOwnerCountry(data.payload.country);
    setLoading(false);
  }

  useEffect(() => {
    if (!getOrganizationProposalDataCallable) return;
    getCustomPresets();
  }, [getOrganizationProposalDataCallable]);

  return {
    isTierLoading: loading,
  };
}
