import { useFunction } from 'modules/firebase';
import { subscriptionSelectors } from 'modules/subscription';
import { useSetRecoilState } from 'recoil';
import { toast } from 'sonner';

export function useStripePrices() {
  const setProducts = useSetRecoilState(subscriptionSelectors.productsSelector);
  const getProducts = useFunction<undefined, StripeProductWithPrices[]>(
    'getProducts',
  );

  async function getStripeProducts() {
    if (!getProducts) return;
    try {
      const { data } = await getProducts();
      if (!data.success) throw new Error();

      setProducts(data.payload);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch available products. Try again later.');
    }
  }

  return {
    getStripeProducts,
  };
}
