import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  view: ProposalSummaryView;
  toggleView: (newView: ProposalSummaryView) => void;
}

export const ProposalSummaryToggle: React.FC<Props> = ({
  view,
  toggleView,
}) => {
  function getItemClassName(type: ProposalSummaryView) {
    return view === type
      ? 'proposal__summary__view--active'
      : 'proposal__summary__view--inactive';
  }

  return (
    <div className="proposal__summary__toggle">
      <Button
        type="button"
        onClick={() => toggleView('table-of-contents')}
        className={`text--sm proposal__summary__view__item ${getItemClassName(
          'table-of-contents',
        )}`}
      >
        <span>
          <FormattedMessage id="proposal-summary.table-of-contents" />
        </span>
      </Button>
      <Button
        type="button"
        onClick={() => toggleView('brand-customization')}
        className={`text--sm proposal__summary__view__item ${getItemClassName(
          'brand-customization',
        )}`}
      >
        <span>
          <FormattedMessage id="proposal-summary.brand-customization" />
        </span>
      </Button>
    </div>
  );
};
