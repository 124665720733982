import documentIcon from 'assets/icons/icon-sparks-duo-48.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { useBeforeUnload, useSubmitOnEnter } from 'shared/hooks';

export const AppSumoStatusModal: React.FC<ModalProps> = ({ onClose }) => {
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  useBeforeUnload(onClose, true);

  return (
    <>
      <div className="f f--col f--align-center">
        <img src={documentIcon} alt="DocumentIcon" />
        <h1 className="t-center text--xl__responsive t-text-1 t-bold proposal-service__plus__modal__title">
          <FormattedMessage id="settings.subscription.appsumo-success-modal-title" />
        </h1>
        <div className="d--ib text--xs t-center u-maxwidth--432 s-bottom--med">
          <span className="t-text-2">
            <FormattedMessage id="settings.subscription.appsumo-success-modal-description" />
          </span>
        </div>
      </div>
      <div className="btn-group btn-group--simple f--center">
        <Button
          size="lrg"
          style="primary"
          ref={submitButtonRef}
          onClick={onClose}
          className="s-top--sml"
          btnSelector="proposal-expired-failed-confirm-btn"
        >
          <FormattedMessage id="settings.subscription.appsumo-success-modal-confirm" />
        </Button>
      </div>
    </>
  );
};
