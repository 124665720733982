import { atom } from 'recoil';

export const mobileNavDropdownVisibleAtom = atom<boolean>({
  key: 'mobileNavDropdownVisible',
  default: false,
});

export const mobileSearchVisibleAtom = atom<boolean>({
  key: 'mobileSearchVisible',
  default: false,
});
