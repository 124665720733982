import React from 'react';
import { PublicFileSegmentListItem } from './PublicFileSegmentListItem';

interface Props {
  item: FileSegment;
  index: number;
  style: Proposal['style'];
}

export const PublicFileSegmentList: React.FC<Props> = ({
  item,
  index,
  style,
}) => {
  return (
    <ul className="proposal-block-file__list" id={`file-segment-${index}`}>
      {item.files.map((file, index) => (
        <PublicFileSegmentListItem key={index} file={file} style={style} />
      ))}
    </ul>
  );
};
