import {
  ErrorMessage,
  FieldValuesFromFieldErrors,
} from '@hookform/error-message';
import { Link } from 'gatsby';
import React from 'react';
import { FieldErrors, FieldName } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

interface TooltipComponentProps {
  className?: string;
}

interface FieldWrapperProps<T extends Record<string, any>> {
  name: FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>;
  errors?: FieldErrors<T>;
  labelId?: string;
  labelClassName?: string;
  isRequired?: boolean;
  errorClassName?: string;
  helpTextId?: string;
  helpTextClassName?: string;
  hasPercentage?: boolean;
  containerClassName?: string;
  isPathMatchingLogin?: boolean;
  tooltipComponent?: React.FC<TooltipComponentProps>;
  tooltipClassName?: string;
  children?: React.ReactNode;
}

export const FieldWrapper = <T extends Record<string, any>>({
  labelId,
  labelClassName = 't-label t-text-2',
  isRequired,
  errors,
  containerClassName,
  errorClassName = 't-danger text--sm s-top--tny',
  helpTextClassName = 'field__helptext text--sm t-text-3',
  name,
  helpTextId,
  isPathMatchingLogin,
  tooltipComponent: TooltipComponent,
  tooltipClassName,
  hasPercentage,
  children,
}: FieldWrapperProps<T>) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {labelId && !isPathMatchingLogin && (
        <label
          htmlFor={name}
          className={labelClassName}
          data-cy={`${name}-label`}
        >
          <FormattedMessage id={labelId} />{' '}
          {isRequired && <span className="m-left-negative-3">*</span>}
        </label>
      )}

      {isPathMatchingLogin && (
        <div className="f f--justify-between">
          {labelId && (
            <label
              htmlFor={name}
              className={labelClassName}
              data-cy={`${name}-label`}
            >
              <FormattedMessage id={labelId} />{' '}
              {isRequired && <span className="m-left-negative-3">*</span>}
            </label>
          )}
          <Link
            to={formatMessage({ id: 'routes.forgot-password' })}
            className="t-label"
            data-cy="forgot-password-link"
          >
            <FormattedMessage id="inputs.password.forgot-password" />
          </Link>
        </div>
      )}

      {TooltipComponent && <TooltipComponent className={tooltipClassName} />}
      <div className={`${containerClassName || ''} s-top--tny`}>
        {children}
        {hasPercentage && '%'}
      </div>

      {errors && (
        <ErrorMessage
          name={name}
          errors={errors}
          as={<p className={errorClassName} />}
          data-cy="field-validation-error"
        />
      )}
      {helpTextId && (
        <p className={helpTextClassName}>
          <FormattedMessage id={helpTextId} />
        </p>
      )}
    </>
  );
};
