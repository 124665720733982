import { useScrollIntoView } from 'modules/authentication';
import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

export const CompanyNameInput: React.FC<
  InputHTMLAttributes<HTMLInputElement>
> = ({ autoFocus }) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<Settings>();

  return (
    <FieldWrapper
      name="name"
      errors={errors}
      labelId="inputs.company-name.label"
      isRequired
    >
      <input
        type="text"
        className={`input input__box input__med ${
          errors.name ? 'input__box__error' : ''
        }`}
        onFocus={handleFocus}
        autoFocus={autoFocus}
        placeholder={formatMessage({ id: 'inputs.company-name.placeholder' })}
        data-cy="company-name-input"
        {...register('name', {
          maxLength: {
            value: 100,
            message: formatMessage({ id: 'inputs.company-name.max-length' }),
          },
          required: formatMessage({ id: 'inputs.company-name.required' }),
          validate: (value) => {
            return (
              !!value.trim() ||
              formatMessage({ id: 'inputs.company-name.required' })
            );
          },
        })}
      />
    </FieldWrapper>
  );
};
