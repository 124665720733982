import { AccessList } from '../components';

export function areAccessListsEqual(
  list1: AccessList[],
  list2: AccessList[],
): boolean {
  const emails1 = list1?.map((item) => item.email)?.sort();
  const emails2 = list2?.map((item) => item.email)?.sort();

  if (emails1?.length !== emails2?.length) {
    return false;
  }

  const areEqual = emails1?.every((email, index) => email === emails2[index]);

  return areEqual;
}
