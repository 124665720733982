import { useEffect, useRef } from 'react';
import { useKeyPress } from './useKeyPress';

export function useSubmitOnEnter<T extends HTMLElement>() {
  const ref = useRef<T>(null);
  const enterPressed = useKeyPress('Enter');
  useEffect(() => {
    if (enterPressed) ref.current?.click();
  }, [enterPressed]);

  return ref;
}
