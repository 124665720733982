import React, { PropsWithChildren } from 'react';
import { CreateProposalButton, NavSearch } from 'shared/components';

interface Props extends PropsWithChildren {
  rootUrl?: string;
  headerVisible?: boolean;
  navButtons?: NavigationButtons[];
  isManageSection?: boolean;
}

export const DesktopHeader: React.FC<Props> = ({ isManageSection = false }) => {
  return (
    <>
      <NavSearch
        className="d--desktop--only"
        inputSelector="navigation-search-bar"
      />
      {!isManageSection && (
        <CreateProposalButton
          className="d--desktop--only"
          btnSelector="navigation-create-proposal-btn"
        />
      )}
    </>
  );
};
