import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

export const ProposalNameInput: React.FC = () => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<PublishStatusAware<Proposal>>();

  return (
    <FieldWrapper
      name="name"
      errors={errors}
      labelId="inputs.proposal.label"
      isRequired
    >
      <input
        type="text"
        className={`input input__line proposal-builder-heading__title ${
          errors.name ? 'input__error' : ''
        }`}
        placeholder={formatMessage({ id: 'inputs.proposal.placeholder' })}
        data-cy="builder-proposal-name-input"
        onFocus={handleFocus}
        {...register('name', {
          required: formatMessage({ id: 'inputs.proposal.required' }),
          maxLength: {
            value: 300,
            message: formatMessage({ id: 'inputs.proposal.max-length' }),
          },
        })}
      />
    </FieldWrapper>
  );
};
