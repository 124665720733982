import googleIcon from 'assets/icons/icon-facebook-16.svg';
import React from 'react';
import { Button } from 'shared/components';

interface Props {
  authLoading: boolean;
  loginWithFacebook: () => void;
}

export const FacebookLogin: React.FC<Props> = ({
  authLoading,
  loginWithFacebook,
}) => {
  return (
    <Button
      style="outline"
      size="lrg"
      className="u-width--full"
      disabled={authLoading}
      onClick={loginWithFacebook}
      btnSelector="facebook-login-btn"
    >
      <img src={googleIcon} alt="Facebook Icon" className="btn__icon" />
    </Button>
  );
};
