import { DualPrice, Price, SinglePrice, formatPrice } from 'modules/proposals';
import React from 'react';

interface Props {
  currency: string;
  subtotalAmount: number;
  exchangeRate?: number;
  ISOCountry?: string;
}

export const SubtotalProposalPrice: React.FC<Props> = ({
  currency,
  subtotalAmount,
  exchangeRate,
  ISOCountry,
}) => (
  <Price
    currency={currency}
    dualPriceElement={
      <DualPrice>
        {exchangeRate && (
          <p className="t-eta">
            {formatPrice(subtotalAmount / exchangeRate, 'eur', ISOCountry)}
          </p>
        )}
        <p className="o-60 text--xxs__responsive">
          {formatPrice(subtotalAmount, currency, ISOCountry)}
        </p>
      </DualPrice>
    }
    singlePriceElement={
      <SinglePrice className="t-eta">
        {formatPrice(subtotalAmount, currency, ISOCountry)}
      </SinglePrice>
    }
  />
);
