import { useFeatureFlag } from 'modules/feature-flags';
import React, { PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';
import { useSubscriptionTier } from '../hooks';
import { subscriptionAtoms } from '../state';

interface Props extends PropsWithChildren {
  tier: SubscriptionTierAlias;
  /** Check for EXACTLY that tier, nothing more */
  exact?: boolean;
}

/** Determine whether the user's subscription tier matches the required tier to render the element children.
 * Also checks the SUBSCRIPTION feature flag wrapper for subscriptions, because if subscriptions are not enabled the content should be shown.
 */
export const SubscriptionTierWrapper: React.FC<Props> = ({
  tier,
  exact,
  children,
}) => {
  const subscriptionsEnabled = useFeatureFlag('SUBSCRIPTION');
  const enabled = useSubscriptionTier(tier, exact);
  const status = useRecoilValue(subscriptionAtoms.status);

  if (subscriptionsEnabled && !enabled && status === null) return null;

  return <>{children}</>;
};
