import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { subscriptionSelectors } from '../state';

export function useStripeProducts() {
  const products = useRecoilValue(subscriptionSelectors.productsSelector);
  const plusYearlyProduct = useMemo(
    () => products?.find(({ name }) => name === 'Yearly Plus'),
    [products],
  );
  const productsLoading = useMemo(() => !products?.length, [products]);

  return {
    products,
    plusYearlyProduct,
    productsLoading,
  };
}
