import { PageProps } from 'gatsby';
import { isServiceModalOpen, serviceSelector } from 'modules/services';
import { SettingsLayout } from 'modules/settings';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import {
  AddIcon,
  Button,
  CreateProposalButton,
  List,
  ListError,
  PageLoading,
  SEO,
  Sort,
} from 'shared/components';
import { useCloseModalOnCleanup } from 'shared/hooks';
import { applySort } from 'shared/utils';
import { ServiceListError, ServiceListItem, ServiceModal } from '../components';

interface Props extends PageProps {
  proposalsLoading: boolean;
}

export const Services: React.FC<Props> = ({ location, proposalsLoading }) => {
  const { formatMessage } = useIntl();

  // const [query, setQuery] = useState('');
  const [sortOption, setSortOption] = useState<SortOption>('newest');

  const [modalOpen, setModalOpen] = useRecoilState(isServiceModalOpen);
  const { documents, isLoading, error } = useSelector(
    serviceSelector.getServicesByCreationTime,
  );

  const services = useMemo(() => {
    // const filteredServices = useFuseObjectSearch(['name'], query, documents);
    if (!documents) return [];
    return applySort(sortOption, documents);
  }, [documents, sortOption]);

  useCloseModalOnCleanup(setModalOpen);
  if (proposalsLoading) return <PageLoading isLoading={proposalsLoading} />;

  return (
    <>
      <SEO
        title={formatMessage({ id: 'services.seo.title' })}
        description={formatMessage({ id: 'services.seo.description' })}
        href={location.href}
      />

      <SettingsLayout isManageSection>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="services-title"
        >
          <FormattedMessage id="services.title" />
        </header>{' '}
        <div className="manage__header__action__container">
          <Button
            type="button"
            onClick={() => setModalOpen(true)}
            style="secondary"
            size="lrg"
            className="manage__header__action"
            btnSelector="add-service-btn"
          >
            <AddIcon />
            <span className="d--desktop--only">
              <FormattedMessage id="buttons.add-new-service" />
            </span>
          </Button>
          <CreateProposalButton className="d--desktop--only" />
        </div>
        <div className="dashboard__section__mobile__actions">
          <header className="text--xs t-bold" data-cy="services-mobile-title">
            <FormattedMessage
              id="services.mobile-title"
              values={{
                length: services?.length,
              }}
            />
          </header>
          {Boolean(services?.length) && (
            <div className="f f--align-center">
              <Button
                type="button"
                onClick={() => setModalOpen(true)}
                style="secondary"
                size="med"
                btnSelector="add-service-mobile-btn"
              >
                <span className="d--mobile--only">
                  <FormattedMessage id="buttons.add-new-service" />
                </span>
              </Button>
              <Sort sortOption={sortOption} onSetSortOption={setSortOption} />
            </div>
          )}
        </div>
        {/**TODO @markoboras0712 in new design we've removed search, check with design team why */}
        {/* <div className="manage__header__container">
          {Boolean(documents?.length) && (
            <div className="manage__actions__container">
              <ListSearch
                query={query}
                onSetQuery={setQuery}
                recentSearchLocalStorageKey={SERVICE_RECENT_SEARCHES_LS_KEY}
                selector="services"
              />
            </div>
          )}
        </div> */}
        {Boolean(services?.length) && (
          <div className="dashboard__section__actions dashboard__section__actions--manage">
            <span className="t-text-2 text--sm">
              <FormattedMessage
                id="services.length"
                values={{
                  length: services?.length,
                }}
              />
            </span>
            <Sort
              sortOption={sortOption}
              onSetSortOption={setSortOption}
              btnSelector="sort-units"
            />
          </div>
        )}
        <List
          documents={documents}
          results={services}
          isLoading={isLoading}
          error={error}
          // query={query}
          itemComponent={ServiceListItem}
          errorComponent={ServiceListError}
          searchErrorComponent={ListError}
          loadingType="list"
          className="dashboard__section__list__container manage__list"
          errorClassName="dashboard__section__error__container"
          onClick={() => setModalOpen(true)}
        />
      </SettingsLayout>
      {modalOpen && <ServiceModal onClose={() => setModalOpen(false)} />}
    </>
  );
};
