import { useLocation } from '@reach/router';
import documentIcon from 'assets/icons/icon-search-proposal-12.svg';
import { format } from 'date-fns';
import { Link, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { GLOBAL_RECENT_SEARCHES_LS_KEY } from 'shared/const';
import { useKeyPress, useOnClickRecentSearchResults } from 'shared/hooks';
import { SearchResultsAcceptanceStatusIcon } from '../seaarch-results-acceptance-status-icon';

interface Props {
  proposal: Proposal;
  index: number;
  isActive?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const ProposalSearchResultsListItem: React.FC<Props> = ({
  proposal,
  index,
  isActive,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { formatMessage } = useIntl();
  const enterPress = useKeyPress('Enter');
  const { pathname, search } = useLocation();
  const { handleSetRecentSearch } = useOnClickRecentSearchResults(
    GLOBAL_RECENT_SEARCHES_LS_KEY,
  );

  /**Enter press */
  useEffect(() => {
    if (enterPress && isActive) {
      navigate(
        formatMessage({ id: 'routes.new-proposal' }, { id: proposal.id }),
        {
          state: {
            lastPageUrl: `${pathname + search}`,
          },
        },
      );
    }
  }, [enterPress, isActive]);

  return (
    <Link
      to={formatMessage({ id: 'routes.new-proposal' }, { id: proposal.id })}
      state={{
        lastPageUrl: `${pathname + search}`,
      }}
      className={`u-width--full search-result-item search__results__proposal__item__container  ${
        isActive ? 'search__results__client__item--active' : ''
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => handleSetRecentSearch(proposal.name || '')}
    >
      <img src={documentIcon} alt="DocumentIcon" />
      <div className="t-ellipsis-2-row">
        {proposal.client && (
          <span className="t-text-2 search__results__proposal__item__label t-ellipsis t-shorten">
            {proposal.client.name}
          </span>
        )}
        <span className="t-shorten text--sm t-bold search__results__proposal__item__title t-ellipsis-2-row">
          {proposal.name}
        </span>
        <div className="f f--align-center proposal-item__footer s-top--sml">
          <SearchResultsAcceptanceStatusIcon
            publishStatus={proposal.publishStatus}
            index={index}
          />
          <span className="t-text-2 text--xsm">·</span>
          <p className="text--tny t-text-2">
            {format(proposal.creationDateTime, 'MMM dd, yyyy')}
          </p>
        </div>
      </div>
    </Link>
  );
};
