import React from 'react';
import { CreateFromTemplateButton } from '../../item-actions/fragments';
import { PreviewButton } from '../../shared-buttons';
import { ActionDrawerClassNames } from '../ActionDrawerClassNames';
import { ArchiveButton } from '../archive-button';
import { DeleteButton } from '../delete-button';
import { DuplicateButton } from '../duplicate-button';

interface Props extends ActionDrawerClassNames {
  proposal: Proposal;
  isOverview?: boolean;
}

export const TemplateDrawerButtons: React.FC<Props> = ({
  archiveClassName,
  duplicateClassName,
  deleteClassName,
  proposal,
  isOverview,
}) => (
  <>
    {isOverview && (
      <>
        <CreateFromTemplateButton
          className="actionbar__drawer__btn"
          btnSelector="preview-proposal-btn"
          isDrawerBtn
        />
        <PreviewButton
          className="actionbar__drawer__btn"
          btnSelector="preview-proposal-btn"
          isDrawerBtn
        />
      </>
    )}
    <DuplicateButton
      className={duplicateClassName}
      proposal={proposal}
      btnSelector="template-page-duplicate-btn"
    />
    <ArchiveButton
      className={archiveClassName}
      btnSelector="template-page-archive-btn"
    />
    <DeleteButton
      className={deleteClassName}
      btnSelector="template-page-delete-btn"
    />
  </>
);
