import dragIcon from 'assets/icons/icon-drag-16-secondary.svg';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FileAttachmentInput } from '../file-attachment-input';
import { MobileSegmentMover } from '../mobile-segment-mover';
import { SegmentActions } from '../segment-actions';
import { SegmentDeleteButton } from '../segment-delete-button';

interface Props {
  index: number;
  provided: DraggableProvided;
  segmentsFileSize: number;
  segmentsLength: number;
  context: UseFormReturn<PublishStatusAware<Proposal>, any, undefined>;
  addSegment: (type: ProposalSegment['type'], index: number) => void;
  deleteSegment: (index: number) => void;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

export const FileSegmentCard: React.FC<Props> = ({
  index,
  provided,
  segmentsFileSize,
  segmentsLength,
  context,
  addSegment,
  deleteSegment,
  moveItem,
}) => {
  return (
    <>
      <div
        className="proposal-service proposal-service--service"
        id={`file-segment-${index}`}
      >
        <div
          {...provided.dragHandleProps}
          className="proposal-service__dragger"
          data-cy={`drag-and-drop-btn-${index}`}
        >
          <img src={dragIcon} alt="DragIcon" />
        </div>
        <SegmentDeleteButton index={index} onDelete={deleteSegment} />
        <MobileSegmentMover
          index={index}
          moveItem={moveItem}
          segmentsLength={segmentsLength}
        />
        <label
          className="field__lbl s-bottom--sml"
          data-cy={`file-attachment-label-${index}`}
        >
          <FormattedMessage id="inputs.proposal-file.label" />
        </label>
        <FileAttachmentInput
          segmentsFileSize={segmentsFileSize}
          index={index}
          {...context}
        />
      </div>

      <SegmentActions index={++index} onAdd={addSegment} />
    </>
  );
};
