import { useFormGuard } from 'modules/form';
import { OnboardingSubscribeToNewsletter } from 'modules/onboarding';
import { Settings } from 'modules/settings';
import React, { PropsWithChildren } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { CompanyLogoPicker, CompanyNameInput } from 'shared/components';

interface Props extends PropsWithChildren {
  config?: Settings | null;
  formClassName?: string;
  onSubmit: (data: Settings) => void;
}

export const OnboardingCompanyInfo: React.FC<Props> = ({
  config,
  formClassName,
  children,
  onSubmit,
}) => {
  const form = useForm<Settings>({
    mode: 'onSubmit',
    defaultValues: {
      ...config,
    },
  });
  useFormGuard(form);

  const hasUnsubscribedOnOnboarding = useWatch({
    name: 'hasUnsubscribedOnOnboarding',
    control: form.control,
  });
  const { setValue } = form;

  function handleToggle() {
    setValue('hasUnsubscribedOnOnboarding', !hasUnsubscribedOnOnboarding);
  }

  return (
    <>
      <FormProvider {...form}>
        <form
          className={formClassName || ''}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <CompanyNameInput />
          <div className="field">
            <CompanyLogoPicker />
          </div>
          <OnboardingSubscribeToNewsletter
            hasUnsubscribedFromNewsletter={hasUnsubscribedOnOnboarding || false}
            handleToggle={handleToggle}
          />
          {children}
        </form>
      </FormProvider>
    </>
  );
};
