import { useProposalSegments, useSegmentsFileSize } from 'modules/proposals';
import { subscriptionSelectors } from 'modules/subscription';
import React, { memo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { UseFormReturn } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { DeleteModal } from 'shared/components';
import { useModal } from 'shared/hooks';
import {
  MediaFilePlusModal,
  SegmentActions,
  SegmentRenderer,
} from '../segment-renderer';

interface Props {
  formState: UseFormReturn<PublishStatusAware<Proposal>>['formState'];
}

export const SegmentList: React.FC<Props> = memo(
  () => {
    const [deletionIndex, setDeletionIndex] = useState<number | undefined>();

    const { isModalOpen: isPlusModalOpen, closeModal, openModal } = useModal();
    const { reorderSegments, addSegment, deleteSegment, moveItem, segments } =
      useProposalSegments();
    const segmentsFileSize = useSegmentsFileSize();
    const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);

    function onDeleteClick(index: number) {
      setDeletionIndex(index);
    }

    function onDeleteConfirm(index: number) {
      deleteSegment(index);
      setDeletionIndex(undefined);
    }

    function onDeleteCancel() {
      setDeletionIndex(undefined);
    }

    function handleAddSegment(type: ProposalSegment['type'], index: number) {
      if (!isTierOne && (type === 'file' || type === 'media')) {
        openModal();
        return;
      }

      addSegment(type, index);
      return;
    }

    return (
      <DragDropContext onDragEnd={reorderSegments}>
        <Droppable droppableId="service-list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {segments?.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <SegmentRenderer
                      item={item}
                      index={index}
                      provided={provided}
                      segmentsFileSize={segmentsFileSize}
                      segmentsLength={segments?.length}
                      addSegment={handleAddSegment}
                      deleteSegment={onDeleteClick}
                      moveItem={moveItem}
                    />
                  )}
                </Draggable>
              ))}
              <>{provided.placeholder}</>
            </div>
          )}
        </Droppable>

        {!segments?.length && (
          <SegmentActions
            index={0}
            onAdd={handleAddSegment}
            className="proposal-service__actions--empty"
          />
        )}

        {deletionIndex !== undefined && (
          <DeleteModal
            title="section"
            onClose={onDeleteCancel}
            onConfirm={() => onDeleteConfirm(deletionIndex)}
            delSelector="delete-action-btn"
          />
        )}

        {isPlusModalOpen && <MediaFilePlusModal onClose={closeModal} />}
      </DragDropContext>
    );
  },
  (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    const hasErrors = !!Object.keys(nextProps.formState.errors);
    return (
      prevProps.formState.isDirty === nextProps.formState.isDirty && !hasErrors
    );
  },
);
