import { useScrollIntoView } from 'modules/authentication';
import { onlyNumbersRegex } from 'modules/validation';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';
import { isZeroBeforeDot } from 'shared/utils';
import { VatPercentageTooltip } from './VatPercentageTooltip';

interface Props {
  className?: string;
}

export const OnboardingVatPercentageInput: React.FC<Props> = ({
  className,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<Settings>();

  return (
    <FieldWrapper
      name="tax.vatPercentage"
      errors={errors}
      labelId="inputs.vat-percentage.label"
      hasPercentage
      containerClassName="f f--align-center"
      tooltipComponent={VatPercentageTooltip}
      tooltipClassName="btn__icon__right"
    >
      <input
        inputMode="decimal"
        className={`input input__box input__w__micro s-right--sml u-width--100 ${
          errors.tax?.vatPercentage ? 'input__box__error' : ''
        } ${className || ''}`}
        data-cy="vat-precentage-input"
        type="number"
        onFocus={handleFocus}
        {...register('tax.vatPercentage', {
          min: {
            value: 0,
            message: formatMessage({
              id: 'inputs.vat-percentage.min-value-error',
            }),
          },
          max: {
            value: 100,
            message: formatMessage({
              id: 'inputs.vat-percentage.max-value-error',
            }),
          },
          pattern: {
            value: onlyNumbersRegex,
            message: formatMessage({ id: 'inputs.vat-percentage.invalid' }),
          },
          validate: (value) => {
            if (isZeroBeforeDot(String(value))) {
              return formatMessage({
                id: 'inputs.vat-percentage.starts-with-zero',
              });
            }
            return;
          },
        })}
      />
    </FieldWrapper>
  );
};
