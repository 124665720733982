import { navigate } from 'gatsby';
import { useCallback, useEffect } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

export function useFormGuard<Model extends FieldValues>(
  form: UseFormReturn<Model>,
) {
  const {
    formState: { dirtyFields },
  } = form;
  const { formatMessage } = useIntl();
  const isDirty = !!Object.keys(dirtyFields)?.length;

  const handlePopstate = useCallback((event: PopStateEvent) => {
    event.preventDefault();
    const formGuardMessage = confirm(
      formatMessage({ id: 'buttons.confirm-navigation' }),
    );

    if (formGuardMessage) navigate('/dashboard/proposals/overview');

    window.history.pushState(null, '', window.location.href);
  }, []);

  const handleBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  }, []);

  useEffect(() => {
    if (isDirty) {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', handlePopstate);
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('popstate', handlePopstate);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handlePopstate, handleBeforeUnload, isDirty]);
}
