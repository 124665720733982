import { useLocation, useParams } from '@reach/router';
import { navigate } from 'gatsby';
import { useOrganizationIdSelector } from 'modules/authorization';
import { showDebugStatuses } from 'modules/firebase';
import { serviceSelector } from 'modules/services';
import {
  Settings,
  arePresetsDirtyAtom,
  customPresetsAtom,
  settingsSelector,
  useSettings,
} from 'modules/settings';
import { subscriptionSelectors } from 'modules/subscription';
import { unitSelector } from 'modules/units';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { useModal } from 'shared/hooks';
import { isDeepEqual, isProposalBuilderLocation } from 'shared/utils';
import { toast } from 'sonner';
import { Proposal } from '../Proposal';
import { confirmationModalOptions } from '../const';
import { getUpdatedProposal, hasOnlyCustomTagsChanged } from '../utils';
import { useProposalValidity } from './useProposalValidity';
import { useProposals } from './useProposals';
import { usePublicProposal } from './usePublicProposal';

export function useProposalActions(
  proposal?: PublishStatusAware<Proposal> | Proposal,
) {
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const organizationId = useOrganizationIdSelector();
  const { config } = useSelector(settingsSelector.getState);
  const { documents: units } = useSelector(unitSelector.getState);
  const { documents: services } = useSelector(serviceSelector.getState);
  const customPresets = useRecoilValue(customPresetsAtom);
  const arePresetsDirty = useRecoilValue(arePresetsDirtyAtom);

  const { pathname } = useLocation();
  const publishStatusModal = useModal();
  const { updateAsync } = useSettings();
  const acceptanceStatusModal = useModal();
  const { id = proposal?.id } = useParams();

  const form = useForm<PublishStatusAware<Proposal>>({
    mode: 'onSubmit',
    defaultValues: {
      ...proposal,
      saved: false,
    },
  });

  const {
    createEntityWithImagesAsync,
    updateEntityWithImagesAsync,
    deleteEntityAsync,
  } = useProposals();
  const { resetAcceptanceStatus } = usePublicProposal();

  const { isProposalValid } = useProposalValidity(form.setError);

  function onUpdate() {
    let data = form.getValues();
    if (data.nextPublishStatus === 'deleted') {
      deleteEntityAsync(id).catch((error) => toast.warning(error.message));
      publishStatusModal.closeModal();
      if (isProposalBuilderLocation(pathname)) {
        navigate('/dashboard/proposals/overview');
        return;
      }
      return;
    }
    if (data.nextPublishStatus !== 'published' && config) {
      data.publishStatus = data.nextPublishStatus;
      data.acceptanceStatus = 'pending';
      data.companyInfo = { ...new Settings(config), customPresets: [] };
    }
    if (data.nextPublishStatus === 'published') {
      data.acceptanceStatus = 'pending';
      data.publishDateTime = +new Date();
    }
    if (data.publishStatus !== 'published') {
      data = getUpdatedProposal({ data, services, units });
    }
    const { nextPublishStatus, ...rest } = data;
    updateEntityWithImagesAsync(proposal?.id || id, {
      ...rest,
      publishStatus: nextPublishStatus as PublishStatus,
    });
    if (acceptanceStatusModal.isModalOpen) {
      resetAcceptanceStatus({
        organizationId,
        proposalId: id,
        oldStatus: proposal?.acceptanceStatus || '',
      });
      acceptanceStatusModal.closeModal();
    }
    if (publishStatusModal.isModalOpen) publishStatusModal.closeModal();
  }

  function onCancel() {
    if (!proposal) return;
    form.setValue('nextPublishStatus', proposal.publishStatus);
    publishStatusModal.closeModal();
  }

  async function submit(data: PublishStatusAware<Proposal>) {
    if (showDebugStatuses) {
      console.log(`Submitting proposal with data: `, { data });
    }
    if (!proposal?.id && config) {
      data.companyInfo = { ...new Settings(config), customPresets: [] };
      form.reset(
        {
          ...data,
          saved: true,
        },
        { keepDirty: false },
      );
      /**If proposal presets are updated */
      if (arePresetsDirty) {
        await updateAsync({
          ...config,
          customPresets,
        });
      }
      return createEntityWithImagesAsync(id, data);
    }
    if (data.publishStatus !== 'published') {
      data = getUpdatedProposal({ data, services, units });
    }
    /**Demo proposals don't have companyInfo. */
    if (
      (data.nextPublishStatus !== 'published' && config) ||
      (!data?.companyInfo?.name && config)
    ) {
      data.companyInfo = { ...new Settings(config), customPresets: [] };
    }
    if (!isProposalValid(data)) {
      if (showDebugStatuses) console.log('Proposal is not valid', { data });
      toast.error(
        'Your proposal cannot be updated. Please correct all errors in proposal builder.',
      );
      return;
    }
    /** If acceptance status is not "pending" and the proposal is published already (ie accessible), open acceptance status change confirmation modal.
     *  This will change the acceptanceStatus to "pending" upon confirmation and send e-mail notifications to access list members if there are any.
     *  Don't show this modal to free plan or no plan users
     * Also don't show this modal to users who have only changed custom tags on published proposals with acceptanceStatus other than "pending"
     * */
    const isOnlyDirtyFieldCustomTags = hasOnlyCustomTagsChanged(
      form.formState.dirtyFields,
      form.formState.isDirty,
    );
    const isAcceptanceStatusModalShown =
      data.acceptanceStatus !== 'pending' &&
      data.publishStatus === 'published' &&
      isTierOne &&
      !isOnlyDirtyFieldCustomTags;
    if (isAcceptanceStatusModalShown) {
      acceptanceStatusModal.openModal();
      return;
    }
    if (
      data.nextPublishStatus &&
      data.publishStatus !== data.nextPublishStatus
    ) {
      publishStatusModal.openModal();
      return;
    }
    await updateEntityWithImagesAsync(id, {
      ...data,
      isPDFUpToDate: false,
    });
    /**If proposal presets are updated */
    if (config && arePresetsDirty) {
      await updateAsync({
        ...config,
        customPresets,
      });
    }

    form.reset({ ...data, saved: true }, { keepDirty: false });
    return;
  }

  function getPublishStatusModalProps() {
    const data = form.getValues();
    return confirmationModalOptions[data.publishStatus][data.nextPublishStatus];
  }

  /**Multiple users can edit same proposal in the same moment so we need to track incoming proposal updates and reinitialize our form. */
  useEffect(() => {
    if (
      proposal?.id &&
      isProposalBuilderLocation(pathname) &&
      !isDeepEqual(proposal, form.getValues(), 'saved')
    ) {
      form.reset({ ...proposal, nextPublishStatus: proposal.publishStatus });
    }
  }, [proposal, pathname]);

  return {
    submit,
    onCancel,
    onUpdate,
    getPublishStatusModalProps,
    publishStatusModal,
    acceptanceStatusModal,
    form,
  };
}
