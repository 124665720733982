import { useRecoilValue } from 'recoil';
import {
  overviewAtoms,
  useFilteredProposalsSelector,
  useOverviewFiltersState,
} from '../state';

export function useProposalOverview() {
  const query = useRecoilValue(overviewAtoms.overviewQuery);
  const [filters, setFilters] = useOverviewFiltersState();
  const filteredProposals = useFilteredProposalsSelector(query);

  function handleRemoveFilter(index: number) {
    setFilters((prevFilters) => [
      ...prevFilters.slice(0, index),
      ...prevFilters.slice(index + 1),
    ]);
  }

  return {
    filteredProposals,
    filters,
    handleRemoveFilter,
  };
}
