import React from 'react';
import { SharedButtons } from '../shared-buttons';

interface Props {
  publishStatus: PublishStatus;
}

export const ItemActions: React.FC<Props> = ({ publishStatus }) => (
  <div className="proposal-item__actions">
    <div className="actionbar__buttons actionbar__buttons__container">
      <SharedButtons
        isItemActions
        publishStatus={publishStatus}
        publishClassName="btn btn__primary btn__med"
        copyToClipboardClassName="btn btn__secondary btn__med"
        previewClassName="btn btn__outline btn__med"
        returnToDraftClassName="btn btn__outline btn__med"
        unpublishClassName="btn btn__outline btn__med"
      />
    </div>
  </div>
);
