import isHotkey from 'is-hotkey';
import { useState } from 'react';
import { CustomEditor } from 'shared/components';
import { UseModalHook, useModal } from 'shared/hooks';
import {
  closeLinkTooltip,
  insertLink,
  isLinkActive,
  isLinkHttpUrl,
  isListItem,
  toggleBlock,
  toggleMark,
  unwrapLink,
} from 'shared/utils';
import { Descendant, Editor, Element as SlateElement } from 'slate';
import { richTextHotkeys } from '../const';
import { isRichTextElementVariant, isRichTextLeafVariant } from '../utils';

export interface SlateApiReturn {
  text: string;
  url: string;
  linkModal: UseModalHook;
  getInitialValue: (defaultValue?: string) => Descendant[];
  onKeydown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onSetText: (text: string) => void;
  onSetUrl: (url: string) => void;
  handleLinkMouseDown: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleSave: () => void;
}

export function useSlateApi(editor: CustomEditor): SlateApiReturn {
  const [url, setUrl] = useState('');
  const [text, setText] = useState('');

  const linkModal = useModal();
  const { closeModal, openModal } = linkModal;

  function getInitialValue(defaultValue?: string): Descendant[] {
    return defaultValue
      ? JSON.parse(defaultValue)
      : [
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ];
  }

  function handleLinkMouseDown(
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) {
    event.preventDefault();
    if (!editor?.selection) return;
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    } else {
      const selectedText = editor.selection
        ? Editor.string(editor, editor.selection)
        : '';
      if (selectedText) setText(selectedText);
      openModal();
    }
    return;
  }

  function handleSave() {
    const updatedUrl = isLinkHttpUrl(url) ? url : `https://${url}`;
    insertLink(editor, updatedUrl, text);
    closeModal();
    setText('');
    setUrl('');
  }

  function onKeydown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' && editor) {
      if (isLinkActive(editor)) {
        event.preventDefault();
        closeLinkTooltip(editor);
      }

      const lastDescendant = [...editor.children].pop();
      if (!SlateElement.isElement(lastDescendant)) return;

      const lastChildElement = [...lastDescendant.children].pop();
      if (!SlateElement.isElement(lastChildElement)) return;

      if (isListItem(lastChildElement) && !lastChildElement.children[0]?.text) {
        toggleBlock(editor, lastChildElement.type as 'ol' | 'ul');
        return;
      }
    }

    for (const hotkey in richTextHotkeys) {
      if (isHotkey(hotkey, event as unknown as KeyboardEvent) && editor) {
        event.preventDefault();
        const mark = richTextHotkeys[hotkey];

        if (mark === 'link') {
          handleLinkMouseDown(event);
          return;
        }

        if (isRichTextLeafVariant(mark)) {
          toggleMark(editor, mark);
          return;
        }

        if (isRichTextElementVariant(mark)) {
          toggleBlock(editor, mark);
          return;
        }

        return;
      }
    }
  }

  return {
    text,
    url,
    linkModal,
    getInitialValue,
    onKeydown,
    handleLinkMouseDown,
    handleSave,
    onSetText: setText,
    onSetUrl: setUrl,
  };
}
