import { useFunction } from 'modules/firebase';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import { adminAction } from '../redux';

export const useUserReferrals = () => {
  const getReferrals = useFunction<undefined, ReferralAnalytics[]>(
    'getReferrals',
  );
  const dispatch = useDispatch();

  const getUserReferrals = useCallback(async () => {
    try {
      dispatch(adminAction.loading());
      const { data } = await getReferrals();
      if (!data.success) throw new Error(data.message);
      dispatch(adminAction.addUserReferralsData(data.payload));
    } catch (error) {
      adminAction.error(error);
      toast.error('Failed to get user referrals. Please try again.');
    }
  }, [getReferrals]);

  useEffect(() => {
    if (!getReferrals) return;
    getUserReferrals();
  }, [getReferrals]);
};
