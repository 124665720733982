import { useSelectCustomTag } from 'modules/proposals-overview';
import { subscriptionSelectors } from 'modules/subscription';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { CustomTagFreemiumSelect } from './CustomTagFreemiumSelect';
import {
  CustomTagInput,
  CustomTagSelectResults,
  CustomTagTooltip,
} from './custom-tags';

export const CustomTagSelect: React.FC = () => {
  const { register, setValue } = useFormContext<PublishStatusAware<Proposal>>();

  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const {
    customTags,
    wrapperRef,
    inputValue,
    showResults,
    recentSearches,
    isFocused,
    inputRef,
    setIsFocused,
    handleFocus,
    setShowResults,
    handleAddCustomTag,
    handleKeyDown,
    handleSearch,
    removeTagAtIndex,
  } = useSelectCustomTag(setValue);

  if (!isTierOne) {
    return <CustomTagFreemiumSelect />;
  }

  return (
    <div ref={wrapperRef}>
      <div className="f f--align-baseline s-bottom--tny">
        <p className="t-text-2 t-label s-right--sml">
          <FormattedMessage id="inputs.custom-tags.label" />
        </p>
        <CustomTagTooltip />
      </div>
      <CustomTagInput
        inputRef={inputRef}
        isFocused={isFocused}
        inputValue={inputValue}
        customTags={customTags}
        onSetIsFocused={setIsFocused}
        handleFocus={handleFocus}
        onSetShowResults={setShowResults}
        handleRemoveTagAtIndex={removeTagAtIndex}
        handleKeyDown={handleKeyDown}
        handleSearch={handleSearch}
        register={register}
      />

      {showResults && (
        <CustomTagSelectResults
          inputValue={inputValue}
          recentSearches={recentSearches}
          handleAddCustomTag={handleAddCustomTag}
        />
      )}
    </div>
  );
};
