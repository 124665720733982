import userAvatarIconOff from 'assets/icons/icon-user-avatar-off-32.svg';
import userAvatarIconOn from 'assets/icons/icon-user-avatar-on-32.svg';
import { mobileNavDropdownVisibleSelector } from 'modules/dashboard';
import { settingsSelector } from 'modules/settings';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Loading } from '../loading';

export const MobileNavDropdown: React.FC = () => {
  const mobileNavdropdownVisible = useRecoilValue(
    mobileNavDropdownVisibleSelector,
  );
  const setMobileNavdropdownVisible = useSetRecoilState(
    mobileNavDropdownVisibleSelector,
  );
  const { config } = useSelector(settingsSelector.getState);

  const handleOpenDropdownTogle = () => {
    setMobileNavdropdownVisible(!mobileNavdropdownVisible);
  };

  function handleUserAvatar() {
    if (config?.companyImage) return config?.companyImage;
    if (mobileNavdropdownVisible) return userAvatarIconOn;
    return userAvatarIconOff;
  }

  if (config === undefined) return <Loading type="user-avatar" />;

  return (
    <section className="companyselector">
      <div onClick={handleOpenDropdownTogle}>
        <img
          src={handleUserAvatar()}
          className="user-avatar__icon"
          alt="userAvatarIcon"
        />
      </div>
    </section>
  );
};
