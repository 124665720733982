import infoIcon from 'assets/icons/icon-info-16.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  className?: string;
}

export const ProductVatExcludedTooltip: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={`product-vat-excluded-tooltip__container s-left--tny ${
        className || ''
      }`}
    >
      <img
        src={infoIcon}
        alt="Info icon"
        className="visibility-selector-modal__icon"
      />
      <div className="product-vat-excluded-tooltip f f--align-center">
        <p className="product-vat-excluded-tooltip__content">
          <FormattedMessage id="current-plan-card.tooltip.vat-excluded" />
        </p>
        <div className="product-vat-excluded-tooltip__arrow" />
      </div>
    </div>
  );
};
