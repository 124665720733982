import twitterIcon from 'assets/icons/icon-x-16.svg';
import React from 'react';
import { Button } from 'shared/components';

interface Props {
  authLoading: boolean;
  loginWithTwitter: () => void;
}

export const TwitterLogin: React.FC<Props> = ({
  authLoading,
  loginWithTwitter,
}) => {
  return (
    <Button
      style="outline"
      size="lrg"
      disabled={authLoading}
      className="u-width--full"
      onClick={loginWithTwitter}
      data-cy="twitter-login-btn"
    >
      <img src={twitterIcon} alt="Twitter Icon" className="btn__icon" />
    </Button>
  );
};
