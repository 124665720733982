import { useEffect } from 'react';
import { SetterOrUpdater } from 'recoil';

/**Close modal if it is open on cleanup hook */

export function useCloseModalOnCleanup(setModalOpen: SetterOrUpdater<boolean>) {
  /**Close modal if it is open on cleanup hook */
  useEffect(() => {
    return () => setModalOpen(false);
  }, []);
}
