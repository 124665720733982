import React from 'react';

export const TableOfContentsMediaIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className="btn__icon"
      fill="none"
    >
      <g clipPath="url(#clip0_11816_27279)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V11.2929L3.14645 9.14645C3.34171 8.95118 3.65829 8.95118 3.85355 9.14645L5.5 10.7929L10.1464 6.14645C10.3417 5.95118 10.6583 5.95118 10.8536 6.14645L15 10.2929V2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H2ZM0 12.4999V12.5001V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V11.501V11.499V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V12.4999ZM15 14V11.7071L10.5 7.20711L5.85355 11.8536C5.65829 12.0488 5.34171 12.0488 5.14645 11.8536L3.5 10.2071L1 12.7071V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14ZM4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5ZM5 3C3.89543 3 3 3.89543 3 5C3 6.10457 3.89543 7 5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3Z"
          fill="#A8A8A8"
        />
      </g>
      <defs>
        <clipPath id="clip0_11816_27279">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
