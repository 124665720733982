import { useEffect } from 'react';

/**
 * This hook is used to execute some function before the user leaves the page with hard-refresh or closing the tab.
 */

export function useBeforeUnload(
  functionToExecute: () => void,
  shouldPrevent?: boolean,
) {
  function handleBeforeUnload(event: BeforeUnloadEvent) {
    if (shouldPrevent) event.preventDefault();
    functionToExecute();
  }

  useEffect(() => {
    // Execute this function only on hard refresh and leaving the tab
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);
}
