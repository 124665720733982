import { Sketch } from '@uiw/react-color';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useScreenWidth } from 'shared/hooks';

interface ListItemProps {
  colorKey: keyof ProposalColor;
  titleId: string;
  proposal: PublishStatusAware<Proposal> | Proposal;
  handleActive(): void;
  handleColorChange(colorHex: string, colorKey: keyof ProposalColor): void;
}

export const ProposalAppearanceAccordionColorListItemModal: React.FC<
  ListItemProps
> = ({ colorKey, titleId, proposal, handleActive, handleColorChange }) => {
  const width = useScreenWidth();

  return (
    <Modal close={handleActive} isDashboardModal>
      <ModalHeader isConfirmationModal>
        <p className="text--xl__responsive">
          <FormattedMessage id={titleId} />
        </p>
      </ModalHeader>
      <ModalContent>
        <Sketch
          className="proposal__summary__accordion__color__picker"
          color={proposal?.style?.color?.[colorKey]}
          onChange={(color) => handleColorChange(color.hex, colorKey)}
          style={{ width: `${width - 44}px` }}
          presetColors={['#2B358D', '##8039FF', '#CE3582']} // TO-DO @markoboras0712 add preset colors to user
        />
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleActive} type="button" size="lrg" style="outline">
          <FormattedMessage id="buttons.close" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
