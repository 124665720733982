import { subDays } from 'date-fns';

export function getStatisticsStartDate(option: ProposalStatisticsOption) {
  const now = new Date();
  switch (option) {
    case 'last7Days':
      return subDays(now, 7);
    case 'last30Days':
      return subDays(now, 30);
    case 'last90Days':
      return subDays(now, 90);
    case 'last365Days':
      return subDays(now, 365);
    default:
      return now;
  }
}
