import { Sketch } from '@uiw/react-color';
import { useScrollIntoView } from 'modules/authentication';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useClickOutsideElement, useMobile } from 'shared/hooks';
import { ProposalAppearanceAccordionColorListItemModal } from './ProposalAppearanceAccordionColorListItemModal';

interface ListItemProps {
  colorKey: keyof ProposalColor;
  titleId: string;
  proposal: PublishStatusAware<Proposal> | Proposal;
  handleColorChange(colorHex: string, colorKey: keyof ProposalColor): void;
}

export const ProposalAppearanceAccordionColorListItem: React.FC<
  ListItemProps
> = ({ colorKey, titleId, proposal, handleColorChange }) => {
  const isMobile = useMobile();
  const [isActive, setIsActive] = useState(false);
  const { onFocus } = useScrollIntoView();
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const ref = useClickOutsideElement<HTMLDivElement>(
    () => !isMobile && setIsActive(false),
    true,
    true,
  );

  function handleActive(event?: React.MouseEvent<HTMLDivElement>) {
    if (
      event?.target instanceof HTMLElement &&
      !event.target.closest('#color-picker')
    ) {
      if (colorPickerRef.current) onFocus(colorPickerRef.current);
      if (isActive) {
        setIsActive(false);
      } else {
        setIsActive(true);
      }
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      const color = proposal?.style?.color?.[colorKey];
      handleColorChange(color, colorKey);
      setIsActive(false);
    }
  }

  return (
    <>
      <div
        ref={ref}
        onClick={handleActive}
        className="proposal__summary__accordion__color__item__container"
      >
        <div
          className={`proposal__summary__accordion__item__color ${
            isActive && 'proposal__summary__accordion__item__color--active'
          }`}
          style={{ backgroundColor: proposal?.style?.color?.[colorKey] }}
        />

        {isActive && !isMobile && (
          <div id="color-picker" onKeyDown={handleKeyDown} tabIndex={0}>
            <Sketch
              className="proposal__summary__accordion__color__picker"
              color={proposal?.style?.color?.[colorKey]}
              onChange={(color) => handleColorChange(color.hex, colorKey)}
              presetColors={['#2B358D', '#8039FF', '#CE3582', '#FFFFFF']} // TO-DO @markoboras0712 add preset colors to user
            />
          </div>
        )}
        <div className="proposal__summary__accordion__color__item__content">
          <span className="t-text-2 text--sm t-bold">
            <FormattedMessage id={titleId} />
          </span>
          <span className="t-text-2 text--xxs__responsive">
            {proposal?.style?.color?.[colorKey]}
          </span>
        </div>
      </div>
      {isMobile && isActive && (
        <ProposalAppearanceAccordionColorListItemModal
          colorKey={colorKey}
          titleId={titleId}
          handleActive={handleActive}
          handleColorChange={handleColorChange}
          proposal={proposal}
        />
      )}
    </>
  );
};
