import { OptionType } from 'shared/components';

export const customFonts = [
  {
    name: 'Aeonik',
  },
  {
    name: 'Bw Gradual',
  },
  {
    name: 'Inter',
  },
  {
    name: 'Space Grotesk',
  },
  {
    name: 'Atkinson Hyperlegible',
  },
  {
    name: 'Poppins',
  },
  {
    name: 'Fira Sans',
  },
  {
    name: 'IBM Plex Sans',
  },
  {
    name: 'PT Serif',
  },
  {
    name: 'EB Garamond',
  },
  {
    name: 'Open Sans',
  },
];

export const fontOptions: OptionType[] = customFonts.map((f) => {
  return {
    value: f.name,
    label: f.name,
  };
});
