import closeIcon from 'assets/icons/icon-close.svg';
import React from 'react';

interface Props {
  value: string;
  onDelete: () => void;
}

export const CustomTagInputItem: React.FC<Props> = ({ value, onDelete }) => (
  <div className="custom-tags__input__item">
    <span className="t-label custom-tags__input__item__label">{value}</span>
    <div className="custom-tags__input__item__icon">
      <img
        onClick={onDelete}
        className="f--shrink-zero"
        src={closeIcon}
        alt="CloseIcon"
      />
    </div>
  </div>
);
