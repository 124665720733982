import { useLocation } from '@reach/router';
import {
  mobileNavDropdownVisibleSelector,
  mobileSearchVisibleSelector,
} from 'modules/dashboard';
import React, { PropsWithChildren, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMobile } from 'shared/hooks';
import { MobileNavDropdownContent } from '../navigation';
import { MainMobile } from './MainMobile';

interface Props extends PropsWithChildren {
  contentClassName?: string;
  settingsBackComponent?: React.FC;
}

export const Main: React.FC<Props> = ({
  settingsBackComponent: SettingsBackComponent,
  children,
}) => {
  const { pathname } = useLocation();
  const isMobile = useMobile();
  const mobileNavdropdownVisible = useRecoilValue(
    mobileNavDropdownVisibleSelector,
  );
  const [mobileSearchVisible, setMobileSearchVisible] = useRecoilState(
    mobileSearchVisibleSelector,
  );

  const readyToRenderMobileNavDropdown = mobileNavdropdownVisible && isMobile;
  const readyToRenderMobileSearch = mobileSearchVisible && isMobile;

  /** Hide mobile nav dropdown content on location change */
  useEffect(() => {
    setMobileSearchVisible(false);
  }, [pathname]);

  if (readyToRenderMobileSearch) {
    return <></>;
  }

  if (readyToRenderMobileNavDropdown) {
    return (
      <div className="companyselector-mobile__dropdown__container">
        <MobileNavDropdownContent />
      </div>
    );
  }

  if (isMobile) {
    return (
      <MainMobile>
        {SettingsBackComponent && <SettingsBackComponent />}
        {children}
      </MainMobile>
    );
  }

  return <>{children}</>;
};
