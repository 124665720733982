import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalContent, ModalHeader } from 'shared/components';
import { usePricingSelectPlan, useStripeProducts } from '../hooks';
import { ProductItem } from './ProductItem';
import { SubscriptionSelectModalMobile } from './SubscriptionSelectModalMobile';

export const SubscriptionSelectModal: React.FC<ModalProps> = ({ onClose }) => {
  const { plusYearlyProduct } = useStripeProducts();
  const { isCheckoutLoading, handleCheckout } = usePricingSelectPlan();

  return (
    <Modal
      containerClassName="subscription-product__group__modal"
      isPropozePlusModal
      close={onClose}
    >
      <ModalHeader headerSelector="propoze-plus-modal-title">
        <p className="text--xl__responsive">
          <FormattedMessage id="subscription-select-modal.heading" />
        </p>
      </ModalHeader>
      <ModalContent
        className="subscription-product__group__content"
        contentSelector="propoze-plus-modal-content"
      >
        {plusYearlyProduct && (
          <SubscriptionSelectModalMobile product={plusYearlyProduct} />
        )}
        <div className="subscription-product__group">
          {plusYearlyProduct && (
            <ProductItem
              key={plusYearlyProduct.id}
              product={plusYearlyProduct}
              onSelect={handleCheckout}
              name={plusYearlyProduct.name}
              isCheckoutLoading={isCheckoutLoading}
            />
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};
