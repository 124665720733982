import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  FieldWrapper,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';

interface Props extends ModalProps {
  defaultText: string;
  defaultUrl?: string;
  onSetText: (text: string) => void;
  onSetUrl: (url: string) => void;
  onHandleSave: () => void;
}

export const RichTextToolbarLinkModal: React.FC<Props> = ({
  defaultText,
  defaultUrl,
  onClose,
  onSetText,
  onSetUrl,
  onHandleSave,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    onSetText?.(event.target.value);
  }

  function handleUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    onSetUrl?.(event.target.value);
  }

  return (
    <Modal isDashboardModal close={onClose}>
      <ModalHeader>
        <p className="text--xl__responsive">
          <FormattedMessage id="inputs.link.title" />
        </p>
      </ModalHeader>
      <ModalContent>
        <div className="field">
          <FieldWrapper name="name" labelId="inputs.link.text.label">
            <input
              type="text"
              className="input input__box input__med"
              autoFocus
              defaultValue={defaultText}
              onChange={handleTextChange}
              onFocus={handleFocus}
              placeholder={formatMessage({
                id: 'inputs.link.text.placeholder',
              })}
            />
          </FieldWrapper>
        </div>

        <FieldWrapper name="url" isRequired labelId="inputs.link.url.label">
          <input
            type="url"
            className="input input__box input__med"
            onChange={handleUrlChange}
            onFocus={handleFocus}
            defaultValue={defaultUrl}
            placeholder={formatMessage({
              id: 'inputs.link.url.placeholder',
            })}
          />
        </FieldWrapper>
      </ModalContent>

      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            size="lrg"
            style="outline"
            onClick={onClose}
            ref={closeButtonRef}
            btnSelector="cancel-btn"
          >
            <FormattedMessage id="buttons.cancel" />
          </Button>
          <Button
            size="lrg"
            style="primary"
            type="submit"
            className="account-delete-modal__action-btn"
            onClick={onHandleSave}
            disabled={!defaultUrl}
            ref={submitButtonRef}
            btnSelector="info-modal-delete-account-btn"
          >
            <FormattedMessage id="buttons.save" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
