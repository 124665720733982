import React from 'react';

export const BackIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
      className="btn__icon btn__icon__left"
      fill="none"
    >
      <path
        d="M11.2498 4.25006H2.56034L5.03009 1.78031C5.16671 1.63886 5.2423 1.44941 5.2406 1.25276C5.23889 1.05611 5.16001 0.868003 5.02095 0.728947C4.8819 0.589891 4.69379 0.511015 4.49714 0.509306C4.30049 0.507597 4.11104 0.583193 3.96959 0.719811L0.21959 4.46981C0.0789866 4.61046 0 4.80119 0 5.00006C0 5.19893 0.0789866 5.38967 0.21959 5.53031L3.96959 9.28031C4.03877 9.35194 4.12153 9.40908 4.21304 9.44839C4.30454 9.48769 4.40295 9.50838 4.50254 9.50925C4.60212 9.51011 4.70088 9.49114 4.79306 9.45343C4.88523 9.41572 4.96897 9.36003 5.03939 9.28961C5.10981 9.21919 5.1655 9.13545 5.20321 9.04328C5.24092 8.9511 5.25989 8.85235 5.25903 8.75276C5.25816 8.65318 5.23747 8.55476 5.19817 8.46326C5.15886 8.37176 5.10172 8.289 5.03009 8.21981L2.56034 5.75006H11.2498C11.4488 5.75006 11.6395 5.67104 11.7802 5.53039C11.9208 5.38974 11.9998 5.19897 11.9998 5.00006C11.9998 4.80115 11.9208 4.61038 11.7802 4.46973C11.6395 4.32908 11.4488 4.25006 11.2498 4.25006Z"
        fill="#3B3B3B"
      />
    </svg>
  );
};
