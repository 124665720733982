import {
  AuthorizationCodeForm,
  proposalsAtoms,
  useClientEmailSelector,
  usePublicProposal,
} from 'modules/proposals';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import {
  AcceptIcon,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PencilIcon,
  RejectIcon,
} from 'shared/components';
import { ProposalAcceptanceAction } from './ProposalAcceptanceAction';

interface Props {
  authorized: boolean;
  organizationId: string;
  proposalId: string;
  close: VoidFunction;
}

export const ProposalAcceptanceModal: React.FC<Props> = ({
  authorized,
  proposalId,
  organizationId,
  close,
}) => {
  const setAccessLoading = useSetRecoilState(proposalsAtoms.accessLoading);
  const { acceptProposal, declineProposal, suggestChanges } =
    usePublicProposal();
  const clientEmail = useClientEmailSelector();

  const [step, setStep] = useState<'email' | 'auth-code' | 'actions'>(
    'actions',
  );
  const [responseStatus, setResponseStatus] =
    useState<ProposalRespondStatus | null>(null);
  const [feedback, setFeedback] = useState('');

  function getCallable() {
    switch (responseStatus) {
      case 'accept':
        return acceptProposal;
      case 'reject':
        return declineProposal;
      default:
        return suggestChanges;
    }
  }

  async function updateProposalStatus() {
    if (!responseStatus) return;

    const callable = getCallable();
    setAccessLoading(true);
    callable({ organizationId, proposalId, clientEmail, feedback }).then(() =>
      setAccessLoading(false),
    );
    close();
  }

  function onActionSelected() {
    if (authorized) {
      updateProposalStatus();
      return;
    }

    setStep('email');
  }

  return (
    <Modal
      close={close}
      closeIconClassName="authorization-code-form__close__icon"
    >
      <ModalHeader
        isConfirmationModal
        headerSelector="proposal-acceptance-header"
      >
        <p className="text--xl__responsive s-right--lrg">
          <FormattedMessage
            id={`proposal-acceptance-controller.modal.heading.${step}`}
          />
        </p>
      </ModalHeader>
      {step === 'actions' && (
        <ModalContent className="proposal-acceptance-actions__container">
          <ProposalAcceptanceAction
            text="Accept proposal"
            feedback={feedback}
            onSetFeedback={setFeedback}
            Icon={AcceptIcon}
            isActive={responseStatus === 'accept'}
            responseStatus={responseStatus}
            onActionSelected={() => setResponseStatus('accept')}
          />
          <ProposalAcceptanceAction
            text="Suggest changes"
            Icon={PencilIcon}
            feedback={feedback}
            onSetFeedback={setFeedback}
            isActive={responseStatus === 'suggest-changes'}
            responseStatus={responseStatus}
            onActionSelected={() => setResponseStatus('suggest-changes')}
          />
          <div className="divider-24" />
          <p className="t-text-1 text--xs m-bottom-12">Not happy?</p>{' '}
          <ProposalAcceptanceAction
            text="Decline proposal"
            Icon={RejectIcon}
            feedback={feedback}
            onSetFeedback={setFeedback}
            isActive={responseStatus === 'reject'}
            responseStatus={responseStatus}
            onActionSelected={() => setResponseStatus('reject')}
          />
        </ModalContent>
      )}
      <ModalFooter>
        {step === 'actions' ? (
          <div className="btn-group m-left-auto--desktop">
            <Button
              size="lrg"
              style="primary"
              btnSelector="accept-proposal-btn"
              className="u--width--full--mobile"
              disabled={!responseStatus}
              onClick={onActionSelected}
            >
              Submit response
            </Button>
          </div>
        ) : (
          <div className="authorization-code-form__content">
            <AuthorizationCodeForm
              organizationId={organizationId}
              proposalId={proposalId}
              onCancel={() => setStep('actions')}
              onCodeSent={() => setStep('auth-code')}
              onAuthorized={() => updateProposalStatus()}
            />
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
};
