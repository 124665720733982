/**
 * Determines if a service segment should be visible.
 *
 * This function takes a `ServiceSegment` object and a boolean indicating whether the current view is a preview.
 * If the view is not a preview, the function returns true.
 * If the view is a preview, the function checks if the segment has a service or a unit, and returns true if it does, and false otherwise.
 *
 * @param segment - The `ServiceSegment` object to check.
 * @param isPreview - A boolean indicating whether the current view is a preview.
 * @returns A boolean indicating whether the service segment should be visible.
 */
export function isServiceSegmentVisible(
  segment: ServiceSegment,
  isPreview?: boolean,
): boolean {
  if (!isPreview) return true;

  const hasServiceOrUnit = Boolean(segment.service || segment.unit);
  return hasServiceOrUnit;
}
