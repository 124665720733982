import { firebaseAtoms } from 'modules/firebase';
import React, { memo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { MediaListItemControls } from './MediaListItemControls';

interface Props {
  file: ProposalFile;
  index: number;
  proposalFilesLength: number;
  onRemoveFile: (file: ProposalFile) => void;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

export const MediaListItemImage: React.FC<Props> = memo(
  ({ file, index, proposalFilesLength, onRemoveFile, moveItem }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const blobUrlCache = useRecoilValue(firebaseAtoms.blobUrlCache);

    return (
      <div
        className={`media-input__image ${
          isExpanded
            ? 'media-input__image--expanded'
            : 'media-input__image--collapsed'
        }`}
      >
        <MediaListItemControls
          file={file}
          index={index}
          proposalFilesLength={proposalFilesLength}
          isExpanded={isExpanded}
          onRemoveFile={onRemoveFile}
          onSetIsExpanded={setIsExpanded}
          moveItem={moveItem}
        />
        <img
          key={blobUrlCache[file.name] || file.url}
          src={blobUrlCache[file.name] || file.url}
          alt={blobUrlCache[file.name] || file.name}
          data-cy={`media-file-${index}`}
          className="media-input__list__image"
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.file.name === nextProps.file.name;
  },
);
