import { useDualPricing, useProposalPrices } from 'modules/proposals';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useISOCountry } from 'shared/hooks';
import {
  DiscountSegmentPrice,
  SubtotalSegmentPrice,
  TotalSegmentPrice,
} from './fragments';

interface Props {
  index: number;
}

export const SegmentPrices: React.FC<Props> = ({ index }) => {
  const currency = useWatch({ name: 'currency' });
  const pricePerUnit = useWatch({ name: `segments.${index}.pricePerUnit` });
  const quantity = useWatch({ name: `segments.${index}.quantity` });
  const discount = useWatch({ name: `segments.${index}.discount` });
  const { ISOCountry } = useISOCountry();
  const { exchangeRate } = useDualPricing(currency);

  const { totalAmount, discountAmount, subtotalAmount } = useProposalPrices(
    pricePerUnit,
    quantity,
    discount,
  );

  return (
    <>
      <div className="segment-prices__total">
        <div className="segment-prices__subtotal">
          <span className="segment-prices__subtotal-label t-eta">
            <FormattedMessage id="proposals.prices.total" />
          </span>
          <div>
            <TotalSegmentPrice
              currency={currency}
              totalAmount={totalAmount}
              exchangeRate={exchangeRate}
              ISOCountry={ISOCountry}
            />
          </div>
        </div>
      </div>

      <div className="segment-prices__total">
        <div className="segment-prices__subtotal">
          <span className="segment-prices__subtotal-label t-eta">
            <FormattedMessage id="proposals.prices.discount" />
          </span>
          <div>
            <DiscountSegmentPrice
              currency={currency}
              discountAmount={discountAmount}
              exchangeRate={exchangeRate}
              ISOCountry={ISOCountry}
            />
          </div>
        </div>
      </div>

      <div className="segment-prices__total">
        <div className="segment-prices__subtotal">
          <strong className="segment-prices__subtotal-label t-eta">
            <FormattedMessage id="proposals.prices.subtotal" />
          </strong>
          <div>
            <SubtotalSegmentPrice
              currency={currency}
              subtotalAmount={subtotalAmount}
              exchangeRate={exchangeRate}
              ISOCountry={ISOCountry}
            />
          </div>
        </div>
      </div>
    </>
  );
};
