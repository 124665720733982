import {
  useAuthorization,
  useOrganizationIdSelector,
} from 'modules/authorization';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button, EmailInput, Modal } from 'shared/components';
import { useSubmitOnEnter } from 'shared/hooks';

interface Props extends ModalProps {
  item?: OrganizationUser;
}

export const UserModal: React.FC<Props> = (props) => {
  const organizationId = useOrganizationIdSelector();

  const { createEntityAsync } = useAuthorization();
  const form = useForm<OrganizationUser>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();

  useEffect(() => {
    form.register('organizationId');
    form.setValue('organizationId', organizationId);

    form.register('role');
    form.setValue('role', props.item?.role || 'moderator');

    form.register('status');
    form.setValue('status', props.item?.status || 'pending');
  }, [props.item, form.setValue]);

  return (
    <Modal {...props}>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            createEntityAsync(data);
            props.onClose();
          })}
        >
          <header className="modal__header text--xl__responsive">
            <h1 className="t-delta">
              {props.item ? (
                <FormattedMessage id="buttons.edit-user" />
              ) : (
                <FormattedMessage id="buttons.add-new-user" />
              )}
            </h1>
          </header>
          <div className="modal__content">
            <div className="field">
              <EmailInput />
            </div>
          </div>
          <div className="modal__footer">
            <div className="btn-group btn-group--simple">
              <Button ref={submitButtonRef} style="primary" size="lrg">
                <FormattedMessage id="buttons.save" />
              </Button>
              <Button onClick={props.onClose} size="lrg" style="outline">
                <FormattedMessage id="buttons.cancel" />
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
