import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  onUpdateClientData: () => void;
}

export const ClientUpdateToast: React.FC<Props> = ({ onUpdateClientData }) => {
  return (
    <div className="f f--justify-between f--align-start g-16">
      <div className="f f--col">
        <div className="f f--align-center">
          <p className="t-text-1 text--sm u-maxwidth--380">
            <strong className="text--xsm">
              <FormattedMessage id="client-update-toast.client-information" />
            </strong>{' '}
            <FormattedMessage id="client-update-toast.description" />
          </p>
        </div>
        <Button
          type="submit"
          size="med"
          style="primary"
          className="client__update__toast__btn"
          onClick={onUpdateClientData}
          btnSelector="client-update-toast-btn"
        >
          <FormattedMessage id="client-update-toast.button" />
        </Button>
      </div>
    </div>
  );
};
