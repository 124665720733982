import { useFunction } from 'modules/firebase';
import { useEffect } from 'react';

export function useProposalViewed(
  hasPermissions: boolean,
  organizationId: string,
  proposalId: string,
) {
  const setProposalLastViewedDateTimeCallable =
    useFunction<SetProposalLastViewedDateTimeData>(
      'setProposalLastViewedDateTime',
    );

  async function incrementProposalView(
    organizationId: string,
    proposalId: string,
  ) {
    try {
      await setProposalLastViewedDateTimeCallable({
        organizationId,
        proposalId,
      });
    } catch {
      console.error('Failed to increment proposal view');
    }
  }

  useEffect(() => {
    if (hasPermissions) return;

    incrementProposalView(organizationId, proposalId);
  }, [hasPermissions]);
}
