import closeIcon from 'assets/icons/icon-close-12.svg';
import magnifierIcon from 'assets/icons/icon-magnifier-12.svg';
import { useAnimation } from 'framer-motion';
import { overviewAtoms } from 'modules/proposals-overview';
import React, { HTMLProps, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { useMobile } from 'shared/hooks';
import { getCommandKeyCopy } from 'shared/utils';

interface Props extends HTMLProps<HTMLInputElement> {
  value: string;
  showResults?: boolean;
  onSetQuery: (query: string) => void;
}

export const SearchForm: React.FC<Props> = ({
  showResults,
  value,
  autoFocus,
  placeholder,
  onFocus,
  onSetQuery,
  onChange,
}) => {
  const isMobile = useMobile();
  const control = useAnimation();
  const inputRef = useRef<HTMLInputElement>(null);
  const setOverviewQuery = useSetRecoilState(overviewAtoms.overviewQuery);

  function focusInput() {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }

  function handleClickInside() {
    focusInput();
    control.start({
      width: `${isMobile ? '100%' : '540px'}`,
      transform: 'translateX(0%)',
    });
  }

  function handleKeyPress(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      handleClickInside();
    }
    if (event.key === 'Escape') {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }

  function handleClickOutside() {
    control.start({ width: '100%', transform: 'translateX(0%)' });
  }

  function handleCancel() {
    onSetQuery('');
    setOverviewQuery('');
  }

  /**TODO @markoboras0712 after we write user stories and thoroughly think about all possible cases finish this. */
  // function handleEnterPress(e: React.KeyboardEvent<HTMLInputElement>) {
  //   if (e.key === 'Enter') {
  //     setOverviewQuery(e.currentTarget.value);
  //   }
  // }

  useEffect(() => {
    if (showResults) {
      handleClickInside();
    } else {
      handleClickOutside();
    }
  }, [showResults]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div onClick={!isMobile ? handleClickInside : undefined}>
      <input
        className="input input__box search__wrapper__input input__med"
        type="text"
        ref={inputRef}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        // onKeyDown={handleEnterPress}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
      <img
        src={magnifierIcon}
        alt="MagnifierIcon"
        className="search__wrapper__search--icon"
      />
      {!showResults && (
        <div className="hotkey__search__icon">
          <span className="hothey__search__text">
            {getCommandKeyCopy()} + K
          </span>
        </div>
      )}

      {Boolean(value?.length) && showResults && (
        <img
          src={closeIcon}
          alt="CloseIcon"
          className="search__wrapper__close--icon"
          data-cy="proposal-search-cancel-icon"
          onClick={handleCancel}
        />
      )}
    </div>
  );
};
