import sparksIcon from 'assets/icons/icon-sparks-16.svg';
import {
  FreeTrialEndedWrapper,
  PropozePlusUpgradeModal,
  usePropozePlusModal,
} from 'modules/subscription';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  onCloseSlidingMenu?: (menuOpen: boolean) => void;
}

export const FreeTrialEndedNotice: React.FC<Props> = ({
  onCloseSlidingMenu,
}) => {
  const { isModalVisible, openModal, closeModal } =
    usePropozePlusModal(onCloseSlidingMenu);

  return (
    <FreeTrialEndedWrapper>
      <div
        className="free-trial-ended-notice__wrapper c-pointer"
        data-cy="free-trial-ended-notice"
        onClick={openModal}
      >
        <img src={sparksIcon} alt="Sparks icon" className="btn__icon" />
        <div className="d--ib">
          <Button
            type="button"
            onClick={openModal}
            className="btn__slim t-primary t-bold text--xsm"
            btnSelector="propoze-plus-notice-upgrade-btn"
          >
            <FormattedMessage id="free-trial-ended-notice.description-1" />
          </Button>
          <span className="text--xsm t-text-1">
            <FormattedMessage id="free-trial-ended-notice.description-2" />{' '}
          </span>
        </div>
        {isModalVisible && <PropozePlusUpgradeModal onClose={closeModal} />}
      </div>
    </FreeTrialEndedWrapper>
  );
};
