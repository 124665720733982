import React from 'react';
import { SharedButtonsClassNames } from '../SharedButtonsClassNames';
import { CopyToClipboardButton } from '../copy-to-clipboard-button';
import { PreviewButton } from '../preview-button';

interface Props extends SharedButtonsClassNames {
  isEditorActions?: boolean;
  isItemActions?: boolean;
}

export const PublishedSharedButtons: React.FC<Props> = ({
  isEditorActions,
  isItemActions,
  copyToClipboardClassName,
  previewClassName,
}) => {
  return (
    <>
      <CopyToClipboardButton
        className={copyToClipboardClassName}
        btnSelector="published-page-copy-link-btn"
      />
      {isEditorActions && (
        <div className="actionbar__shared__buttons">
          <PreviewButton
            className={`${previewClassName} ${isEditorActions ? 'actionbar__editor__preview' : ''}`}
            btnSelector="proposal-preview-btn"
            isEditorActions={isEditorActions}
          />
        </div>
      )}
      {isItemActions && (
        <PreviewButton
          className={previewClassName}
          btnSelector="proposal-preview-btn"
        />
      )}
    </>
  );
};
