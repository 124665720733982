import assertNever from 'assert-never';
import React from 'react';
import { ActionDrawerClassNames } from './ActionDrawerClassNames';
import { ArchivedDrawerButtons } from './archived-drawer-buttons';
import { DraftDrawerButtons } from './draft-drawer-buttons';
import { PublishedDrawerButtons } from './published-drawer-buttons';
import { TemplateDrawerButtons } from './template-drawer-buttons';

interface Props extends ActionDrawerClassNames {
  publishStatus: PublishStatus;
  proposal: Proposal;
  isItemActions?: boolean;
  isEditorActions?: boolean;
  isOverview?: boolean;
}

export const ActionDrawerButtons: React.FC<Props> = ({
  publishStatus,
  proposal,
  isItemActions,
  isEditorActions,
  isOverview,
  ...rest
}) => {
  switch (publishStatus) {
    case 'draft':
      return (
        <DraftDrawerButtons
          isOverview={isOverview}
          proposal={proposal}
          {...rest}
        />
      );
    case 'published':
      return (
        <PublishedDrawerButtons
          proposal={proposal}
          isOverview={isOverview}
          isEditorActions={isEditorActions}
          isItemActions={isItemActions}
          {...rest}
        />
      );
    case 'template':
      return (
        <TemplateDrawerButtons
          proposal={proposal}
          isOverview={isOverview}
          {...rest}
        />
      );
    case 'archived':
      return (
        <ArchivedDrawerButtons
          proposal={proposal}
          isOverview={isOverview}
          {...rest}
        />
      );
    default:
      return assertNever(publishStatus);
  }
};
