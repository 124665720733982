/**
 * Determines the operating system of the user's device.
 *
 * This function uses the `navigator.platform` property to get the platform of the user's device.
 * It then checks if this platform is in a list of Macintosh platforms.
 * If it is, the function returns 'macOS'.
 * If it's not, the function returns 'Windows/Linux'.
 *
 * @returns A string representing the operating system of the user's device. Possible values are 'macOS' and 'Windows/Linux'.
 */

export function getOperatingSystem() {
  const platform = window.navigator.platform;
  const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];

  if (macPlatforms.indexOf(platform) !== -1) {
    return 'macOS';
  }
  return 'Windows/Linux';
}
