import expiredIcon from 'assets/icons/icon-clock-12-expired.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  className?: string;
}

export const ProposalExpiredTooltip: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={`proposal-overview-expired-tooltip__container ${
        className || ''
      }`}
    >
      <img
        src={expiredIcon}
        className="btn__icon btn__icon__left c-pointer"
        alt="ExpiredIcon"
      />
      <div className="proposal-overview-expired-tooltip f f--align-center">
        <p className="proposal-overview-expired-tooltip__content">
          <FormattedMessage id="proposals.overview.expired.tooltip" />
        </p>
        <div className="proposal-overview-expired-tooltip__arrow" />
      </div>
    </div>
  );
};
