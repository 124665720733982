import React from 'react';

export const ClipboardIcon: React.FC = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className="btn__icon btn__icon__left"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13496_14434)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 0C7.275 0 6.3 0.375 5.625 1.125L4.725 1.95C4.425 2.25 4.425 2.7 4.725 3C5.025 3.3 5.475 3.3 5.775 3L6.675 2.1C7.5 1.275 9 1.275 9.825 2.1C10.275 2.55 10.5 3.15 10.5 3.75C10.5 4.35 10.275 4.95 9.825 5.325L9 6.225C8.7 6.525 8.7 6.975 9 7.275C9.15 7.425 9.375 7.5 9.525 7.5C9.675 7.5 9.9 7.425 10.05 7.275L10.95 6.375C11.625 5.7 12 4.725 12 3.75C12 2.775 11.625 1.8 10.875 1.125C10.2 0.375 9.225 0 8.25 0ZM6.225 9L5.325 9.9C4.5 10.725 3 10.725 2.175 9.9C1.725 9.45 1.5 8.85 1.5 8.25C1.5 7.65 1.725 7.05 2.175 6.675L3 5.775C3.3 5.475 3.3 5.025 3 4.725C2.7 4.425 2.25 4.425 1.95 4.725L1.125 5.625C0.375 6.3 0 7.275 0 8.25C0 9.225 0.375 10.2 1.125 10.875C1.8 11.625 2.775 12 3.75 12C4.725 12 5.7 11.625 6.375 10.875L7.275 9.975C7.575 9.675 7.575 9.225 7.275 8.925C6.975 8.625 6.525 8.7 6.225 9ZM3.89993 7.05L7.04993 3.9C7.34993 3.6 7.79993 3.6 8.09993 3.9C8.39993 4.2 8.39993 4.65 8.09993 4.95L4.94993 8.1C4.79993 8.25 4.57493 8.325 4.42493 8.325C4.27493 8.325 4.04993 8.25 3.89993 8.1C3.59993 7.8 3.59993 7.35 3.89993 7.05Z"
          fill="#8039FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_13496_14434">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
