interface ListItem {
  colorKey: keyof ProposalColor;
  titleId: string;
}

export const proposalAppearanceListItems: ListItem[] = [
  {
    colorKey: 'proposalTitle',
    titleId: 'proposal-appearance-accordion.color.proposal-title',
  },
  {
    colorKey: 'accentColor',
    titleId: 'proposal-appearance-accordion.color.accent-color',
  },
  //TO-DO @markoboras0712 - uncomment when we agree what to offer user next
  // {
  //   colorKey: 'headings',
  //   titleId: 'proposal-appearance-accordion.color.headings',
  // },
  // {
  //   colorKey: 'subheadings',
  //   titleId: 'proposal-appearance-accordion.color.subheadings',
  // },
  // {
  //   colorKey: 'cardTitle',
  //   titleId: 'proposal-appearance-accordion.color.card-title',
  // },
  // {
  //   colorKey: 'bodyText',
  //   titleId: 'proposal-appearance-accordion.color.body-text',
  // },
  // {
  //   colorKey: 'price',
  //   titleId: 'proposal-appearance-accordion.color.price',
  // },
  // {
  //   colorKey: 'subtotalPrice',
  //   titleId: 'proposal-appearance-accordion.color.subtotal-price',
  // },
  // {
  //   colorKey: 'cardBackground',
  //   titleId: 'proposal-appearance-accordion.color.card-background',
  // },
  // {
  //   colorKey: 'background',
  //   titleId: 'proposal-appearance-accordion.color.background',
  // },
  // {
  //   colorKey: 'borders',
  //   titleId: 'proposal-appearance-accordion.color.borders',
  // },

  // {
  //   colorKey: 'buttonSecondary',
  //   titleId: 'proposal-appearance-accordion.color.button-secondary',
  // },
];
