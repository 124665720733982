import deleteIcon from 'assets/icons/icon-trash-12.svg';
import { useClients } from 'modules/clients';
import { useNumberOfSentProposalsSelector } from 'modules/proposals';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DeleteModal, ListItemActionDrawer } from 'shared/components';
import { useModal } from 'shared/hooks';
import { ClientModal } from '../client-modal';

interface Props extends Client {
  index?: number;
}

export const ClientListItem: React.FC<Props> = (props) => {
  const { deleteEntityAsync } = useClients();

  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal();

  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal,
  } = useModal();

  const removeItem = () => {
    if (props.id) deleteEntityAsync(props.id);
    closeDeleteModal();
  };

  const numberOfSentProposals = useNumberOfSentProposalsSelector(props?.id);

  const openDeleteModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    openModal();
    e.currentTarget.blur();
  };

  return (
    <>
      <div
        className="settings-itemlist__item"
        data-cy={`client-list-item-${props.index}`}
      >
        <div className="f f--col g-4">
          <Button
            type="button"
            onClick={openEditModal}
            className="d--ib t-ellipsis settings-itemlist__item__name text--xs__responsive t-text-1 t-bold"
          >
            {props.name}
          </Button>
          <div className="settings-client__info--mobile">
            <p className="text--xsm t-text-2">Sent proposals</p>
            <span className="s-right--xlrg text--xsm t-text-2">
              {numberOfSentProposals}
            </span>
          </div>
        </div>

        <div className="btn-group btn-group--simple settings-itemlist__item__actions">
          <span className="text--sm t-text-2 settings__number__of__sent__proposals">
            {numberOfSentProposals}
          </span>
          <Button
            style="outline"
            size="med"
            onClick={openEditModal}
            btnSelector={`edit-client-${props.index}`}
          >
            <FormattedMessage id="buttons.edit" />
          </Button>
          <Button
            style="ghost"
            size="med"
            onClick={openDeleteModal}
            btnSelector={`delete-client-btn-${props.index}`}
          >
            <img src={deleteIcon} alt="DeleteIcon" />
          </Button>
        </div>
        <ListItemActionDrawer className="settings__actionbar__drawer">
          <Button
            type="button"
            className="actionbar__drawer__btn"
            onClick={openEditModal}
            btnSelector={`edit-client-${props.index}`}
          >
            <FormattedMessage id="buttons.edit" />
          </Button>
          <Button
            type="button"
            className="actionbar__drawer__btn t-danger"
            onClick={openDeleteModal}
            btnSelector={`delete-client-btn-${props.index}`}
          >
            <FormattedMessage id="buttons.delete" />
          </Button>
        </ListItemActionDrawer>
      </div>

      {isEditModalOpen && <ClientModal item={props} onClose={closeEditModal} />}

      {isDeleteModalOpen && (
        <DeleteModal
          title={props.name}
          name="client"
          onClose={closeDeleteModal}
          onConfirm={removeItem}
          delSelector="confirm-delete-btn"
        />
      )}
    </>
  );
};
