export interface Unit {
  id?: string;
  name: string;
  index: number;
  createdAt?: number;
}

export class Unit {
  constructor(data?: Unit) {
    this.id = data?.id;
    this.name = data?.name || '';
    this.index = data?.index || 0;
    this.createdAt = data?.createdAt || 1609455600000; // 2021-01-01 fallback for units created before createdAt was introduced
  }
}
