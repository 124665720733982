import { isFileSegment, isMediaSegment } from '../type-guards';

export function getFileNames(data: Omit<Proposal, 'id'>) {
  return data?.segments?.flatMap((obj) => {
    if (isFileSegment(obj)) {
      return obj.files.map((file) => file.name);
    }
    if (isMediaSegment(obj)) {
      return obj.files.map((media) => media.name);
    }
    return [];
  });
}
