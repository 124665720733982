import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestore } from 'modules/firebase';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import { settingsSelector } from '../redux';

export function useSettings() {
  const organizationId = useOrganizationIdSelector();

  const { config } = useSelector(settingsSelector.getState);
  const firestoreFunctions = useFirestore<Settings>(organizationId);

  const documentName = 'settings';

  const hasFinishedOnboarding = useCallback(() => Boolean(config), [config]);

  async function createAsync(data: Settings) {
    try {
      firestoreFunctions.createWithImagesAsync(
        data,
        ['companyImage'],
        documentName,
      );
    } catch {
      toast.error(`Settings creation failed. Try again later.`);
    }
  }

  async function updateAsync(data: Settings, successText = '') {
    try {
      await firestoreFunctions.updateWithImagesAsync(
        data,
        ['companyImage'],
        documentName,
      );

      if (successText) {
        toast.success(successText);
      }
    } catch {
      toast.error(`Settings update failed. Try again later.`);
    }
  }

  async function updateOnOnboardingAsync(data: Settings) {
    try {
      await firestoreFunctions.updateWithImagesAsync(
        data,
        ['companyImage'],
        documentName,
      );
    } catch {
      toast.error(`Settings update failed. Try again later.`);
    }
  }

  return {
    ...firestoreFunctions,
    createAsync,
    updateAsync,
    updateOnOnboardingAsync,
    hasFinishedOnboarding,
  };
}
