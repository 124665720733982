import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProposalSummaryDrawerToggle } from './ProposalSummaryDrawerToggle';

interface Props {
  proposal?: PublishStatusAware<Proposal> | Proposal;
  isPreview?: boolean;
  isSubscribed?: boolean;
  onHandleToggle: () => void;
}

export const ProposalSummaryHeader: React.FC<Props> = ({
  proposal,
  isPreview,
  isSubscribed,
  onHandleToggle,
}) => {
  return (
    <>
      <div className="f f--between-center proposal__summary__title__container">
        <span className="t-label d--desktop--only">
          <FormattedMessage id="proposal-summary.title" />
        </span>
        <span className="text--sm t-bold t-text-1 t-ellipsis s-right--sml d--mobile--only">
          {proposal?.name}
        </span>
        <ProposalSummaryDrawerToggle
          isSubscribed={isSubscribed}
          onHandleToggle={onHandleToggle}
          isOpen
        />
      </div>
      {isPreview && <div className="proposal__summary__title__border" />}
    </>
  );
};
