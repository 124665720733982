import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

export function useScrollToError(
  form: UseFormReturn<PublishStatusAware<Proposal>>,
) {
  const { formState } = form;
  const {
    errors: { segments, client, currency },
  } = formState;

  const elementWithError = useMemo(() => {
    if (client || currency) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      return;
    }

    if (segments) {
      const errorKeys: string[] = Object.keys(segments).filter(
        (key) => segments[key],
      );

      return (
        document.querySelector(
          `input[name^="segments.${errorKeys[0]}.title"]`,
        ) ||
        document.querySelector(`div[name^="segments.${errorKeys[0]}.content"]`)
      );
    }

    return null;
  }, [segments]);

  return elementWithError;
}
