import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { authenticationSelector } from 'modules/authentication';
import { proposalsAtoms, useProposalsSelector } from 'modules/proposals';
import { useConfigSelector } from 'modules/settings';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';

export function useDashboardRedirects() {
  const location = useLocation();
  const [isReady, setIsReady] = useState(false);

  const config = useConfigSelector();
  const proposals = useProposalsSelector();
  const proposalsLoading = useRecoilValue(proposalsAtoms.isLoading);
  const { user, isLoading: isUserLoading } = useSelector(
    authenticationSelector.getState,
  );

  useEffect(() => {
    if (!user) {
      const queryParams = new URLSearchParams(location.search);
      const source = queryParams.get('source');
      if (source) {
        navigate(`/login?source=${source}`);
      } else {
        navigate(`/login`);
      }
      return;
    }

    const shouldFinishOnboarding = config && config.isOnboardingInProgress;
    if (shouldFinishOnboarding) {
      navigate('/onboarding');
      return;
    }

    setIsReady(true);
  }, [user, config, proposals]);

  const isLoading = isUserLoading || !isReady;

  return { isLoading, proposalsLoading };
}
