import { useParams } from '@reach/router';
import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreDocument } from 'modules/firebase';
import { useConfigSelector } from 'modules/settings';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { Proposal } from '../Proposal';

export function useSingleProposalListener(orgId?: string) {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [proposal, setProposal] = useState<
    PublishStatusAware<Proposal> | undefined
  >(undefined);

  const config = useConfigSelector();
  const organizationId = useOrganizationIdSelector();
  const companyId = orgId || organizationId;

  const { listen } = useFirestoreDocument<PublishStatusAware<Proposal>>(
    `${companyId}/proposals/entities/${id}`,
  );

  /** Fetch single proposal document from firestore */
  useEffect(() => {
    if (!companyId || !id) return;
    if (!proposal) setIsLoading(true);

    const listener = listen(
      (doc) => {
        /**New proposal draft */
        if (!doc) {
          const newProposal = new Proposal();
          delete newProposal.id;
          setProposal({
            ...newProposal,
            nextPublishStatus: 'draft',
            vatPercentage: config?.tax.vatPercentage || 0,
            currency: config?.defaultCurrency || null,
            responseStatus: config?.defaultProposalResponseStatus || 'allowed',
            servicePricesVisibility:
              config?.defaultServicePricesVisibility || 'visible',
          });
          return;
        }
        const proposal: PublishStatusAware<Proposal> = {
          ...new Proposal(doc),
          nextPublishStatus: doc.publishStatus,
          saved: false,
          customTags: doc.customTags || [],
        };
        setProposal(proposal);
      },
      (error) => {
        if (error) {
          toast.error('Something went wrong. Please, try again later.');
        }
      },
      () => setIsLoading(false),
    );

    return listener;
  }, [companyId, id]);

  return { isLoading, proposal };
}
