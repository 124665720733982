import React from 'react';
import { usePricingSelectPlan } from '../hooks';
import { ProductItem } from './ProductItem';

interface Props {
  product: StripeProductWithPrices;
}

export const SubscriptionSelectModalMobile: React.FC<Props> = ({ product }) => {
  const { isCheckoutLoading, handleCheckout } = usePricingSelectPlan();

  return (
    <div className="subscription-product__group__mobile">
      <ProductItem
        key={product.id}
        product={product}
        onSelect={handleCheckout}
        name={product.name}
        isCheckoutLoading={isCheckoutLoading}
      />
    </div>
  );
};
