import React, {
  CSSProperties,
  ForwardedRef,
  MouseEventHandler,
  ReactNode,
  forwardRef,
} from 'react';

type Props = {
  id?: string;
  children?: ReactNode;
  style?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'ghost'
    | 'text'
    | 'destructive'
    | 'destructive__outline'
    | 'icon'
    | 'tooltip';
  size?: 'sml' | 'med' | 'lrg';
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  btnSelector?: string;
  value?: string;
  name?: string;
  inlineStyle?: CSSProperties;
  tabIndex?: number;
};

export const Button = forwardRef(function (
  {
    id,
    children,
    style,
    size,
    type = 'button',
    disabled = false,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
    className,
    btnSelector,
    value,
    name,
    inlineStyle,
    tabIndex,
  }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const btnClasses = [
    'btn',
    style ? `btn__${style}` : '',
    size ? `btn__${size}` : '',
    className || '',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <button
      id={id}
      className={btnClasses}
      type={type}
      disabled={disabled}
      data-cy={btnSelector}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      style={inlineStyle}
      ref={ref}
      value={value}
      name={name}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
});
