import archiveIcon from 'assets/icons/icon-archive-12-white.svg';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  className: string;
  btnSelector?: string;
}

export const ArchiveButton: React.FC<Props> = ({ className, ...props }) => {
  const { setValue, getValues } =
    useFormContext<PublishStatusAware<Proposal>>();

  function handleArchive() {
    setValue('nextPublishStatus', 'archived');
    setValue('name', `Archived: ${getValues().name}`);
  }

  return (
    <Button
      className={`${className} btn__icon__stroke`}
      onClick={handleArchive}
      {...props}
      type="submit"
    >
      <img
        src={archiveIcon}
        alt="ArchiveIcon"
        className="btn__icon btn__icon__left"
      />
      <FormattedMessage id="buttons.archive" />
    </Button>
  );
};
