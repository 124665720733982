import { createAction } from 'modules/redux-store';

export const unitAction = {
  loading: () => createAction('units/LOADING'),

  onSnapshotUpdate: (documents: Unit[]) =>
    createAction('units/ON_SNAPSHOT_UPDATE', { documents }),

  onSnapshotError: (error: FirestoreError) =>
    createAction('units/ON_SNAPSHOT_ERROR', { error }),

  logout: () => createAction('authentication/LOGOUT'),
};
