import React from 'react';

export const TableOfContentsFileIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="16"
      className="btn__icon"
      viewBox="0 0 10 16"
      fill="none"
    >
      <path
        d="M3.5 4.5V10C3.5 10.828 4.172 11.5 5 11.5C5.828 11.5 6.5 10.828 6.5 10V3.5C6.5 1.843 5.157 0.5 3.5 0.5C1.843 0.5 0.5 1.843 0.5 3.5V11C0.5 13.485 2.515 15.5 5 15.5C7.485 15.5 9.5 13.485 9.5 11V4.5"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
