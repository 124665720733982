import { navigate, useLocation } from '@reach/router';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  useAnimatedDropdown,
  useClickOutsideElement,
  useMobile,
} from 'shared/hooks';
import { capitalizeFirstLetter, isDateArray } from 'shared/utils';
import { useOverviewFiltersState } from '../state';
import { isFilterOption } from '../utils';

export function useProposalOverviewFilterList(
  handleAddFilterModal?: () => void,
  handleEditFilterModal?: (type: FilterOption) => void,
) {
  const { formatMessage } = useIntl();
  const { pathname, search } = useLocation();

  const [filters, setFilters] = useOverviewFiltersState();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [editFilter, setEditFilter] = useState<FilterOption | null>(null);

  const isMobile = useMobile();
  const {
    isOpen: isAddFilterOpen,
    handleCloseDropdownToggle: closeAddFilter,
    handleOpenDropdownToggle: openAddFilter,
  } = useAnimatedDropdown();
  const {
    isOpen: isEditFilterOpen,
    handleCloseDropdownToggle: closeEditFilter,
    handleOpenDropdownToggle: openEditFilter,
  } = useAnimatedDropdown();

  const addFilterRef = useClickOutsideElement<HTMLDivElement>(
    closeAddFilter,
    true,
    true,
  );
  const editFilterRef = useClickOutsideElement<HTMLDivElement>(
    closeEditFilter,
    true,
    true,
  );
  const addFilterDropdownRef = useMemo(
    () =>
      isAddFilterOpen && !isMobile && !isCalendarOpen ? addFilterRef : null,
    [isAddFilterOpen, isMobile, isCalendarOpen, addFilterRef],
  );
  const editFilterDropdownRef = useMemo(
    () =>
      isEditFilterOpen && !isMobile && !isCalendarOpen ? editFilterRef : null,
    [isEditFilterOpen, isMobile, isCalendarOpen, editFilterRef],
  );

  function handleAddFilter() {
    if (isEditFilterOpen) closeEditFilter();
    if (isMobile && handleAddFilterModal) {
      return handleAddFilterModal();
    }
    return isAddFilterOpen ? closeAddFilter() : openAddFilter();
  }

  function handleEditFilter(filter: string) {
    if (isAddFilterOpen) closeAddFilter();
    if (!isFilterOption(filter)) return;
    setEditFilter(filter);
    if (isMobile && handleEditFilterModal) {
      return handleEditFilterModal(filter);
    }
    return isEditFilterOpen ? closeEditFilter() : openEditFilter();
  }

  function removeFilterGroup(filterType: string) {
    setFilters((currentFilters) =>
      currentFilters.filter((filter) => filter?.type !== filterType),
    );
  }

  function clearAllFilters() {
    const currentPage = Number(search.replace(/\D/g, ''));
    if (currentPage > 1) {
      navigate(pathname);
    }
    setFilters([]);
  }

  function renderFilterValue(filter?: Filter) {
    if (!filter) return null;

    /**Render dates */
    if (isDateArray(filter?.value)) {
      if (filter?.value?.length === 1) {
        return format(filter?.value[0], 'MMM d');
      }
      if (filter?.value?.length >= 2) {
        const startDate = format(filter?.value?.[0], 'MMM d');
        const endDate = format(filter?.value?.[1], 'MMM d');
        return `${startDate} to ${endDate}`;
      }
    }

    /**Render proposal status */
    if (
      filter?.type === 'proposal-status' &&
      typeof filter?.value === 'string'
    ) {
      return formatMessage({ id: `proposals.${filter?.value}.title` });
    }

    if (typeof filter?.value === 'string') {
      return filter?.type === 'client'
        ? capitalizeFirstLetter(filter?.label || filter?.value)
        : capitalizeFirstLetter(filter?.value);
    }

    if (filter?.type !== 'date') return JSON.stringify(filter?.value);
    return '';
  }

  return {
    filters,
    editFilter,
    isEditFilterOpen,
    isAddFilterOpen,
    addFilterDropdownRef,
    editFilterDropdownRef,
    isMobile,
    setIsCalendarOpen,
    handleAddFilter,
    handleEditFilter,
    removeFilterGroup,
    clearAllFilters,
    renderFilterValue,
  };
}
