import { motion } from 'framer-motion';
import React from 'react';

interface Props {
  onHandleToggle: () => void;
}

export const ProposalSummaryOverlay: React.FC<Props> = ({ onHandleToggle }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: { duration: 0.25, delay: 0.25, ease: 'easeOut' },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0, delay: 0, ease: 'easeOut' },
      }}
      className="proposal__summary__overlay"
      onClick={onHandleToggle}
    />
  );
};
