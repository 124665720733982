import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListErrorMessage } from './ListErrorMessage';

export const ListError: React.FC = () => {
  return (
    <div className="dashboard__overview__list__error">
      <ListErrorMessage
        message={
          <FormattedMessage id="proposals.overview.search.no-results.title" />
        }
        description={
          <FormattedMessage id="proposals.overview.search.no-results.description" />
        }
      />
    </div>
  );
};
