export interface RoleClaim {
  role: Role;
  organizationId: string;
  organizationName: string;
}

export class RoleClaim {
  constructor(role: Role, organizationId: string, organizationName: string) {
    this.role = role;
    this.organizationId = organizationId;
    this.organizationName = organizationName;
  }
}
