import { countries } from 'countries-list';

export function getCountryISOFromName(
  countryName?: string | null,
): string | undefined {
  const ISOCountry = Object.entries(countries).find(
    ([, country]) => country.name === countryName,
  );

  return ISOCountry?.[0] || 'US';
}
