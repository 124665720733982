import deleteIcon from 'assets/icons/icon-trash-12.svg';
import React from 'react';
import { Button } from 'shared/components';

interface Props {
  index: number;
  onDelete: (index: number) => void;
}

export const SegmentDeleteButton: React.FC<Props> = ({ onDelete, index }) => (
  <Button
    type="button"
    onClick={() => onDelete(index)}
    size="med"
    style="ghost"
    className="proposal-service__delete"
    btnSelector="delete-component-btn"
  >
    <img src={deleteIcon} alt="DeleteIcon" className="btn__icon" />
  </Button>
);
