import { authenticationSelector } from 'modules/authentication';
import { showDebugStatuses } from 'modules/firebase';
import { settingsSelector } from 'modules/settings';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { toast } from 'sonner';
import { subscriptionAtoms } from '../state';
import { useFreeSubscription } from './useFreeSubscription';

export function useAutoFreeSubscription() {
  const { user } = useSelector(authenticationSelector.getState);
  const { config } = useSelector(settingsSelector.getState);
  const customerId = useRecoilValue(subscriptionAtoms.customerId);
  const subscriptionId = useRecoilValue(subscriptionAtoms.subscriptionId);
  const loadingDocument = useRecoilValue(subscriptionAtoms.loadingDocument);

  const { formatMessage } = useIntl();
  const { isLoading, handleFreeSubscription } = useFreeSubscription();

  useEffect(() => {
    // Stop execution if not all data is available yet.
    if (!config || loadingDocument || isLoading || !user) {
      if (showDebugStatuses) {
        console.log('Stop execution', {
          config,
          loadingDocument,
          isLoading,
          user,
        });
      }

      return;
    }

    // Customer and subscription already exists
    if (customerId && subscriptionId) {
      if (showDebugStatuses) {
        console.log('Customer and subscription id already exists');
      }
      return;
    }

    if (showDebugStatuses) {
      console.log('Handle free subscription', { customerId, subscriptionId });
    }
    handleFreeSubscription().catch(() =>
      toast.error(
        formatMessage({
          id: 'free-subscription.subscribe.api.error',
        }),
      ),
    );
  }, [user, config, isLoading, customerId, subscriptionId, loadingDocument]);
}
