import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  isConfirmationModal?: boolean;
  isActionModal?: boolean;
  headerSelector?: string;
  className?: string;
}

export const ModalHeader: React.FC<Props> = ({
  children,
  isConfirmationModal,
  isActionModal,
  headerSelector,
  className,
}) => {
  return (
    <header
      className={`${
        isConfirmationModal ? 'confirmation-modal__header' : ''
      } modal__header text--xl__responsive ${
        isActionModal ? 'modal__header--action' : ''
      } ${className || ''}`}
      data-cy={headerSelector}
    >
      {children}
    </header>
  );
};
