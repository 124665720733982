export function hasUnitChanged(
  unit1?: Unit | null,
  unit2?: Unit | null,
): boolean {
  const props = ['name', 'id'];

  for (const prop of props) {
    if (unit1?.[prop] !== unit2?.[prop]) {
      return true;
    }
  }

  return false;
}
