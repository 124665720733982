import { useScrollIntoView } from 'modules/authentication';
import { serviceSelector } from 'modules/services';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FieldWrapper } from 'shared/components';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  sendName: (name: string) => void;
  inputSelector?: string;
}

export const ServiceNameInput: React.FC<Props> = ({
  autoFocus,
  sendName,
  inputSelector,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const [initialName, setInitialName] = useState('');

  const { documents } = useSelector(serviceSelector.getState);

  const {
    formState: { errors },
    register,
    getValues,
  } = useFormContext<Service>();

  function handleEmptyString(value: string) {
    return (
      !!value.trim() || formatMessage({ id: 'inputs.service-name.required' })
    );
  }

  function handleAlreadyExists(value: string) {
    if (value !== initialName) {
      return (
        !documents?.find(({ name }) => name === value) ||
        formatMessage(
          { id: 'inputs.service-name.already-exists' },
          { name: value },
        )
      );
    }
    return;
  }

  useEffect(() => {
    sendName(getValues('name'));
    setInitialName(getValues('name'));
  }, []);

  return (
    <FieldWrapper
      name="name"
      errors={errors}
      labelId="inputs.service-name.label"
      isRequired
    >
      <input
        type="text"
        autoComplete="off"
        autoFocus={autoFocus}
        className={`input input__med input__box ${
          errors.name ? 'input__box__error' : ''
        }`}
        placeholder={formatMessage({ id: 'inputs.service-name.placeholder' })}
        data-cy={inputSelector}
        onFocus={handleFocus}
        {...register('name', {
          required: formatMessage({ id: 'inputs.service-name.required' }),
          maxLength: {
            value: 200,
            message: formatMessage({
              id: 'inputs.service-name.max-length',
            }),
          },
          validate: {
            emptyString: (value) => handleEmptyString(value),
            alreadyExists: (value) => handleAlreadyExists(value),
          },
        })}
      />
    </FieldWrapper>
  );
};
