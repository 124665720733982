import Fuse from 'fuse.js';

export function useFuseObjectSearch<T extends object>(
  keys: string[],
  query?: string,
  documents?: T[],
) {
  if (!documents) return [];
  if (!query) return documents;

  const options = {
    // Properties of 'documents' to be included in the search
    keys,
    // Adjust the threshold for search sensitivity (lower means more strict)
    threshold: 0.3,
  };

  const fuse = new Fuse(documents, options);
  return fuse.search(query).map((result) => result.item);
}
