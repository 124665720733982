import { useState } from 'react';
import { useIntl } from 'react-intl';
import { NOTIFY_CREATOR_TIMESTAMPS_LS_KEY } from 'shared/const';
import { useLocalStorage, useMobile } from 'shared/hooks';
import { toast } from 'sonner';
import { NotifyOwnerTimestamp } from '../models';
import { usePublicProposal } from './usePublicProposal';

export function useNotifyOwner(
  organizationId: string,
  email: string,
  proposalId: string | undefined,
  onSetError: (error: boolean) => void,
) {
  const isMobile = useMobile();
  const { formatMessage } = useIntl();
  const { contactProposalOwner } = usePublicProposal();
  const [loading, setLoading] = useState(false);
  const [timestamps, setTimestamps] = useLocalStorage<NotifyOwnerTimestamp[]>(
    NOTIFY_CREATOR_TIMESTAMPS_LS_KEY,
  );

  const setNewTimestamps = (proposalId: string) => {
    if (!timestamps) {
      setTimestamps([{ proposalId, timestamp: Date.now().toString() }]);
      return;
    }

    setTimestamps([
      ...timestamps,
      { proposalId, timestamp: Date.now().toString() },
    ]);
  };
  async function notifyOwner() {
    if (!proposalId) return;

    setNewTimestamps(proposalId);
    setLoading(true);
    const sent = await contactProposalOwner({
      proposalId,
      organizationId,
      clientEmail: email,
    });
    setLoading(false);
    if (!sent) {
      onSetError(true);
      return;
    }
    toast.success(formatMessage({ id: 'proposal-expired.success.title' }), {
      position: isMobile ? 'top-center' : 'top-right',
    });
  }

  return { loading, timestamps, notifyOwner };
}
