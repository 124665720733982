import { useLocation } from '@reach/router';
import createIcon from 'assets/icons/icon-create-proposal-12.svg';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useProposals } from 'modules/proposals';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  publishStatus?: PublishStatus;
}

export const ProposalListError: React.FC<Props> = ({ publishStatus }) => {
  const { formatMessage } = useIntl();
  const { reserveProposalId } = useProposals();
  const { pathname, search } = useLocation();
  const [newProposalId, setNewProposalId] = useState('');

  useEffect(() => setNewProposalId(reserveProposalId), []);

  if (publishStatus === 'archived') {
    return (
      <motion.div
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.2, type: 'spring' }}
        className="message--info dashboard__main__error"
      >
        <div className="message__content">
          <span className="text--xs t-bold t-text-1">
            <FormattedMessage id="errors.archived-proposal" />
          </span>
          <p className="text--xsm t-text-2">
            <FormattedMessage id="proposals.archived-proposals" />
          </p>
        </div>
      </motion.div>
    );
  }

  if (publishStatus === 'template') {
    return (
      <motion.div
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.2, type: 'spring' }}
        className="message--info dashboard__main__error"
      >
        <div className="message__content">
          <span className="text--xs t-bold t-text-1">
            <FormattedMessage id="errors.template-proposal" />
          </span>
          <p className="text--xsm t-text-2">
            <FormattedMessage
              id="proposals.template-proposals"
              values={{
                link: (
                  <Link
                    className="btn btn__text t-primary"
                    to={formatMessage({ id: 'routes.draft' })}
                  >
                    <FormattedMessage id="proposals.template-proposals-existing-link" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      style={{
        opacity: 0,
        y: 8,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 0.2, type: 'spring' }}
      className="message--info dashboard__main__error"
    >
      <div className="message__content">
        <span className="text--xs t-bold t-text-1">
          <FormattedMessage id="errors.proposal" />
        </span>
        <p className="text--xsm t-text-2">
          <FormattedMessage id="proposals.get-started" />
        </p>
      </div>
      <Link
        className="btn btn__primary btn__med"
        to={formatMessage({ id: 'routes.new-proposal' }, { id: newProposalId })}
        state={{ lastPageUrl: `${pathname}${search}` }}
        data-cy="create-proposal-btn"
      >
        <img
          src={createIcon}
          alt="Create new proposal icon"
          className="btn__icon btn__icon__left"
        />

        <FormattedMessage id="buttons.create-proposal" />
      </Link>
    </motion.div>
  );
};
