import { useRecoilValue } from 'recoil';
import { subscriptionAtoms } from '../state';
import { SUBSCRIPTION_TIER_MAP } from '../const';
import {
  isSubscriptionTierEnabled,
  isSubscriptionTierEnabledExact,
} from '../utils';

export function useSubscriptionTier(
  required: SubscriptionTierAlias,
  exact = false,
) {
  const tier = SUBSCRIPTION_TIER_MAP[required];
  const current = useRecoilValue(subscriptionAtoms.tier);

  return exact
    ? isSubscriptionTierEnabledExact(current, tier)
    : isSubscriptionTierEnabled(current || 0, tier);
}
