import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';
import { emailRegex } from 'shared/utils';

export const ClientEmailInput: React.FC = () => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<Client>();

  return (
    <FieldWrapper name="email" errors={errors} labelId="inputs.email.label">
      <input
        type="email"
        className={`input input__med input__box ${
          errors.email ? 'input__box__error' : ''
        }`}
        placeholder={formatMessage({ id: 'inputs.client-email.placeholder' })}
        data-cy="client-email-input"
        autoComplete="off"
        onFocus={handleFocus}
        {...register('email', {
          pattern: {
            value: emailRegex,
            message: formatMessage({ id: 'inputs.email.invalid' }),
          },
        })}
      />
    </FieldWrapper>
  );
};
