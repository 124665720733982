import { FirestoreError } from 'firebase/firestore';
import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreEntitites } from 'modules/firebase';
import { toast } from 'sonner';
import { getFileNames } from '../utils';

export function useProposals() {
  const organizationId = useOrganizationIdSelector();

  const entityFunctions = useFirestoreEntitites<Proposal>(
    'proposals',
    organizationId,
  );

  function reserveProposalId() {
    return entityFunctions.getEntityDocumentReference().id;
  }

  async function createEntityAsync(data: Proposal) {
    try {
      const documentReference = await entityFunctions.createEntityAsync(data);
      if (documentReference instanceof FirestoreError) throw FirestoreError;
      return documentReference.id;
    } catch {
      toast.error(`We couldn't duplicate the proposal. Try again later.`);
      return;
    }
  }

  function createEntityWithImagesAsync(
    entityId: string,
    data: Omit<Proposal, 'id'>,
  ) {
    try {
      const imageProps = [...getFileNames(data), 'imageUrl'];
      entityFunctions.createEntityWithImagesAsync(data, imageProps, entityId);
      toast.success(`Proposal ${data.name} has been successfully created.`);
    } catch {
      toast.error(
        `We couldn't save the proposal ${data.name}. Try again later.`,
      );
    }
  }

  async function updateEntityAsync(
    entityId: string,
    data: Omit<Proposal, 'id'>,
  ) {
    try {
      await entityFunctions.updateEntityAsync(entityId, data);

      toast.success(`Proposal ${data.name} has successfully been updated.`);
    } catch {
      toast.error(
        `We couldn't update the proposal ${data.name}. Try again later.`,
      );
    }
  }

  async function updateEntityWithImagesAsync(
    entityId: string,
    data: Omit<Proposal, 'id'>,
  ) {
    try {
      const imageProps = [...getFileNames(data), 'imageUrl'];
      await entityFunctions.updateEntityWithImagesAsync(
        data,
        imageProps,
        entityId,
      );

      toast.success(`Proposal ${data.name} has successfully been updated.`);
    } catch {
      toast.error(
        `We couldn't update the proposal ${data.name}. Try again later.`,
      );
    }
  }

  async function deleteEntityAsync(entityId: string) {
    try {
      await entityFunctions.deleteEntityAsync(entityId);
      toast.success(`Proposal has successfully been deleted.`);
    } catch {
      toast.error(`We couldn't delete the proposal. Try again later.`);
    }
  }

  async function getEntity(entityId: string) {
    return entityFunctions.getEntityByIdAsync(entityId);
  }

  return {
    ...entityFunctions,
    getEntity,
    reserveProposalId,
    createEntityWithImagesAsync,
    updateEntityWithImagesAsync,
    updateEntityAsync,
    deleteEntityAsync,
    createEntityAsync,
  };
}
