import { useScrollIntoView } from 'modules/authentication';
import React, { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FieldWrapper, RichTextEditor } from 'shared/components';

interface Props {
  index: number;
  autoFocus?: boolean;
  control: UseFormReturn<PublishStatusAware<Proposal>>['control'];
  formState: UseFormReturn<PublishStatusAware<Proposal>>['formState'];
  value?: string;
  isFieldRequired?: boolean;
  placeholder?: string;
}

export const ServiceDescriptionInput: React.FC<Props> = memo(
  ({
    control,
    formState: { errors },
    value,
    index,
    autoFocus,
    isFieldRequired,
    placeholder,
  }) => {
    const { handleFocus } = useScrollIntoView();

    return (
      <FieldWrapper
        name={`segments.${index}.content`}
        errors={errors}
        isRequired={isFieldRequired}
      >
        <Controller
          name={`segments.${index}.content`}
          control={control}
          defaultValue={value || ''}
          render={({ field, fieldState }) => (
            <RichTextEditor
              placeholder={placeholder}
              key={index}
              index={index}
              onChange={(value) => field.onChange(value)}
              value={field.value}
              onFocus={handleFocus}
              onBlur={field.onBlur}
              autoFocus={autoFocus}
              hasError={!!fieldState.error}
              inputSelector={`segments.${index}.content`}
              name={`segments.${index}.content`}
            />
          )}
        />
      </FieldWrapper>
    );
  },
  (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    const hasErrors = !!Object.keys(nextProps.formState.errors);
    return (
      prevProps.formState.isDirty === nextProps.formState.isDirty && !hasErrors
    );
  },
);
