import { LOCAL_HOST_DOMAIN } from 'shared/const';
import { isLocalHost } from 'shared/utils';

/**
 * This hook is used to get current domain */

export function useDomain() {
  if (isLocalHost()) {
    return LOCAL_HOST_DOMAIN;
  }
  const isPreviewUrlPattern = /propoze-dev--pr/.test(window.location.origin);
  if (process.env.GATSBY_FIREBASE_PREVIEW === 'true' || isPreviewUrlPattern) {
    return window.location.origin;
  }

  return process.env.GATSBY_PROJECT_DOMAIN;
}
