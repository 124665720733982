import type { PDFDocumentProxy } from 'pdfjs-dist';
import React, { memo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { SizeMe } from 'react-sizeme';
import { MediaListItemControls } from './MediaListItemControls';
import { MediaListItemPageControls } from './MediaListItemPageControls';

/**Source: https://github.com/wojtekmaj/react-pdf/issues/1004 */
if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();
}

interface PdfProps {
  fileUrl: string;
  file: ProposalFile;
  index: number;
  proposalFilesLength: number;
  onRemoveFile: (file: ProposalFile) => void;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

const MediaListItemPDFComponent: React.FC<PdfProps> = ({
  fileUrl,
  file,
  index,
  proposalFilesLength,
  onRemoveFile,
  moveItem,
}) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [isExpanded, setIsExpanded] = useState(true);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  return (
    <div
      className={`media-input__image ${
        isExpanded
          ? 'media-input__image--expanded'
          : 'media-input__image--collapsed'
      }`}
    >
      <SizeMe>
        {({ size }) => (
          <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              renderTextLayer={false}
              className="media-input__pdf"
              renderAnnotationLayer={false}
              pageNumber={pageNumber}
              height={size.height ? size.height : 1}
              width={size.width ? size.width : 1}
            >
              <MediaListItemControls
                file={file}
                index={index}
                proposalFilesLength={proposalFilesLength}
                isExpanded={isExpanded}
                onRemoveFile={onRemoveFile}
                onSetIsExpanded={setIsExpanded}
                moveItem={moveItem}
              />
            </Page>
          </Document>
        )}
      </SizeMe>
      <MediaListItemPageControls
        pageNumber={pageNumber}
        numPages={numPages}
        changePage={changePage}
      />
    </div>
  );
};

export const MediaListItemPDF = memo(MediaListItemPDFComponent);
