import infoIcon from 'assets/icons/icon-info-16.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalFooter } from 'shared/components';
import { useMobile, useModal } from 'shared/hooks';

interface Props {
  className?: string;
}

export const VatIdInputTooltip: React.FC<Props> = ({ className }) => {
  const isMobile = useMobile();
  const { isModalOpen, openModal, closeModal } = useModal();

  function handleOpenModal() {
    if (!isMobile) return;
    openModal();
  }

  return (
    <>
      <div
        className={`free__limit__tooltip__container ${className || ''}`}
        onClick={handleOpenModal}
      >
        <img
          src={infoIcon}
          alt="Info icon"
          className="visibility-selector-modal__icon"
        />
        <div className="free__limit__tooltip u-height--78 u-width-224">
          <span className="text--xxs__responsive">
            <FormattedMessage id="inputs.vat-id.help" />
          </span>
          <div className="free__limit__tooltip__arrow" />
        </div>
      </div>

      {isModalOpen && (
        <Modal close={closeModal} isDashboardModal>
          <span className="text--xs s-bottom--lrg">
            <FormattedMessage id="inputs.vat-id.help" />
          </span>
          <ModalFooter>
            <Button
              onClick={closeModal}
              type="button"
              size="lrg"
              style="outline"
            >
              <FormattedMessage id="buttons.close" />
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
