import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  className?: string;
}

export const DualPrice: React.FC<Props> = ({ children, className }) => (
  <div className={`f f--col f--align-end t-ellipsis ${className}`}>
    {children}
  </div>
);
