import { selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { configAtom } from './atoms';

const configSelector = selector<Settings | null>({
  key: 'configSelector',
  get: ({ get }) => get(configAtom),
  set: ({ get, set }, newValue) => set(configAtom, newValue || get(configAtom)),
});

export const useConfigSelector = () => useRecoilValue(configSelector);
export const useSetConfig = () => useSetRecoilState(configSelector);
