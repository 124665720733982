import React from 'react';
import { FormattedMessage } from 'react-intl';

export const UserListError: React.FC = () => (
  <div className="message message--lrg message--info">
    <div className="message__content">
      <h2 className="t-epsilon">
        <FormattedMessage id="errors.users-overview" />
      </h2>
    </div>
  </div>
);
