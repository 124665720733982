import { useFunction, useStorage } from 'modules/firebase';
import { useState } from 'react';
import { useMobile } from 'shared/hooks';
import { downloadFromUrl } from 'shared/utils';
import { toast as test } from 'sonner';

export function useGeneratePDF(handleCloseDropdownToggle: () => void) {
  const generatePDFCallable = useFunction<GeneratePdfData, GeneratePdfResponse>(
    'generatePDF',
  );
  const isMobile = useMobile();
  const { getDownloadLink } = useStorage();
  const [loading, setLoading] = useState(false);

  async function generatePDF(
    organizationId: string,
    type: PDFExportType,
    proposal?: Proposal,
  ) {
    if (!generatePDFCallable || !proposal) return;
    handleCloseDropdownToggle();
    setLoading(true);

    const promise = async () => {
      const { data } = await generatePDFCallable({
        proposal,
        organizationId,
        isOnePage: type === 'single-page',
      });
      if (!data.success) {
        setLoading(false);
        throw new Error('Failed to generate PDF');
      }
      const url = await getDownloadLink(data.payload.filePath);
      await downloadFromUrl(url, data.payload.fileName);
      setLoading(false);
      return data;
    };

    test.promise(promise, {
      loading: 'Exporting PDF...',
      success: () => 'PDF exported successfully.',
      error: 'Something went wrong. Please try exporting your PDF again.',
      duration: 15000,
      position: isMobile ? 'top-center' : 'top-right',
    });
  }

  return { loading, generatePDF };
}
