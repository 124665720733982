import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AccordionType } from '../ProposalAppearanceAccordionList';
import { ProposalAppearanceAccordionListItemHeader } from './ProposalAppearanceAccordionListItemHeader';

interface Props {
  isSectionActive: boolean;
  activePreset: 'default' | 'dark';
  handleToggle: (type: AccordionType) => void;
  handlePreset: (preset: 'default' | 'dark') => void;
}

export const ProposalAppearanceAccordionPresetsList: React.FC<Props> = ({
  isSectionActive,
  activePreset,
  handleToggle,
  handlePreset,
}) => {
  return (
    <div className="proposal__summary__accordion__item__container">
      <ProposalAppearanceAccordionListItemHeader
        isSectionActive={isSectionActive}
        toggle="presets"
        titleId="proposal-appearance-accordion.presets.title"
        handleToggle={handleToggle}
      />

      <div
        className={`proposal__summary__accordion__container ${
          isSectionActive && 'accordion-active'
        }`}
      >
        <div className="proposal__summary__accordion__presets">
          <div
            onClick={() => handlePreset('default')}
            className="proposal__summary__accordion__preset__layout"
          >
            <div
              className={`proposal__summary__accordion__preset__container u-bg--6 ${
                activePreset === 'default' &&
                'proposal__summary__accordion__preset__container--active'
              }`}
            >
              <div className="proposal__summary__accordion__preset__content">
                <span className="t-label t-text-1">Aa</span>
                <div className="proposal__summary__accordion__bar u-bg--primary" />
              </div>
            </div>
            <span className="proposal__summary__accordion__preset__title text--xxs__responsive t-ellipsis">
              <FormattedMessage id="proposal-appearance-accordion.presets.default" />
            </span>
          </div>
          <div
            onClick={() => handlePreset('dark')}
            className="proposal__summary__accordion__preset__layout"
          >
            <div
              className={`proposal__summary__accordion__preset__container u-bg--dark ${
                activePreset === 'dark' &&
                'proposal__summary__accordion__preset__container--active'
              }`}
            >
              <div className="proposal__summary__accordion__preset__content u-bg--dark-grey">
                <span className="t-label proposal__summary__accordion__dark__label">
                  Aa
                </span>
                <div className="proposal__summary__accordion__bar u-bg--green" />
              </div>
            </div>
            <span className="proposal__summary__accordion__preset__title text--xxs__responsive t-ellipsis">
              <FormattedMessage id="proposal-appearance-accordion.presets.dark" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
