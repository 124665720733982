import { useCallback, useEffect, useRef } from 'react';

export function useClickOutsideElement<T extends HTMLElement>(
  callback: VoidFunction,
  withDefault?: boolean,
  closeWithEscape?: boolean,
) {
  const ref = useRef<T>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (!withDefault) event.preventDefault();
        callback();
      }
    },
    [callback],
  );

  const handleEscapePress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callback();
      }
    },
    [callback],
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    if (closeWithEscape) window.addEventListener('keydown', handleEscapePress);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      if (closeWithEscape) {
        window.removeEventListener('keydown', handleEscapePress);
      }
    };
  }, [handleClickOutside, handleEscapePress]);

  return ref;
}
