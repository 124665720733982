import { useScrollIntoView } from 'modules/authentication';
import {
  AccordionType,
  fontOptions,
  getWeightLabel,
  useProposalAppearanceFontPicker,
  weightOptions,
} from 'modules/proposals';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleValue } from 'react-select';
import { OptionType, StyledSelect, getSelectStyles } from 'shared/components';
import { ProposalAppearanceAccordionListItemHeader } from './ProposalAppearanceAccordionListItemHeader';

interface Props {
  isSectionActive: boolean;
  proposal: PublishStatusAware<Proposal> | Proposal;
  handleToggle: (type: AccordionType) => void;
}

export const ProposalAppearanceAccordionFontPicker: React.FC<Props> = ({
  isSectionActive,
  proposal,
  handleToggle,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const { handleHeadingFontChange, handleHeadingWeightChange } =
    useProposalAppearanceFontPicker(proposal);

  return (
    <div className="proposal__summary__accordion__item__container">
      <ProposalAppearanceAccordionListItemHeader
        isSectionActive={isSectionActive}
        toggle="typography"
        titleId="proposal-appearance-accordion.typography.title"
        handleToggle={handleToggle}
      />
      <div
        className={`proposal__summary__accordion__container ${
          isSectionActive && 'accordion-active'
        }`}
      >
        <div
          className={`proposal__summary__accordion__typography__container ${
            !isSectionActive && 'overflow-hidden'
          }`}
        >
          <div className="proposal__summary__accordion__typography__item__container">
            <span className="t-label">
              <FormattedMessage id="proposal-appearance-accordion.typography.heading" />
            </span>
            <div className="proposal__summary__accordion__typography__select__container">
              <StyledSelect
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.stopPropagation();
                }}
                styles={{
                  ...getSelectStyles<string>(),
                }}
                options={fontOptions}
                value={{
                  value: proposal?.style?.typography?.heading?.fontFamily,
                  label: proposal?.style?.typography?.heading?.fontFamily,
                }}
                onFocus={handleFocus}
                onChange={(option: SingleValue<OptionType<string>>) =>
                  handleHeadingFontChange(option, 'heading')
                }
                className="u-width--full"
                placeholder={formatMessage({
                  id: 'proposal-appearance-accordion.typography.search-fonts',
                })}
              />
              <StyledSelect
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.stopPropagation();
                }}
                styles={{
                  ...getSelectStyles<string>(),
                }}
                onFocus={handleFocus}
                value={{
                  value: proposal?.style?.typography?.heading?.fontWeight,
                  label: getWeightLabel(
                    proposal?.style?.typography?.heading?.fontWeight,
                  ),
                }}
                options={weightOptions}
                onChange={(option: SingleValue<OptionType<string>>) =>
                  handleHeadingWeightChange(option, 'heading')
                }
                className="u-width--full"
                placeholder={formatMessage({
                  id: 'proposal-appearance-accordion.typography.search-font-weights',
                })}
              />
            </div>
          </div>

          {/*
            TO-DO @markoboras0712 hide body typography for now
            <div className="proposal__summary__accordion__typography__item__container">
              <span className="t-label">
                <FormattedMessage id="proposal-appearance-accordion.typography.body" />
              </span>
              <div className="proposal__summary__accordion__typography__select__container">
                <StyledSelect
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') e.stopPropagation();
                  }}
                  menuShouldBlockScroll={isMobile ? true : false}
                  styles={{
                    ...getSelectStyles(),
                    menuList: (provided) => ({
                      ...provided,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                  }}
                  options={fontOptions}
                  value={{
                    value: getValues('style.typography.body.fontFamily'),
                    label: getValues('style.typography.body.fontFamily'),
                  }}
                  onChange={(option) => handleHeadingFontChange(option, 'body')}
                  className="u-width--full"
                  placeholder={formatMessage({
                    id: 'proposal-appearance-accordion.typography.search-fonts',
                  })}
                />
                <StyledSelect
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') e.stopPropagation();
                  }}
                  menuShouldBlockScroll={isMobile ? true : false}
                  value={{
                    value: getValues('style.typography.heading.fontWeight'),
                    label: getWeightLabel(
                      getValues('style.typography.heading.fontWeight'),
                    ),
                  }}
                  styles={{
                    ...getSelectStyles(),
                    menuList: (provided) => ({
                      ...provided,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                  }}
                  options={weightOptions}
                  onChange={(option) =>
                    handleHeadingWeightChange(option, 'body')
                  }
                  className="u-width--full"
                  placeholder={formatMessage({
                    id: 'proposal-appearance-accordion.typography.search-font-weights',
                  })}
                />
              </div>
            </div> */}
        </div>
      </div>
    </div>
  );
};
