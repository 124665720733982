import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import { authorizationSelector } from 'modules/authorization';
import { FeatureFlagWrapper } from 'modules/feature-flags';
import {
  FreeTrialEndedNotice,
  FreeTrialLeftNotice,
  GracePeriodNotice,
  PropozePlusNotice,
} from 'modules/subscription';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BackIcon, Button } from 'shared/components';
import { isLocationTypeGuard } from 'shared/utils';

interface Props {
  showNotice?: boolean;
  onToggleMenu?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ManageSidebar: React.FC<Props> = ({
  showNotice = true,
  onToggleMenu,
}) => {
  const location = useLocation();
  const { formatMessage } = useIntl();

  const state = isLocationTypeGuard(location) && location.state;
  const { adminRole } = useSelector(authorizationSelector.getState);

  function handleReturnBack() {
    if (state && state?.lastPageUrl) {
      navigate(state?.lastPageUrl);
      return;
    }
    navigate(formatMessage({ id: 'routes.overview' }));
  }

  return (
    <nav className="dashboard__sidebar__nav" onClick={onToggleMenu}>
      <ul>
        <Button
          type="button"
          className="dashboard__sidebar__back"
          btnSelector="dashboard-sidebar-back"
          onClick={handleReturnBack}
        >
          <BackIcon />
          <span className="text--sm t-bold">
            <FormattedMessage id="buttons.back" />
          </span>
        </Button>
        {adminRole === 'superAdmin' && (
          <>
            <Link
              className="dashboard__sidebar__link"
              activeClassName="is-active"
              to={formatMessage({ id: 'routes.admin.users' })}
              data-cy="sidebar-admin-dashboard-users"
            >
              <FormattedMessage id="admin.users.title" />
            </Link>
            <Link
              className="dashboard__sidebar__link"
              activeClassName="is-active"
              to={formatMessage({ id: 'routes.admin.referrals' })}
              data-cy="sidebar-admin-dashboard-proposals"
            >
              <FormattedMessage id="admin.referrals.title" />
            </Link>
            <Link
              className="dashboard__sidebar__link"
              activeClassName="is-active"
              to={formatMessage({ id: 'routes.admin.proposals' })}
              data-cy="sidebar-admin-dashboard-proposals"
            >
              <FormattedMessage id="admin.proposals.title" />
            </Link>
            <Link
              to={formatMessage({ id: 'routes.admin.overview' })}
              className="dashboard__sidebar__link"
              activeClassName="is-active"
              data-cy="sidebar-admin-dashboard-rights-management"
            >
              <FormattedMessage id="admin.summary.title" />
            </Link>
            <div className="divider--verylight-5 s-top--sml s-bottom--sml" />
          </>
        )}
        <Link
          activeClassName="is-active"
          to={formatMessage({ id: 'routes.clients' })}
          className="dashboard__sidebar__link"
          data-cy="dashboard-sidebar-client"
        >
          <FormattedMessage id="clients.title" />
        </Link>
        <Link
          activeClassName="is-active"
          to={formatMessage({ id: 'routes.services' })}
          className="dashboard__sidebar__link"
          data-cy="dashboard-sidebar-service"
        >
          <FormattedMessage id="services.title" />
        </Link>
        <Link
          activeClassName="is-active"
          to={formatMessage({ id: 'routes.units' })}
          className="dashboard__sidebar__link"
          data-cy="dashboard-sidebar-units"
        >
          <FormattedMessage id="units.title" />
        </Link>
        <div className="divider--verylight-5 s-top--sml s-bottom--sml" />
        <Link
          to={formatMessage({ id: 'routes.proposal-settings' })}
          activeClassName="is-active"
          className="dashboard__sidebar__link"
          data-cy="sidebar-proposal-builder-settings"
        >
          Proposal settings
        </Link>
        <div className="divider--verylight-5 s-top--sml s-bottom--sml" />
        <Link
          to={formatMessage({ id: 'routes.company-info' })}
          activeClassName="is-active"
          className="dashboard__sidebar__link"
          data-cy="sidebar-general-info-settings"
        >
          <FormattedMessage id="settings.general-information.sidebar" />
        </Link>
        <Link
          to={formatMessage({ id: 'routes.address' })}
          activeClassName="is-active"
          className="dashboard__sidebar__link"
          data-cy="sidebar-address-settings"
        >
          <FormattedMessage id="settings.address-settings.sidebar" />
        </Link>
        <Link
          to={formatMessage({ id: 'routes.tax-settings' })}
          activeClassName="is-active"
          className="dashboard__sidebar__link"
          data-cy="sidebar-tax-settings"
        >
          <FormattedMessage id="settings.tax-settings.sidebar" />
        </Link>{' '}
        <div className="divider--verylight-5 s-top--sml s-bottom--sml" />
        <FeatureFlagWrapper flag="SUBSCRIPTION">
          <Link
            to={formatMessage({ id: 'routes.subscription-settings' })}
            activeClassName="is-active"
            className="dashboard__sidebar__link"
            data-cy="sidebar-subscription-settings"
          >
            <FormattedMessage id="settings.subscription.sidebar" />
          </Link>
        </FeatureFlagWrapper>
        <Link
          to="/dashboard/settings/account"
          activeClassName="is-active"
          className="dashboard__sidebar__link"
          data-cy="sidebar-account-settings"
        >
          <FormattedMessage id="settings.account.title" />
        </Link>
        {/* 
          Uncomment this when feature Users is ready
          {!isOwner() && (
            
              <Link
                activeClassName="is-active"
                to={formatMessage({ id: 'routes.users' })}
                className="dashboard__sidebar__link"
              >
                <FormattedMessage id="users.title" />
              </Link>
           
          )} */}
      </ul>
      {showNotice && <PropozePlusNotice className="free__limit__container" />}
      {showNotice && <GracePeriodNotice />}
      {showNotice && <FreeTrialLeftNotice />}
      {showNotice && <FreeTrialEndedNotice />}
    </nav>
  );
};
