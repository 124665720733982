import { isValid } from 'mailchecker';
import {
  AuthenticationFormState,
  useScrollIntoView,
} from 'modules/authentication';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { VALIDATORS } from 'shared/utils';
import { FieldWrapper } from './FieldWrapper';

/**
 *  Run disposable email check on production only so we can still
 *  use the disposable emails locally for testing
 **/
const isProduction = process.env.NODE_ENV === 'production';

export const EmailInput: React.FC = () => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<AuthenticationFormState>();

  return (
    <FieldWrapper
      name="email"
      errors={errors}
      labelId="inputs.email.label"
      isRequired
    >
      <input
        type="email"
        className={`input input__med input__box ${
          errors.email ? 'input__box__error' : ''
        }`}
        placeholder={formatMessage({ id: 'inputs.email.placeholder' })}
        data-cy="form-email-input"
        onFocus={handleFocus}
        {...register('email', {
          required: formatMessage({ id: 'inputs.email.required' }),
          validate: (email) =>
            isProduction
              ? isValid(email) || formatMessage({ id: 'inputs.email.invalid' })
              : true,
          pattern: {
            value: VALIDATORS.email,
            message: formatMessage({ id: 'inputs.email.invalid' }),
          },
        })}
      />
    </FieldWrapper>
  );
};
