export interface AuthenticationFormState {
  email: string;
  password: string;
}

export class AuthenticationFormState {
  constructor() {
    this.email = '';
    this.password = '';
  }
}
