import proposalNoPermissionsIlustration from 'assets/images/proposal-no-permissions-ilustration.svg';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { authenticationSelector } from 'modules/authentication';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { PublicProposalStatus } from '../status';

export const PublicProposalNoPermissions: React.FC = () => {
  const { formatMessage } = useIntl();
  const { user } = useSelector(authenticationSelector.getState);

  return (
    <>
      <motion.div
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, type: 'intertia' }}
      >
        <img
          src={proposalNoPermissionsIlustration}
          alt="Proposal restricted - no permission"
          className="restricted-proposal__graphic__image"
        />
      </motion.div>
      <PublicProposalStatus
        message={
          <FormattedMessage id="errors.public-proposals.no-permission" />
        }
      />
      <motion.div
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6, type: 'intertia' }}
        className="s-top--lrg restricted-proposal__button"
        data-cy="back-to-homepage-btn"
      >
        <Link
          to={formatMessage({
            id: `${user ? 'routes.overview' : 'routes.home'}`,
          })}
          className="btn btn__med btn__primary"
        >
          {user ? (
            <FormattedMessage id="errors.public-proposals.no-permission.user.action" />
          ) : (
            <FormattedMessage id="errors.public-proposals.no-permission.guest.action" />
          )}
        </Link>
      </motion.div>
    </>
  );
};
