import { motion } from 'framer-motion';
import { FreeTierNotice } from 'modules/subscription';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProposalAppearanceAccordionListItemHeader } from './fragments/ProposalAppearanceAccordionListItemHeader';

const variants = {
  initial: { opacity: 0, y: -8 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -8 },
};

export const ProposalAppearanceAccordionFreemiumList: React.FC = () => {
  return (
    <>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={variants}
        transition={{ duration: 0.15 }}
        className="proposal__summary__accordion__container toc__freemium__list"
      >
        <div className="toc__freemium__list__overlay" />
        <div className="proposal__summary__accordion__item__container">
          <ProposalAppearanceAccordionListItemHeader
            toggle="presets"
            titleId="proposal-appearance-accordion.presets.title"
          />
        </div>

        <div className="proposal__summary__accordion__item__container">
          <ProposalAppearanceAccordionListItemHeader
            toggle="color"
            titleId="proposal-appearance-accordion.color.title"
          />
        </div>
        <div className="proposal__summary__accordion__item__container">
          <ProposalAppearanceAccordionListItemHeader
            toggle="typography"
            titleId="proposal-appearance-accordion.typography.title"
          />
        </div>
      </motion.div>
      <div className="f f--col m-top-auto toc__freemium__notification">
        <span className="t-bold t-text-1 text--xs">
          <FormattedMessage id="proposal-summary.plus-notice-brand.header" />
        </span>
        <p className="t-text-2 text--xsm">
          <FormattedMessage id="proposal-summary.plus-notice-brand.description" />
        </p>
        <FreeTierNotice
          noticeActionId="propoze-plus-notice.free.action"
          noticeDescriptionId="propoze-plus-notice.free.description"
          containerClassName="s-top--med"
        />
      </div>
    </>
  );
};
