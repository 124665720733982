import { ErrorComponentProps } from 'modules/dashboard';
import { Unit, UnitListItem, useUnits, useUnitsOrdering } from 'modules/units';
import React, { PropsWithChildren, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Loading, Pagination } from 'shared/components';
import { useHasMounted, usePaginationPages } from 'shared/hooks';

interface Props extends EntityState<Unit> {
  isLoading: boolean;
  // query?: string;
  loadingType?: LoadingTypes;
  documents?: Unit[];
  // results?: Unit[];
  limit?: number;
  className?: string;
  errorClassName?: string;
  errorComponent?: React.FC<ErrorComponentProps>;
  searchErrorComponent?: React.FC<ErrorComponentProps>;
  onClick?: VoidFunction;
}

/**TO-DO
 * @markoboras0712 this component needs refactor when I move clients/services/units to recoil
 */

export const DraggableList: React.FC<PropsWithChildren<Props>> = ({
  loadingType = 'card',
  limit = 15,
  isLoading,
  errorComponent: ErrorComponent,
  // searchErrorComponent: SearchErrorComponent,
  documents,
  // query,
  className,
  errorClassName,
  // results,
  children,
  onClick,
}) => {
  const { units, reorderUnits, getValues } = useUnitsOrdering(documents);
  const { reorderEntitiesAsync } = useUnits();
  const data = getValues('units');
  const isFirstRender = useHasMounted();

  const { totalPages, onGoToPage, endIndex, startingIndex, pageNumber } =
    usePaginationPages(limit, units?.length);

  useEffect(() => {
    if (!isFirstRender && data) reorderEntitiesAsync(data);
  }, [units, data]);

  if (isLoading || !documents) {
    return (
      <>
        {[...new Array(3)].map((_, index) => (
          <Loading key={`loading-list-${index}`} type={loadingType} />
        ))}
      </>
    );
  }

  if (!documents?.length && ErrorComponent) {
    return (
      <div className={errorClassName}>
        <ErrorComponent onClick={() => onClick?.()} />{' '}
      </div>
    );
  }

  // if (!results?.length && SearchErrorComponent) {
  //   return (
  //     <div className={errorClassName}>
  //       <SearchErrorComponent onClick={() => onClick?.()} />{' '}
  //     </div>
  //   );
  // }

  return (
    <div className={className}>
      {children}
      {/* {query.length > 0 && results?.length ? (
        <ul>
          {results?.slice(startingIndex, endIndex)?.map((item, index) => (
            <li>
              <UnitListItem
                index={index}
                key={index}
                item={item}
                query={query}
              />
            </li>
          ))}
        </ul>
      ) : ( */}
      <DragDropContext onDragEnd={reorderUnits}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {units?.slice(startingIndex, endIndex)?.map((item, index) => (
                <Draggable key={item.key} draggableId={item.key} index={index}>
                  {(provided) => (
                    <li>
                      <UnitListItem
                        index={index}
                        key={index}
                        item={item}
                        provided={provided}
                        // query={query}
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              <>{provided.placeholder}</>
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      {/* )} */}

      {documents && documents?.length > limit && (
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          onGoToPage={onGoToPage}
        />
      )}
    </div>
  );
};
