import { Settings } from 'modules/settings';
import React, { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  AddressOneInput,
  AddressTwoInput,
  CityInput,
  CountrySelect,
  PostalCodeInput,
  StateInput,
} from 'shared/components';

interface Props extends PropsWithChildren {
  config: Settings;
  onSubmit: (data: Settings) => void;
}

export const AddressStep: React.FC<Props> = ({
  config,
  onSubmit,
  children,
}) => {
  const form = useForm<Settings>({
    mode: 'onSubmit',
    defaultValues: new Settings(config),
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <h2 className="text--xl t-black t-bold s-bottom--lrg">
          <FormattedMessage id="inputs.address.title" />
        </h2>
        <div className="field">
          <AddressOneInput
            label="inputs.address.label"
            required="inputs.address.required"
            placeholder="inputs.address.placeholder"
            inputSelector="address-one-input"
          />
        </div>
        <div className="field">
          <AddressTwoInput
            label="inputs.address-two.label"
            placeholder="inputs.address-two.placeholder"
            inputSelector="address-two-input"
          />
        </div>
        <div className="field" data-cy="country-settings-container">
          <CountrySelect
            label="dropdowns.country.label"
            required="dropdowns.country.required"
            placeholder="dropdowns.country.placeholder"
          />
        </div>
        <div className="field">
          <StateInput
            label="inputs.state.label"
            placeholder="inputs.state.placeholder"
            inputSelector="state-input"
          />
        </div>
        <div className="address-form__group">
          <div className="field__half--width client-modal__spacer u-width--full">
            <CityInput
              label="inputs.city.label"
              required="inputs.city.required"
              placeholder="inputs.city.placeholder"
              inputSelector="city-input"
            />
          </div>
          <div className="field__half--width">
            <PostalCodeInput
              label="inputs.postal-code.label"
              required="inputs.postal-code.required"
              placeholder="inputs.postal-code.placeholder"
              inputSelector="postal-code-input"
            />
          </div>
        </div>
        {children}
      </form>
    </FormProvider>
  );
};
