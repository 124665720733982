import React, { PropsWithChildren } from 'react';
import { Button } from 'shared/components';

interface Props extends PropsWithChildren {
  type: ProposalSegment['type'];
  index: number;
  btnSelector?: string;
  onAdd: (type: ProposalSegment['type'], index: number) => void;
}

export const SegmentButton: React.FC<Props> = ({
  type,
  index,
  children,
  btnSelector,
  onAdd,
}) => {
  const baseClass = 'btn btn__lrg';
  const typeClassMap = {
    service: 'btn__primary',
    heading: 'btn__outline',
    text: 'btn__outline',
    file: 'btn__outline',
    media: 'btn__outline',
  };

  const classNames = `${baseClass} ${typeClassMap[type] || ''}`;

  return (
    <Button
      type="button"
      onClick={() => onAdd(type, index)}
      className={classNames}
      btnSelector={`${btnSelector}${index}`}
    >
      {children}
    </Button>
  );
};
