import { Accept } from 'react-dropzone';

export const imageTypes: Record<string, string[]> = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/svg+xml': ['.svg'],
  'image/bmp': ['.bmp'],
  'image/webp': ['.webp'],
  'image/vnd.adobe.photoshop': ['.psd'],
  'image/x-icon': ['.ico'],
};

export const videoTypes: Record<string, string[]> = {
  'video/mp4': ['.mp4', '.m4v'],
  'video/quicktime': ['.mov', '.qt'],
  'video/mpeg': ['.mpg', '.mpeg', '.mpe'],
  'video/ogg': ['.ogv'],
  'video/webm': ['.webm'],
  'video/3gpp': ['.3gp'],
  'video/3gpp2': ['.3g2'],
  'video/x-ms-wmv': ['.wmv'],
  'video/x-matroska': ['.mkv'],
  'video/x-msvideo': ['.avi'],
  'video/x-flv': ['.flv'],
};

export const mediaAttachmentSupportedTypes: Accept = {
  ...imageTypes,
  ...videoTypes,

  'application/pdf': ['.pdf'], // PDF File
};
