import { REFERRALS_LIST } from 'modules/onboarding';

/**
 * This function takes a referral code and returns its description.
 * If the referral code does not have a description, the referral code itself is returned.
 *
 * @param {string} referralCode - The referral code.
 * @returns {string} The referral description.
 */
export const getReferralDescription = (
  referralCode: ReferralSource,
): string => {
  const description =
    REFERRALS_LIST.find((item) => item.source === referralCode)?.description ||
    referralCode;
  return description;
};
