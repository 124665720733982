import { useEffect, useRef, useState } from 'react';
import { useKeyPress } from './useKeyPress';

export function useListNavigation<T>(items?: T[] | null) {
  const [cursor, setCursor] = useState<number>(0);
  const [hovered, setHovered] = useState<T | undefined>(undefined);

  const upPress = useKeyPress('ArrowUp');
  const downPress = useKeyPress('ArrowDown');

  const ref = useRef<HTMLUListElement | null>(null);

  /**Hover */
  useEffect(() => {
    if (items?.length && hovered) {
      setCursor(items?.indexOf(hovered));
    }
  }, [hovered]);

  /**Down press*/
  useEffect(() => {
    if (items?.length && downPress) {
      setCursor((prev) => (prev < items?.length - 1 ? prev + 1 : prev));
    }
  }, [downPress]);

  /**Up press*/
  useEffect(() => {
    if (items?.length && upPress) {
      setCursor((prev) => (prev > 0 ? prev - 1 : prev));
    }
  }, [upPress]);

  /**Scroll to the active element */
  useEffect(() => {
    if (ref.current && items && items?.length > cursor) {
      const activeElement = ref.current.children[cursor];

      if (activeElement instanceof HTMLElement) {
        activeElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [cursor, items]);

  return { ref, cursor, setHovered };
}
