import { DualPrice, Price, SinglePrice, formatPrice } from 'modules/proposals';
import React from 'react';

interface Props {
  currency: string;
  totalSubtotal: number;
  exchangeRate?: number;
  ISOCountry?: string;
}

export const SubtotalPublicServiceSegmentCardPrice: React.FC<Props> = ({
  currency,
  totalSubtotal,
  exchangeRate,
  ISOCountry,
}) => (
  <Price
    currency={currency}
    dualPriceElement={
      <DualPrice>
        {exchangeRate && (
          <p className="t-eta">
            <strong>
              {formatPrice(totalSubtotal / exchangeRate, 'eur', ISOCountry)}
            </strong>
          </p>
        )}
        <p className="o-60 text--xxs__responsive">
          {formatPrice(totalSubtotal, currency, ISOCountry)}
        </p>
      </DualPrice>
    }
    singlePriceElement={
      <SinglePrice className="t-eta">
        <strong>{formatPrice(totalSubtotal, currency, ISOCountry)}</strong>
      </SinglePrice>
    }
  />
);
