import { motion } from 'framer-motion';
import React from 'react';
import { useIntl } from 'react-intl';

interface StepTextProps {
  currentStep: number;
}

const StepText: React.FC<StepTextProps> = ({ currentStep }) => {
  const { formatMessage } = useIntl();
  const steps = [
    {
      id: 1,
      label: formatMessage({ id: 'onboarding.stepper.step-1.title' }),
      description: formatMessage({
        id: 'onboarding.stepper.step-1.description',
      }),
    },
    {
      id: 2,
      label: formatMessage({ id: 'onboarding.stepper.step-2.title' }),
      description: formatMessage({
        id: 'onboarding.stepper.step-2.description',
      }),
    },
    {
      id: 3,
      label: formatMessage({ id: 'onboarding.stepper.step-3.title' }),
      description: formatMessage({
        id: 'onboarding.stepper.step-3.description',
      }),
    },
  ];
  const stepInfo = steps.find((step) => step.id === currentStep);

  return (
    <div className="f f--col">
      <span className="text--sm t-bold t-text-1">{stepInfo?.label}</span>
      <span className="text--xsm t-text2">{stepInfo?.description}</span>
    </div>
  );
};

interface Props {
  currentStep: number;
}

export const MobileOnboardingStepper: React.FC<Props> = ({ currentStep }) => {
  const strokeWidth = 2;
  const effectiveDiameter = 32 - strokeWidth * 2;
  const radius = effectiveDiameter / 2;
  const circumference = 2 * Math.PI * radius;

  const fillPercentage = (step: number) => {
    switch (step) {
      case 1:
        return circumference * 0.67; // 33% filled
      case 2:
        return circumference * 0.34; // 66% filled
      case 3:
        return 0; // 100% filled
      default:
        return circumference;
    }
  };

  return (
    <div className="onboarding-stepper__container--mobile">
      <svg width="32" height="32" viewBox="0 0 32 32">
        <circle
          cx="16"
          cy="16"
          r={radius}
          fill="transparent"
          strokeWidth={strokeWidth}
          stroke="#D9D9D9"
        />
        <motion.circle
          cx="16"
          cy="16"
          r={radius}
          fill="transparent"
          strokeWidth={strokeWidth}
          stroke="#8039FF"
          initial={{
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: circumference,
          }}
          animate={{
            strokeDashoffset: fillPercentage(currentStep),
          }}
          transition={{ duration: 0.6 }}
          transform="rotate(-90 16 16)"
        />
        <text
          x="16"
          y="16"
          dominantBaseline="middle"
          textAnchor="middle"
          className="text--xsm t-text-1"
        >
          {`${currentStep}/3`}
        </text>
      </svg>
      <StepText currentStep={currentStep} />
    </div>
  );
};
