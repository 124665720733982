import { useDispatch } from 'react-redux';
import { authorizationAction } from '../redux';

export function useAuthorizationDispatch() {
  const dispatch = useDispatch();

  return {
    updateUserRoles: (roles: RoleClaim[] | Partial<RoleClaim>[]) =>
      dispatch(authorizationAction.updateUserRoles(roles)),

    updateAdminRole: (adminRole: Role) =>
      dispatch(authorizationAction.updateAdminRole(adminRole)),
  };
}
