export function isPublishStatusArray(
  values: unknown,
): values is PublishStatus[] {
  return (
    Array.isArray(values) &&
    values.every((item) =>
      ['published', 'draft', 'archived', 'template'].includes(item),
    )
  );
}
