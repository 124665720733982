import { subscriptionSelectors } from 'modules/subscription';
import { useEffect, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useMobile } from 'shared/hooks';
import { useActiveSectionObserver } from './useActiveSectionObserver';

export function useTableOfContents(
  isOpen: boolean,
  onSetTocOpen: (isOpen: boolean) => void,
  proposal?: PublishStatusAware<Proposal> | Proposal,
) {
  const ref = useRef<HTMLUListElement>(null);

  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const isMobile = useMobile();
  const sections = useMemo(
    () =>
      proposal?.segments?.map(
        (segment, index) => `${segment?.type}-segment-${index}`,
      ) || [],
    [proposal?.segments],
  );
  const { activeSection, setActiveIndex } = useActiveSectionObserver(sections);

  function handleToggle() {
    const htmlElement = document.documentElement;
    if (!isOpen) {
      htmlElement.classList.add('is-locked');
    } else {
      htmlElement.classList.remove('is-locked');
    }

    onSetTocOpen(!isOpen);
  }

  function scrollToActiveSection(index: number, type: ProposalSegment['type']) {
    const element = document.getElementById(`${type}-segment-${index}`);
    if (element instanceof HTMLElement) {
      setActiveIndex(index);
      element?.scrollIntoView({ behavior: 'auto', block: 'end' });
      if (isMobile) handleToggle();
    }
  }

  function isActiveSection(index: number, type: ProposalSegment['type']) {
    const elementId = `${type}-segment-${index}`;

    return elementId === activeSection;
  }

  /**Automatically scroll in ToC if element is not visible */
  useEffect(() => {
    const activeSectionIndex = activeSection?.split('-').pop();
    if (!activeSectionIndex || !isOpen || !isTierOne) return;

    const currentElement = ref.current?.children[Number(activeSectionIndex)];
    if (currentElement instanceof Element) {
      currentElement.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }, [activeSection, isOpen]);

  return {
    ref,
    isOpen,
    isMobile,
    handleToggle,
    scrollToActiveSection,
    isActiveSection,
  };
}
