import { useLocation } from '@reach/router';
import { useFunction } from 'modules/firebase';
import { useSubscriptionHelpers } from 'modules/subscription';
import { useDomain } from 'shared/hooks';

export function useStripeCheckout() {
  const currentDomain = useDomain();
  const { pathname } = useLocation();
  const { isFreeTrial } = useSubscriptionHelpers();

  const createBillingPortalSession = useFunction<
    CreateBillingPortalSession,
    StripeBillingPortalSession
  >('createBillingPortalSession');

  async function createBillingPortal(customerId: string) {
    if (!createBillingPortalSession) return;
    try {
      const { data } = await createBillingPortalSession({
        customerId,
        returnUrl: `${currentDomain}${pathname}`,
        isFreeTrial: isFreeTrial || false,
      });

      if (!data.success) throw new Error(data.message);
      return data.payload;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  return {
    createBillingPortal,
  };
}
