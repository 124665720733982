import eyeIconUnhidden from 'assets/icons/icon-eye-12-crossed.svg';
import eyeIconHidden from 'assets/icons/icon-eye-12.svg';
import {
  AuthenticationFormState,
  useScrollIntoView,
} from 'modules/authentication';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from './FieldWrapper';

export const passwordRegex = /^(?=\S).{5,}(\S)$/i;

interface Props {
  isPathMatchingLogin?: boolean;
}

export const PasswordInput: React.FC<Props> = ({ isPathMatchingLogin }) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
  } = useFormContext<AuthenticationFormState>();

  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  return (
    <FieldWrapper
      name="password"
      errors={errors}
      labelId="inputs.password.label"
      isRequired
      isPathMatchingLogin={isPathMatchingLogin}
    >
      <div className="auth__eye__container">
        <input
          type={showPassword ? 'text' : 'password'}
          className={`input input__box input__med ${
            errors.password ? 'input__box__error' : ''
          }`}
          placeholder={formatMessage({ id: 'inputs.password.placeholder' })}
          data-cy="form-password-input"
          onFocus={handleFocus}
          autoComplete="password"
          {...register('password', {
            required: formatMessage({ id: 'inputs.password.required' }),
            pattern: {
              value: passwordRegex,
              message: formatMessage({ id: 'inputs.password.invalid' }),
            },
          })}
        />
        <div onClick={togglePasswordVisibility} className="auth__eye">
          {showPassword ? (
            <img src={eyeIconUnhidden} alt="eyeIconUnhidden" />
          ) : (
            <img src={eyeIconHidden} alt="eyeIconHidden" />
          )}
        </div>
      </div>
    </FieldWrapper>
  );
};
