import { atom } from 'recoil';

const loadingDocument = atom<boolean>({
  key: 'stripe-loading-doc',
  default: true,
});

const loadingOrganizationTiers = atom<boolean>({
  key: 'subscription-loading-organization-tiers',
  default: true,
});

const organizationTiers = atom<Record<string, SubscriptionTier>>({
  key: 'subscription-organization-tiers',
  default: {},
});

const active = atom<boolean>({
  key: 'subscription-active',
  default: false,
});

const products = atom<StripeProductWithPrices[]>({
  key: 'stripe-products',
  default: [],
});

const customerId = atom<string | undefined>({
  key: 'stripe-customer-id',
  default: undefined,
});

const discountPercentage = atom<number | null | undefined>({
  key: 'subscription-discount-percentage',
  default: undefined,
});

const subscriptionId = atom<string | null | undefined>({
  key: 'stripe-subscription-id',
  default: undefined,
});

const priceId = atom<string | null | undefined>({
  key: 'subscription-price-id',
  default: undefined,
});

const productId = atom<string | null | undefined>({
  key: 'subscription-product-id',
  default: undefined,
});
const start = atom<number | null | undefined>({
  key: 'subscription-start',
  default: undefined,
});

const end = atom<number | null | undefined | 'permanent'>({
  key: 'subscription-end',
  default: undefined,
});

const upcomingSubscription = atom<UpcomingSubscriptionPlan | null | undefined>({
  key: 'subscription-upcoming-plan',
  default: undefined,
});

const status = atom<Subscription['status']>({
  key: 'subscription-status',
  default: undefined,
});

const tier = atom<SubscriptionTier>({
  key: 'subscription-tier',
  default: 0,
});

const document = atom<Subscription | undefined>({
  key: 'stripe-subscription',
  default: undefined,
});

const error = atom<string | undefined>({
  key: 'stripe-error',
  default: undefined,
});

const isStatusLoadingModalOpen = atom<boolean>({
  key: 'status-loading-modal',
  default: false,
});

const isGracePeriod = atom<boolean | null | undefined>({
  key: 'is-grace-period',
  default: undefined,
});

const isFreeTrial = atom<boolean | null | undefined>({
  key: 'is-free-trial',
  default: false,
});

const hasUsedFreeTrial = atom<boolean | null | undefined>({
  key: 'has-used-free-trial',
  default: false,
});

export const subscriptionAtoms = {
  status,
  tier,
  organizationTiers,
  active,
  start,
  end,
  priceId,
  productId,
  customerId,
  discountPercentage,
  subscriptionId,
  upcomingSubscription,
  products,
  document,
  error,
  loadingDocument,
  loadingOrganizationTiers,
  isStatusLoadingModalOpen,
  isGracePeriod,
  hasUsedFreeTrial,
  isFreeTrial,
};
