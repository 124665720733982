import { User } from 'firebase/auth';
import { authenticationAction } from './actions';

interface ReducerState {
  readonly isLoading: boolean;
  readonly user?: User | null;
  readonly error?: FirebaseAuthError;
}

const INITIAL_STATE: ReducerState = {
  isLoading: false,
  user: undefined,
  error: undefined,
};

export const authenticationReducer = (
  state: ReducerState = INITIAL_STATE,
  action: ActionUnion<typeof authenticationAction>,
): ReducerState => {
  switch (action.type) {
    case 'authentication/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'authentication/LOGIN':
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
      };
    case 'authentication/ON_SNAPSHOT_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case 'authentication/RESET_ERRORS':
      return {
        ...state,
        error: undefined,
      };
    case 'authentication/LOGOUT':
      return { ...INITIAL_STATE, user: null };
    default:
      return state || INITIAL_STATE;
  }
};
