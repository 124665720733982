import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

interface Props {
  label: string;
  placeholder: string;
  inputSelector?: string;
}

export const AddressTwoInput: React.FC<Props> = ({
  label,
  placeholder,
  inputSelector,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<Settings>();

  return (
    <FieldWrapper name="address.addressTwo" errors={errors} labelId={label}>
      <input
        type="text"
        className={`input input__box input__med ${
          errors.address?.addressTwo ? 'input__box__error' : ''
        }`}
        data-cy={inputSelector}
        onFocus={handleFocus}
        {...register('address.addressTwo')}
        placeholder={formatMessage({
          id: placeholder,
        })}
      />
    </FieldWrapper>
  );
};
