import successIcon from 'assets/icons/icon-success-12.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  productId: string;
  features: string[];
  plusFeatures?: string[];
  isVatNoteVisible?: boolean;
  className?: string;
  featuresSelector?: string;
  isLegacyFreeUser?: boolean;
  isTierOne?: boolean;
}

export const ProductFeatureList: React.FC<Props> = ({
  features,
  productId,
  isVatNoteVisible,
  className,
  plusFeatures,
  featuresSelector,
  isLegacyFreeUser,
  isTierOne,
}) => {
  if (!features?.length) return null;

  return (
    <>
      {isLegacyFreeUser && !isTierOne && (
        <span className="t-text-1 text--sm s-bottom--12">
          Your free Propoze plan is always there if you choose not to upgrade.
          We appreciate your support as a valued member of the Propoze
          community!
        </span>
      )}
      <ul
        className={`list--bullets s-bottom--12  ${className || ''}`}
        data-cy={featuresSelector}
      >
        {features.map((feature, index) => (
          <li
            key={`${productId}_feature_${index}`}
            className="f f--align-start g-4"
          >
            <img
              src={successIcon}
              className="s-top--tny btn__icon__left"
              alt="Success Icon"
            />
            <span className="text--sm t-text-1 s-left--tny">{feature}</span>
          </li>
        ))}
      </ul>
      {isLegacyFreeUser && !isTierOne && plusFeatures?.length && (
        <ul
          className={`list--bullets s-bottom--12 ${className || ''} o-60 c-disabled`}
          data-cy="propoze-plus-features-list"
        >
          {plusFeatures?.map((feature, index) => (
            <li
              key={`${productId}_feature_${index}`}
              className="f f--align-start g-4"
            >
              <img
                src={successIcon}
                className="s-top--tny btn__icon__left"
                alt="Success Icon"
              />
              <span className="text--sm t-text-1 s-left--tny">{feature}</span>
            </li>
          ))}
        </ul>
      )}
      {isVatNoteVisible && (
        <span className="t-text-2 subscription-product__group__vat__notice">
          <FormattedMessage id="subscription-select-modal.vat-notice" />
        </span>
      )}
    </>
  );
};
