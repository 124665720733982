import checkMarkIcon from 'assets/icons/icon-checkmark-16-primary.svg';
import documentIcon from 'assets/icons/icon-document-16.svg';
import lockedIcon from 'assets/icons/icon-locked-16.svg';
import mediaIcon from 'assets/icons/icon-media-16.svg';
import pdfExportIcon from 'assets/icons/icon-pdf-export-16.svg';
import pencilIcon from 'assets/icons/icon-pencil-16.svg';
import {
  isLegacyFreeUserAtom,
  useOrganizationIdSelector,
} from 'modules/authorization';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape, useModal } from 'shared/hooks';
import { useSubscriptionHelpers } from '../hooks';

export const FreeTrialNoticeModal: React.FC = () => {
  const organizationId = useOrganizationIdSelector();
  const isLegacyFreeUser = useRecoilValue(isLegacyFreeUserAtom);
  const { isFreeTrialActive } = useSubscriptionHelpers();

  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const hideFreeTrialNoticeModalOrganizations = JSON.parse(
    localStorage.getItem('hideFreeTrialNoticeModal') || '[]',
  );
  const { isModalOpen, closeModal } = useModal(
    !hideFreeTrialNoticeModalOrganizations?.includes(organizationId),
  );

  function closeFreeTrialNoticeModal() {
    localStorage.setItem(
      'hideFreeTrialNoticeModal',
      JSON.stringify([
        ...hideFreeTrialNoticeModalOrganizations,
        organizationId,
      ]),
    );
    closeModal();
  }

  if (!isModalOpen || isLegacyFreeUser || !isFreeTrialActive) {
    return null;
  }

  return (
    <Modal
      isDashboardModal
      containerClassName="free-trial-notice__modal"
      close={closeFreeTrialNoticeModal}
    >
      <ModalHeader>
        <p className="text--xl__responsive t-bold t-text-1">
          <FormattedMessage id="free-trial-notice-modal.title" />
        </p>
      </ModalHeader>
      <ModalContent
        className="subscription-product__group__content"
        contentSelector="propoze-plus-modal-content"
      >
        <p className="t-text-1 t-bold text--xs__responsive s-bottom--med">
          <FormattedMessage id="free-trial-notice-modal.description" />
        </p>
        <ul
          className="list--bullets s-bottom--lrg list--2x2__responsive r-g-16"
          data-cy="free-trial-notice-modal-features"
        >
          <li className="f f--align-center g-16">
            <img src={documentIcon} alt="Document Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.unlimited-proposals-title" />
              </p>
              <span className="text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.unlimited-proposals-description" />
              </span>
            </div>
          </li>
          <li className="f f--align-center g-16">
            <img src={pencilIcon} alt="Pencil Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.custom-branding-title" />
              </p>
              <span className="text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.custom-branding-description" />
              </span>
            </div>
          </li>
          <li className="f f--align-center g-16">
            <img src={pdfExportIcon} alt="PDF Export Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.pdf-export-title" />
              </p>
              <span className="text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.pdf-export-description" />
              </span>
            </div>
          </li>
          <li className="f f--align-center g-16">
            <img src={lockedIcon} alt="Locked Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.access-control-title" />
              </p>
              <span className="text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.access-control-description" />
              </span>
            </div>
          </li>
          <li className="f f--align-center g-16">
            <img src={mediaIcon} alt="Media Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.media-attachments-title" />
              </p>
              <span className="text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.media-attachments-description" />
              </span>
            </div>
          </li>{' '}
          <li className="f f--align-center g-16">
            <img src={checkMarkIcon} alt="Checkmark Icon" />
            <div className="f f--col g-4 t-text-1">
              <p className="t-bold text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.streamlined-approvals-title" />
              </p>
              <span className="text--xxs__responsive">
                <FormattedMessage id="free-trial-notice-modal.plus-features.streamlined-approvals-description" />
              </span>
            </div>
          </li>
        </ul>
      </ModalContent>
      <ModalFooter className="m-left-auto u--width--full--mobile">
        <Button
          type="button"
          ref={closeButtonRef}
          onClick={closeFreeTrialNoticeModal}
          size="lrg"
          style="primary"
        >
          <FormattedMessage id="buttons.get-started-now" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
