import { useFeatureFlag } from 'modules/feature-flags';
import React, { PropsWithChildren } from 'react';
import { useSubscriptionHelpers } from '../hooks';

/** Determine whether user is in free trial period. */
export const FreeTrialLeftWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isFreeTrialActive } = useSubscriptionHelpers();
  const subscriptionsEnabled = useFeatureFlag('SUBSCRIPTION');

  if (!isFreeTrialActive || !subscriptionsEnabled) {
    return null;
  }

  return <>{children}</>;
};
