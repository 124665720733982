import { useFunction } from 'modules/firebase';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

export const useAdminRightsManagement = () => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const editAdminRole = useFunction<EditRoleData>('editAdminRole');

  const changeAdminRole = (email: string, adminRole: Role) => {
    setLoading(true);
    editAdminRole?.({ email, adminRole })
      .then(() => {
        toast.success(
          formatMessage({
            id: 'admin.summary.rights.success',
          }),
        );
      })
      .catch(() => {
        toast.error(
          formatMessage({
            id: 'admin.summary.rights.error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { loading, changeAdminRole };
};
