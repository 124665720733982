/**
 * Determines if a heading segment should be visible.
 *
 * This function takes a `HeadingSegment` object and an optional boolean indicating whether the current view is a preview.
 * If the view is not a preview or `isPreview` is not provided, the function returns true.
 * If the view is a preview, the function checks if the segment has a title or a subtitle, and returns true if it does, and false otherwise.
 *
 * @param segment - The `HeadingSegment` object to check.
 * @param isPreview - An optional boolean indicating whether the current view is a preview.
 * @returns A boolean indicating whether the heading segment should be visible.
 */
export function isHeadingSegmentVisible(
  segment: HeadingSegment,
  isPreview?: boolean,
): boolean {
  if (!isPreview) return true;

  const hasTitleOrDescription = Boolean(segment.title || segment.subtitle);
  return hasTitleOrDescription;
}
