import { getReferralDescription } from 'modules/admin';
import React from 'react';
import { TableHead } from 'shared/components';

export const ReferralsTable: React.FC<{
  userReferrals: ReferralAnalytics[];
}> = ({ userReferrals }) => {
  return (
    <div className="table-wrapper">
      <table className="table table--fixed">
        <colgroup>
          <col width="144"></col>
          <col width="164"></col>
          <col width="164"></col>
          <col width="192"></col>
          <col width="144"></col>
          <col width="144"></col>
        </colgroup>
        <thead>
          <tr>
            <TableHead label="ID" />
            <TableHead label="Company name" />
            <TableHead label="Email" />
            <TableHead label="Referrals" />
            <TableHead label="Country" />
            <TableHead label="City" />
          </tr>
        </thead>
        <tbody>
          {userReferrals?.map(
            ({ id, companyName, email, referral, country, city }) => (
              <tr key={`user-${id}`}>
                <td>{id}</td>
                <td>{companyName || ''}</td>
                <td>{email}</td>
                <td>{getReferralDescription(referral)}</td>
                <td>{country || ''}</td>
                <td>{city || ''}</td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    </div>
  );
};
