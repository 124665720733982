import { signOut } from 'firebase/auth';
import {
  authenticationSelector,
  useAuthDispatch,
} from 'modules/authentication';
import { mobileNavDropdownVisibleSelector } from 'modules/dashboard';
import { auth, useFunction } from 'modules/firebase';
import { useSetOverviewFiltersOption } from 'modules/proposals-overview';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';

export function useAuthentication() {
  const authDispatch = useAuthDispatch();
  const { user } = useSelector(authenticationSelector.getState);
  const setFilters = useSetOverviewFiltersOption();
  const setMobileNavdropdownVisible = useSetRecoilState(
    mobileNavDropdownVisibleSelector,
  );

  const deleteUser = useFunction<DeleteUserData>('deleteUser');

  async function logout() {
    try {
      await signOut(auth);
      authDispatch.logout();
      setFilters([]);
      setMobileNavdropdownVisible(false);
    } catch (e) {
      authDispatch.error(e);
    }
  }

  async function userDelete() {
    if (!user) return;

    try {
      logout();
      await deleteUser?.({ uid: user.uid });
    } catch (e) {
      authDispatch.error(e);
    }
  }

  return {
    logout,
    userDelete,
  };
}
