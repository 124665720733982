import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'shared/components';
import { SaveNewVersionComponent } from '../save-new-version-component';

interface Props {
  publishStatus: Proposal['publishStatus'];
  isSaved?: boolean;
  isEditorActions?: boolean;
}

export const SaveNewVersionButton: React.FC<Props> = ({
  publishStatus,
  isSaved,
  isEditorActions,
}) => {
  const {
    setValue,
    formState: { isDirty, isSubmitting },
  } = useFormContext<PublishStatusAware<Proposal>>();

  function getClassName() {
    if (!isDirty && isSaved) {
      return 'btn__green';
    }
    if (isSubmitting) {
      return 'btn__dark';
    }

    return 'btn__primary';
  }

  function handleSaveNewVersion() {
    setValue('nextPublishStatus', publishStatus);
  }

  return (
    <Button
      className={`${getClassName()} m-left-auto ${
        isEditorActions && 'actionbar__editor__actions'
      }`}
      size="med"
      type="submit"
      disabled={!isDirty}
      onClick={handleSaveNewVersion}
      btnSelector={!isDirty ? 'save-btn-disabled' : 'builder-save-changes-btn'}
    >
      <SaveNewVersionComponent
        isSubmitting={isSubmitting}
        isSaved={isSaved}
        isDirty={isDirty}
        publishStatus={publishStatus}
      />
    </Button>
  );
};
