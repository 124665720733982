export function isSubscriptionTierEnabled(
  current: SubscriptionTier,
  required: SubscriptionTier,
) {
  return current >= required;
}

export function isSubscriptionTierEnabledExact(
  current: SubscriptionTier,
  required: SubscriptionTier,
) {
  return current === required;
}
