import { useFunction } from 'modules/firebase';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { adminAction } from '../redux';

export const useActiveUsersStats = () => {
  const dispatch = useDispatch();
  const getLastMonthActiveUsers = useFunction<undefined, number>(
    'getLastMonthActiveUsers',
  );

  const fetchLastMonthActiveUsers = useCallback(() => {
    dispatch(adminAction.loading());
    getLastMonthActiveUsers?.()
      .then(({ data }) => {
        if (!data.success || typeof data.payload !== 'number') {
          throw new Error('Invalid data');
        }
        return dispatch(
          adminAction.addNumberOfActiveUsersLastMonth(data.payload),
        );
      })
      .catch((error) => dispatch(adminAction.error(error)));
  }, [getLastMonthActiveUsers]);

  useEffect(fetchLastMonthActiveUsers, [fetchLastMonthActiveUsers]);
};
