import { useProposalGlobalSearch } from 'modules/proposals';
import React, { useState } from 'react';

export const useDashboardSearch = (callback: VoidFunction) => {
  const [query, setQuery] = useState('');
  const { proposals, clients } = useProposalGlobalSearch(query);

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value);
    callback();
  }

  return { query, proposals, clients, setQuery, handleSearch };
};
