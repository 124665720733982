import { motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { AccordionType } from '../ProposalAppearanceAccordionList';

interface Props {
  isSectionActive?: boolean;
  toggle: AccordionType;
  titleId: string;
  handleToggle?: (type: AccordionType) => void;
}

export const ProposalAppearanceAccordionListItemHeader: React.FC<Props> = ({
  isSectionActive,
  toggle,
  titleId,
  handleToggle,
}) => {
  return (
    <Button
      type="button"
      onClick={() => handleToggle?.(toggle)}
      className="f f--between-center proposal__summary__accordion__header__container"
      tabIndex={0}
    >
      <span className="proposal__summary__accordion__header text--xs">
        <FormattedMessage id={titleId} />
      </span>

      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        className="btn__icon f--shrink-zero"
        animate={{ rotate: isSectionActive ? 0 : -180 }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
        fill="none"
      >
        <path
          d="M11 9.17188L8.17157 6.34345L5.34314 9.17188"
          stroke="#706F6F"
          strokeWidth="0.75"
        />
      </motion.svg>
    </Button>
  );
};
