import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Settings } from '../Settings';

export const settingsFirestoreConverter = {
  toFirestore: (settings: Settings) => ({
    ...new Settings(settings),
  }),
  fromFirestore: (doc: QueryDocumentSnapshot<Settings>) => {
    return new Settings(doc.data());
  },
};
