import { countryToLocaleMap } from 'shared/const';

export function formatPrice(
  price: number,
  currency: string | null = 'USD',
  ISOCountry = 'US',
): string {
  if (!currency) return Number(0).toFixed(2);
  const locale = countryToLocaleMap[ISOCountry] || 'en-US';

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    useGrouping: true,
    currencyDisplay: 'narrowSymbol',
    localeMatcher: 'best fit',
    numberingSystem: 'latn',
  }).format(price);
}
