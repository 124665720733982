import { navigate } from 'gatsby';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { toast } from 'sonner';
import { subscriptionAtoms } from '../state';
import { useStripeCheckout } from './checkout';

export function usePricingSelectPlan() {
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const customerId = useRecoilValue(subscriptionAtoms.customerId);

  const { createBillingPortal } = useStripeCheckout();

  async function handleCheckout() {
    try {
      if (!customerId) {
        toast.error(
          'Please wait for a few seconds while your auto-free subscription is activating. Thank you for your patience!',
        );
        return;
      }

      setIsCheckoutLoading(true);

      const billingPortalData = await createBillingPortal(customerId);

      if (!billingPortalData || !billingPortalData.url) {
        toast.error(
          'Sorry, the creation of your checkout failed. Please try again later or contact support@propoze.app for assistance.',
        );
        return;
      }

      navigate(billingPortalData.url);
    } catch {
      throw new Error('Could not create checkout session');
    } finally {
      setIsCheckoutLoading(false);
    }
  }

  return {
    isCheckoutLoading,
    handleCheckout,
  };
}
