import { useProposalOverviewFilterOptions } from 'modules/proposals-overview';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useKeyPress, useListNavigation, useMobile } from 'shared/hooks';
import { ProposalOverviewFilterOption } from '../ProposalOverviewFilterOption';

interface Props {
  acceptanceStatus: string[];
}

export const ProposalOverviewAcceptanceStatusOptions: React.FC<Props> = ({
  acceptanceStatus,
}) => {
  const isMobile = useMobile();
  const { formatMessage } = useIntl();
  const enterPress = useKeyPress('Enter');

  const { handleFilterChange } = useProposalOverviewFilterOptions();
  const { cursor, setHovered } = useListNavigation<string>(acceptanceStatus);

  /**Enter press */
  useEffect(() => {
    if (acceptanceStatus?.length && enterPress) {
      handleFilterChange('acceptance', acceptanceStatus[cursor]);
    }
  }, [enterPress]);

  return (
    <ul className="proposal-overview__filter__options">
      {acceptanceStatus?.length ? (
        <>
          {acceptanceStatus?.map((status, index) => (
            <React.Fragment key={status}>
              <ProposalOverviewFilterOption
                type="acceptance"
                name={status}
                label={formatMessage({
                  id: `proposals.overview.label.acceptance-status-${status}`,
                })}
                isActive={index === cursor}
                onMouseEnter={() => setHovered(status)}
                onMouseLeave={() => setHovered(undefined)}
                handleFilterChange={handleFilterChange}
              />
              {index !== acceptanceStatus?.length - 1 && isMobile && (
                <div className="proposal-overview__actions__divider" />
              )}
            </React.Fragment>
          ))}
        </>
      ) : (
        <span className="proposal__overview__filter__option__error text--sm">
          <FormattedMessage id="proposals.overview.filter.no-results" />
        </span>
      )}
    </ul>
  );
};
