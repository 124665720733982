import { toast } from 'sonner';
import { STORAGE_LIMIT_MESSAGE } from '../const';

/** This function checks if user has his limit of total file size upload on account in storage (1GB). */
export function isAccountTotalUploadSizeUsed(
  totalUploadSize: number,
  config: Settings | null,
) {
  if (
    config?.storageLeftInBytes &&
    totalUploadSize > config?.storageLeftInBytes
  ) {
    toast.error(STORAGE_LIMIT_MESSAGE);
    return true;
  }
  return;
}
