export interface Client {
  id?: string;
  name: string;
  email: string | null;
  vatId: string | null;
  phone: string | null;
  address: Address;
  createdAt: number;
}

export class Client {
  constructor(data?: Client) {
    this.id = data?.id;
    this.name = data?.name || '';
    this.email = data?.email || null;
    this.vatId = data?.vatId || null;
    this.phone = data?.phone || '';
    this.createdAt = data?.createdAt || 1609455600000; // 2021-01-01 fallback for clients created before createdAt was introduced

    this.address = {
      addressOne: data?.address?.addressOne || '',
      city: data?.address?.city || '',
      postalCode: data?.address?.postalCode || '',
      addressTwo: data?.address?.addressTwo || null,
      country: data?.address?.country || null,
      state: data?.address?.state || null,
    };
  }
}
