import { PageProps } from 'gatsby';
import {
  AdminListError,
  ReferralsTable,
  adminSelector,
  useUserReferrals,
} from 'modules/admin';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Loading, Pagination } from 'shared/components';
import { usePaginationPages } from 'shared/hooks';
import { AdminLayout } from '../components/admin-layout';

export const AdminReferralsOverview: React.FC<PageProps> = () => {
  useUserReferrals();
  const { formatMessage } = useIntl();
  const { isLoading, userReferrals } = useSelector(adminSelector.getState);
  const { totalPages, onGoToPage, endIndex, startingIndex, pageNumber } =
    usePaginationPages(50, userReferrals?.length);

  const loadingTable = useMemo(
    () => isLoading || !userReferrals?.length,
    [isLoading, userReferrals],
  );

  return (
    <>
      <AdminLayout>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="admin-users-title"
        >
          <FormattedMessage id="admin.referrals.title" />
        </header>
        <div className="admin-dashboard__container">
          {loadingTable ? (
            <>
              <Loading type="list" />
              <Loading type="list" />
              <Loading type="list" />
            </>
          ) : (
            <>
              {userReferrals?.length ? (
                <>
                  <ReferralsTable
                    userReferrals={userReferrals?.slice(
                      startingIndex,
                      endIndex,
                    )}
                  />
                  <Pagination
                    totalPages={totalPages}
                    pageNumber={pageNumber}
                    onGoToPage={onGoToPage}
                  />
                </>
              ) : (
                <AdminListError
                  message={formatMessage({
                    id: 'errors.referrals-overview',
                  })}
                />
              )}
            </>
          )}
        </div>
      </AdminLayout>
    </>
  );
};
