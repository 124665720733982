import { useAuthentication } from 'modules/authentication';
import { subscriptionSelectors } from 'modules/subscription';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Modal } from 'shared/components';
import { DeleteAccountConfirmModal } from './DeleteAccountConfirmModal';
import { DeleteAccountInfoModal } from './DeleteAccountInfoModal';
import { DeleteAccountStatusModal } from './DeleteAccountStatusModal';

export const DeleteAccountModal: React.FC<ModalProps> = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [currentLocalStep, setCurrentLocalStep] =
    useState<deleteAccountSteps>('info');

  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);

  const { userDelete } = useAuthentication();

  function onConfirmDelete() {
    setLoading(true);
    userDelete().finally(() => setLoading(false));
  }

  const deleteAccountModal = {
    ['info']: (
      <DeleteAccountInfoModal
        onSetCurrentLocalStep={setCurrentLocalStep}
        onClose={onClose}
      />
    ),
    ['confirm']: (
      <DeleteAccountConfirmModal
        hasActivePlusSubscription={isTierOne}
        onSetCurrentLocalStep={setCurrentLocalStep}
        onClose={onClose}
      />
    ),
    ['status']: (
      <DeleteAccountStatusModal
        loading={loading}
        onConfirmDeleteAccount={onConfirmDelete}
      />
    ),
  };

  return (
    <Modal
      isPropozePlusModal
      containerClassName={
        currentLocalStep !== 'status'
          ? undefined
          : 'modal__delete__account--confirm'
      }
    >
      {deleteAccountModal[currentLocalStep]}
    </Modal>
  );
};
