export function hasServiceChanged(
  service1?: Service | null,
  service2?: Service | null,
): boolean {
  const props = ['name', 'id'];

  for (const prop of props) {
    if (service1?.[prop] !== service2?.[prop]) {
      return true;
    }
  }

  return false;
}
