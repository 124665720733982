import duplicateIcon from 'assets/icons/icon-duplicate-12.svg';
import { addDays } from 'date-fns';
import { Proposal, useConfirmDuplicate, useProposals } from 'modules/proposals';
import { Settings, useConfigSelector } from 'modules/settings';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  className: string;
  proposal: Proposal;
  btnSelector?: string;
}

export const DuplicateButton: React.FC<Props> = ({
  className,
  proposal,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { createEntityAsync } = useProposals();
  const { isDirty, handleConfirmDuplicate } = useConfirmDuplicate();

  const config = useConfigSelector();

  async function onClick(e: React.MouseEvent) {
    if (isDirty && !handleConfirmDuplicate(e)) return;

    const id = await createEntityAsync(
      new Proposal({
        ...proposal,
        name: `Duplicate of: ${proposal.name}`,
        displayDateTime: +new Date(),
        creationDateTime: +new Date(),
        validUntilDateTime: +new Date(addDays(new Date(), 14)),
        publishStatus: 'draft',
        visibility: 'public',
        ownerNotifiedDate: null,
        companyInfo: config
          ? { ...new Settings(config) }
          : proposal.companyInfo,
        vatPercentage: config?.tax?.vatPercentage || proposal.vatPercentage,
        currency: config?.defaultCurrency || proposal.currency,
        responseStatus: config?.defaultProposalResponseStatus || 'allowed',
        servicePricesVisibility:
          config?.defaultServicePricesVisibility || 'visible',
        acceptanceStatus: 'pending',
      }),
    );

    if (typeof id === 'string') {
      if (window.Cypress) {
        return;
      }

      window.open(
        formatMessage({ id: 'routes.new-proposal' }, { id }),
        '_blank',
      );
      return;
    }
  }

  return (
    <Button
      type="button"
      className={`${className} btn__icon__stroke`}
      onClick={onClick}
      {...props}
    >
      <img
        src={duplicateIcon}
        alt="DuplicateIcon"
        className="btn__icon btn__icon__left"
      />
      <FormattedMessage id="buttons.duplicate" />
    </Button>
  );
};
