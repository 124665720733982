import { CSSProperties } from 'react';

export function generateTypography(
  type: 'heading' | 'body',
  proposal?: Proposal,
): CSSProperties {
  return {
    fontFamily: proposal?.style?.typography?.[type].fontFamily || 'Aeonik',
    fontWeight: proposal?.style?.typography?.[type].fontWeight || '400',
  };
}
