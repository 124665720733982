import {
  applyMiddleware,
  combineReducers,
  createStore,
  ReducersMapObject,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

export function createReduxStore(appReducer: ReducersMapObject<AppState>) {
  /**
   * Create the composing function for our middlewares
   * Include dev tools support
   */
  const composeEnhancers = composeWithDevTools({});

  /**
   * We'll create our store with the combined reducers and all enchancers
   */
  return createStore(
    combineReducers(appReducer),
    {},
    process.env.NODE_ENV === 'production'
      ? applyMiddleware()
      : composeEnhancers(applyMiddleware()),
  );
}
