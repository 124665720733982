import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocalStorage } from 'shared/hooks';
import { subscriptionSelectors } from '../state';

export function usePropozePlusModal(
  onCloseSlidingMenu?: (menuOpen: boolean) => void,
) {
  const [source, setSource] = useLocalStorage<string>('source');
  const products = useRecoilValue(subscriptionSelectors.productsSelector);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const querySource = queryParams.get('source');
    const src = querySource || source;

    if (src === 'subscribeButton' && products?.length) {
      setIsModalVisible(true);
      setSource('');
      navigate(window.location.pathname, { replace: true });
    }
  }, [location.search, products, source]);

  function openModal() {
    onCloseSlidingMenu?.(false);
    setIsModalVisible(true);
  }

  function closeModal() {
    const htmlElement = document.documentElement;

    htmlElement.classList.remove('is-locked');
    setIsModalVisible(false);
  }

  return { isModalVisible, openModal, closeModal };
}
