import { useOrganizationIdSelector } from 'modules/authorization';
import { useFunction } from 'modules/firebase';
import { useState } from 'react';
import { toast } from 'sonner';
import { useStripeCustomer } from '../customer';
import { showDebugStatuses } from './../../../firebase/utils/index';

interface CreateStripeSubscriptionProps {
  priceId?: string;
  customerId?: string;
  isFreeTrial?: boolean;
}

export function useStripeSubscription() {
  const organizationId = useOrganizationIdSelector();
  const [isLoading, setIsLoading] = useState(false);

  const createSubscription =
    useFunction<SubscribeUserData>('createSubscription');
  const { createStripeCustomer } = useStripeCustomer();

  async function createStripeSubscription({
    priceId,
    customerId,
    isFreeTrial,
  }: CreateStripeSubscriptionProps) {
    if (!createSubscription) return;
    setIsLoading(true);

    try {
      if (!customerId) {
        if (showDebugStatuses) {
          console.log('New customer flow', {
            customerId,
            priceId,
            isFreeTrial,
          });
        }
        const newCustomerId = await createStripeCustomer();
        if (!newCustomerId) {
          throw new Error('Error in creating new customer');
        }
        const { data } = await createSubscription({
          priceId: priceId || process.env.GATSBY_FREE_PLAN_ID,
          customerId: newCustomerId,
          organizationId,
          isFreeTrial,
        });
        if (!data.success) {
          throw new Error('Error in creating new subscription');
        }
        return;
      }
      if (showDebugStatuses) {
        console.log('Existing customer flow', {
          customerId,
          priceId: priceId || process.env.GATSBY_FREE_PLAN_ID,
          isFreeTrial,
          organizationId,
        });
      }
      const { data } = await createSubscription({
        priceId: priceId || process.env.GATSBY_FREE_PLAN_ID,
        customerId,
        organizationId,
        isFreeTrial,
      });
      if (!data.success) {
        throw new Error('Error in creating new subscription');
      }
      return;
    } catch (error) {
      console.error(error);
      toast.error(`Subscribing user failed. Try again later.`);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    createStripeSubscription,
  };
}
