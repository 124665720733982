export const countryToLocaleMap: { [key: string]: string } = {
  AD: 'ca-AD', // Andorra
  AE: 'ar-AE', // United Arab Emirates
  AF: 'ps-AF', // Afghanistan
  AG: 'en-AG', // Antigua and Barbuda
  AI: 'en-AI', // Anguilla
  AL: 'sq-AL', // Albania
  AM: 'hy-AM', // Armenia
  AO: 'pt-AO', // Angola
  AR: 'es-AR', // Argentina
  AS: 'en-AS', // American Samoa
  AT: 'de-AT', // Austria
  AU: 'en-AU', // Australia
  AW: 'nl-AW', // Aruba
  AX: 'sv-AX', // Åland Islands
  AZ: 'az-AZ', // Azerbaijan
  BA: 'bs-BA', // Bosnia and Herzegovina
  BB: 'en-BB', // Barbados
  BD: 'bn-BD', // Bangladesh
  BE: 'nl-BE', // Belgium
  BF: 'fr-BF', // Burkina Faso
  BG: 'bg-BG', // Bulgaria
  BH: 'ar-BH', // Bahrain
  BI: 'rn-BI', // Burundi
  BJ: 'fr-BJ', // Benin
  BL: 'fr-BL', // Saint Barthélemy
  BM: 'en-BM', // Bermuda
  BN: 'ms-BN', // Brunei
  BO: 'es-BO', // Bolivia
  BQ: 'nl-BQ', // Bonaire, Sint Eustatius and Saba
  BR: 'pt-BR', // Brazil
  BS: 'en-BS', // Bahamas
  BT: 'dz-BT', // Bhutan
  BW: 'en-BW', // Botswana
  BY: 'be-BY', // Belarus
  BZ: 'en-BZ', // Belize
  CA: 'en-CA', // Canada
  CC: 'en-CC', // Cocos (Keeling) Islands
  CD: 'fr-CD', // Congo (Democratic Republic)
  CF: 'fr-CF', // Central African Republic
  CG: 'fr-CG', // Congo
  CH: 'de-CH', // Switzerland
  CI: 'fr-CI', // Côte d'Ivoire
  CK: 'en-CK', // Cook Islands
  CL: 'es-CL', // Chile
  CM: 'fr-CM', // Cameroon
  CN: 'zh-CN', // China
  CO: 'es-CO', // Colombia
  CR: 'es-CR', // Costa Rica
  CU: 'es-CU', // Cuba
  CV: 'pt-CV', // Cape Verde
  CW: 'nl-CW', // Curaçao
  CX: 'en-CX', // Christmas Island
  CY: 'el-CY', // Cyprus
  CZ: 'cs-CZ', // Czech Republic
  DE: 'de-DE', // Germany
  DJ: 'fr-DJ', // Djibouti
  DK: 'da-DK', // Denmark
  DM: 'en-DM', // Dominica
  DO: 'es-DO', // Dominican Republic
  DZ: 'ar-DZ', // Algeria
  EC: 'es-EC', // Ecuador
  EE: 'et-EE', // Estonia
  EG: 'ar-EG', // Egypt
  EH: 'ar-EH', // Western Sahara
  ER: 'ti-ER', // Eritrea
  ES: 'es-ES', // Spain
  ET: 'am-ET', // Ethiopia
  FI: 'fi-FI', // Finland
  FJ: 'en-FJ', // Fiji
  FK: 'en-FK', // Falkland Islands
  FM: 'en-FM', // Micronesia
  FO: 'fo-FO', // Faroe Islands
  FR: 'fr-FR', // France
  GA: 'fr-GA', // Gabon
  GB: 'en-GB', // United Kingdom
  GD: 'en-GD', // Grenada
  GE: 'ka-GE', // Georgia
  GF: 'fr-GF', // French Guiana
  GG: 'en-GG', // Guernsey
  GH: 'en-GH', // Ghana
  GI: 'en-GI', // Gibraltar
  GL: 'kl-GL', // Greenland
  GM: 'en-GM', // Gambia
  GN: 'fr-GN', // Guinea
  GP: 'fr-GP', // Guadeloupe
  GQ: 'es-GQ', // Equatorial Guinea
  GR: 'el-GR', // Greece
  GT: 'es-GT', // Guatemala
  GU: 'en-GU', // Guam
  GW: 'pt-GW', // Guinea-Bissau
  GY: 'en-GY', // Guyana
  HK: 'zh-HK', // Hong Kong
  HN: 'es-HN', // Honduras
  HR: 'hr-HR', // Croatia
  HT: 'ht-HT', // Haiti
  HU: 'hu-HU', // Hungary
  ID: 'id-ID', // Indonesia
  IE: 'en-IE', // Ireland
  IL: 'he-IL', // Israel
  IM: 'en-IM', // Isle of Man
  IN: 'hi-IN', // India
  IO: 'en-IO', // British Indian Ocean Territory
  IQ: 'ar-IQ', // Iraq
  IR: 'fa-IR', // Iran
  IS: 'is-IS', // Iceland
  IT: 'it-IT', // Italy
  JE: 'en-JE', // Jersey
  JM: 'en-JM', // Jamaica
  JO: 'ar-JO', // Jordan
  JP: 'ja-JP', // Japan
  KE: 'sw-KE', // Kenya
  KG: 'ky-KG', // Kyrgyzstan
  KH: 'km-KH', // Cambodia
  KI: 'en-KI', // Kiribati
  KM: 'ar-KM', // Comoros
  KN: 'en-KN', // Saint Kitts and Nevis
  KP: 'ko-KP', // North Korea
  KR: 'ko-KR', // South Korea
  KW: 'ar-KW', // Kuwait
  KY: 'en-KY', // Cayman Islands
  KZ: 'kk-KZ', // Kazakhstan
  LA: 'lo-LA', // Laos
  LB: 'ar-LB', // Lebanon
  LC: 'en-LC', // Saint Lucia
  LI: 'de-LI', // Liechtenstein
  LK: 'si-LK', // Sri Lanka
  LR: 'en-LR', // Liberia
  LS: 'en-LS', // Lesotho
  LT: 'lt-LT', // Lithuania
  LU: 'fr-LU', // Luxembourg
  LV: 'lv-LV', // Latvia
  LY: 'ar-LY', // Libya
  MA: 'ar-MA', // Morocco
  MC: 'fr-MC', // Monaco
  MD: 'ro-MD', // Moldova
  ME: 'sr-ME', // Montenegro
  MF: 'fr-MF', // Saint Martin
  MG: 'mg-MG', // Madagascar
  MH: 'en-MH', // Marshall Islands
  MK: 'mk-MK', // North Macedonia
  ML: 'fr-ML', // Mali
  MM: 'my-MM', // Myanmar
  MN: 'mn-MN', // Mongolia
  MO: 'zh-MO', // Macau
  MP: 'en-MP', // Northern Mariana Islands
  MQ: 'fr-MQ', // Martinique
  MR: 'ar-MR', // Mauritania
  MS: 'en-MS', // Montserrat
  MT: 'mt-MT', // Malta
  MU: 'en-MU', // Mauritius
  MV: 'dv-MV', // Maldives
  MW: 'ny-MW', // Malawi
  MX: 'es-MX', // Mexico
  MY: 'ms-MY', // Malaysia
  MZ: 'pt-MZ', // Mozambique
  NA: 'en-NA', // Namibia
  NC: 'fr-NC', // New Caledonia
  NE: 'fr-NE', // Niger
  NF: 'en-NF', // Norfolk Island
  NG: 'en-NG', // Nigeria
  NI: 'es-NI', // Nicaragua
  NL: 'nl-NL', // Netherlands
  NO: 'nb-NO', // Norway
  NP: 'ne-NP', // Nepal
  NR: 'en-NR', // Nauru
  NU: 'en-NU', // Niue
  NZ: 'en-NZ', // New Zealand
  OM: 'ar-OM', // Oman
  PA: 'es-PA', // Panama
  PE: 'es-PE', // Peru
  PF: 'fr-PF', // French Polynesia
  PG: 'en-PG', // Papua New Guinea
  PH: 'en-PH', // Philippines
  PK: 'ur-PK', // Pakistan
  PL: 'pl-PL', // Poland
  PM: 'fr-PM', // Saint Pierre and Miquelon
  PN: 'en-PN', // Pitcairn Islands
  PR: 'es-PR', // Puerto Rico
  PS: 'ar-PS', // Palestine
  PT: 'pt-PT', // Portugal
  PW: 'en-PW', // Palau
  PY: 'es-PY', // Paraguay
  QA: 'ar-QA', // Qatar
  RE: 'fr-RE', // Réunion
  RO: 'ro-RO', // Romania
  RS: 'sr-RS', // Serbia
  RU: 'ru-RU', // Russia
  RW: 'rw-RW', // Rwanda
  SA: 'ar-SA', // Saudi Arabia
  SB: 'en-SB', // Solomon Islands
  SC: 'en-SC', // Seychelles
  SD: 'ar-SD', // Sudan
  SE: 'sv-SE', // Sweden
  SG: 'en-SG', // Singapore
  SH: 'en-SH', // Saint Helena
  SI: 'sl-SI', // Slovenia
  SJ: 'nb-SJ', // Svalbard and Jan Mayen
  SK: 'sk-SK', // Slovakia
  SL: 'en-SL', // Sierra Leone
  SM: 'it-SM', // San Marino
  SN: 'fr-SN', // Senegal
  SO: 'so-SO', // Somalia
  SR: 'nl-SR', // Suriname
  SS: 'en-SS', // South Sudan
  ST: 'pt-ST', // São Tomé and Príncipe
  SV: 'es-SV', // El Salvador
  SX: 'nl-SX', // Sint Maarten
  SY: 'ar-SY', // Syria
  SZ: 'en-SZ', // Eswatini
  TC: 'en-TC', // Turks and Caicos Islands
  TD: 'fr-TD', // Chad
  TF: 'fr-TF', // French Southern Territories
  TG: 'fr-TG', // Togo
  TH: 'th-TH', // Thailand
  TJ: 'tg-TJ', // Tajikistan
  TK: 'en-TK', // Tokelau
  TL: 'pt-TL', // Timor-Leste
  TM: 'tk-TM', // Turkmenistan
  TN: 'ar-TN', // Tunisia
  TO: 'to-TO', // Tonga
  TR: 'tr-TR', // Turkey
  TT: 'en-TT', // Trinidad and Tobago
  TV: 'en-TV', // Tuvalu
  TW: 'zh-TW', // Taiwan
  TZ: 'sw-TZ', // Tanzania
  UA: 'uk-UA', // Ukraine
  UG: 'en-UG', // Uganda
  US: 'en-US', // United States
  UY: 'es-UY', // Uruguay
  UZ: 'uz-UZ', // Uzbekistan
  VA: 'it-VA', // Vatican City
  VC: 'en-VC', // Saint Vincent and the Grenadines
  VE: 'es-VE', // Venezuela
  VG: 'en-VG', // British Virgin Islands
  VI: 'en-VI', // U.S. Virgin Islands
  VN: 'vi-VN', // Vietnam
  VU: 'bi-VU', // Vanuatu
  WF: 'fr-WF', // Wallis and Futuna
  WS: 'sm-WS', // Samoa
  YE: 'ar-YE', // Yemen
  YT: 'fr-YT', // Mayotte
  ZA: 'en-ZA', // South Africa
  ZM: 'en-ZM', // Zambia
  ZW: 'en-ZW', // Zimbabwe
};
