import { adminAction } from 'modules/admin';
import { useSetOverviewFiltersOption } from 'modules/proposals-overview';
import { useDispatch } from 'react-redux';
import { authenticationAction } from '../redux';

export function useAuthDispatch() {
  const dispatch = useDispatch();
  const setFilters = useSetOverviewFiltersOption();

  return {
    login: (user: unknown) =>
      dispatch(authenticationAction.login(user as unknown as FirebaseUser)),

    logout: () => {
      dispatch(authenticationAction.logout());
      dispatch(adminAction.logout());
      setFilters([]);
    },

    error: (error: FirebaseAuthError) =>
      dispatch(authenticationAction.error(error)),

    resetErrors: () => dispatch(authenticationAction.resetErrors()),
  };
}
