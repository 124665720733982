import dragIcon from 'assets/icons/icon-drag-16-secondary.svg';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRequiredProposalFieldCheck } from 'shared/hooks';
import { MobileSegmentMover } from '../mobile-segment-mover';
import { SegmentActions } from '../segment-actions';
import { SegmentDeleteButton } from '../segment-delete-button';
import { ServiceDescriptionInput } from '../service-description-input';

interface Props {
  item: TextSegment;
  index: number;
  provided: DraggableProvided;
  segmentsLength: number;
  context: UseFormReturn<PublishStatusAware<Proposal>, any, undefined>;
  addSegment: (type: ProposalSegment['type'], index: number) => void;
  deleteSegment: (index: number) => void;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

export const TextSegmentCard: React.FC<Props> = ({
  index,
  provided,
  segmentsLength,
  item,
  context,
  addSegment,
  deleteSegment,
  moveItem,
}) => {
  const { formatMessage } = useIntl();
  const { isFieldRequired } = useRequiredProposalFieldCheck();

  return (
    <>
      <div
        className="proposal-service proposal-service--richtext"
        id={`text-segment-${index}`}
      >
        <div
          {...provided.dragHandleProps}
          className="proposal-service__dragger"
          data-cy={`drag-and-drop-btn-${index}`}
        >
          <img src={dragIcon} alt="DragIcon" />
        </div>
        <SegmentDeleteButton index={index} onDelete={deleteSegment} />
        <MobileSegmentMover
          index={index}
          moveItem={moveItem}
          segmentsLength={segmentsLength}
        />
        <div className="proposal-service__body">
          <div className="field">
            <ServiceDescriptionInput
              value={item.content}
              index={index}
              isFieldRequired={isFieldRequired}
              placeholder={formatMessage({
                id: 'inputs.rich-text.placeholder',
              })}
              {...context}
            />
          </div>
        </div>
      </div>

      <SegmentActions index={++index} onAdd={addSegment} />
    </>
  );
};
