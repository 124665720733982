import clockIcon from 'assets/icons/icon-clock-12-grey.svg';
import closeIcon from 'assets/icons/icon-close-12.svg';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { GLOBAL_RECENT_SEARCHES_LS_KEY } from 'shared/const';
import {
  useKeyPress,
  useListNavigation,
  useMobile,
  useOnClickRecentSearchResults,
} from 'shared/hooks';

interface Props {
  onSetQuery: (query: string) => void;
}

export const RecentSearchesList: React.FC<Props> = ({ onSetQuery }) => {
  const isMobile = useMobile();
  const enterPress = useKeyPress('Enter');

  const { recentSearches, handleSetRecentSearch, deleteRecentSearch } =
    useOnClickRecentSearchResults(GLOBAL_RECENT_SEARCHES_LS_KEY);
  const { ref, cursor, setHovered } = useListNavigation(recentSearches);

  function handleSelectItem(
    search: string,
    e?: React.MouseEvent<HTMLSpanElement>,
  ) {
    e?.stopPropagation();
    handleSetRecentSearch(search);
    onSetQuery(search);
  }

  /**Enter press */
  useEffect(() => {
    if (recentSearches?.length && enterPress) {
      handleSelectItem(recentSearches[cursor]);
    }
  }, [enterPress]);

  return (
    <motion.aside
      style={{
        opacity: 0,
        y: -20,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.2,
        delay: 0.3,
        type: 'intertia',
      }}
      className="search__results"
    >
      <ul
        ref={ref}
        className="search__results__wrapper search__results__wrapper--recent"
      >
        <span className="t-text-2 t-label search__results__label">
          <FormattedMessage id="search.results.recent" />
        </span>
        {recentSearches?.map((search, i) => (
          <React.Fragment key={search}>
            <li
              onMouseEnter={() => setHovered(search)}
              onMouseLeave={() => setHovered(undefined)}
              data-cy={`search-results-${i}`}
              className={`text--sm t-text-1 ${
                i === cursor ? 'search__results__wrapper--recent__result' : ''
              }`}
            >
              <div className="f g-4">
                <img
                  src={clockIcon}
                  alt="Clock icon"
                  className="btn__icon btn__icon__left"
                />
                <span
                  className="t-ellipsis-2-row s-right--med"
                  onClick={(e) => handleSelectItem(search, e)}
                >
                  {search}
                </span>
              </div>
              {(i === cursor || isMobile) && (
                <img
                  src={closeIcon}
                  alt="Close icon"
                  className="search__result__img"
                  data-cy={`proposal-search-results-close-${i}`}
                  onClick={() => deleteRecentSearch(search)}
                />
              )}
            </li>
            {i !== recentSearches?.length - 1 && (
              <div className="custom-tags-search__results__divider" />
            )}
          </React.Fragment>
        ))}
      </ul>
    </motion.aside>
  );
};
