import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

export const PhoneInput: React.FC = () => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<Client>();

  return (
    <FieldWrapper name="phone" errors={errors} labelId="inputs.phone.label">
      <input
        inputMode="tel"
        type="tel"
        className={`input input__med input__box ${
          errors.phone ? 'input__box__error' : ''
        }`}
        placeholder={formatMessage({ id: 'inputs.phone.placeholder' })}
        data-cy="client-phone-input"
        onFocus={handleFocus}
        {...register('phone')}
      />
    </FieldWrapper>
  );
};
