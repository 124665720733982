import { useProposalOverviewFilterOptions } from 'modules/proposals-overview';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { useIntl } from 'react-intl';

interface Props {
  onSetIsCalendarOpen?: (isOpen: boolean) => void;
}

export const ProposalOverviewDateOptions: React.FC<Props> = ({
  onSetIsCalendarOpen,
}) => {
  const { formatMessage } = useIntl();
  const { selectedDates, handleDateChange } =
    useProposalOverviewFilterOptions();

  function handleOpen() {
    onSetIsCalendarOpen?.(true);
  }

  function handleClose() {
    onSetIsCalendarOpen?.(false);
  }

  return (
    <Flatpickr
      data-enable-time
      options={{
        dateFormat: 'M d',
        enableTime: false,
        mode: 'range',
        inline:
          true /**Source: https://flatpickr.js.org/examples/#inline-calendar */,
        disableMobile: true,
      }}
      value={selectedDates || undefined}
      autoFocus
      onChange={handleDateChange}
      onOpen={handleOpen}
      onClose={handleClose}
      className="input input__med input__box"
      placeholder={formatMessage({
        id: 'datepickers.display-date.placeholder',
      })}
      data-cy="proposal-overview-filter-date-picker"
    />
  );
};
