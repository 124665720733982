import React from 'react';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '.';
import { Button } from '../button';
import { LoadingSpinner } from '../loading';

interface Props extends ConfirmationModalProps {
  loading?: boolean;
}

export const ConfirmationModal: React.FC<Props> = ({
  onClose,
  onSubmit,
  title,
  subtitle,
  confirmationButtonLabel,
  declineButtonLabel,
  loadingButtonLabel,
  type,
  loading,
}) => {
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();

  return (
    <Modal close={onClose} isDashboardModal>
      <ModalHeader isConfirmationModal>
        <p className="text--xl__responsive">{title}</p>
      </ModalHeader>
      <ModalContent>
        <p className="text--xs__responsive t-text-2">{subtitle}</p>
      </ModalContent>
      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            type="button"
            onClick={onClose}
            ref={closeButtonRef}
            style="outline"
            size="lrg"
            btnSelector="take-me-back-btn"
          >
            {declineButtonLabel}
          </Button>
          {loading ? (
            <Button
              type="button"
              size="lrg"
              disabled={loading}
              className={`${
                type === 'delete' ? 'btn__destructive' : 'btn__primary'
              }`}
            >
              <LoadingSpinner height={12} width={12} type="negative" />
              <p className="btn__icon__right">{loadingButtonLabel}</p>
            </Button>
          ) : (
            <Button
              type="submit"
              onClick={onSubmit}
              ref={submitButtonRef}
              size="lrg"
              className={`${
                type === 'delete' ? 'btn__destructive' : 'btn__primary'
              }`}
              btnSelector="modal-confirm-btn"
            >
              {confirmationButtonLabel}
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};
