import checkmarkIcon from 'assets/icons/icon-checkmark-copied-16.svg';
import clipboardIcon from 'assets/icons/icon-clipboard-16.svg';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Tooltip } from 'shared/components';

interface Props {
  content?: string;
}

export const AppSumoClipboardButton: React.FC<Props> = ({ content }) => {
  const [copied, setCopied] = useState(false);

  function copyToClipboard() {
    if (!content) return;
    navigator?.clipboard.writeText(content);
    setCopied(true);
  }

  useEffect(() => {
    if (!copied) return;

    const timeoutId = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [copied]);

  return (
    <div className="clipboard__container">
      <div className="f f--align-center clipboard__appsumo__code">
        <span className="text--xs t-text-1"> {content}</span>
        <Button type="button" className="btn__slim">
          <Tooltip
            onClick={copyToClipboard}
            iconPath={copied ? checkmarkIcon : clipboardIcon}
            messageId={
              copied
                ? 'settings.subscription.customer-id.copied'
                : 'settings.subscription.customer-id.tooltip'
            }
            iconClassName="clipboard"
            tooltipClassName={`tooltip ${copied && 'tooltip--copied'}`}
          />
        </Button>
      </div>
      <span className="text--xxs__responsive t-text-3 u-width--full">
        <FormattedMessage id="settings.subscription.appsumo-code-clipboard-description" />
      </span>
    </div>
  );
};
