import { PageProps } from 'gatsby';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AdminLayout } from '../components/admin-layout';
import { AdminUserForm } from '../components/admin-user-form';

export const AdminOverview: React.FC<PageProps> = () => {
  return (
    <>
      <AdminLayout>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="admin-rights-management-title"
        >
          <FormattedMessage id="admin.summary.rights.title" />
        </header>
        <div className="admin-dashboard__container">
          <div className="admin-dashboard__description">
            <p className="t-eta">
              <FormattedMessage id="admin.summary.rights.prerequisites" />
            </p>
            <p className="t-eta">
              <FormattedMessage id="admin.summary.rights.description-action" />
            </p>
          </div>
          <AdminUserForm />
        </div>
      </AdminLayout>
    </>
  );
};
