import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  footerSelector?: string;
  className?: string;
}

export const ModalFooter: React.FC<Props> = ({
  children,
  footerSelector,
  className,
}) => {
  return (
    <footer
      className={`modal__footer ${className || ''}`}
      data-cy={footerSelector || ''}
    >
      {children}
    </footer>
  );
};
