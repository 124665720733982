import outLinkIcon from 'assets/icons/icon-external-16.svg';
import editIcon from 'assets/icons/icon-pen-16.svg';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Icon from 'react-icons-kit';
import { unlink } from 'react-icons-kit/fa/unlink';
import { ensureHttpUrl } from 'shared/utils';

interface Props {
  index: number;
  element: LinkElement;
  attributes: {
    'data-slate-node': 'element';
    'data-slate-inline'?: true;
    'data-slate-void'?: true;
    dir?: 'rtl';
    ref: any;
  };
  onOpenEditModal: () => void;
  onHandleUnlink: () => void;
}

export const RichTextToolbarLinkTooltip: React.FC<Props> = ({
  index,
  element,
  attributes,
  onOpenEditModal,
  onHandleUnlink,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [root, setRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setRoot(
      document.getElementById(
        `global-tooltip-container-${index}-${element.url}-${element.children
          .map((child) => child.text)
          .join('')}`,
      ),
    );
  }, []);

  return (
    <>
      {root &&
        ReactDOM.createPortal(
          <div
            ref={tooltipRef}
            className="slate__link__tooltip"
            contentEditable={false}
          >
            <img
              src={outLinkIcon}
              alt="Out link icon"
              className="s-right--sml"
            />
            <a
              {...attributes}
              href={ensureHttpUrl(element.url)}
              rel="noreferrer"
              className="text--xs btn__text u-maxwidth--244__responsive t-ellipsis"
            >
              {element.url}
            </a>
            <div className="slate__link__tooltip__vertical__divider" />
            <div
              onClick={onOpenEditModal}
              className="slate__link__tooltip__icon__container"
            >
              <img src={editIcon} alt="Edit icon" className="c-pointer" />
            </div>
            <div
              onClick={onHandleUnlink}
              className="slate__link__tooltip__icon__container"
            >
              <Icon icon={unlink} className="c-pointer">
                {unlink}
              </Icon>
            </div>
          </div>,
          root,
        )}
    </>
  );
};
