import { PageProps } from 'gatsby';
import { clientSelector, isClientModalOpen } from 'modules/clients';
import { SettingsLayout } from 'modules/settings';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import {
  AddIcon,
  Button,
  CreateProposalButton,
  List,
  ListError,
  PageLoading,
  SEO,
  Sort,
} from 'shared/components';
import { useCloseModalOnCleanup } from 'shared/hooks';
import { applySort } from 'shared/utils';
import { ClientListError, ClientListItem, ClientModal } from '../components';

interface Props extends PageProps {
  proposalsLoading: boolean;
}

export const Clients: React.FC<Props> = ({ location, proposalsLoading }) => {
  const { formatMessage } = useIntl();

  // const [query, setQuery] = useState('');
  const [sortOption, setSortOption] = useState<SortOption>('newest');

  const [modalOpen, setModalOpen] = useRecoilState(isClientModalOpen);
  const { documents, isLoading, error } = useSelector(
    clientSelector.getClientsByCreationTime,
  );
  const clients = useMemo(() => {
    // const filteredClients = useFuseObjectSearch(['name'], query, documents);
    if (!documents) return [];
    return applySort(sortOption, documents);
  }, [documents, sortOption]);
  useCloseModalOnCleanup(setModalOpen);
  if (proposalsLoading) return <PageLoading isLoading={proposalsLoading} />;

  return (
    <>
      <SEO
        title={formatMessage({ id: 'clients.seo.title' })}
        description={formatMessage({ id: 'clients.seo.description' })}
        href={location.href}
      />
      <SettingsLayout isManageSection>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="clients-title"
        >
          <FormattedMessage id="clients.title" />
        </header>
        <div className="manage__header__action__container">
          <Button
            type="button"
            onClick={() => setModalOpen(true)}
            style="secondary"
            size="lrg"
            className="manage__header__action"
            btnSelector="add-client-btn"
          >
            <AddIcon />
            <span className="d--desktop--only">
              <FormattedMessage id="buttons.add-new-client" />
            </span>
          </Button>
          <CreateProposalButton className="d--desktop--only" />
        </div>

        <div className="dashboard__section__mobile__actions">
          <header className="text--xs t-bold" data-cy="clients-mobile-title">
            <FormattedMessage
              id="clients.mobile-title"
              values={{
                length: clients?.length,
              }}
            />
          </header>
          {Boolean(clients?.length) && (
            <div className="f f--align-center">
              <Button
                type="button"
                onClick={() => setModalOpen(true)}
                style="secondary"
                size="med"
                btnSelector="add-client-mobile-btn"
              >
                <span className="d--mobile--only">
                  <FormattedMessage id="buttons.add-new-client" />
                </span>
              </Button>
              <Sort sortOption={sortOption} onSetSortOption={setSortOption} />
            </div>
          )}
        </div>

        {/**TODO @markoboras0712 in new design we've removed search, check with design team why */}
        {/* <div className="manage__header__container" data-cy="clients-list">
          {Boolean(documents?.length) && (
            <div className="manage__actions__container">
              <ListSearch
                query={query}
                onSetQuery={setQuery}
                recentSearchLocalStorageKey={CLIENT_RECENT_SEARCHES_LS_KEY}
                selector="clients"
              />
            </div>
          )}
        </div> */}
        {Boolean(clients?.length) && (
          <div className="dashboard__section__actions dashboard__section__actions--manage">
            <span className="t-text-2 text--sm">
              <FormattedMessage
                id="clients.length"
                values={{
                  length: clients?.length,
                }}
              />
            </span>
            <div className="f">
              <p className="dashboard__section__sent__proposals__label text--xsm t-text-2">
                <FormattedMessage id="clients.sent-proposals" />
              </p>
              <Sort
                sortOption={sortOption}
                onSetSortOption={setSortOption}
                btnSelector="sort-clients"
              />
            </div>
          </div>
        )}
        <List
          documents={documents}
          results={clients}
          isLoading={isLoading}
          // query={query}
          error={error}
          itemComponent={ClientListItem}
          errorComponent={ClientListError}
          searchErrorComponent={ListError}
          loadingType="list"
          className="dashboard__section__list__container manage__list"
          errorClassName="dashboard__section__error__container"
          onClick={() => setModalOpen(true)}
        />
      </SettingsLayout>
      {modalOpen && <ClientModal onClose={() => setModalOpen(false)} />}
    </>
  );
};
