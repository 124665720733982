import iconWarning from 'assets/icons/icon-warning-24.svg';
import { useResendEmailVerificationLink } from 'modules/authentication';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  LoadingSpinner,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape } from 'shared/hooks';
import { toast } from 'sonner';

interface Props extends ModalProps {
  email: string;
}

export const EmailVerificationModal: React.FC<Props> = ({ email, onClose }) => {
  const [loading, setLoading] = useState(false);

  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const { resendEmailVerificationLink } = useResendEmailVerificationLink();

  async function handleResend() {
    try {
      setLoading(true);
      await resendEmailVerificationLink({ email });
      toast.success(
        'Verification link has been successfully sent. Please check your email.',
      );
      onClose();
    } catch {
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal>
      <ModalHeader className="f--align-center">
        <img
          src={iconWarning}
          className="btn__icon__left"
          alt="Email not verified icon"
        />
        <p className="account-delete-modal__header text--xl__responsive">
          <FormattedMessage id="settings.account.verification-modal.title" />
        </p>
      </ModalHeader>
      <ModalContent>
        <p className="text--xs__responsive u-maxwidth--432 t-text-2">
          <FormattedMessage id="settings.account.verification-modal.description" />
        </p>
      </ModalContent>
      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            size="lrg"
            style="outline"
            onClick={onClose}
            ref={closeButtonRef}
            btnSelector="cancel-btn"
          >
            <FormattedMessage id="account-settings.delete-modal.action.cancel" />
          </Button>
          {loading ? (
            <Button
              type="button"
              disabled={loading}
              size="lrg"
              style="secondary"
              className="u-fit--content"
            >
              <LoadingSpinner height={12} width={12} type="primary" />
              <p className="s-left--med">
                <FormattedMessage id="free-subscription.subscribe.api.loading" />
              </p>
            </Button>
          ) : (
            <Button
              size="lrg"
              style="secondary"
              type="submit"
              onClick={handleResend}
              btnSelector="info-modal-delete-account-btn"
            >
              <FormattedMessage id="settings.account.verification-modal.resend" />
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};
