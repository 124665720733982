import { StylesConfig } from 'react-select';

export type OptionType<T = any> = {
  label: string;
  value: T;
  id?: string;
};

export const getSelectStyles = <T extends object | string>(
  hasError?: boolean,
  controlMinWidth?: number,
): StylesConfig<OptionType<T>, false> => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? 'hsla(0, 0%, 18%, 0.04)'
      : 'hsl(0, 0%, 100%)',
    borderColor: state.isFocused ? '#706F6F' : hasError ? '#f9395c' : '#D9D9D9',
    ':hover': {
      borderColor: '#706F6F',
      transition: 'border-color 0.2s ease-out',
    },
    boxShadow: 'none',
    cursor: 'pointer',
    minHeight: '40px',
    minWidth: controlMinWidth ? `${controlMinWidth}px` : '148px',
    transition: 'all 0.2s ease-out',
    ...(hasError &&
      !state.isFocused && {
        borderColor: '#f9395c',
        transition: 'border-color 0.2s ease-out',
      }),
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'hsl(0, 0%, 20%)',
    maxWidth: 'calc(100% - 16px)',
    transition: 'all 0.2s ease-out',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'hsl(0, 0%, 95%)',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'hsl(0, 0%, 40%)',
    ':hover': {
      color: 'hsl(0, 0%, 40%)',
      transition: 'color 0.2s ease-out',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'hsl(0, 0%, 100%)',
    marginTop: '8px',
  }),
  menuList: (provided, { options }) => ({
    ...provided,
    ...(options?.length > 10
      ? { maxHeight: 'calc(8 * (1em + 24px))', overflowY: 'auto' }
      : {}),
    paddingBottom: '8px',
    paddingTop: '8px',
  }),
  option: (provided, state) => ({
    ...provided,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'hsl(0, 0%, 97%)',
      transition: 'all 0.2s ease-out',
    },
    backgroundColor:
      state.isSelected || state.isFocused ? 'hsl(0, 0%, 97%)' : 'transparent',
    color: state.isSelected ? 'hsl(0, 0%, 30%)' : 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    position: 'absolute',
    width: 'calc(100% - 37px)', // 37px is width of indicatorsContainer with divider
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    position: 'absolute',
    right: 0,
  }),
  placeholder: (provided) => ({ ...provided, color: '#a8a8a8' }),
});
