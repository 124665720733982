import { useProposalOverviewFilterOptions } from 'modules/proposals-overview';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useKeyPress, useListNavigation, useMobile } from 'shared/hooks';
import { ProposalOverviewFilterOption } from '../ProposalOverviewFilterOption';

interface Props {
  customTags: string[];
}

export const ProposalOverviewCustomTagsOptions: React.FC<Props> = ({
  customTags,
}) => {
  const isMobile = useMobile();
  const enterPress = useKeyPress('Enter');
  const { handleFilterChange } = useProposalOverviewFilterOptions();
  const { ref, cursor, setHovered } = useListNavigation<string>(customTags);

  /**Enter press */
  useEffect(() => {
    if (customTags?.length && enterPress) {
      handleFilterChange('tags', customTags[cursor]);
    }
  }, [enterPress]);

  return (
    <ul ref={ref} className="proposal-overview__filter__options">
      {customTags?.length ? (
        <>
          {customTags.map((tag, index) => (
            <React.Fragment key={tag}>
              <ProposalOverviewFilterOption
                type="tags"
                name={tag}
                label={tag}
                handleFilterChange={handleFilterChange}
                isActive={index === cursor}
                onMouseEnter={() => setHovered(tag)}
                onMouseLeave={() => setHovered(undefined)}
              />
              {index !== customTags?.length - 1 && isMobile && (
                <div className="proposal-overview__actions__divider" />
              )}
            </React.Fragment>
          ))}
        </>
      ) : (
        <span className="proposal__overview__filter__option__error text--sm">
          <FormattedMessage id="proposals.overview.filter.no-results" />
        </span>
      )}
    </ul>
  );
};
