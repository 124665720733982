import dragIcon from 'assets/icons/icon-drag-16-secondary.svg';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRequiredProposalFieldCheck } from 'shared/hooks';
import { MobileSegmentMover } from '../mobile-segment-mover';
import { SegmentActions } from '../segment-actions';
import { SegmentDeleteButton } from '../segment-delete-button';
import { SegmentPrices } from '../segment-prices';
import { ServiceDescriptionInput } from '../service-description-input';
import {
  DiscountInput,
  PricePerUnitInput,
  QuantityInput,
  ServiceSelect,
  UnitSelect,
} from './fragments';

interface Props {
  item: ServiceSegment;
  index: number;
  provided: DraggableProvided;
  context: UseFormReturn<PublishStatusAware<Proposal>, any, undefined>;
  segmentsLength: number;
  addSegment: (type: ProposalSegment['type'], index: number) => void;
  deleteSegment: (index: number) => void;
  moveItem(index: number, direction: 'up' | 'down'): void;
}

export const ServiceSegmentCard: React.FC<Props> = ({
  item,
  index,
  provided,
  segmentsLength,
  context,
  addSegment,
  deleteSegment,
  moveItem,
}) => {
  const { formatMessage } = useIntl();
  const { isFieldRequired } = useRequiredProposalFieldCheck();

  return (
    <>
      <div
        id={`service-segment-${index}`}
        className="proposal-service proposal-service--service"
      >
        <div
          {...provided.dragHandleProps}
          className="proposal-service__dragger d--desktop--only"
          data-cy={`drag-and-drop-btn-${index}`}
        >
          <img src={dragIcon} alt="DragIcon" />
        </div>
        <header className="proposal-service__header">
          <SegmentDeleteButton index={index} onDelete={deleteSegment} />
          <MobileSegmentMover
            index={index}
            moveItem={moveItem}
            segmentsLength={segmentsLength}
          />
          <div className="field">
            <ServiceSelect
              value={item.service}
              index={index}
              isFieldRequired={isFieldRequired}
              {...context}
            />
          </div>
        </header>

        <div className="proposal-service__layout">
          <div className="proposal-service__description">
            <ServiceDescriptionInput
              value={item.content}
              index={index}
              placeholder={formatMessage({
                id: 'inputs.service-description.placeholder',
              })}
              isFieldRequired={isFieldRequired}
              {...context}
            />
          </div>
          <div className="proposal-service__content">
            <div className="proposal-service__fields">
              <div className="field">
                <UnitSelect
                  value={item.unit}
                  index={index}
                  isFieldRequired={isFieldRequired}
                  {...context}
                />
              </div>
              <div className="field">
                <QuantityInput
                  value={item.quantity}
                  index={index}
                  {...context}
                />
              </div>
              <div className="field">
                <PricePerUnitInput
                  value={item.pricePerUnit}
                  index={index}
                  {...context}
                />
              </div>
              <div className="field">
                <DiscountInput
                  value={item.discount}
                  index={index}
                  {...context}
                />
              </div>
            </div>
            <div className="proposal-service__summary t-ellipsis">
              <SegmentPrices index={index} />
            </div>
          </div>
        </div>
      </div>

      <SegmentActions index={++index} onAdd={addSegment} />
    </>
  );
};
