import { useFormGuard } from 'modules/form';
import { REFERRALS_LIST } from 'modules/onboarding';
import React, { PropsWithChildren } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

interface Props extends PropsWithChildren {
  config: Settings;
  onSubmit: (data: Settings) => void;
}

export const ReferralStep: React.FC<Props> = ({
  config,
  onSubmit,
  children,
}) => {
  const form = useForm<Settings>({
    mode: 'onSubmit',
    defaultValues: { ...config },
  });
  useFormGuard(form);

  const referral = useWatch({ name: 'referral', control: form.control });

  function handleSelect(source: ReferralSource) {
    form.setValue('referral', source);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ul className="list--bullets list__item__bold list--2x2__responsive u-maxwidth--568__responsive">
          {REFERRALS_LIST.map(({ source, description }) => (
            <li
              onClick={() => handleSelect(source)}
              key={source}
              className="referral-step__option"
            >
              <input
                type="radio"
                name="referral"
                id={source}
                checked={referral === source}
                onChange={() => handleSelect(source)}
                className="input__radio"
                data-cy="referral-option"
              />
              <label className="text--xs t-text-1 t-normal" htmlFor={source}>
                {description}
              </label>
            </li>
          ))}
        </ul>
        {children}
      </form>
    </FormProvider>
  );
};
