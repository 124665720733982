import deleteIcon from 'assets/icons/icon-trash-12.svg';
import { useServices } from 'modules/services';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DeleteModal, ListItemActionDrawer } from 'shared/components';
import { useModal } from 'shared/hooks';
import { ServiceModal } from '../service-modal';

interface Props extends Service {
  index?: number;
}

export const ServiceListItem: React.FC<Props> = (props) => {
  const { deleteEntityAsync } = useServices();

  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal();

  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal,
  } = useModal();

  const removeItem = () => {
    if (props.id) deleteEntityAsync(props.id);
    closeDeleteModal();
  };

  const openDeleteModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    openModal();
    e.currentTarget.blur();
  };

  return (
    <>
      <div
        className="settings-itemlist__item"
        data-cy={`service-list-item-${props.index}`}
      >
        <Button
          type="button"
          onClick={openEditModal}
          className="d--ib t-ellipsis settings-itemlist__item__name text--xs__responsive t-text-1 t-bold"
        >
          {props.name}
        </Button>
        <div className="btn-group btn-group--simple settings-itemlist__item__actions">
          <Button
            onClick={openEditModal}
            style="outline"
            size="med"
            btnSelector={`edit-service-${props.index}`}
          >
            <FormattedMessage id="buttons.edit" />
          </Button>
          <Button
            onClick={openDeleteModal}
            style="ghost"
            size="med"
            btnSelector={`delete-service-${props.index}`}
          >
            <img src={deleteIcon} alt="DeleteIcon" />
          </Button>
        </div>
        <ListItemActionDrawer className="settings__actionbar__drawer">
          <Button
            type="button"
            className="actionbar__drawer__btn"
            onClick={openEditModal}
            btnSelector={`edit-client-${props.index}`}
          >
            <FormattedMessage id="buttons.edit" />
          </Button>
          <Button
            type="button"
            className="actionbar__drawer__btn t-danger btn__icon__fill"
            onClick={openDeleteModal}
            btnSelector={`delete-service-btn-${props.index}`}
          >
            <FormattedMessage id="buttons.delete" />
          </Button>
        </ListItemActionDrawer>
      </div>

      {isEditModalOpen && (
        <ServiceModal item={props} onClose={closeEditModal} />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          title={props.name}
          name="service"
          onClose={closeDeleteModal}
          onConfirm={removeItem}
          delSelector="delete-btn"
        />
      )}
    </>
  );
};
