import React from 'react';

export const SortCheckmarkIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className="btn__icon btn__icon__left"
      fill="none"
    >
      <path
        d="M1 9L5 13L15 3"
        stroke="#8039FF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
